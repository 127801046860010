import React from "react";
import "./updateemployeedependants.scss";
import { UpdateEmployeeDependantsTable } from "./UpdateEmployeesDependantsTable";

export const UpdateEmployeeDependants = () => {
  return (
    <>
      <UpdateEmployeeDependantsTable />
    </>
  );
};
