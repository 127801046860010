import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Leaves/leaves.scss";
import Chip from "@mui/material/Chip";
import { useFormik } from "formik";
import { Selectelement } from "../../components/Select_field/Selectelement";
import { Link, useNavigate } from "react-router-dom";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { AllLeavesTable } from './allLeavesTable'
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { RouteStrings } from "../../utils/common";

export const AdminLeaveCount = () => {
    const { userInfo, screenwidth } = useSelector((state) => state.UIStore);
    let email = userInfo ? userInfo.work_email : null
    const organization_name = userInfo ? userInfo.organization_name : null
    let userName = userInfo ? userInfo.full_name : null
    let employee_id = userInfo ? userInfo.emp_id : null
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { getRequest, postRequest } = PostRequestHook()
    const [holidays, setHolidays] = useState(false);
    const [dateArray, setDateArray] = useState([]);
    const [dateArray2, setDateArray2] = useState([]);
    const [startDate, setStartDate] = useState();
    const [dayError, setDayError] = useState(false);
    const [addleave, setAddleave] = useState(false);
    const [fieldData, setFieldData] = useState([]);

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [leaveTypeArray, setLeaveTypeArray] = useState([])
    const [superAdminModal, setSuperAdminModal] = useState(false)
    const [sendTo, setSendTo] = useState([])
    const [sendCc, setSendCc] = useState([])
    const [employeeGender, setEmployeeGender] = useState("")
    const [maternityMonth, setMaternityMonth] = useState(0);
    const [maternityLeave, setMaternityLeave] = useState({ start: "", end: "" })
    const [finacialYear, setFinancialYear] = useState({})
    const [filterValue, setFiltervalue] = useState({})

    let emptyday = [];

    useEffect(() => {
        getAdmins()
        getFinancialYear()
    }, [])
    useEffect(() => {
        getleaveTypes()
    }, [employeeGender])

    // get financial year
    const getFinancialYear = async () => {
        var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/get-financialyear`)
        setFinancialYear(response?.data?.data ? response?.data?.data?.[0] : {})
    }

    useEffect(() => {
        getEmployeeLeaves()
    }, [])
    const getEmployeeLeaves = async () => {
        var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/leave_details/${employee_id}`)
        // if (response) {
        setFieldData(response?.data?.data ? response?.data?.data : [])
        let filterData = _filterLeaveStatus(response?.data?.data ? response?.data?.data : [])
        setFiltervalue(filterData)
        // }
    }
    const _filterLeaveStatus = (data) => {
        const result = {};

        data.forEach((innerItem) => {
            const isApproved = innerItem.is_approved;

            if (!result[isApproved]) {
                result[isApproved] = [];
            }

            result[isApproved].push(innerItem);
        });
        return result
    }

    const getleaveTypes = async () => {
        if (userInfo.emp_id == "") {
            setSuperAdminModal(true)
        } else {
            var types = await getRequest(`${configUrl.getAllLeave}${organization_name}/all_leave_types`)
            var sortedLeaveType = (types?.data?.leaves_types ? types?.data?.leaves_types : []).filter(item => {
                if (employeeGender === "male" && item.type === "Maternity Leave") {
                    return false
                } else if (employeeGender === "female" && item.type === "Paternity Leave") {
                    return false;
                }
                return true;
            })
            setLeaveTypeArray(sortedLeaveType)
            const maternityObject = types?.data?.leaves_types.filter(item => item.id == 4)
            setMaternityMonth(maternityObject[0].total_leaves)
        }
    }
    const getAdmins = async () => {
        var response = await getRequest(`${configUrl.getEmailCredentials}${employee_id}`)
        if (response.status == 200) {
            let getdata = response?.data?.data
            setEmployeeGender(getdata.gender_name)
            var matrix_total = [...getdata.reporting_matrix_manager_information, ...getdata.emp_matrix_manager_information]
            let ccSend = {
                reportingperson: getdata.reporting_person_information,
                // matrixmanager: role == "super_admin" ? matrix_total : getdata.reporting_matrix_manager_information
                matrixmanager: getdata.emp_matrix_manager_information
                // matrixmanager: matrix_total
            }
            if (ccSend.reportingperson.length == 0 && ccSend.matrixmanager.length == 0) {
                snackBarContent(false, "You are not assigned to Reporting Person")
            } else if (ccSend.reportingperson.length == 0) {
                const [firstValue, ...remainingValues] = ccSend.matrixmanager
                sendTo.push(firstValue.email)
                if (remainingValues.length != 0) {
                    remainingValues.filter(item => {
                        sendCc.push(item.email)
                    })
                }
            } else {
                sendTo.push(ccSend.reportingperson[0].email)
                if (ccSend.matrixmanager.length != 0) {
                    ccSend.matrixmanager.filter(item => {
                        sendCc.push(item.email)
                    })
                }
            }
        }
    }


    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }


    const handleSwitchChange = (e) => {
        if (e == 1) {
            setStartDate();
            setDateArray(emptyday);
            setDateArray2(emptyday);
            setHolidays(false);
            formik.setFieldValue("isHalfDay", 0)
        } else if (e == 0) {
            setStartDate();
            setDateArray(emptyday);
            setDateArray2(emptyday);
            setHolidays(true);
            formik.setFieldValue("isHalfDay", 1)
        }
    };

    const handleHalfHoliday = (date) => {
        if (date) {
            setDayError(false);
            let item = [formatDate(date)];
            setDateArray2([date]);
            setDateArray(item)
            setStartDate(date);
        } else setStartDate();
    };

    useEffect(() => {
        if (dateArray.length < 1) {
            setStartDate()
        }
    }, [dateArray])
    const handleListHolidays = (date) => {
        if (date) {
            let selectedDate = formatDate(date)
            let items = [...dateArray];
            let items2 = [...dateArray2];
            if (!dateArray.includes(selectedDate)) {
                items.push(selectedDate);
                items.sort((date1, date2) => new Date(date1) - new Date(date2));
                items2.push(date);
                items2.sort((date1, date2) => new Date(date1) - new Date(date2));
            } else {
                const isSameNumber = (element) => element == selectedDate;
                let indexNumber = dateArray.findIndex(isSameNumber);
                items = items.filter((date) => date != selectedDate);
                items.sort((date1, date2) => new Date(date1) - new Date(date2));

                delete items2[indexNumber];

                items2 = items2.filter((el) => el != null);
                items2.sort((date1, date2) => new Date(date1) - new Date(date2));
            }
            setDayError(false);
            setDateArray(items);
            setDateArray2(items2);
            setStartDate(date)
        } else
            setStartDate();
    };

    const handleDelete = (e) => {
        if (dateArray2.length - 1 == 0) {
            setDayError(true);
            setStartDate();
        }
        let a = e.target.parentElement.getAttribute("id");

        const remData = (b) => {
            delete dateArray2[b];
            delete dateArray[b];
            let remData = dateArray.filter((el) => el != null);
            let remData2 = dateArray2.filter((el) => el != null);
            setDateArray2(remData2);
            setDateArray(remData);
        };
        if (a == null) {
            let b = e.target.parentElement.parentElement.getAttribute("id");
            remData(b);
        } else {
            remData(a);
        }
    };



    const postApplyLeave = async (data) => {
        var response = await postRequest(configUrl.applyleave, data)
        if (response.status == 201 || response.status == 200) {
            snackBarContent(true, response.data.message)
            setAddleave(false)
            setDateArray([])
            setDateArray2([])
            getEmployeeLeaves()
        } else if (response.response.data.status == 400 || response.response.data.status == 409 || response.response.status == 400) {
            snackBarContent(false, response.response.data.message)
        }
    }

    const formatDate = (dateString) => {
        var date = new Date(dateString);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
        return formattedDate
    }

    const handleMaternityError = (date) => {
        if (date) {
            var enddate = new Date(date.getFullYear(), date.getMonth() + Number(maternityMonth), date.getDate() - 1)
            let data = { start: formatDate(date), end: formatDate(enddate) }

            let insert = { ...maternityLeave, ...data }
            setMaternityLeave(insert)
            formik.values.from_date = data.start
            formik.errors.from_date = false
        } else {
            let data = { start: '', end: '' }

            let insert = { ...maternityLeave, ...data }
            setMaternityLeave(insert)
            formik.values.from_date = ''
            formik.errors.from_date = false
        }
    }

    const formik = useFormik({
        initialValues: {
            reason: "",
            leave_type: "",
            dates: dateArray2,
            from_date: maternityLeave.start,
            isHalfDay: ""
        },
        validate: (values) => {
            let errors = {};
            if (!values.reason) {
                errors.reason = "Required";
            }
            if (!values.leave_type) {
                errors.leave_type = "Required";
            }
            if (values.leave_type == 4) {
                if (!values.from_date) {
                    errors.from_date = "Select Start Date";
                }
            } else {
                if (dateArray2.length == 0) {
                    errors.dates = "Select Dates";
                }
            }
            return errors;
        },
        onSubmit: (value, { resetForm }) => {
            let halfday = holidays ? 1 : 0

            if (sendTo.length == 0) {
                snackBarContent(false, "You are not assigned to Reporting Person")
            } else {
                let _sendcc = sendCc.filter(item => item !== sendTo[0])
                const value2 = {
                    ...value, dates: dateArray.toString(), send_to_cc: [...new Set(_sendcc)].toString(), send_to: [...new Set(sendTo)].toString(),
                    is_approved: 0, emp_name: userName, isHalfDay: halfday, organization_name: organization_name,
                    emp_id: employee_id, leave_type: Number(value.leave_type),
                    no_of_days: formik.values.leave_type == 4 ? Number(maternityMonth) : (halfday == 1 ? Number(0.5) : dateArray2.length),
                };

                if (value.leave_type == 4) {
                    const value3 = { ...value2, to_date: maternityLeave.end }
                    delete value3.dates
                    postApplyLeave(value3)
                } else if (dateArray2.length !== 0) {
                    const value4 = { ...value2 }
                    delete value4.from_date
                    postApplyLeave(value4)
                }
            }
        },
    });

    const triggerError = () => {
        if (formik.values.leave_type !== 4) {
            if (dateArray2.length == 0) {
                setDayError(true);
            }
        }
    };

    const handleFocus = () => {
        if (formik.values.leave_type !== 4) {
            if (dateArray2.length == 0) {
                setDayError(true);
            }
        }
    };

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const _formatDate = (dateString) => {
        var date = new Date(dateString);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var formattedDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
        return formattedDate
    }

    return (
        <>
            <div className="leves_style px-md-2 px-lg-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="header_color">Leave Management</h3>
                    </div>
                </div>
                <div className="text-end">
                    <Link id="dashboardbtn" to="/" className="goback">
                        Back to Dashboard
                    </Link>
                    <Button
                        id="aplyleavebtn"
                        variant="primary"
                        onClick={() => {
                            if (sendTo.length == 0) {
                                snackBarContent(false, "You are not assigned to Reporting Person")
                            } else {
                                setAddleave(!addleave);
                                setDateArray(emptyday);
                                setDateArray2(emptyday);
                                setHolidays(false);
                                setStartDate()
                                formik.setFieldValue("isHalfDay", "")
                                formik.resetForm()
                                setMaternityLeave({ start: "", end: "" })
                            }
                        }}
                    >
                        {addleave ? "All leaves" : "Apply Leave"}
                    </Button>
                </div>

                <div>
                    {addleave ? (
                        <div className="leave_div">
                            <h4 className="mb-4 font_color">Apply for Leave</h4>
                            <Form onSubmit={formik.handleSubmit} autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Selectelement
                                            select_Label="Leave Type"
                                            name="leave_type"
                                            lableClass="font_color"
                                            handleBlur={formik.handleBlur}
                                            handleChange={(e) => {
                                                formik.handleChange(e)
                                                setDateArray([])
                                                setDateArray2([])
                                                setStartDate()
                                                // formik.setFieldValue("isHalfDay", "")
                                                setHolidays(false);
                                            }}
                                            optionArray={
                                                <>
                                                    {leaveTypeArray.length == 0 ?
                                                        null : <>
                                                            {leaveTypeArray.map((leave, i) => {
                                                                return (
                                                                    <option key={i} value={leave.id}>
                                                                        {leave.type}
                                                                    </option>
                                                                );
                                                            })}
                                                        </>}

                                                </>
                                            }
                                            formikValidation={
                                                formik.touched.leave_type && formik.errors.leave_type ? (
                                                    <small className="text-danger">
                                                        {formik.errors.leave_type}
                                                    </small>
                                                ) : null
                                            }
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label className="font_color">Reason for Leave</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Enter field"
                                                style={{ minHeight: "80px" }}
                                                name="reason"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.reason && formik.errors.reason ? (
                                                <small className="text-danger">
                                                    {formik.errors.reason}
                                                </small>
                                            ) : null}
                                        </Form.Group>
                                    </div>
                                    {formik.values.leave_type == 4 ? null : <div className="col-md-12 mt-2 mb-1">
                                        <div className="select_div">
                                            <button id="fullday" type="button" className={`me-2 ${holidays ? "slectedoption" : "full_day"}`}
                                                onClick={() => handleSwitchChange(1)}>Full day</button>
                                            <button id="halfday" type="button" onClick={() => handleSwitchChange(0)} className={holidays ? "full_day" : "slectedoption"}>Half Day</button>
                                        </div></div>}
                                    <div className={formik.values.leave_type == 4 ? "col-md-12" : "col-md-5 col-lg-4"}>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="font_color">
                                                Select leave Dates
                                            </Form.Label>

                                            {formik.values.leave_type == 4 ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <Form.Label className="font_color">
                                                                Start Date
                                                            </Form.Label>
                                                            <DatePicker
                                                                id="maternitystart"
                                                                selected={maternityLeave.start ? new Date(maternityLeave.start) : ""}
                                                                value={maternityLeave.start ? new Date(maternityLeave.start) : ""}
                                                                onChange={(date) => {
                                                                    handleMaternityError(date)
                                                                }}
                                                                onSelect={(date) => {
                                                                    handleMaternityError(date)
                                                                }}
                                                                selectsStart
                                                                startDate={maternityLeave.start ? new Date(maternityLeave.start) : ""}
                                                                // endDate={maternityLeave.end ? new Date(maternityLeave.end) : ""}
                                                                // minDate={new Date()}
                                                                className="setting_field"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-yyyy"
                                                            />
                                                            {formik.touched.from_date && formik.errors.from_date ? (
                                                                <small className="text-danger">
                                                                    {formik.errors.from_date}
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Form.Label className="font_color">
                                                                End Date
                                                            </Form.Label>
                                                            <DatePicker
                                                                id="maternityend"
                                                                selected={maternityLeave.end ? new Date(maternityLeave.end) : ""}
                                                                selectsEnd
                                                                className="setting_field"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-yyyy"
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    <DatePicker
                                                        id="leavedate"
                                                        shouldCloseOnSelect={false}
                                                        selected={startDate}
                                                        onSelect={holidays ? handleHalfHoliday : handleListHolidays}
                                                        onChange={(e) => {
                                                            if (e) setStartDate(e)
                                                            else setStartDate()
                                                        }}
                                                        highlightDates={dateArray2}
                                                        calendarClassName={
                                                            holidays
                                                                ? "datepicker-calendar halfday"
                                                                : " datepicker-calendar"
                                                        }
                                                        // minDate={new Date()}
                                                        className="setting_field"
                                                        // filterDate={isWeekday}
                                                        onClickOutside={handleFocus}
                                                        // onKeyDown={(e) => {
                                                        //     e.preventDefault();
                                                        // }}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="dd-mm-yyyy"
                                                    />
                                                    <div>
                                                        {dayError ? (
                                                            <small className="text-danger">Select Date</small>
                                                        ) : null}
                                                    </div>
                                                </>
                                            }
                                        </Form.Group>
                                    </div>
                                    {formik.values.leave_type == 4 ? null :
                                        <div className="col-md-7 col-lg-8 my-auto">
                                            <div>
                                                {/* <label className="font_color mb-2">{dateArray2.length == 0 ? null : ""}{" "}</label> */}
                                                <div className="chip_div">
                                                    {dateArray2.map((date, i) => {
                                                        return (
                                                            <Chip
                                                                key={date}
                                                                className="m-2"
                                                                color="primary"
                                                                id={i}
                                                                label={_formatDate(date)}
                                                                onDelete={handleDelete}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className="text-end mt-4">
                                    <Button type="submit" onClick={triggerError}>
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <>
                            <div className="mt-4">
                                <h5><span className="text-decoration-underline">Leave Year</span>  - {Object.keys(finacialYear).length > 0 && <>{_formatDate(finacialYear.financialYearStart)} to {_formatDate(finacialYear.financialYearEnd)}</>} </h5>
                                <div className="mt-4 p-2 p-md-4">
                                    <Tabs defaultActiveKey="tab1" id="my-tabs" className='leave_tab'>
                                        <Tab eventKey="tab1" title={screenwidth < 768 ? "Pending" : "Leaves for Approval"}>
                                            <div className="leavesTable">
                                                {filterValue?.[0]?.length > 0 ? <>
                                                    <AllLeavesTable Data={filterValue[0]} refreshData={getEmployeeLeaves} financialYear={finacialYear} />
                                                </> : <h5 className="mt-4">No Leaves Pending for Approval for this Leave Year </h5>}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="tab2" title={screenwidth < 768 ? "Approved" : "Approved Leaves"}>
                                            <div className="leavesTable">
                                                {filterValue?.[1]?.length > 0 ? <>
                                                    <AllLeavesTable Data={filterValue[1]} refreshData={getEmployeeLeaves} financialYear={finacialYear} />
                                                </> : <h5 className="mt-4">No Data Found for this Leave Year </h5>}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="tab3" title={screenwidth < 768 ? "Rejected" : "Rejected Leaves"}>
                                            <div className="leavesTable">
                                                {filterValue?.[2]?.length > 0 ? <>
                                                    <AllLeavesTable Data={filterValue[2]} refreshData={getEmployeeLeaves} financialYear={finacialYear} />
                                                </> : <h5 className="mt-4">No Data Found for this Leave Year </h5>}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </>
                        // <div className="leavesTable mt-5">
                        //     {fieldData.length > 0 ? <>
                        //         <h5><span className="text-decoration-underline">Leave Year</span>  - {Object.keys(finacialYear).length > 0 && <>{_formatDate(finacialYear.financialYearStart)} to {_formatDate(finacialYear.financialYearEnd)}</>} </h5>
                        //         <AllLeavesTable Data={fieldData} refreshData={getEmployeeLeaves} financialYear={finacialYear} />
                        //         {/* <AllLeavesList Data={allLeaves} financialYear={financialYear} refreshData={getLeaves} /> */}
                        //     </> : <h5>No Data Found for Leave Year {Object.keys(finacialYear).length > 0 && <>{_formatDate(finacialYear.financialYearStart)} to {_formatDate(finacialYear.financialYearEnd)}</>}</h5>}
                        // </div>
                        // <div className="leavesTable mt-5">
                        //     <AllLeavesTable Data={fieldData} refreshData={getEmployeeLeaves} financialYear={finacialYear} />
                        // </div>
                    )}
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

            <Modal backdrop="static" centered show={superAdminModal} onHide={() => setSuperAdminModal(false)}>
                <Modal.Body className="bg-light rounded">
                    <h5>Please update Your details</h5>
                    <div className="text-end mt-3">
                        <a className="btn btn-success" href={RouteStrings.userprofile}>Update Details</a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
