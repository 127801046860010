import React, { useEffect, useState } from 'react'
import './assignlevel.scss'
import { Button, Form, Table } from 'react-bootstrap'
import { Selectelement } from '../../components/Select_field/Selectelement'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { PostRequestHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { SnackBar } from '../../components/SnackBars/SnackBar'
import { Images } from '../../utils/images'
import Select from 'react-select';

export const AssignLevels = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [parentOption, setParentOption] = useState(null);
    const [hrOption, setHrOption] = useState(null)
    const [viewRole, setViewRole] = useState(false)

    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [success, setSuccess] = useState(false)

    const [levels, setLevels] = useState([])
    const [employeeArray, setEmployeeArray] = useState([])
    const [contactPerson, setContactPerson] = useState([])
    const [hrPerson, setHrPerson] = useState([])

    const [icon, setIcon] = useState()

    const { getRequest, postRequest, putRequest, deleteRequest } = PostRequestHook()
    useEffect(() => {
        getLeveldata()
        getEmployeelist()
        getHrList()
    }, [])
    const deleteIcon = []

    const getLeveldata = async () => {
        var levelList = await getRequest(configUrl.getAllLevels)
        if (levelList) {
            setLevels(levelList?.data?.message ? levelList?.data?.message : [])
            if (levelList.data.message.length != 0) {
                let test = levelList.data.message
                test.filter(item => deleteIcon.push(item.id))
                const max = Math.max(...deleteIcon.map(Number));
                setIcon(max);
            }
        }
    }

    const getEmployeelist = async () => {
        var employeeData = await getRequest(configUrl.employeelist)
        var converted_data = Array.converterid(employeeData?.data?.data ? employeeData?.data?.data : [])
        setEmployeeArray(converted_data)
    }

    const getHrList = async () => {
        var hrlist = await getRequest(configUrl.getHrList)
        if (hrlist) {
            var converted_data = Array.convertListHr(hrlist?.data?.data ? hrlist?.data?.data : [])
            setHrPerson(converted_data)
        }
    }

    const formik = useFormik({
        initialValues: {
            employee_id: selectedOption,
            level: "",
            parent: "",
            reporting_hr: ""
        },
        validate: (values) => {
            let errors = {}
            if (!values.employee_id) {
                errors.employee_id = "select employee Id"
            }
            if (!values.level) {
                errors.level = "select level"
            }
            if (!values.parent && values.level !== "1") {
                errors.parent = "select reporting person"
            }
            if (!values.reporting_hr && values.level !== "1") {
                errors.reporting_hr = "select reporting HR"
            }
            return errors
        },
        onSubmit: (value, { resetForm }) => {

            // var parentArr = []
            // if (value.parent != "") {
            //     value.parent.forEach((item) => {
            //         parentArr.push(item.value)
            //     })
            // }
            var value2 = { ...value, level: Number(value.level) }
            assignLevelEmployee(value2, resetForm)
        }
    })

    const assignLevelEmployee = async (data, resetForm) => {
        const response = await postRequest(configUrl.assignLevels, data)
        if (response.status == 201 || response.status == 200) {
            resetForm()
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            setSelectedOption(null)
            setParentOption(null)
            setHrOption(null)
        } else if (response.response.data.status == 400 || response.response.data.status == 409) {
            setSuccess(false)
            setSnackMessage(response.response.data.message)
            setSnackOpen(true)
        }
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handlecontactPersondropdown = async (e) => {
        var parentsData = await getRequest(`${configUrl.getParentsData}${e.target.value}`)
        if (parentsData) {
            var converted_data = Array.converterid(parentsData?.data?.data ? parentsData?.data?.data : [])
            setContactPerson(converted_data)
            formik.setFieldValue("parent", "")
        }
    }

    const handleDeleteLevel = async (e) => {
        var deletelevel = await deleteRequest(`${configUrl.deleteLevel}${e}`)
        if (deletelevel.status == 200) {
            getLeveldata()
            setSuccess(true)
            setSnackMessage(deletelevel.data.message)
            setSnackOpen(true)
        } else if (deletelevel.response.data.status == 400) {
            setSuccess(false)
            setSnackMessage(deletelevel.response.data.message)
            setSnackOpen(true)
        }
    }

    const handleAddLevel = async () => {
        var addLevel = await postRequest(configUrl.addLevel)
        if (addLevel) {
            getLeveldata()
            setSuccess(true)
            setSnackMessage(addLevel.data.message)
            setSnackOpen(true)
        }
    }

    return (
        <>
            <div className="createrole_style">
                <div className="d-flex justify-content-between mb-4">
                    <div><h3>{viewRole ? "Create Levels" : "Assign Levels"}</h3></div>
                    <div className='text-end'>
                        <Link to="/" className="goback">Back to Dashboard</Link>
                        <Button type='button' onClick={viewRole ? () => setViewRole(false) : () => setViewRole(true)}>{viewRole ? "Assign Levels" : "Create Levels"}</Button>
                    </div>
                </div>
                <div className='mt-5'>
                    {viewRole ?
                        <div>
                            <Table striped bordered hover className='table_assignrole'>
                                <thead>
                                    <tr>
                                        <th>Sl no.</th>
                                        <th>Level</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {levels.length == 0 ? null : <>
                                        {levels.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.type}</td>
                                                    <td>{item.id == icon ? <img src={Images.deleteLogo} id={item.id} alt="icon" className='mx-2' onClick={(e) => handleDeleteLevel(e.target.id)} /> : null}</td>
                                                </tr>
                                            )
                                        })}
                                    </>}
                                </tbody>
                            </Table>

                            <div className='mt-4 text-end pe-3'>
                                <button className='btn btn-primary' onClick={handleAddLevel}>Add Level</button>
                            </div>
                        </div> :
                        <div className='formforgot_width'>
                            <Form onSubmit={formik.handleSubmit}>
                                <div className='mb-4'>
                                    <label className='font_color mb-2'>Employee Name</label>
                                    <Select
                                        onBlur={formik.handleBlur}
                                        onChange={(item) => {
                                            setSelectedOption(item);
                                            formik.setFieldValue("employee_id", item.value)
                                        }}
                                        options={employeeArray}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={selectedOption}
                                    />
                                    {formik.touched.employee_id && formik.errors.employee_id ? <small className='text-danger'>{formik.errors.employee_id}</small> : null}
                                </div>
                                <Selectelement select_Label="Level" lableClass="font_color" name="level"
                                    handleChange={(e) => {
                                        formik.handleChange(e)
                                        handlecontactPersondropdown(e)
                                    }}
                                    handleBlur={formik.handleBlur}
                                    value={formik.values.level}
                                    optionArray={levels.length == 0 ? null : <>
                                        {levels.map((item, i) => {
                                            return (
                                                <option key={i} value={item.id}>
                                                    {item.type}
                                                </option>
                                            );
                                        })}
                                    </>}
                                    formikValidation={formik.touched.level && formik.errors.level ? <small className='text-danger'>{formik.errors.level}</small> : null}
                                />
                                <div className='mb-4'>
                                    <label className='font_color mb-2'>Reporting Person</label>
                                    <Select
                                        onBlur={formik.handleBlur}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue("parent", selectedOption.value)
                                            setParentOption(selectedOption)
                                        }}
                                        // isMulti
                                        options={contactPerson}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={parentOption}
                                        isDisabled={formik.values.level == "1" ? true : false}
                                    />
                                    {formik.touched.parent && formik.errors.parent ? <small className='text-danger'>{formik.errors.parent}</small> : null}
                                </div>

                                <div className='mb-4'>
                                    <label className='font_color mb-2'>Reporting HR</label>
                                    <Select
                                        onBlur={formik.handleBlur}
                                        onChange={(item) => {
                                            setHrOption(item)
                                            formik.setFieldValue("reporting_hr", item.value)
                                        }}
                                        options={hrPerson}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={hrOption}

                                    />
                                    {formik.touched.reporting_hr && formik.errors.reporting_hr ? <small className='text-danger'>{formik.errors.reporting_hr}</small> : null}
                                </div>
                                <div className='text-end mt-4'><Button type="submit">Submit</Button></div>
                            </Form>
                        </div>
                    }
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    )
}
