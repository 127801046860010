import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useDispatch, useSelector } from "react-redux";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { DynamicProductTable } from "./DynamicProductTable";

export const InventoryDashboard = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { getRequest, postRequest } = PostRequestHook()
    // const [show, setShow] = useState(false);
    const [fieldData, setFieldData] = useState([]);
    // const [viewStatus, setViewStatus] = useState([]);

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")


    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }

    useEffect(() => {
        getProductStatus()
    }, [])
    const getProductStatus = async () => {
        var response = await getRequest(configUrl.getProductStatus)
        if (response.status == 200 || response.status == 201) {
            getTableData(response?.data?.payload)

        }

    }
    const getTableData = async (headertable) => {
        // console.log("🚀 ~ file: Dashboard.js:51 ~ getTableData ~ headertable:", headertable)
        let response = await getRequest(configUrl.getStockList)
        segregateData((response?.data?.payload ? response?.data?.payload : []), headertable)
    }
    const segregateData = async (data, headertable) => {
        var segregatedData = {};
        data.forEach((item) => {
            if (!segregatedData.hasOwnProperty(item.productName)) {
                segregatedData[item.productName] = {};
            }

            const productName = item.productName;
            if (!segregatedData[productName].hasOwnProperty(item.productStatusName)) {
                segregatedData[productName][item.productStatusName] = [];
            }

            segregatedData[productName][item.productStatusName].push(item);
        });
        const productStatusNamesData = headertable

        // Connect additional productStatusName data with existing data for each product name
        const resultData = Object.keys(segregatedData).map((productName) => {
            const productData = segregatedData[productName];
            const productStatusData = productStatusNamesData.map((statusData) => {
                const productStatusName = statusData.productStatusName;
                if (productData.hasOwnProperty(productStatusName)) {
                    return {
                        productStatusName,
                        data: productData[productStatusName],
                    };
                } else {
                    return {
                        productStatusName,
                        data: [],
                    };
                }
            });

            return {
                productName,
                productStatusData,
            };
        });
        setFieldData(resultData)
    }

    // const handleClose = () => {
    //     setShow(false);
    //     setViewStatus([])
    // };

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };
    const navigate = useNavigate()

    return (
        <>
            <div className="leves_style px-md-2 px-md-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="header_color">Inventory Dashboard</h3>
                    </div>
                </div>
                <div className="text-end">
                    <button className="btn btn-primary" onClick={() => navigate("/stocklist")}>Total Stock List</button>
                </div>
                {fieldData.length > 0 &&
                    <div className="text-center mt-3">
                        <div className="table_scroll">
                            <DynamicProductTable data={fieldData} />
                        </div>
                    </div>}
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

            {/* <Modal show={show} onHide={handleClose}>

                {viewStatus.map(item => {
                    return (
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>Leave Application Status</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modalBody pb-3">
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Leave Type:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.leave_type_name}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">leave Days:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.leave_type == 4 ? `${item.no_of_days} months` : item.no_of_days}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Leave Dates:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{(item.dates).replace(/,/g, ", ")}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Reason for Leave:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.reason}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Leave Status:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.is_approved == 1 ? "Approved" : "Rejected"}</p>
                                        </div>
                                    </div>
                                    {item.is_approved == 2 ?
                                        <div className="row my-2">
                                            <div className="col-6 text-end">
                                                <p className="mb-0 font_color">Reason to reject:</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-0 font_color">{item.reason_to_reject}</p>
                                            </div>
                                        </div> : null}
                                </div>
                            </Modal.Body>
                        </>
                    )
                })}
            </Modal > */}
        </>
    );
};
