import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  useTable,
  usePagination,
  useFilters,
} from "react-table";
import "../../style/tableStyle.scss";
import { GlobalFilter } from "../../utils/GlobalFilter";
import { ColumnFilter } from "../../utils/ColumnFilter";
import Modal from "react-bootstrap/Modal";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { TableNavigation } from "../../utils/TableNavigation";
import { Images } from "../../utils/images";

export const AllLeavesTable = (props) => {
  const { Data, refreshData, financialYear } = props

  const [show, setShow] = useState(false);
  const [fieldData, setFieldData] = useState([]);
  const [viewStatus, setViewStatus] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [snackMessage, setSnackMessage] = useState("")
  const [reload, setReload] = useState(false)

  const { deleteRequest, getRequest } = PostRequestHook()
  const { userInfo } = useSelector((state) => state.UIStore);
  const organization_name = userInfo ? userInfo.organization_name : null
  const employee_id = userInfo ? userInfo.emp_id : null

  // useEffect(() => {
  //   getAdminLeaves()
  // }, [])
  // const getAdminLeaves = async () => {
  //   var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/leave_details/${employee_id}`)
  //   if (response) {
  //     setFieldData(response?.data?.data ? response?.data?.data : [])
  //   }
  // }

  const handleShow = (data) => {
    setViewStatus([data])
    setShow(true)
  };
  const handleCancel = (data) => {
    deleteLeave(data)
  };

  const deleteLeave = async (data) => {
    var response = await deleteRequest(`${configUrl.getAllLeave}${organization_name}/cancel_leave/${data.id}`)
    if (response) {
      if (response.status == 201 || response.status == 200) {
        setSuccess(true)
        setSnackMessage(response.data.message)
        setSnackOpen(true)
        refreshData()
      }
    }
  }

  const handleClose = () => {
    setShow(false);
    setViewStatus([])
  };

  const handlesnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };


  const columns = useMemo(
    () => [
      {
        Header: "Leave Type",
        accessor: "leave_type_name",
        Filter: ColumnFilter,
      },
      {
        Header: "Leave Dates",
        accessor: "dates",
        Filter: ColumnFilter,
        Cell: (cellProps) => {
          let result = [];
          const dateList = cellProps.cell.row.original.dates
          if (cellProps.cell.row.original.leave_type !== 4) {
            const dates = dateList.split(",");


            for (let i = 0; i < dates.length; i++) {
              const currentDate = new Date(dates[i]);
              const nextDate = new Date(dates[i + 1]);


              if (i < 3) {
                result.push(formatDate(dates[i]));
              }


              if (nextDate - currentDate !== 86400000) {
                break;
              }
            }

            if (result.length < dates.length) {
              result.push("...");
            }
          } else {
            const newDateRange = maternityDateFormat(dateList)
            result.push(newDateRange)
          }
          return <span>{result.join(", ")}</span>
        },
      },
      {
        Header: "No of days",
        accessor: "no_of_days",
        Filter: ColumnFilter,
        Cell: (cellProps) => {
          return <span>{cellProps.cell.row.original.leave_type == 4 ? `${cellProps.cell.row.original.no_of_days} months` :
            `${cellProps.cell.row.original.no_of_days == 0.5 ? "Half Day" : cellProps.cell.row.original.no_of_days}`}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "is_approved",
        Filter: ColumnFilter,
        Cell: (cellProps) => {
          return <span>{cellProps.cell.row.original.is_approved == 0 ? "Pending" :
            (cellProps.cell.row.original.is_approved == 1 ? "Approved" : "Rejected")}</span>;
        },
      },
      {
        Header: "Approval By",
        accessor: "action_taken_by_name",
        Filter: ColumnFilter,
        Cell: (cellProps) => {
          return <span>
            {cellProps.cell.row.original.action_taken_by_name ? `${cellProps.cell.row.original.action_taken_by_id} - ${cellProps.cell.row.original.action_taken_by_name}` : "---"}
          </span>
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: ColumnFilter,
        Cell: (cellProps) => (
          <div>
            <button
              id="viewbtn"
              className="btn btn-transparent p-0 mx-2 border-0"
              onClick={() => handleShow(cellProps.cell.row.original)}
            >
              <img src={Images.viewIcon} alt="icon" />
            </button>
            {cellProps.cell.row.original.is_approved === 0 ? (
              <>
                <button
                  id="cancelbtn"
                  className="btn btn-transparent p-0 mx-2 border-0"
                  onClick={() => handleCancel(cellProps.cell.row.original)}>
                  <img src={Images.deleteLogo} alt="icon" />
                </button>
              </>
            ) : null}
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => Data, [Data]);

  // create a table instance
  const {
    tableInstance,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  const { globalFilter, pageSize, pageIndex } = state;


  // date format change
  const formatDate = (dateString) => {
    var date = new Date(dateString);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    var formattedDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
    return formattedDate
  }

  // change the date format 
  const maternityDateFormat = (dateList) => {
    const [startDate, endDate] = dateList.split(" to ");
    const newDateRange = `${formatDate(startDate)} to ${formatDate(endDate)}`;
    return newDateRange
  }

  // change the otherleaveDatesFormat
  const othersDateFormat = (originalDateRange) => {
    const dateArray = originalDateRange.split(",");

    const newDateArray = dateArray.map((date) => {
      return `${formatDate(date)}`;
    });

    const newDateRange = newDateArray.join(", ");
    return newDateRange
  }

  return (
    <>
      <div className="mt-5 mb-3 d-flex justify-content-between">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

        <div>
          <select
            id="tablenumber"
            value={pageSize}
            className="selectTag w-auto"
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            <option value="" disabled>
              Select
            </option>
            {[5, 10, 15, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="text-center mt-3">
        <div className="table_scroll">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <div className="d-flex align-items-center mb-3">
          <div className="w-100 text-center mt-3 mb-1">
            <button
              className="mx-1 skipToBtn"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="mx-1 actionBtn"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
            <span className="mx-2 pageNumber">
              <strong>{pageIndex + 1}</strong>{" "}
            </span>
            <button
              className="mx-1 actionBtn"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="skipToBtn"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div> */}
        {Data.length > 0 ? <div className="text-center">
          <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
            nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
        </div> : <h5>No Data Found for Financial year {Object.keys(financialYear).length > 0 && <>{formatDate(financialYear.financialYearStart)} to {formatDate(financialYear.financialYearEnd)}</>}</h5>}
      </div>

      <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
        snackbg={success} message={snackMessage} />

      {/* Modal for the view field data */}
      <Modal show={show} onHide={handleClose}>

        {viewStatus.map(item => {
          return (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Leave Application Status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="modalBody pb-3">
                  <div className="row my-2">
                    <div className="col-5 col-md-4 text-end">
                      <p className="mb-0 font_color">Leave Type:</p>
                    </div>
                    <div className="col-7 col-md-8">
                      <p className="mb-0 font_color">{item.leave_type_name}</p>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-5 col-md-4 text-end">
                      <p className="mb-0 font_color">leave Days:</p>
                    </div>
                    <div className="col-7 col-md-8">
                      <p className="mb-0 font_color">{item.leave_type == 4 ? `${item.no_of_days} months` : item.no_of_days}</p>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-5 col-md-4 text-end">
                      <p className="mb-0 font_color">Leave Dates:</p>
                    </div>
                    <div className="col-7 col-md-8">
                      <p className="mb-0 font_color">{item.leave_type == 4 ? maternityDateFormat(item.dates) : othersDateFormat(item.dates)}</p>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-5 col-md-4  text-end">
                      <p className="mb-0 font_color">Reason for Leave:</p>
                    </div>
                    <div className="col-7 col-md-8">
                      <p className="mb-0 font_color word_wrap">{item.reason}</p>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-5 col-md-4  text-end">
                      <p className="mb-0 font_color">Leave Status:</p>
                    </div>
                    <div className="col-7 col-md-8">
                      <p className="mb-0 font_color">{item.is_approved == 1 ? "Approved" : (item.is_approved == 0 ? "Pending" : "Rejected")}</p>
                    </div>
                  </div>
                  {item.is_approved == 2 ?
                    <div className="row my-2">
                      <div className="col-5 col-md-4  text-end">
                        <p className="mb-0 font_color">Reason to reject:</p>
                      </div>
                      <div className="col-7 col-md-8">
                        <p className="mb-0 font_color word_wrap">{item.reason_to_reject}</p>
                      </div>
                    </div> : null}
                </div>
              </Modal.Body>
            </>
          )
        })}
      </Modal >
    </>
  );
};
