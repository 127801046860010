import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./leaves.scss";
import { Link } from "react-router-dom";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { AllLeavesList } from "./AllLeavesList";
import * as XLSX from 'xlsx';
import { CSVLink, CSVDownload } from "react-csv";
import { Tab, Tabs } from "react-bootstrap";

export const Leaves = () => {
  const { userInfo, screenwidth } = useSelector((state) => state.UIStore);
  const organization_name = userInfo ? userInfo.organization_name : null
  let employee_id = userInfo ? userInfo.emp_id : null
  let email_id = userInfo ? userInfo.work_email : null
  let role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

  const { getRequest, postRequest } = PostRequestHook()

  const [allLeaves, setAllLeaves] = useState([]);
  const [financialYear, setFinancialYear] = useState({})


  useEffect(() => {
    getLeaves()
    getFinancialYear()
  }, [])

  // get finacial year
  const getFinancialYear = async () => {
    var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/get-financialyear`)
    setFinancialYear(response?.data?.data ? response?.data?.data?.[0] : {})
  }


  const filterLeaveStatus = (data) => {
    const result = {};

    data.forEach((item) => {
      item.data.forEach((innerItem) => {
        const isApproved = innerItem.is_approved;

        if (!result[isApproved]) {
          result[isApproved] = [];
        }

        result[isApproved].push({ ...innerItem, emp_id: item.emp_id, emp_name: item.emp_name, gender_name: item.gender_name });
      });
    });

    return result
  }

  const _filterLeaveStatus = (data) => {
    const result = {};

    data.forEach((innerItem) => {
      const isApproved = innerItem.is_approved;

      if (!result[isApproved]) {
        result[isApproved] = [];
      }

      result[isApproved].push(innerItem);
    });
    return result
  }

  const [filterValue, setFiltervalue] = useState({})

  const getLeaves = async () => {
    var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/all_leaves`)
    if (response) {

      var respectiveLeadsLeaveaData = []
      if (response?.data?.data !== undefined) {
        if (role == "super_admin" || role == "hr") {
          const adminLeaves = response?.data?.data.filter(item => item.emp_id != employee_id)
          const _filterData = filterLeaveStatus(adminLeaves)
          let result = {
            0: separateObjectsByProperties(_filterData?.[0] || [], ['emp_id', 'emp_name', 'gender_name']),
            1: separateObjectsByProperties(_filterData?.[1] || [], ['emp_id', 'emp_name', 'gender_name']),
            2: separateObjectsByProperties(_filterData?.[2] || [], ['emp_id', 'emp_name', 'gender_name']),
          }
          setFiltervalue(result)
          setAllLeaves(adminLeaves)
        } else {
          const adminLeaves = response?.data?.data.filter(item => item.emp_id != employee_id)
          adminLeaves.filter(item => {
            item.data.filter(value => {
              var sendmail = [value.send_to, value.send_to_cc]
              var mailTrigger = sendmail.flatMap(str => str.split(','))

              if (mailTrigger.includes(email_id)) {
                var value2 = { ...value, emp_id: item.emp_id, emp_name: item.emp_name }
                respectiveLeadsLeaveaData.push(value2)
              }
            })
          })
          let _filterData = _filterLeaveStatus(respectiveLeadsLeaveaData)
          let _result = {
            0: separateObjectsByProperties(_filterData?.[0] || [], ['emp_id', 'emp_name', 'gender_name']),
            1: separateObjectsByProperties(_filterData?.[1] || [], ['emp_id', 'emp_name', 'gender_name']),
            2: separateObjectsByProperties(_filterData?.[2] || [], ['emp_id', 'emp_name', 'gender_name']),
          }
          setFiltervalue(_result)
          var result = separateObjectsByProperties(respectiveLeadsLeaveaData, ['emp_id', 'emp_name', 'gender_name']);

          setAllLeaves(result)
        }
      }
    }
  }

  const separateObjectsByProperties = (arr, properties) => {
    return Object.values(arr.reduce((result, obj) => {
      const propValues = properties.map(property => obj[property]);
      const key = propValues.join('-');

      if (result[key]) {
        result[key].data.push(obj);
      } else {
        const commonValues = properties.reduce((commonObj, property, index) => {
          commonObj[property] = propValues[index];
          return commonObj;
        }, {});

        result[key] = {
          ...commonValues,
          data: [obj]
        };
      }

      return result;
    }, {}));
  }

  // Download data to the Excel

  const leaveData = allLeaves.map(item => {
    const leave_type_name = item?.data?.map(innerItem => innerItem?.leave_type_name).join('\n');
    const dates = item?.data?.map(innerItem => innerItem?.dates).join('\n');
    const no_of_days = (item?.data?.map(innerItem => innerItem.isHalfDay == 1 ? "Half Day" : (innerItem.leave_type == 4 ? `${innerItem.no_of_days} months` : innerItem.no_of_days)).join('\n'));
    const action_taken_by_name = item?.data?.map(innerItem => innerItem?.action_taken_by_name).join('\n');
    // const status = item?.data?.map(innerItem => innerItem?.is_approved == 1 ? "Approved" : "Rejected").join('\n');
    const status = item?.data?.map(innerItem => innerItem.is_approved == 0 ? "New" : (innerItem.is_approved == 1 ? "Approved" : "Rejected")).join('\n');
    return {
      "emp_id*": item.emp_id,
      "emp_name*": item.emp_name,
      "Leave Type*": leave_type_name,
      "Leave Dates*": dates,
      "No Of Days*": no_of_days,
      "Approval By*": action_taken_by_name,
      "Status*": status
    };
  });

  // date format change
  const formatDate = (dateString) => {
    var date = new Date(dateString);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    var formattedDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
    return formattedDate
  }

  return (
    <>
      <div className="leves_style px-md-2 px-lg-4">
        <div className="d-flex justify-content-between mt-4">
          <div>
            <h3 className="header_color">Employee Leave Management</h3>
          </div>
        </div>
        <div className="text-end">
          <Link id="dashboardbtn" to="/" className="goback">
            Back to Dashboard
          </Link>
        </div>


        <div>
          {allLeaves.length > 0 &&
            <div className="text-end">
              <CSVLink data={leaveData} filename={"Leave Attendance"} separator="," className="btn btn-success" >
                Download
              </CSVLink>
            </div>}
          <div className="mt-4">
            <h5><span className="text-decoration-underline">Leave Year</span>  - {Object.keys(financialYear).length > 0 && <>{formatDate(financialYear.financialYearStart)} to {formatDate(financialYear.financialYearEnd)}</>} </h5>
            <div className="mt-4 p-2 p-md-4">
              <Tabs defaultActiveKey="tab1" id="my-tabs" className='leave_tab'>
                <Tab eventKey="tab1" title={screenwidth < 768 ? "Pending" : "Leaves for Approval"}>
                  <div className="leavesTable">
                    {filterValue?.[0]?.length > 0 ? <>
                      <AllLeavesList Data={filterValue[0]} financialYear={financialYear} refreshData={getLeaves} />
                    </> : <h5 className="mt-4">No Leaves Pending for Approval for this Leave Year </h5>}
                  </div>
                </Tab>
                <Tab eventKey="tab2" title={screenwidth < 768 ? "Approved" : "Approved Leaves"}>
                  <div className="leavesTable">
                    {filterValue?.[1]?.length > 0 ? <>
                      <AllLeavesList Data={filterValue[1]} financialYear={financialYear} refreshData={getLeaves} />
                    </> : <h5 className="mt-4">No Data Found for this Leave Year </h5>}
                  </div>
                </Tab>
                <Tab eventKey="tab3" title={screenwidth < 768 ? "Rejected" : "Rejected Leaves"}>
                  <div className="leavesTable mt-4">
                    {filterValue?.[2]?.length > 0 ? <>
                      <AllLeavesList Data={filterValue[2]} financialYear={financialYear} refreshData={getLeaves} />
                    </> : <h5 className="mt-4">No Data Found for this Leave Year </h5>}
                  </div>
                </Tab>
              </Tabs>
            </div>
            {/* <AllLeavesList Data={allLeaves} financialYear={financialYear} refreshData={getLeaves} /> */}

          </div>
        </div>
      </div >
    </>
  );
};
