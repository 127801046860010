import React from 'react'
import { Form } from 'react-bootstrap'
import './selectelement.scss'

export const Selectelement = (props) => {
    const { lableClass, select_Label, name, handleBlur, handleChange, value, disabled, optionArray, defaultValue, formikValidation } = props
    return (
        <Form.Group className="mb-4">
            <Form.Label className={lableClass}>{select_Label}</Form.Label>
            <Form.Select
                name={name}
                onBlur={handleBlur}
                onChange={handleChange}
                value={value}
                selected={value}
                disabled={disabled}
                placeholder={value}
            >
                {defaultValue ? <option value="" disabled selected>{value}</option> : <option value="" disabled selected>Select</option>}
                {optionArray}
            </Form.Select>
            {formikValidation}
        </Form.Group>
    )
}
