import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTable } from 'react-table';

export const DynamicProductTable = ({ data }) => {
    const [show, setShow] = useState(false);
    const [fieldData, setFieldData] = useState([]);
    const [viewStatus, setViewStatus] = useState([]);
    const [serviceSegregatedData, setServiceSegregatedData] = useState({
        insideServiceItems: [],
        outsideServiceItems: []
    });

    const handleClose = () => {
        setShow(false);
        setViewStatus([])
        setServiceSegregatedData({
            insideServiceItems: [],
            outsideServiceItems: []
        })
    };

    //  Extract dynamic headers from the data
    const headers = React.useMemo(() => {
        const uniqueProductNames = Array.from(new Set(data.map((item) => item.productName)));

        const statusHeaders = data[0].productStatusData.map((status) => ({
            Header: status.productStatusName,
            accessor: (row) => {
                const statusData = row[status.productStatusName];
                return statusData.length;
            },
            onClick: (e, column, rowValue) => {
                var productName = column;
                const productData = data.find((item) => item.productName === rowValue.productName);
                if (productData) {

                    const inStockData = productData.productStatusData.find(
                        (statusData) => statusData.productStatusName === productName
                    )?.data;
                    console.log(inStockData);
                    setViewStatus(inStockData)
                    serviceSegregate(inStockData)
                    setShow(true);
                }
            },
        }));

        return [
            {
                Header: "SL No.",
                accessor: (row, index) => index + 1,
                Cell: ({ value }) => <span>{value}</span>,
            },
            {
                Header: 'productName',
                accessor: 'productName',
            },
            {
                Header: 'Total Items',
                accessor: (row) => {
                    let totalItems = 0;
                    const productData = data.find((item) => item.productName === row.productName);
                    if (productData) {
                        productData.productStatusData.forEach((status) => {
                            totalItems += status.data.length;
                        });
                    }
                    return totalItems;
                },
            },
            ...statusHeaders,
        ];
    }, [data]);

    //  Flatten data to ensure each element in the array corresponds to a row
    const flattenedData = React.useMemo(() => {
        const rowData = data.map((item) => {
            const row = {
                productName: item.productName,
            };
            item.productStatusData.forEach((status) => {
                row[status.productStatusName] = status.data;
            });
            return row;
        });
        return rowData;
    }, [data]);

    // Create a table instance
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns: headers, data: flattenedData });


    const serviceSegregate = (value) => {
        const insideServiceItems = value.filter(item => item.isServiceProduct === 1);
        const outsideServiceItems = value.filter(item => item.isServiceProduct === 0);

        // Create a new object with the segregated data
        const result = {
            insideServiceItems,
            outsideServiceItems
        };
        setServiceSegregatedData(result)
    }

    return (
        <>
            <table {...getTableProps()} style={{ border: '1px solid black' }}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                            padding: '5px', cursor: 'default'
                                        }}
                                    >
                                        {cell.column.onClick ? (
                                            <>
                                                <button
                                                    onClick={(e) =>
                                                        cell.column.onClick &&
                                                        cell.column.onClick(e, cell.column.id, row.original, cell.value)
                                                    }
                                                    style={{
                                                        backgroundColor: "#fff", color: "#003bc0", border: "0", borderRadius: "4px",
                                                        padding: "4px 20px", fontWeight: "600"
                                                    }}
                                                >
                                                    {cell.value}
                                                </button>
                                            </>
                                        ) : (
                                            cell.render('Cell')
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{viewStatus.length > 0 && <>{`${viewStatus[0].productName} -- ${viewStatus[0].productStatusName} items`}</>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {viewStatus.length > 0 ? <>
                        {(viewStatus.length > 0 && viewStatus[0].productStatus == "2") ? <>
                            {serviceSegregatedData.outsideServiceItems.length > 0 ? <div className='mb-4'>
                                <h6 className='font_color'>Outside Service Items:</h6>
                                <div className="row">
                                    <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product code</p></div>
                                    <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product Label Number</p></div>
                                </div>
                                {serviceSegregatedData.outsideServiceItems.map((item, i) => (
                                    <div className="row" key={i}>
                                        <div className="col-6 text-center"><p className='font_color mb-0'>{item.productCode}</p></div>
                                        <div className="col-6 text-center"><p className='font_color mb-0'>{item.productLabel}</p></div>
                                    </div>
                                ))}
                            </div> : null}

                            {serviceSegregatedData.insideServiceItems.length > 0 ? <div className='mb-3'>
                                <h6 className='font_color'>Inside Service Items:</h6>
                                <div className="row">
                                    <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product code</p></div>
                                    <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product Label Number</p></div>
                                </div>
                                {serviceSegregatedData.insideServiceItems.map((item, i) => (
                                    <div className="row" key={i}>
                                        <div className="col-6 text-center"><p className='font_color mb-0'>{item.productCode}</p></div>
                                        <div className="col-6 text-center"><p className='font_color mb-0'>{item.productLabel}</p></div>
                                    </div>
                                ))}
                            </div> : null}

                        </> : <>
                            <div className="row">
                                <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product code</p></div>
                                <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product Label Number</p></div>
                            </div>
                            {viewStatus.length > 0 && viewStatus.map((item, i) => {
                                return (

                                    <>
                                        <div className="row" key={i}>
                                            <div className="col-6 text-center"><p className='font_color mb-0'>{item.productCode}</p></div>
                                            <div className="col-6 text-center"><p className='font_color mb-0'>{item.productLabel}</p></div>
                                        </div>

                                    </>
                                )
                            })}
                        </>}
                    </> : <>
                        <div className="my-4 text-center">
                            <p className="font_color">No items</p>
                        </div></>}

                    {/* {viewStatus.length > 0 && viewStatus.map((item, i) => {
                        if (item.productStatus == 2) {
                            const insideServiceItems = viewStatus.filter(item => item.isServiceProduct === 1);
                            const outsideServiceItems = viewStatus.filter(item => item.isServiceProduct === 0);
                            return (<>
                                <div className='mb-3'>
                                    <h2>Outside Service Items:</h2>
                                    <div className="row">
                                        <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product code</p></div>
                                        <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product Label Number</p></div>
                                    </div>
                                    {outsideServiceItems.map((item, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-6 text-center"><p className='font_color mb-0'>{item.productCode}</p></div>
                                            <div className="col-6 text-center"><p className='font_color mb-0'>{item.productLabel}</p></div>
                                        </div>
                                    ))}
                                </div>
                                <div className='mb-3'>
                                    <h2>Inside Service Items:</h2>
                                    <div className="row">
                                        <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product code</p></div>
                                        <div className="col-6 text-center"><p className='font_color mb-0 text-decoration-underline'>Product Label Number</p></div>
                                    </div>
                                    {insideServiceItems.map((item, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-6 text-center"><p className='font_color mb-0'>{item.productCode}</p></div>
                                            <div className="col-6 text-center"><p className='font_color mb-0'>{item.productLabel}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </>)
                        } else {
                            return (

                                <>
                                    <div className="row" key={i}>
                                        <div className="col-6 text-center"><p className='font_color mb-0'>{item.productCode}</p></div>
                                        <div className="col-6 text-center"><p className='font_color mb-0'>{item.productLabel}</p></div>
                                    </div>

                                </>
                            )
                        }
                    })} */}
                </Modal.Body>
            </Modal >
        </>
    );
};

