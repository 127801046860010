import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { Selectelement } from "../../../components/Select_field/Selectelement";
import { PostRequestHook } from "../../../apis/Services";
import { configUrl } from "../../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { Input_element } from "../../../components/input_field/Input_element";
import { VendorTable } from "./VendorTable";
import { RegEx } from "../../../utils/RegEx";

export const InventoryVendor = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { postRequest, putRequest } = PostRequestHook()
    const [stockList, setStockList] = useState(false);

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [updateVendor, setUpdateVendor] = useState(false)
    const [updateId, setUpdateId] = useState("")

    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }

    const formik = useFormik({
        initialValues: {
            vendorName: "",
            companyName: "",
            vendorType: "",
            vendorEmail: "",
            vendorContactNumber: "",
            vendorFax: "",
            vendorGst: "",
            vendorPanNo: "",
            vendorWebsite: "",
            vendorAddress: "",
            vendorDescription: ""
        },
        validate: (values) => {
            let errors = {};
            if (!values.vendorName) {
                errors.vendorName = "Required";
            }
            if (!values.companyName) {
                errors.companyName = "Required";
            }
            if (!values.vendorType) {
                errors.vendorType = "Required";
            }
            if (!values.vendorEmail) {
                errors.vendorEmail = "Required";
            } else if (!RegEx.email__regEx.test(values.vendorEmail)) {
                errors.vendorEmail = "Enter Valid Email"
            }
            if (!values.vendorContactNumber) {
                errors.vendorContactNumber = "Required";
            }
            if (!values.vendorGst) {
                errors.vendorGst = "Required";
            }
            if (!values.vendorAddress) {
                errors.vendorAddress = "Required";
            }
            if (!values.vendorPanNo) {
                errors.vendorPanNo = "Required";
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            let value2 = { ...values, vendorContactNumber: values.vendorContactNumber.toString().trim() }
            // console.log("🚀 ~ file: InventoryVendor.js:78 ~ onSubmit: ~ value2:", value2)
            var response = updateVendor ? await putRequest(`${configUrl.updateVendor}${updateId}`, value2)
                : await postRequest(configUrl.createVendor, value2)
            if (response.status == 201 || response.status == 200) {
                snackBarContent(true, response.data.message)
                setStockList(false)
                setUpdateVendor(false)
                resetForm()
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                snackBarContent(false, response.response.data.message)
            }
        },
    });

    const handleViewClick = (cell) => {
        setUpdateId(cell.cell.row.original.id)
        var { id, status, created_by, created_at, updated_at, updated_by, ...rest } = cell.cell.row.original
        // console.log("🚀 ~ file: InventoryVendor.js:95 ~ handleViewClick ~ rest:", rest)
        formik.setValues(rest)
        setUpdateVendor(true)
        setStockList(!stockList)
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="leves_style px-md-2 px-md-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="header_color">{stockList ? 'Add Vendor' : 'Vendors List'}</h3>
                    </div>
                </div>
                <div className="text-end">
                    {/* <Link to="/" className="goback">
                        Back to Dashboard
                    </Link> */}
                    {/* {stockList ? */}
                    <Button
                        variant="primary"
                        onClick={() => {
                            setStockList(!stockList);
                            setUpdateVendor(false)
                            formik.setValues({
                                vendorName: "",
                                companyName: "",
                                vendorType: "",
                                vendorEmail: "",
                                vendorContactNumber: "",
                                vendorFax: "",
                                vendorGst: "",
                                vendorPanNo: "",
                                vendorWebsite: "",
                                vendorAddress: "",
                                vendorDescription: ""
                            })
                            formik.setTouched({})
                        }}
                    >
                        {stockList ? "Vendors List" : "Add New Vendor"}
                    </Button>
                    {/* : null} */}
                </div>

                <div>
                    {stockList ? (
                        <div className="test_bg p-md-4 mt-4">
                            {/* <h4 className="mb-4">Update the Stock item</h4> */}
                            <Form
                                onSubmit={formik.handleSubmit}
                                autoComplete="off">
                                <div className="mb-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Vendor Name <strong className="text-danger">*</strong></>}
                                                type="text"
                                                name="vendorName"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.vendorName}
                                                placeholder="Enter Vendor name"
                                                formikValidation={
                                                    formik.touched.vendorName &&
                                                        formik.errors.vendorName ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.vendorName}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Company Name <strong className="text-danger">*</strong></>}
                                                type="text"
                                                name="companyName"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.companyName}
                                                placeholder="Enter Company name"
                                                formikValidation={
                                                    formik.touched.companyName &&
                                                        formik.errors.companyName ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.companyName}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Selectelement
                                                optionArray={
                                                    <>
                                                        {["Manufacturer", "Retailer", "Wholesaler", "Service Provider"].length == 0 ?
                                                            null : <>
                                                                {["Manufacturer", "Retailer", "Wholesaler", "Service Provider"].map((leave, i) => {
                                                                    return (
                                                                        <option key={i} value={leave}>
                                                                            {leave}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </>}

                                                    </>
                                                }
                                                select_Label={<>Vendor Type <strong className="text-danger">*</strong></>}
                                                name="vendorType"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.vendorType}
                                                formikValidation={
                                                    formik.touched.vendorType &&
                                                        formik.errors.vendorType ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.vendorType}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Email <strong className="text-danger">*</strong></>}
                                                type="email"
                                                name="vendorEmail"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.vendorEmail}
                                                placeholder="Enter Valid email"
                                                formikValidation={
                                                    formik.touched.vendorEmail &&
                                                        formik.errors.vendorEmail ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.vendorEmail}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Contact Number <strong className="text-danger">*</strong></>}
                                                type="number"
                                                name="vendorContactNumber"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.vendorContactNumber ? Number(formik.values.vendorContactNumber) : ""}
                                                placeholder="Enter Contact number"
                                                formikValidation={
                                                    formik.touched.vendorContactNumber &&
                                                        formik.errors.vendorContactNumber ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.vendorContactNumber}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>GST Number <strong className="text-danger">*</strong></>}
                                                type="text"
                                                name="vendorGst"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.vendorGst}
                                                placeholder="Enter GST number"
                                                formikValidation={
                                                    formik.touched.vendorGst &&
                                                        formik.errors.vendorGst ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.vendorGst}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>FAX </>}
                                                type="text"
                                                name="vendorFax"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.vendorFax}
                                                placeholder="Enter FAX number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>PAN <strong className="text-danger">*</strong></>}
                                                type="text"
                                                name="vendorPanNo"
                                                handleChange={(e) => {
                                                    formik.setFieldValue('vendorPanNo', e.target.value.toUpperCase())
                                                }}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.vendorPanNo}
                                                placeholder="Enter PAN number"
                                                formikValidation={
                                                    formik.touched.vendorPanNo &&
                                                        formik.errors.vendorPanNo ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.vendorPanNo}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label="Website"
                                                type="text"
                                                name="vendorWebsite"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.vendorWebsite}
                                                placeholder="Enter website"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Address <strong className="text-danger">*</strong></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ minHeight: "80px" }}
                                                    name="vendorAddress"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.vendorAddress}
                                                    placeholder="Enter Address"
                                                />
                                                {
                                                    formik.touched.vendorAddress &&
                                                        formik.errors.vendorAddress ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.vendorAddress}
                                                        </small>
                                                    ) : null
                                                }
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ minHeight: "80px" }}
                                                    name="vendorDescription"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.vendorDescription}
                                                    placeholder="Enter Description"
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end mt-4">
                                    <Button type="submit">
                                        {updateVendor ? "update" : "Add"}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <div className="leavesTable mt-5">
                            <VendorTable onViewClick={handleViewClick} />
                        </div>
                    )}
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

        </>
    );
};
