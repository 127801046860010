import React, { useRef, useState } from "react";
import "./createuser.scss";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Alert, Button, Container, Snackbar, Stack, StepButton } from "@mui/material";
import CreateButton from "../../components/CreateButton/CreateButton";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import CustomInput from "../Fields/CustomInput";
import employeeInfo from "./employeeinfo.json";
import { Link } from "react-router-dom";
import { EmployeeTable } from "./EmployeeTable";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { RegEx } from "../../utils/RegEx";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ModalComponent } from "../../components/modal/ModalComponent";
import { Modal } from "react-bootstrap";
import { setDropdownlist, setIsAuth } from "../../store/reducers/ui.reducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Images } from "../../utils/images";
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { RouteStrings } from "../../utils/common";


export const CreateUser = () => {
  const inputElement = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [infoValue, setInfoValue] = useState(employeeInfo[0].fields);
  const { getRequest, postRequest, putRequest } = PostRequestHook()

  const [isCreateUser, setIsCreateUser] = useState(false);
  const [employeedata, setEmployeeData] = useState([])
  const [snackOpen, setSnackOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [snackMessage, setSnackMessage] = useState("")
  const [individualInfo, setIndividualInfo] = useState([])
  const [detailModal, setDetailModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [excelModal, setExcelModal] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [updateInfo, setUpdateInfo] = useState({})
  const [editInfo, setEditInfo] = useState(false)
  const [employeeID, setEmployeeID] = useState("")
  const [emplid, setEmpId] = useState("")
  const [employeeType, setEmployeeType] = useState([])
  const [calculateAge, setCalculateAge] = useState()
  const [calculateExp, setCalculateExp] = useState()
  const [employeeArray, setEmployeeArray] = useState([])
  const [sortedEmployeeArray, setSortedEmployeeArray] = useState([])
  const [superAdminModal, setSuperAdminModal] = useState(false)

  const [onChangeData, setChangeData] = useState({})
  const [superAdminUpdate, setSuperAdminUpdate] = useState(false)
  const [file, setFile] = useState();

  const { dropdownList, userInfo, auth, access_token } = useSelector(state => state.UIStore)
  let userDropdowns = dropdownList ? dropdownList.dropdownList : null

  useEffect(() => {
    getEmployeeList()
    updateSuperDetails()
  }, [])
  const getEmployeeList = async () => {
    var response = await getRequest(configUrl.employeelist)
    setEmployeeData(response?.data?.data)
    var converted_data = Array.converterid(response?.data?.data ? response?.data?.data : [])
    var _converted_data = converted_data.filter(item => item.value !== "")
    setEmployeeArray(_converted_data)
  }

  const snackBarContent = (isSuccess, message) => {
    setSuccess(isSuccess)
    setSnackMessage(message)
    setSnackOpen(true)
  }

  const updateSuperDetails = () => {
    if (!userInfo.emp_id) {
      setSuperAdminModal(true)
      // snackBarContent(false, "Please update Admin Details")
    }
  }

  useEffect(() => {
    assigndropdown(infoValue)
  }, [infoValue])

  const assigndropdown = (value) => {
    var dropdowns = value.filter((item) => {
      if (item.field_values) {
        item.field_values = userDropdowns ? userDropdowns[item.name] : []
      }
      return item
    })
    setEmployeeType(dropdowns)
  }

  useEffect(() => {
    getDropdownData()
  }, [])

  const getDropdownData = async () => {
    var response = await getRequest(configUrl.getAllDropdowns)
    dispatch(setDropdownlist({
      dropdownList: response?.data?.data
    }))
  }

  const bloodGroupOptions = [{ value: "O+ve", name: "O+ve" }, { value: "A+ve", name: "A+ve" },
  { value: "B+ve", name: "B+ve" }, { value: "AB+ve", name: "AB+ve" }, { value: "O-ve", name: "O-ve" },
  { value: "A-ve", name: "A-ve" }, { value: "B-ve", name: "B-ve" }, { value: "AB-ve", name: "AB-ve" }]

  var steps = [];
  employeeInfo.filter((item) => {
    let xyz = { label: item.title };
    steps.push(xyz);
  });

  const handlechangeinfo = (i) => {
    setInfoValue(employeeInfo[i].fields);
  };

  const handleupdateAction = (i) => {
    setActiveStep(i)
    setInfoValue(employeeInfo[i].fields);
    if (employeeInfo[i].fields[2].name == "date_of_joining") {
      autoCalculateExp(updateInfo[employeeInfo[i].fields[2].name])
    }
    if (employeeInfo[i].fields[0].name == "date_of_birth") {
      autoCalculateage(updateInfo[employeeInfo[i].fields[0].name])
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handlechangeinfo(activeStep - 1);
    if (editInfo) {
      if (superAdminUpdate) {
      } else {
        if (employeeInfo[activeStep - 1].fields[2].name == "date_of_joining") {
          autoCalculateExp(updateInfo[employeeInfo[activeStep - 1].fields[2].name])
        }
        if (employeeInfo[activeStep - 1].fields[0].name == "date_of_birth") {
          autoCalculateage(updateInfo[employeeInfo[activeStep - 1].fields[0].name])
        }
      }
    }
  };
  const errorMessage = {
    color: "#e35050",
    position: "absolute",
    fontSize: "12px",
  };

  const postPayloadData = async (data) => {
    let matrixList = onChangeData.matrix_manager ? onChangeData.matrix_manager.map(value => {
      return { "employee_id": value.value }
    }) : [];

    let _payload1 = {
      ...data,
      date_of_birth: _formatDate(data.date_of_birth),
      date_of_joining: _formatDate(data.date_of_joining),
      gender_id: Number(data.gender_id),
      employee_type_id: Number(data.employee_type_id),
      department_id: Number(data.department_id ? data.department_id : 0),
      designation_id: Number(data.designation_id ? data.designation_id : 0),
      years_of_experience: Number(data.years_of_experience ? data.years_of_experience : 0),
      shifts_id: Number(data.shifts_id ? data.shifts_id : 0),
      marital_status: Number(data.marital_status),
      esi_number: data.esi_number ? data.esi_number.toString() : "NULL",
      pf_number: data.pf_number ? data.pf_number : "NULL",
      uan_number: data.uan_number ? data.uan_number.toString() : "NULL",
      aadhaar_number: Number(data.aadhaar_number),
      bank_account_number: Number(data.bank_account_number),
      bank_account_type_id: Number(data.bank_account_type_id),
      phone_number: data.phone_number.toString(),
      emergency_contact: data.emergency_contact.toString(),
      year_of_passing: data.year_of_passing ? data.year_of_passing.toString() : "NULL",
      highest_qualification: data.highest_qualification ? data.highest_qualification : "NULL",
      university_name: data.university_name ? data.university_name : "NULL",
      matrix_manager: matrixList,
      reporting_person: onChangeData.reporting_person ? onChangeData.reporting_person.value : ""
    }


    let updateData = { ..._payload1 }
    delete updateData.isUpdated
    delete updateData.role_name
    let sendData = editInfo ? updateData : _payload1
    const response = editInfo ?
      await putRequest(`${configUrl.updateEmployeeDetail}${employeeID}`, sendData) :
      await postRequest(configUrl.createnewEmployee, sendData)
    if (response) {
      if (response.status == 201 || response.status == 200) {
        snackBarContent(true, response.data.message)
        setIsCreateUser(editInfo ? (superAdminUpdate ? false : true) : false)
        getEmployeeList()
        if (editInfo) {
          if (superAdminUpdate) {
            setSuperAdminUpdate(false)
            setCalculateAge("")
            setCalculateExp("")
            setChangeData({})
            dispatch(setIsAuth({
              isAuth: auth.isAuth,
              data: { ...userInfo, emp_id: sendData.employee_id },
              refresh_token: auth.refresh_token
            }))
          } else {
            getDetailsById(emplid)
          }
        } else {
          setCalculateAge("")
          setCalculateExp("")
          setChangeData({})
        }
      } else if (response.response.status == 400 || response.response.data.status == 409) {
        snackBarContent(false, response.response.data.message)
      }
    }
  }

  const handleEmployeeView = (cell) => {
    let viewDeatil = cell.cell.row.original
    setIndividualInfo([viewDeatil])
    autoCalculateExp(viewDeatil.date_of_joining)
    setDetailModal(true)
  }

  const handledeleteModal = (cell, data) => {
    setDeleteId(cell)
    setDeleteModal(true)
  }

  const getEmployeesWithReportingPerson = (employeeId) => {
    const employeesWithReportingPerson = employeedata.filter(
      (employee) => employee.reporting_person === employeeId
    );
    const employeeIds = employeesWithReportingPerson.map((employee) => employee.employee_id);
    return employeeIds;
  }

  const [_open, setDeleteOpen] = useState(false)
  const handleCheckChildrens = (employeeId) => {
    var chidrens = getEmployeesWithReportingPerson(employeeId)
    if (chidrens.length == 0) {
      handledeleteEmployee(employeeId)
    } else {
      setDeleteOpen(true)
    }
  }

  const handledeleteEmployee = async (employeeId) => {
    setDeleteModal(false)
    var response = await putRequest(`${configUrl.deleteEmployee}${employeeId}`)
    if (response) {
      if (response.status == 200) {
        snackBarContent(true, response.data.message)
        setDeleteModal(false)
        getEmployeeList()
      }
    }
  }


  const getDetailsById = async (empId) => {
    var response = await getRequest(`${configUrl.getEmployeeDetails}${empId}`)

    if (response) {
      let viewDeatil = response?.data?.data
      var matrixManagersDefault = viewDeatil.matrix_manager.split(",");
      var matrixManagersDefaultOptions = viewDeatil.matrix_manager ? employeeArray.filter(option => matrixManagersDefault.includes(option.value))
        : [];
      var reportingpersondefault = viewDeatil.reporting_person ? employeeArray.find(option => option.value === viewDeatil.reporting_person) : "";

      let _viewDeatil = {
        ...viewDeatil,
        date_of_joining: viewDeatil.date_of_joining ? _formatDate(viewDeatil.date_of_joining) : '',
        date_of_birth: viewDeatil.date_of_birth ? _formatDate(viewDeatil.date_of_birth) : '',
        department_id: viewDeatil.department_id ? viewDeatil.department_id.toString() : "",
        designation_id: viewDeatil.designation_id ? viewDeatil.designation_id.toString() : "",
        bank_account_type_id: viewDeatil.bank_account_type_id ? viewDeatil.bank_account_type_id.toString() : "",
        employee_type_id: viewDeatil.employee_type_id ? viewDeatil.employee_type_id.toString() : "",
        gender_id: viewDeatil.gender_id ? viewDeatil.gender_id.toString() : "",
        shifts_id: viewDeatil.shifts_id ? viewDeatil.shifts_id.toString() : "",
        matrix_manager: matrixManagersDefaultOptions,
        reporting_person: reportingpersondefault
      }
      var { id, role_id, gender_name, employee_type_name, shift_name, role_name, reporting_person_information,
        matrix_manager_information, bank_account_type_name, designation_name, department_name, assignedProducts, ...rest } = _viewDeatil
      setUpdateInfo(rest)
      setChangeData(rest)
      autoCalculateExp(rest.date_of_joining)
      autoCalculateage(rest.date_of_birth)
    }
  }

  const handleEmployeeUpdate = (cell) => {
    setChangeData({})

    let viewDeatil = cell
    var matrixManagersDefault = viewDeatil.matrix_manager.split(",");
    var matrixManagersDefaultOptions = viewDeatil.matrix_manager ? employeeArray.filter(option => matrixManagersDefault.includes(option.value))
      : [];
    var reportingpersondefault = viewDeatil.reporting_person ? employeeArray.find(option => option.value === viewDeatil.reporting_person) : "";

    let _viewDeatil = {
      ...viewDeatil,
      date_of_joining: viewDeatil.date_of_joining ? _formatDate(viewDeatil.date_of_joining) : "",
      date_of_birth: viewDeatil.date_of_birth ? _formatDate(viewDeatil.date_of_birth) : "",
      department_id: viewDeatil.department_id ? viewDeatil.department_id.toString() : "",
      designation_id: viewDeatil.designation_id ? viewDeatil.designation_id.toString() : "",
      bank_account_type_id: viewDeatil.bank_account_type_id ? viewDeatil.bank_account_type_id.toString() : "",
      employee_type_id: viewDeatil.employee_type_id ? viewDeatil.employee_type_id.toString() : "",
      gender_id: viewDeatil.gender_id ? viewDeatil.gender_id.toString() : "",
      shifts_id: viewDeatil.shifts_id ? viewDeatil.shifts_id.toString() : "",
      matrix_manager: matrixManagersDefaultOptions,
      reporting_person: reportingpersondefault
    }

    var { id, role_id, gender_name, employee_type_name, shift_name, marital_name, reporting_person_information,
      matrix_manager_information, bank_account_type_name, designation_name, department_name, assignedProducts, ...rest } = _viewDeatil
    if (_viewDeatil.isUpdated == 0) {
      setChangeData(rest)
      setSuperAdminUpdate(true)
    } else {
      setUpdateInfo(rest)
      setChangeData(rest)
    }
    setIsCreateUser(true)
    setEditInfo(true)
    setEmpId(rest.employee_id)
    setEmployeeID(_viewDeatil.id)
    setDetailModal(false)
    setActiveStep(0)
    setInfoValue(employeeInfo[0].fields);
    var sortEmployees = employeeArray.filter(item => item.value !== (rest.employee_id || ""))
    setSortedEmployeeArray(sortEmployees)
  }

  const handleDetailModal = () => {
    setIndividualInfo([])
    setDetailModal(false)
  }

  const dispatch = useDispatch();
  const autoCalculateage = (date) => {
    if (date) {
      var dob = new Date(date);
      var month_diff = Date.now() - dob.getTime();
      var age_dt = new Date(month_diff);
      var year = age_dt.getUTCFullYear();
      var age = Math.floor(year - 1970);
      setCalculateAge(isNaN(age) ? "" : `${age} Years`)
    } else {
      setCalculateAge("0")
    }
  }

  const autoCalculateExp = (date) => {
    if (date) {
      var dob = new Date(date);
      var today = new Date()
      let difference = today.getTime() - dob.getTime();
      let TotalDays = Math.floor(difference / (1000 * 3600 * 24 * 30)) >= "12" ?
        (Math.fround(difference / (1000 * 3600 * 24 * 365))).toFixed(1)
        : Math.floor(difference / (1000 * 3600 * 24 * 30));
      setCalculateExp(`${isNaN(TotalDays) ? "" : TotalDays} ${isNaN(TotalDays) ? "" : Math.floor(difference / (1000 * 3600 * 24 * 30)) >= "12" ? "Year" : "Months"}`)
    } else {
      setCalculateExp("0")
    }
  }

  var res_name = employeeType.reduce(
    (acc, curr) => (
      (acc[curr.name] =
        `${onChangeData[curr.name] ? onChangeData[curr.name] : ""}`),
      acc
    ),
    {}
  );

  const InfoDiv = () => {
    let Required_fields = [];
    employeeType.filter((item) => {
      if (item.isRequired) {
        Required_fields.push(item.name);
      }
    });

    var req_name = Required_fields.reduce(
      (acc, curr) => ((acc[curr] = ""), acc),
      {}
    );

    const validation = async (data) => {
      var data1 = (data.date_of_joining) ? {
        ...data,
        date_of_joining: _formatDate(data.date_of_joining),
        reporting_manager: onChangeData.reporting_person ? onChangeData.reporting_person.value : "",
        matrix_manager: onChangeData.matrix_manager ? onChangeData.matrix_manager.map(value => {
          return { "employee_id": value.value }
        }) : []

      } : (data.date_of_birth) ? {
        ...data,
        date_of_birth: _formatDate(data.date_of_birth)
      } : (data.esi_number == "") || (data.pf_number == "") || (data.uan_number == "") ? {
        ...data,
        esi_number: data.esi_number ? data.esi_number : "NULL",
        pf_number: data.pf_number ? data.pf_number : "NULL",
        uan_number: data.uan_number ? data.uan_number : "NULL"
      } : (data.year_of_passing == "") || (data.highest_qualification == "") || (data.university_name == "") ?
        {
          ...data,
          year_of_passing: data.year_of_passing ? data.year_of_passing.toString() : "NULL",
          highest_qualification: data.highest_qualification ? data.highest_qualification : "NULL",
          university_name: data.university_name ? data.university_name : "NULL",
        } : (data.year_of_passing) ? {
          ...data, year_of_passing: data.year_of_passing.toString()
        } : data

      var data3 = { ...data1 }
      if (data3.yoe_dolarbird) {
        delete data3.yoe_dolarbird
      }

      let sendData = editInfo ? data1 : data3

      var validationUrl = editInfo ? `${configUrl.updateValidation}${employeeID}` : configUrl.basicValidation

      var response = await postRequest(validationUrl, sendData)
      if (response) {
        if (response.status == 201 || response.status == 200) {
          if (editInfo) {
            if (superAdminUpdate) {
              sendPayload()
            } else {
              var testUpload = { ...updateInfo, ...data }
              delete testUpload.age
              delete testUpload.yoe_dolarbird
              postPayloadData(testUpload)
            }
          } else {
            sendPayload()
          }

        } else if (response.response.status == 400 || response.response.data.status == 409) {
          snackBarContent(false, response.response.data.message)
        }
      }
    }

    const sendPayload = () => {
      if (activeStep === steps.length - 1) {
        let _payload = { ...onChangeData };
        delete _payload.age
        delete _payload.yoe_dolarbird
        postPayloadData(_payload)
      } else {
        handlechangeinfo(activeStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    }

    return (
      <Formik
        initialValues={res_name}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
          Object.keys(req_name).forEach(function (key) {
            if (values[key] == "") {
              errors[key] = `Required`;
            }
            if (values.name) {
              if (!RegEx.name__regEx.test(values.name)) {
                errors.name = "Alphabets only";
              }
            }
            // if (values.father_name) {
            //   if (!RegEx.name__regEx.test(values.father_name)) {
            //     errors.father_name = "Alphabets only";
            //   }
            // }
            // if (values.mother_name) {
            //   if (!RegEx.name__regEx.test(values.mother_name)) {
            //     errors.mother_name = "Alphabets only";
            //   }
            // }
            if (values.blood_group) {
              if (!RegEx.blood__regEx.test(values.blood_group)) {
                errors.blood_group = "Select Blood Group";
              }
            }
            // if (values.emergency_contact_name) {
            //   if (!RegEx.name__regEx.test(values.emergency_contact_name)) {
            //     errors.emergency_contact_name = "Alphabets only";
            //   }
            // }
            // if (values.emergency_contact_relation) {
            //   if (!RegEx.name__regEx.test(values.emergency_contact_relation)) {
            //     errors.emergency_contact_relation = "Alphabets only";
            //   }
            // }
            if (values.email) {
              if (!RegEx.email__regEx.test(values.email)) {
                errors.email = "Invalid email address";
              }
            }
            if (values.personal_email) {
              if (!RegEx.email__regEx.test(values.personal_email)) {
                errors.personal_email = "Invalid email address";
              } else if (values.personal_email == (editInfo ? (superAdminUpdate ? onChangeData.email : updateInfo.email) : onChangeData.email)) {
                errors.personal_email = "email and personal email should not be same";
              }
            }
            if (values.phone_number) {
              if (!RegEx.only__number__regEx.test(values.phone_number)) {
                errors.phone_number = "Numeric only";
              }
            }
            if (values.emergency_contact) {
              if (!RegEx.only__number__regEx.test(values.emergency_contact)) {
                errors.emergency_contact = "Numerics only";
              } else if (values.emergency_contact == (editInfo ? (superAdminUpdate ? onChangeData.phone_number : updateInfo.phone_number) : onChangeData.phone_number)) {
                errors.emergency_contact = "phone and emergency contact number should not be same";
              }
            }
            if (values.esi_number) {
              if (!RegEx.only__number__regEx.test(values.esi_number)) {
                errors.esi_number = "Numerics only";
              }
            }
            if (values.aadhaar_number) {
              if (!RegEx.only__number__regEx12.test(values.aadhaar_number)) {
                errors.aadhaar_number = "Numerics only and contains 12 digits";
              }
            }
            if (values.bank_account_number) {
              if (!RegEx.only__number__regEx20.test(values.bank_account_number)) {
                errors.bank_account_number = "Numerics only and contains 10 to 20 digits";
              }
            }
            if (values.pf_number) {
              if (!RegEx.capitalalphanumeric__without__spaces22.test(values.pf_number)) {
                errors.pf_number = "Enter Valid PF number";
              } else if (values.pf_number.length !== 22) {
                errors.pf_number = "must contains 22 digits";
              }
            }
            if (values.pan_number) {
              if (!RegEx.capitalalphanumeric__without__spaces22.test(values.pan_number)) {
                errors.pan_number = "Enter valid PAN number";
              } else if (values.pan_number.length !== 10) {
                errors.pan_number = "contains 10 digits";
              }
            }
            if (values.ifsc_code) {
              if (!RegEx.capitalalphanumeric__without__spaces22.test(values.ifsc_code)) {
                errors.ifsc_code = "Enter valid IFSC";
              } else if (values.ifsc_code.length !== 11) {
                errors.ifsc_code = "contains 11 digits";
              }
            }
            if (values.uan_number) {
              if (!RegEx.only__number__regEx12.test(values.uan_number)) {
                errors.uan_number = "Numerics only and contains 12 digits";
              }
            }
          });
          return errors;
        }}
        onSubmit={(values) => {
          validation(values)
        }

        }
      >

        {({ setFieldValue, values, handleChange, handleBlur, setErrors }) => (
          <Form autoComplete="off">
            <div className="row">
              {employeeType.map((field, i) => {
                if (field.name == "reporting_person") {
                  return (
                    <div className="col-md-6" key={i}>
                      <div className="mb-3">
                        <label className="font_color me-2 form-label">{
                          field.isRequired
                            ? `${field.field_name} *`
                            : `${field.field_name}`
                        }</label>
                        <Select
                          id={field.name}
                          name={field.name}
                          onChange={(item) => {
                            setChangeData({ ...onChangeData, [field.name]: item })
                          }}
                          defaultValue={onChangeData.reporting_person}
                          options={editInfo ? sortedEmployeeArray : employeeArray}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isClearable={true}
                          isDisabled={onChangeData.role_name ? (onChangeData.role_name.trim().replace(/\s+/g, "_").toLowerCase() == "super_admin" ? true : false) : false}
                        />
                        <ErrorMessage
                          component="div"
                          name={field.name}
                          style={errorMessage}
                        />
                      </div>
                    </div>)
                }
                else if (field.name == "matrix_manager") {
                  return (
                    <div className="col-md-6" key={i}>
                      <div className="mb-3">
                        <label className="font_color me-2 form-label">{
                          field.isRequired
                            ? `${field.field_name} *`
                            : `${field.field_name}`
                        }</label>
                        <Select
                          id={field.name}
                          name={field.name}
                          onChange={(item) => {
                            setChangeData({ ...onChangeData, [field.name]: item })
                          }}
                          isMulti
                          options={editInfo ? sortedEmployeeArray : employeeArray}
                          defaultValue={onChangeData.matrix_manager}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage
                          component="div"
                          name={field.name}
                          style={errorMessage}
                        />
                      </div>
                    </div>)
                } else if (field.name == "blood_group") {
                  return (
                    <div className="col-md-6" key={i}>
                      <div className="mb-3">
                        <label className="font_color mb-2">
                          {field.isRequired
                            ? `${field.field_name} *`
                            : `${field.field_name}`}
                        </label>
                        <Field
                          as="select"
                          className="setting_field"
                          name={field.name}
                          onChange={(e) => {
                            handleChange(e)
                            setChangeData({ ...onChangeData, [e.target.name]: e.target.value })
                          }}
                        >
                          <option value="0">
                            select menu
                          </option>
                          {bloodGroupOptions.length == 0 ? null : <>
                            {bloodGroupOptions.map((item) => {
                              return (
                                <option value={item?.value}>{item?.name}</option>
                              );
                            })}
                          </>}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name={field.name}
                          style={errorMessage}
                        />
                      </div>
                    </div>
                  );
                }
                else if (field.feild_type == "select") {
                  let optionValues = field?.field_values ? field?.field_values : [];
                  return (
                    <div className="col-md-6" key={i}>
                      <div className="mb-3">
                        <label className="font_color mb-2">
                          {field.isRequired
                            ? `${field.field_name} *`
                            : `${field.field_name}`}
                        </label>
                        <Field
                          as="select"
                          className="setting_field"
                          name={field.name}
                          onChange={(e) => {
                            handleChange(e)
                            setChangeData({ ...onChangeData, [e.target.name]: e.target.value })
                          }}
                        >
                          <option value="0">
                            select menu
                          </option>
                          {optionValues.length == 0 ? null : <>
                            {optionValues.map((item) => {
                              return (
                                <option value={item?.id}>{item?.name}</option>
                              );
                            })}
                          </>}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name={field.name}
                          style={errorMessage}
                        />
                      </div>
                    </div>
                  );
                } else if (field.feild_type == "textarea") {
                  return (
                    <div className="col-md-6" key={i}>
                      <div className="mb-3">
                        <label className="font_color mb-2">
                          {field.isRequired
                            ? `${field.field_name} *`
                            : `${field.field_name}`}
                        </label>
                        <Field
                          as="textarea"
                          className="setting_field"
                          name={field.name}
                          onChange={(e) => {
                            handleChange(e)
                            setChangeData({ ...onChangeData, [e.target.name]: e.target.value })
                          }}
                          rows="4"
                          cols="50"
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name={field.name}
                          style={errorMessage}
                        />
                      </div>
                    </div>
                  );
                }
                else if (field.name == "date_of_birth") {
                  return (
                    <div className="col-md-6" key={i}>
                      <div className="mb-3">
                        <label className="font_color me-2 form-label">{
                          field.isRequired
                            ? `${field.field_name} *`
                            : `${field.field_name}`
                        }</label>
                        <DatePicker
                          name={field.name}
                          selected={new Date(values[field.name]) == "Invalid Date" ? "" : new Date(values[field.name])}
                          onSelect={(e) => {
                            setFieldValue(field.name, _formatDate(e) || "");
                            setChangeData({ ...onChangeData, [field.name]: _formatDate(e) })
                            autoCalculateage(e)
                          }}
                          onChange={(val) => {
                            setFieldValue(field.name, val ? _formatDate(val) : "");
                            setChangeData({ ...onChangeData, [field.name]: _formatDate(val) })
                            autoCalculateage(val)
                          }}
                          className="setting_field"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="dd-mm-yyyy"
                          maxDate={new Date()}
                        />
                        <ErrorMessage
                          component="div"
                          name={field.name}
                          style={errorMessage}
                        />
                      </div>
                    </div>)
                }
                else if (field.name == "date_of_joining") {
                  return (
                    <div className="col-md-6" key={i}>
                      <div className="mb-3">
                        <label className="font_color me-2 form-label">{
                          field.isRequired
                            ? `${field.field_name} *`
                            : `${field.field_name}`
                        }</label>
                        <DatePicker
                          name={field.name}
                          selected={new Date(values[field.name]) == "Invalid Date" ? "" : new Date(values[field.name])}
                          onSelect={(e) => {
                            setFieldValue(field.name, _formatDate(e) || "");
                            setChangeData({ ...onChangeData, [field.name]: _formatDate(e) })
                            autoCalculateExp(e)
                          }}
                          onChange={(val) => {
                            setFieldValue(field.name, val ? _formatDate(val) || "" : "");
                            setChangeData({ ...onChangeData, [field.name]: _formatDate(val) })
                            autoCalculateExp(val)
                          }}
                          className="setting_field"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="dd-mm-yyyy"
                          maxDate={new Date()}
                        />
                        <ErrorMessage
                          component="div"
                          name={field.name}
                          style={errorMessage}
                        />
                      </div>
                    </div>)
                }
                else {
                  return (
                    <div className="col-md-6" key={i}>
                      <CustomInput
                        label={
                          field.isRequired
                            ? `${field.field_name} *`
                            : `${field.field_name}`
                        }
                        type={field.feild_type}
                        name={field.name}
                        onChange={(e) => {
                          if (field.name == "ifsc_code" || field.name == "pan_number" || field.name == "pf_number") {
                            setFieldValue(field.name, e.target.value.toUpperCase())
                            setChangeData({ ...onChangeData, [e.target.name]: e.target.value.toUpperCase(), age: calculateAge ? calculateAge : "0", yoe_dolarbird: calculateExp ? calculateExp : "0" })
                          } else {
                            setFieldValue(field.name, e.target.value)
                            setChangeData({ ...onChangeData, [e.target.name]: e.target.value, age: calculateAge ? calculateAge : "0", yoe_dolarbird: calculateExp ? calculateExp : "0" })
                          }
                        }}
                        readOnly={(field.name == "age") || (field.name == "yoe_dolarbird")}
                        value={field.name == "age" ? (calculateAge ? calculateAge : "0")
                          : field.name == "yoe_dolarbird" ? (calculateExp ? calculateExp : "0")
                            : values[field.name]}
                        disabled={(editInfo && field.name == "email") ? true : (editInfo && field.name == "employee_id") ? (superAdminUpdate ? false : true) : false}
                      />
                    </div>
                  );
                }
              })}
              <div className="col-md-12">
                <div className="text-end">
                  {activeStep === 0 ? null :
                    <Button
                      id="backbtn"
                      disabled={activeStep === 0}
                      variant="contained"
                      onClick={() => {
                        handleBack()
                        setErrors({})
                      }}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>}

                  <Button variant="contained" type="submit" sx={{ mt: 1, mr: 1 }}
                    id={editInfo ? (superAdminUpdate ? (activeStep === steps.length - 1 ? "submitbtn" : "continuebtn") : "updatebtn")
                      : activeStep === steps.length - 1 ? "submitbtn" : "continuebtn"}
                  >
                    {editInfo ? (superAdminUpdate ? (activeStep === steps.length - 1 ? "Submit" : "Continue") : "Update")
                      : activeStep === steps.length - 1 ? "Submit" : "Continue"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )
        }
      </Formik >
    );
  };

  const showCreateUser = () => {
    setIsCreateUser(true);
    setActiveStep(0)
    setInfoValue(employeeInfo[0].fields);
  };
  const showUsersList = () => {
    setIsCreateUser(false);
    if (editInfo || superAdminUpdate) {
      setEditInfo(false)
      setChangeData({})
      setSuperAdminUpdate(false)
      setUpdateInfo({})
      setCalculateAge("")
      setCalculateExp("")
    }
  };

  // ------import Excel data starts------- 
  const handleFileChange = (e) => {

    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = (e) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const expectedColumns = [
          "employee_id*",
          "name*",
          "gender*",
          "office_email*",
          "phone_number*",
          "date_of_joining*",
          "previous_years_of_experience",
          "father_name*",
          "mother_name*",
          "date_of_birth*",
          "permanent_address*",
          "postal_address*",
          "blood_group*",
          "marital_status*",
          "personal_email*",
          "emergency_contact_number*",
          "emergency_contact_name*",
          "emergency_contact_relation*",
          "year_of_passing",
          "university_name",
          "highest_qualification",
          "esi_number",
          "pf_number",
          "uan_number",
          "pan_number*",
          "aadhaar_number*",
          "bank_account_number*",
          "bank_account_type*",
          "ifsc_code*"
        ]
        const actualColumns = jsonData[0];

        const missingColumns = expectedColumns.filter(column => !(actualColumns.includes(column)))
        if (missingColumns.length > 0) {
          snackBarContent(false, "Please Upload defined Excel format file")
          setFile()
          document.getElementById('files').value = '';
          return;
        } else {
          const nonEmptyRows = jsonData.filter(row => row.length > 0);

          let jsonData1 = JSON.parse(JSON.stringify(nonEmptyRows))
          const sanitizedData = jsonData1.map((row) => {
            return row.map((cell) => {
              return cell ? cell : "";
            });
          });

          const updatedData = sanitizedData.map(item => {
            const updatedItem = {};
            for (let key in item) {
              if (item.hasOwnProperty(key)) {
                const value = item[key];
                updatedItem[key] = typeof value === 'string' && value.trim() !== '' ? value.trim() : value;
              }
            }
            return updatedItem;
          });

          let headers = [updatedData.at(0)]
          let exceldata = updatedData.slice(2, updatedData.length)

          const result = exceldata.map(item => {
            const obj = {};
            for (let key in headers[0]) {
              const index = parseInt(key);
              var headerKey = headers[0][index];
              headerKey = headerKey.replace(/\*/g, '');
              let value = item[key] || "";
              if (headerKey === "blood_group" && typeof value === 'string') {
                value = value.replace(/\s/g, '');
              }
              obj[headerKey] = typeof value === 'string' ? value.trim() : value;
            }
            return obj;
          });

          if (result.length == 0) {
            snackBarContent(false, "No data found in Excel File")
            setFile()
            document.getElementById('files').value = '';
          } else {
            uploadExcelFile(result)

          }
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) { }
  }

  const uploadExcelFile = async (data) => {
    var response = await postRequest(configUrl.uploadExcel, data)
    if (response.status === 201) {
      snackBarContent(true, response.data.message)
      getEmployeeList()
      setExcelModal(false)
      setFile()
    } else if (response.response.status === 404 || response.response.status === 400 || response.response.status === 409) {
      snackBarContent(false, response.response.data.message)
      setFile()
      document.getElementById('files').value = '';
    }
  }

  const handlesnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };
  // ------import Excel data ends------- 

  const formatDate = (dateString) => {
    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const _formatDate = (dateString) => {
    if (dateString) {
      var date = new Date(dateString);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();

      var formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
      return formattedDate
    } else return ''
  }

  // ------export data to the Excel------- 
  const handleExportToExcel = () => {
    // Map the data to match the desired header names
    const mappedData = employeedata.map(item => ({
      "employee_id*": item.employee_id,
      "name*": item.name,
      "gender*": item.gender_name,
      "office_email*": item.email,
      "phone_number*": item.phone_number,
      "date_of_joining*": new Date(item.date_of_joining).toLocaleDateString('en-US'),
      "previous_years_of_experience": item.years_of_experience,
      "father_name*": item.father_name,
      "mother_name*": item.mother_name,
      "date_of_birth*": new Date(item.date_of_birth).toLocaleDateString('en-US'),
      "permanent_address*": item.permanent_address,
      "postal_address*": item.postal_address,
      "blood_group*": item.blood_group,
      "marital_status*": item.marital_name,
      "personal_email*": item.personal_email,
      "emergency_contact_number*": item.emergency_contact,
      "emergency_contact_name*": item.emergency_contact_name,
      "emergency_contact_relation*": item.emergency_contact_relation,
      "year_of_passing": item.year_of_passing,
      "university_name": item.university_name,
      "highest_qualification": item.highest_qualification,
      "esi_number": item.esi_number,
      "pf_number": item.pf_number,
      "uan_number": item.uan_number,
      "pan_number*": item.pan_number,
      "aadhaar_number*": item.aadhaar_number,
      "bank_account_number*": item.bank_account_number,
      "bank_account_type*": item.bank_account_type_name,
      "ifsc_code*": item.ifsc_code
    }));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create a new worksheet from the mapped data
    const worksheet = XLSX.utils.json_to_sheet(mappedData);

    // Add the modified worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, 'Employeedata.xlsx');
  };


  return (
    <>
      <div className="createuserdiv mx-lg-5">
        <div className="d-block d-md-flex justify-content-between mt-4">
          <div>
            <h3> {isCreateUser ? "Create New Employee" : "Employees List"}</h3>
          </div>
          <div>
            <Link id="dashboardbtn" to="/" className="goback">
              Back to Dashboard
            </Link>

            <div className="w-100 text-end">
              {/* <CreateButton
                name={isCreateUser ? "List of Employees" : "Create New Employees"}
                handleClick={isCreateUser ? showUsersList : showCreateUser}
              /> */}
              <button id="createemployeebtn" type="button" className="createSubAdminBtn"
                onClick={isCreateUser ? showUsersList : showCreateUser}>
                {isCreateUser ? "List of Employees" : "Create New Employees"}
              </button>
            </div>

            {/* add the buttons for the excel upload and download the excel template */}
            {isCreateUser ? null :
              <div className="excel_upload_btn_div mt-4 d-md-flex text-end">
                <a id="downloadexcelbtn"
                  href={Images.excel_file}
                  className="btn btn-info text-light mx-md-2"
                >
                  Download Excel Template
                </a>
                <div>
                  <Button
                    id="uploadexcelbtn"
                    variant="contained"
                    color="primary"
                    className="uploadBtn my-2 my-md-0"
                    onClick={() => setExcelModal(true)}
                  >
                    Upload Excel
                  </Button>
                </div>
                <div>
                  <button id="exportexcelbtn" onClick={handleExportToExcel} className="btn btn-success ms-2">Export to Excel</button>
                </div>
              </div>
            }
          </div>
        </div>

        {isCreateUser ? (
          <>
            {/* <Container sx={{ my: 4 }}> */}
            <Stepper alternativeLabel activeStep={activeStep} sx={{ my: 4 }} className="mob_style">
              {steps.map((step, index) => (
                <Step key={step.label} completed={step.completed} onClick={editInfo ? (superAdminUpdate ? () => { } : () => handleupdateAction(index)) : () => { }}>
                  <StepButton id={`stepbtn${index + 1}`}>{step.label}</StepButton>
                </Step>
              ))}
            </Stepper>
            <div className="form_container p-4">{InfoDiv()}</div>
            {/* </Container> */}
          </>
        ) : (
          <>
            <EmployeeTable EmployeeData={employeedata}
              onViewClick={handleEmployeeView} handleDeleteClick={handledeleteModal} />
          </>
        )}
      </div>

      <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
        snackbg={success} message={snackMessage} />

      <ModalComponent show={detailModal} onHide={handleDetailModal}
        modal_header={<>
          {individualInfo.map((item, i) => {
            return (
              <h4 key={i}>Details of <span className="text-primary text-capitalize">{item.name}</span> </h4>
            )
          })}
        </>}
        modal_body={<>

          {individualInfo.map((item, i) => {
            return (
              <div key={i}>
                <div className="mb-3">
                  <h5 className="mb-2 text-decoration-underline font_color">Basic Details</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="font_color">
                        Name: <strong> {item.name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Gender: <strong> {item.gender_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Contact Number: <strong> {item.phone_number}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Employee Id: <strong> {item.employee_id}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h5 className="mb-2 text-decoration-underline font_color">Work Details</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="font_color">
                        Office Email: <strong> {item.email}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Employee Type: <strong> {item.employee_type_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Date of Joining: <strong> {item.date_of_joining ? formatDate(item.date_of_joining) : ""}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Years of Experience(previous) : <strong> {item.years_of_experience ? item.years_of_experience : "0"} years</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Years of Experience(Dollarbird) : <strong> {calculateExp}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        shifts: <strong> {item.shift_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Department: <strong> {item.department_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Designation: <strong> {item.designation_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex">
                        <p className="font_color">
                          Reporting Person:
                        </p>
                        <p className="font_color ms-1">
                          <strong> {item.reporting_person_information &&
                            item.reporting_person_information.length > 0 &&
                            item.reporting_person_information.map((item, i) => {
                              return (<span key={i}>{`${item.employee_id} - ${item.name}`}</span>)
                            })
                          }</strong>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex">
                        <p className="font_color">
                          Matrix Manager:
                        </p>
                        <div>
                          <strong> {item.matrix_manager_information &&
                            item.matrix_manager_information.length > 0 &&
                            item.matrix_manager_information.map((item1, index) => {
                              return (<p className="font_color my-0 ms-1" key={index}>{`${item1.employee_id} - ${item1.name}`}
                                {index !== item.matrix_manager_information.length - 1 && ", "}
                              </p>)
                            })
                          }</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h5 className="mb-2 text-decoration-underline font_color">Personal Information</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="font_color">
                        Date of Birth: <strong> {item.date_of_birth ? formatDate(item.date_of_birth) : ""}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Father Name : <strong> {item.father_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Mother Name: <strong> {item.mother_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Permanent Address: <strong> {item.permanent_address}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Postal Address: <strong> {item.postal_address}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Personal Email: <strong> {item.personal_email}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Blood Group: <strong> {item.blood_group}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Marital Status: <strong> {item.marital_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Emergency Contact Person Name: <strong> {item.emergency_contact_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Emergency Contact Person Relation: <strong> {item.emergency_contact_relation}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Emergency Contact Number: <strong> {item.emergency_contact}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h5 className="mb-2 text-decoration-underline font_color">Educational Details</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="font_color">
                        Highest Qualification : <strong> {item.highest_qualification}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        University Name : <strong> {item.university_name}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Years of Passing : <strong> {item.year_of_passing}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h5 className="mb-2 text-decoration-underline font_color">Account Information</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="font_color">
                        ESI Number: <strong> {item.esi_number}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        PF Number : <strong> {item.pf_number}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        UAN Number: <strong> {item.uan_number}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        PAN Number: <strong> {item.pan_number}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Aadhaar Number : <strong> {item.aadhaar_number}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Bank Account Number: <strong> {item.bank_account_number}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        IFSC code: <strong> {item.ifsc_code}</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="font_color">
                        Bank Account Type: <strong> {item.bank_account_type_name}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <h5 className="mb-2 text-decoration-underline font_color">Assigned Products</h5>
                  {item.assignedProducts.length > 0 ? <>
                    <div className="d-flex my-2">
                      <div className="col-md-3 font_color text-decoration-underline text-center">Product Name</div>
                      <div className="col-md-3 font_color text-decoration-underline text-center">Product Label No.</div>
                      <div className="col-md-3 font_color text-decoration-underline text-center">Date of Assign</div>
                    </div>
                    {item.assignedProducts.map((item, i) => {
                      return (<div className="d-flex my-1" key={i}>
                        <div className="col-md-3 font_color text-center">{item.productName}</div>
                        <div className="col-md-3 font_color text-center">{item.productLabel}</div>
                        <div className="col-md-3 font_color text-center">{item.assignDate}</div>
                      </div>)
                    })}
                  </> : <div>
                    <h6 className="font_color my-4">Products are not assigned</h6>
                  </div>}
                </div>

              </div>
            )
          })}

          <div className="text-end mt-4 mb-2">
            <button id="closebtn" className="btn btn-light mx-2" onClick={handleDetailModal}>
              Close
            </button>
            <button id="editbtn" className="btn btn-light" onClick={() => handleEmployeeUpdate(individualInfo[0])}>
              Edit Information
            </button>
          </div>
        </>}
      />

      {/* delete modal */}
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="bg-light">
          <h5>Are You Sure to Remove the Employee- {deleteId} </h5>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <button id="deleteclose" className="btn btn-primary" onClick={() => setDeleteModal(false)}>
            Close
          </button>
          <button id="deletebtn" className="btn btn-danger" onClick={() => handleCheckChildrens(deleteId)}>
            Delete Employee
          </button>
        </Modal.Footer>
      </Modal>


      {/* excel upload modal */}
      <Modal show={excelModal} onHide={() => {
        setExcelModal(false)
        setFile()
      }}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="bg-light">
          <h5 className="text-primary">Upload Excel File</h5>
          <div className="mt-4">
            <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} ref={inputElement} id="files" hidden />
            <label for="files" className="file_field">Select file</label>
            <div className="mt-2">{file && `Selected file - ${file.name}`}</div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <button id="excelclose" className="btn btn-primary" onClick={() => setExcelModal(false)}>
            Close
          </button>
          <button id="excelsubmitbtn" className="btn btn-success" onClick={() => handleUploadClick()}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Snackbar open={_open} autoHideDuration={4000} onClose={() => setDeleteOpen(false)} className='snackbardiv'>
        <Alert onClose={() => setDeleteOpen(false)} severity={"error"} sx={{ width: '100%' }} className="snack_style bg-danger">
          Employee cannot be deleted because they are currently have the team members assigned to this Employee
        </Alert>
      </Snackbar>

      <Modal backdrop="static" centered show={superAdminModal} onHide={() => setSuperAdminModal(false)}>
        <Modal.Body className="bg-light rounded">
          <h5>Please update Admin details</h5>
          <div className="text-end mt-3">
            <a className="btn btn-success" href={RouteStrings.userprofile}>Update Details</a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
