import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useFilters,
    useExpanded,
} from "react-table";
import "../LeavesTable/leavestable.scss";
import { GlobalFilter } from "../../utils/GlobalFilter";
import { ColumnFilter } from "../../utils/ColumnFilter";
import { Images } from "../../utils/images";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { TableNavigation } from "../../utils/TableNavigation";

export const AllLeavesList = (props) => {
    const { Data, refreshData, financialYear } = props
    const [show, setShow] = useState(false);
    const [rejected, setRejected] = useState(false);

    const [leaveData, setLeaveData] = useState([])

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [leaveId, setleaveId] = useState()
    const [leavecount, setLeavesCount] = useState([])

    const { getRequest, putRequest } = PostRequestHook()

    const { userInfo } = useSelector((state) => state.UIStore);
    const organization_name = userInfo ? userInfo.organization_name : null
    const admin_id = userInfo ? userInfo.emp_id ? userInfo.emp_id : userInfo.id : null
    const admin_name = userInfo ? userInfo.full_name : null
    const admin_email = userInfo ? userInfo.work_email : null
    const admin_role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    // useEffect(() => {
    //     var leaveData = Data ? Data : []
    //     setFieldData(leaveData)
    // }, [Data])

    const handleShow = (data, data1) => {
        var emp_name = data.cell.row.original.emp_name
        var emp_id = data.cell.row.original.emp_id
        var employeeGender = data.cell.row.original.gender_name
        let data2 = { ...data1, emp_name: emp_name, emp_id: emp_id }
        setLeaveData([data2])
        getLeaveCount(emp_id, employeeGender)
        setleaveId(data1.id)
        formik.setValues({
            is_approved: data2.is_approved === 0 ? "" : data2.is_approved,
            reason_to_reject: data2.reason_to_reject
        })
    };

    const getLeaveCount = async (emp_id, employeeGender) => {
        var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/total_count/${emp_id}`)
        if (response) {
            var sortedLeaveType = (response?.data?.leave_count ? response?.data?.leave_count : []).filter(item => {
                if (employeeGender === "male" && item.type === "Maternity Leave") {
                    return false
                } else if (employeeGender === "female" && item.type === "Paternity Leave") {
                    return false;
                }
                return true;
            })
            setLeavesCount(sortedLeaveType)
            setShow(true);
        }
    }

    const changeStatusForLeave = (e) => {
        if (e.target.value === "1") {
            setRejected(false);
        } else if (e.target.value === "2") {
            setRejected(true);
        }
        formik.setFieldValue("is_approved", e.target.value)
    };

    const handleClose = () => {
        setShow(false);
        setLeaveData([])
        formik.resetForm()
        setRejected(false)
    };

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: "Emp ID",
                accessor: "emp_id",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return <span>{cellProps.cell.row.original.emp_id}</span>;
                },
            },

            {
                Header: "Name",
                accessor: "emp_name",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="NameCell">
                            <span className="name_cell_data">
                                {cellProps.cell.row.original.emp_name}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "Leave Type",
                accessor: "leave_type",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.data.map((val) => (
                                <span className="cell_data" style={{ display: "block" }}>
                                    {val.leave_type_name}
                                </span>
                            ))}
                        </div>
                    );
                },
            },
            {

                // Header: <div style={{ width: "220px" }}>Leave Dates</div>,
                Header: "Leave Dates",
                accessor: "dates",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.data.map((val) => {
                                let result = [];
                                const dateList = val.dates
                                if (val.leave_type !== 4) {
                                    const dates = dateList.split(",");


                                    for (let i = 0; i < dates.length; i++) {
                                        const currentDate = new Date(dates[i]);
                                        const nextDate = new Date(dates[i + 1]);


                                        if (i < 2) {
                                            result.push(formatDate(dates[i]));
                                        }


                                        if (nextDate - currentDate !== 86400000) {
                                            break;
                                        }
                                    }

                                    if (result.length < dates.length) {
                                        result.push("...");
                                    }
                                } else {
                                    const newDateRange = maternityDateFormat(dateList)
                                    result.push(newDateRange)
                                }
                                return (
                                    <span className="cell_data" style={{ display: "block" }}>
                                        {result.join(", ")}
                                    </span>
                                )
                            }
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "No. of Days",
                accessor: "no_of_days",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.data.map((val) => (
                                <span className="cell_data" style={{ display: "block" }}>
                                    {val.isHalfDay == 1 ? "Half Day" : (val.leave_type == 4 ? `${val.no_of_days} months` : val.no_of_days)}
                                </span>
                            ))}
                        </div>
                    );
                },
            },

            {
                Header: "Status",
                accessor: "to",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.data.map((val) => (
                                <span className="cell_data" style={{ display: "block" }}>
                                    {val.is_approved == 0 ? "New" : (val.is_approved == 1 ? "Approved" : "Rejected")}
                                    <>
                                    </>
                                </span>
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: "Approval By",
                accessor: "action_taken_by_name",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.data.map((val) => (
                                <span className="cell_data" style={{ display: "block" }}>
                                    {val.action_taken_by_name ? `${val.action_taken_by_id} - ${val.action_taken_by_name}` : "---"}
                                </span>
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: "Approval action",
                accessor: "action",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    const leave = cellProps.cell.row.original.data;
                    return (
                        <div className="cellStyles">
                            {leave.map((val) => (
                                <div className="cell_data">
                                    <img
                                        src={val.is_approved == 0 ? Images.editLogo : Images.viewIcon}
                                        alt="icon"
                                        id={`edit${val.id}`}
                                        className="editIcon mx-2"
                                        onClick={() => handleShow(cellProps, val)}
                                    />
                                </div>
                            ))}
                        </div>
                    );

                },
            },
        ],
        []
    );

    const data = useMemo(() => Data);

    // create a table instance
    const {
        tableInstance,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        useExpanded,
        usePagination
    );

    const { globalFilter, pageSize, pageIndex } = state;

    const formik = useFormik({
        initialValues: {
            is_approved: "",
            reason_to_reject: "",
        },
        validate: (values) => {
            let errors = {};
            if (!values.is_approved) {
                errors.is_approved = "Select Checkbox";
            }
            if (values.is_approved == "0" || values.is_approved == "2") {
                if (!values.reason_to_reject) {
                    errors.reason_to_reject = "Required";
                }
            }
            return errors;
        },
        onSubmit: async (value) => {
            var values = {
                ...value, is_approved: Number(value.is_approved), action_taken_by: admin_email,
                action_taken_by_id: admin_id, action_taken_by_name: admin_name, isHalfDay: leaveData?.[0]?.isHalfDay || '',
            }
            var response = await putRequest(`${configUrl.approveLeave}${organization_name}/update_leave/${leaveId}`,
                values)

            if (response) {
                if (response.status == 201 || response.status == 200) {
                    setSuccess(true)
                    setSnackMessage(response.data.message)
                    setSnackOpen(true)
                    setRejected(false)
                    setShow(false)
                    refreshData()
                } else if (response.response.status == 400 || response.response.data.status == 409) {
                    setSuccess(false)
                    setSnackMessage(response.response.data.message)
                    setSnackOpen(true)
                }
            }
        },
    });

    // date format change
    const formatDate = (dateString) => {
        var date = new Date(dateString);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var formattedDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
        return formattedDate
    }

    // change the date format 
    const maternityDateFormat = (dateList) => {
        const [startDate, endDate] = dateList.split(" to ");
        const newDateRange = `${formatDate(startDate)} to ${formatDate(endDate)}`;
        return newDateRange
    }

    // change the otherleaveDatesFormat
    const othersDateFormat = (originalDateRange) => {
        const dateArray = originalDateRange.split(",");

        const newDateArray = dateArray.map((date) => {
            return `${formatDate(date)}`;
        });

        const newDateRange = newDateArray.join(", ");
        return newDateRange
    }

    return (
        <>
            <div className="mt-5 mb-2 d-flex justify-content-between">
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                <div>
                    <select
                        id="tablenumber"
                        value={pageSize}
                        className="selectTag"
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {[5, 10, 15, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="text-center">
                <div className="table_scroll">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {Data.length > 0 ?
                    <div className="text-center">
                        <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
                            nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
                    </div> : <h5>No Data Found for Financial Year {Object.keys(financialYear).length > 0 && <>{formatDate(financialYear.financialYearStart)} to {formatDate(financialYear.financialYearEnd)}</>}</h5>}
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

            {/* Modal for the view field data */}
            <Modal show={show} onHide={handleClose}>
                {leaveData.map(item => {
                    return (
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>Leave Application of {item.emp_name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modalBody pb-3">
                                    <h5 className="font_color">Details</h5>

                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Employee Id:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.emp_id}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Employee Name:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.emp_name}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Leave Type:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.leave_type_name}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">leave Days:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.leave_type == 4 ? `${item.no_of_days} months` : item.no_of_days}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Leave Dates:</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color">{item.leave_type === 4 ? maternityDateFormat(item.dates) : othersDateFormat(item.dates)}</p>
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-6 text-end">
                                            <p className="mb-0 font_color">Reason for Leave :</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="mb-0 font_color word_wrap">{item.reason}</p>
                                        </div>
                                    </div>
                                    {leavecount.map((item, i) => {
                                        return (
                                            <>
                                                {(item.type == "Loss Of Pay" || item.type == "Work From Home") ? <div className="row my-2" key={i}>
                                                    <div className="col-6 text-end">
                                                        <p className="mb-0 font_color">{`Approved ${item.type}`}:</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="mb-0 font_color">{item.no_of_days}</p>
                                                    </div>
                                                </div> :
                                                    <div className="row my-2" key={i}>
                                                        <div className="col-6 text-end">
                                                            <p className="mb-0 font_color">{`Available ${item.type}`}:</p>
                                                        </div>
                                                        <div className="col-6">
                                                            {/* <p className="mb-0 font_color">{item.type == "Maternity Leave" ? `${item.total_leaves - item.no_of_days} months` : (item.total_leaves - item.no_of_days)}</p> */}
                                                            <p className="mb-0 font_color">{`${item.total_leaves - item.no_of_days} ${item.type == "Maternity Leave" ? "months" : ''}`}</p>
                                                        </div>
                                                    </div>}
                                            </>
                                        )
                                    })}
                                    <Form onSubmit={formik.handleSubmit} autoComplete="off">
                                        <div className="row my-2">
                                            <div className="col-6 text-end">
                                                <p className="mb-0 font_color">Status:</p>
                                            </div>
                                            <div className="col-6">
                                                <div className="mb-3">
                                                    <div>
                                                        <Form.Check
                                                            inline
                                                            label="Accept"
                                                            className="font_color"
                                                            name="is_approved"
                                                            type="radio"
                                                            value="1"
                                                            onChange={(e) => {
                                                                formik.handleChange(e)
                                                                changeStatusForLeave(e)
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            defaultChecked={item.is_approved == 1 ? true : false}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Reject"
                                                            className="font_color"
                                                            name="is_approved"
                                                            type="radio"
                                                            value="2"
                                                            onBlur={formik.handleBlur}
                                                            onChange={(e) => {
                                                                formik.handleChange(e)
                                                                changeStatusForLeave(e)
                                                            }}
                                                            defaultChecked={item.is_approved == 2 ? true : false}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched.is_approved && formik.errors.is_approved ? (
                                                            <small className="text-danger">
                                                                {formik.errors.is_approved}
                                                            </small>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                            {rejected ? (
                                                <>
                                                    <div className="row my-2">
                                                        <div className="col-6 text-end">
                                                            <p className="mb-0 font_color">Remarks:</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <textarea
                                                                name="reason_to_reject"
                                                                id="reason"
                                                                cols="30"
                                                                rows="10"
                                                                placeholder="Enter the remark"
                                                                className="_reason_text_area w-100"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.reason_to_reject}
                                                            ></textarea>
                                                            {
                                                                formik.touched.reason_to_reject && formik.errors.reason_to_reject ? (
                                                                    <small className="text-danger">
                                                                        {formik.errors.reason_to_reject}
                                                                    </small>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ) : <>{formik.values.is_approved === 2 &&
                                                <div className="row my-2">
                                                    <div className="col-6 text-end">
                                                        <p className="mb-0 font_color">Reason to Reject :</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="mb-0 font_color">{item.reason_to_reject}</p>
                                                    </div>
                                                </div>
                                            }</>}

                                        </div>

                                        <div className="text-end pe-4">
                                            <Button
                                                type="submit"
                                                className="btn btn-light"
                                            >
                                                Submit
                                            </Button>
                                        </div>


                                    </Form>
                                </div>
                            </Modal.Body>
                        </>
                    )
                })}
            </Modal>
        </>
    );
};
