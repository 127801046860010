import React, { useEffect, useState } from 'react'
import "./createshift.scss"
import { Link } from 'react-router-dom'
import { Button, Form, Table } from 'react-bootstrap'
import { Images } from '../../utils/images'
import { Input_element } from '../../components/input_field/Input_element'
import { useFormik } from 'formik'
import { PostRequestHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { SnackBar } from '../../components/SnackBars/SnackBar'
import { useDispatch } from 'react-redux'
import { setDropdownlist } from '../../store/reducers/ui.reducer'

export const CreateShift = () => {
    const [createShift, setCreateShift] = useState(true)
    const [shiftlist, setShiftList] = useState([])
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [editData, setEditData] = useState("")

    const { getRequest, postRequest, putRequest } = PostRequestHook()

    useEffect(() => {
        getShifts()
    }, [])
    const getShifts = async () => {
        var shifts = await getRequest(configUrl.getAllShifts)
        if (shifts) {
            setShiftList(shifts?.data?.data ? shifts?.data?.data : [])
        }
    }
    const dispatch = useDispatch()
    const getDropdownData = async () => {
        var response = await getRequest(configUrl.getAllDropdowns)
        dispatch(setDropdownlist({
            dropdownList: response?.data?.data
        }))
    }

    const formik = useFormik({
        initialValues: {
            shift_name: ""
        },
        validate: (values) => {
            let errors = {}
            if (!values.shift_name) {
                errors.shift_name = "Required"
            }
            return errors
        },
        onSubmit: async (values, { resetForm }) => {

            var response = editData ? await putRequest(`${configUrl.editShift}${editData}`, values)
                : await postRequest(configUrl.createShift, values)
            if (response.status == 201 || response.status == 200) {
                setSuccess(true)
                setSnackMessage(response.data.message)
                getShifts()
                setSnackOpen(true)
                setCreateShift(true)
                resetForm()
                getDropdownData()
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                setSuccess(false)
                setSnackMessage(response.response.data.message)
                setSnackOpen(true)
            }
        }
    })

    const handleShiftDelete = async (e, id) => {
        var response = await putRequest(`${configUrl.deleteShift}${id}`)
        if (response.status == 200) {
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            getShifts()
            getDropdownData()
            setEditData("")
        }
    }
    const handleShiftEdit = (e, id) => {
        let xyz = shiftlist.filter((item) => item.id == id)
        formik.setFieldValue(formik.initialValues.shift_name = xyz[0].shift_name)
        setEditData(xyz[0].id)
        setCreateShift(false)
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };


    return (
        <>
            <div className='shift_style'>
                <div className="d-flex justify-content-between mb-4">
                    <div><h3>Shifts</h3></div>
                    <div className='text-end'>
                        <Link id='dashboardbtn' to="/" className="goback">Back to Dashboard</Link>
                        <Button id='createbtn' type='button' onClick={createShift ? () => {
                            setCreateShift(false)
                            formik.setFieldValue(formik.initialValues.shift_name = "")
                            setEditData("")
                        } : () => {
                            setCreateShift(true)
                            setEditData("")
                        }}>
                            {createShift ? "Create New Shift" : "Shift List"}</Button>
                    </div>
                </div>
                <div className='shifts'>
                    {createShift ?
                        <div className='table_shift_div'>
                            <h5 className='mb-3'>Shift List</h5>
                            <div className='table_scroll'>
                                <Table striped bordered className='table_shift mb-0'>
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Shifts</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shiftlist.map((value, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{value.shift_name}</td>
                                                    <td className=''>{value.id > 1 ? <div className='d-flex justify-content-center'>
                                                        <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleShiftEdit(e, value.id)} />
                                                        <img src={Images.deleteLogo} id={`delete${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleShiftDelete(e, value.id)} />
                                                    </div> :
                                                        <>
                                                            <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleShiftEdit(e, value.id)} />
                                                        </>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        :
                        <div className='shift_form'>
                            <h5 className="mb-3 font_color">Add Shift Type</h5>
                            <Form onSubmit={formik.handleSubmit}>
                                <Input_element input_label="Shift Type"
                                    type="text" name="shift_name" lableClass="font_color"
                                    handleChange={formik.handleChange} handleBlur={formik.handleBlur}
                                    value={formik.values.shift_name}
                                    placeholder="Enter Shift name"
                                    formikValidation={formik.touched.shift_name && formik.errors.shift_name ? <small className='text-danger position-absolute'>{formik.errors.shift_name}</small> : null}
                                />
                                <div className='text-end mt-4'>
                                    <Button id={editData ? "updatebtn" : "addbtn"} type="submit">{editData ? "Update" : "Add"}</Button></div>
                            </Form>
                        </div>
                    }

                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    )
}
