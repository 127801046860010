import React from "react";
import { useNavigate } from "react-router-dom";
import "./dashboardhome.scss";
import { RouteStrings } from "../../utils/common";
import { useSelector } from "react-redux";
import { DashboardEmployee } from "../Employeedashboard/DashboardEmployee";

export const DashBoardhome = () => {

  const { userInfo } = useSelector((state) => state.UIStore);
  var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null


  return (
    <>
      {/* {!(role == "super_admin" || role == "hr") ? <>
        <DashboardEmployee />
      </> : <>
        <div className="container dashbaord-body my-4">
          <h3 className="header_color mb-5">Dashboard</h3>
          <div className="my-3">
            <div className="row">
              <div className="col-md-3">
                <div className="card p-3">
                  <h6>Leaves Count</h6>
                  <div className="w-100 text-end">
                    <p className="mb-0">
                      <strong>0</strong>
                    </p>
                    <p className="mb-0">Leaves</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>} */}
      <DashboardEmployee />
    </>

  );
};
