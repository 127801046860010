import React, { useEffect, useState } from 'react'
import './createrole.scss'
import { Button, Form, Table } from 'react-bootstrap'
import { Input_element } from '../../components/input_field/Input_element'
import { Selectelement } from '../../components/Select_field/Selectelement'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { PostRequestHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { SnackBar } from '../../components/SnackBars/SnackBar'
import { Images } from '../../utils/images'
import { RegEx } from '../../utils/RegEx'
import Select from 'react-select';

export const CreateRole = () => {
    const [viewRole, setViewRole] = useState(false)
    const [createRole, setCreateRole] = useState(false)
    const [assignRole, setAssignRole] = useState(false)
    const [typeId, setTypeId] = useState();

    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [success, setSuccess] = useState(false)

    const [roles, setRoles] = useState([])
    const [employeeArray, setEmployeeArray] = useState([])
    const [adminArray, setAdminArray] = useState([])

    const { getRequest, postRequest, putRequest } = PostRequestHook()

    useEffect(() => {
        getRoledata()
        getEmployeelist()
    }, [])

    useEffect(() => {
        getAdmins()
    }, [assignRole])

    const getRoledata = async () => {
        var roleList = await getRequest(configUrl.getAllRoles)
        if (roleList) {
            setRoles(roleList?.data?.data ? roleList?.data?.data : [])
        }
    }
    const getAdmins = async () => {
        var adminList = await getRequest(configUrl.getAdmins)
        if (adminList) {
            setAdminArray(adminList?.data?.data ? adminList?.data?.data : [])
        }
    }
    const getEmployeelist = async () => {
        var employeeData = await getRequest(configUrl.employeelist)
        var converted_data = Array.converterid(employeeData?.data?.data ? employeeData?.data?.data : [])
        setEmployeeArray(converted_data)
    }

    const formikRole = useFormik({
        initialValues: {
            role_name: ""
        },
        validate: (values) => {
            let errors = {}
            if (!values.role_name) {
                errors.role_name = "Required"
            } else if (!RegEx.name__regEx.test(values.role_name)) {
                errors.role_name = "Alphabets only"
            }
            return errors
        },
        onSubmit: async (value, { resetForm }) => {
            const response = typeId ? await putRequest(`${configUrl.updateRole}${typeId}`, value) :
                await postRequest(configUrl.createrole, value)
            if (response) {
                if (response.status == 201 || response.status == 200) {
                    setSuccess(true)
                    setSnackMessage(response.data.message)
                    setSnackOpen(true)
                    getRoledata()
                    setCreateRole(false)
                    setTypeId()
                    resetForm()
                } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                    setSuccess(false)
                    setSnackMessage(response.response.data.message)
                    setSnackOpen(true)
                }
            }
        }
    })


    const formik = useFormik({
        initialValues: {
            employee_id: "",
            role_id: ""
        },
        validate: (values) => {
            let errors = {}
            if (!values.employee_id) {
                errors.employee_id = "select employee"
            }
            if (!values.role_id) {
                errors.role_id = "select role"
            }
            return errors
        },
        onSubmit: async (value, { resetForm }) => {
            var value2 = { ...value, role_id: Number(value.role_id) }
            const response = await putRequest(configUrl.assignrole, value2)
            if (response.status == 201 || response.status == 200) {
                setSuccess(true)
                setSnackMessage(response.data.message)
                resetForm()
                setAssignRole(false)
                setSnackOpen(true)
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                setSuccess(false)
                setSnackMessage(response.response.data.message)
                setSnackOpen(true)
            }
        }
    })

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleTypeDelete = async (e, id) => {
        var response = await putRequest(`${configUrl.deleteRole}${id}`)
        if (response.status == 200) {
            setSuccess(true)
            setSnackMessage(response.data.message)
            getRoledata()
            setSnackOpen(true)
        }
    }

    const handleTypeEdit = (e, id) => {
        var xyz = roles.filter(item => item.id == id)
        formikRole.setFieldValue("role_name", xyz[0].role_name)
        setTypeId(xyz[0].id)
        setCreateRole(true)
    }

    return (
        <>
            <div className="createrole_style">
                <div className="d-flex justify-content-between mb-4">
                    <div><h3>{assignRole ? "Assign Role" : "Assigned Roles List"}</h3></div>
                    <div className='text-end'>
                        <Link id='dashboardbtn' to="/" className="goback">Back to Dashboard</Link>
                        {/* <Button id='rolebtn' type='button' onClick={viewRole ? () => setViewRole(false) : () => setViewRole(true)}>{viewRole ? "Assign Roles list" : "Create Roles"}</Button> */}
                        <Button id='assignbtn' type='button' onClick={assignRole ? () => setAssignRole(false) : () => setAssignRole(true)}>
                            {assignRole ? "Assigned List" : "Assign Role"}</Button>
                    </div>
                </div>
                <div className='table_div'>
                    {/* <h5>
                        {assignRole ? "Assign Role" : "Assigned Roles List"}
                    </h5> */}
                    {/* <div className="mb-4 text-end">
                        <Button id='assignbtn' type='button' className='mx-3' onClick={assignRole ? () => setAssignRole(false) : () => setAssignRole(true)}>
                            {assignRole ? "Assigned List" : "Assign Role"}</Button>
                    </div> */}
                    {assignRole ?
                        <div className='formforgot_width'>
                            <Form onSubmit={formik.handleSubmit}>
                                <div className='mb-4'>
                                    <label className='font_color mb-2'>Employee Name</label>
                                    <Select
                                        id='employeeid'
                                        onBlur={formik.handleBlur}
                                        onChange={(item) => {
                                            formik.setFieldValue("employee_id", item.value)
                                        }}
                                        options={employeeArray}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                    {formik.touched.employee_id && formik.errors.employee_id ? <small className='text-danger'>{formik.errors.employee_id}</small> : null}
                                </div>
                                <Selectelement select_Label="Role" lableClass="font_color" name="role_id" handleChange={formik.handleChange} handleBlur={formik.handleBlur}
                                    optionArray={roles.length == 0 ? null : <>
                                        {roles.map((value, i) => {
                                            return (
                                                <option key={i} value={value.id}>
                                                    {value.role_name}
                                                </option>
                                            );
                                        })}
                                    </>}
                                    formikValidation={formik.touched.role_id && formik.errors.role_id ? <small className='text-danger'>{formik.errors.role_id}</small> : null}
                                />
                                <div className='text-end mt-4'><Button id='submitbtn' type="submit">Submit</Button></div>
                            </Form>
                        </div> :
                        <div className='table_scroll mt-5 pt-5'>
                            <Table striped bordered hover className='table_assignrole mb-0'>
                                <thead>
                                    <tr>
                                        <th>Emp Id</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {adminArray.length == 0 ? null : <>
                                        {adminArray.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item.employee_id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.role_name}</td>
                                                    {/* <td><Button type='button' className='edit_role' onClick={() => setAssignRole(true)}>Reset</Button></td> */}
                                                </tr>
                                            )
                                        })}
                                    </>}
                                </tbody>
                            </Table>
                        </div>
                    }

                </div>
                {/* <div className='mt-5'>
                    {viewRole ?
                        <div className='role_div'>
                            <h5>
                                {createRole ? "Add Roles" : "Roles List"}
                            </h5>
                            <div className="mb-4 text-end">
                                <Button id='createbtn' type='button' className='mx-3' onClick={createRole ? () => {
                                    setCreateRole(false)
                                    formikRole.setFieldValue("role_name", "")
                                    setTypeId()
                                }
                                    : () => {
                                        setCreateRole(true)
                                        setTypeId()
                                    }}
                                >{createRole ? "Roles List" : "Add New Role"}   </Button>
                            </div>
                            {createRole ?
                                <div className='formforgot_width'>
                                    <Form onSubmit={formikRole.handleSubmit}>
                                        <Input_element input_label="Role Name" name="role_name" lableClass="font_color" handleChange={formikRole.handleChange}
                                            handleBlur={formikRole.handleBlur} value={formikRole.values.role_name} type="text" placeholder="Enter Role"
                                            formikValidation={formikRole.touched.role_name && formikRole.errors.role_name ?
                                                <small className='text-danger'>{formikRole.errors.role_name}</small> : null}
                                        />
                                        <div className='text-end mt-4'><Button id={typeId ? "updatebtn" : "addbtn"} type="submit">{typeId ? "Update" : "Add"}</Button></div>
                                    </Form>
                                </div> :
                                <>
                                    <div className='roles table_scroll'>
                                        <Table striped bordered hover className='table_role mb-0'>
                                            <thead>
                                                <tr>
                                                    <th>Sl. no.</th>
                                                    <th>Roles</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {roles.length === 0 ? null :
                                                    <>
                                                        {roles.map((value, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{value.role_name}</td>
                                                                    <td>{value.id > 3 ? <div className="d-flex justify-content-center">
                                                                        <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                                                                        <img src={Images.deleteLogo} id={`delete${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeDelete(e, value.id)} />
                                                                    </div> : null}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </>}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>}

                        </div> :
                        <div className='table_div'>
                            <h5>
                                {assignRole ? "Assign Role" : "Assigned Roles List"}
                            </h5>
                            <div className="mb-4 text-end">
                                <Button id='assignbtn' type='button' className='mx-3' onClick={assignRole ? () => setAssignRole(false) : () => setAssignRole(true)}>
                                    {assignRole ? "Assigned List" : "Assign Role"}</Button>
                            </div>
                            {assignRole ?
                                <div className='formforgot_width'>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <div className='mb-4'>
                                            <label className='font_color mb-2'>Employee Name</label>
                                            <Select
                                                id='employeeid'
                                                onBlur={formik.handleBlur}
                                                onChange={(item) => {
                                                    formik.setFieldValue("employee_id", item.value)
                                                }}
                                                options={employeeArray}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                            {formik.touched.employee_id && formik.errors.employee_id ? <small className='text-danger'>{formik.errors.employee_id}</small> : null}
                                        </div>
                                        <Selectelement select_Label="Role" lableClass="font_color" name="role_id" handleChange={formik.handleChange} handleBlur={formik.handleBlur}
                                            optionArray={roles.length == 0 ? null : <>
                                                {roles.map((value, i) => {
                                                    return (
                                                        <option key={i} value={value.id}>
                                                            {value.role_name}
                                                        </option>
                                                    );
                                                })}
                                            </>}
                                            formikValidation={formik.touched.role_id && formik.errors.role_id ? <small className='text-danger'>{formik.errors.role_id}</small> : null}
                                        />
                                        <div className='text-end mt-4'><Button id='submitbtn' type="submit">Submit</Button></div>
                                    </Form>
                                </div> :
                                <div className='table_scroll'>
                                    <Table striped bordered hover className='table_assignrole mb-0'>
                                        <thead>
                                            <tr>
                                                <th>Emp Id</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adminArray.length == 0 ? null : <>
                                                {adminArray.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.employee_id}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.role_name}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </>}
                                        </tbody>
                                    </Table>
                                </div>
                            }

                        </div>}
                </div> */}
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    )
}
