import React, { useState } from "react";
import { Outlet, Route, Routes, Navigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "../pages/Dashboard/Dashboard";
import { CompanyDetails } from "../pages/CompanyDetails/CompanyDetails";
import { SignIn } from "../pages/Auth/SignIn/SignIn";
import { SignUp } from "../pages/Auth/SignUp/SignUp";
import { ForgotPassword } from "../pages/Auth/ForgotPassword/ForgotPassword";
import { RouteStrings } from "../utils/common";
import { Leaves, leaves } from "../pages/Leaves/Leaves";
import { DashBoardhome } from "../components/Dashboard_Home/DashBoardhome";
import { SettingsPage } from "../pages/Settings/SettingsPage";
import { CreateUser } from "../pages/CreateUser/CreateUser";
import { CreateRole } from "../pages/CreateRole/CreateRole";
import { Payslips } from "../pages/Payslips/Payslips";
import { Fields } from "../pages/Fields/Fields";
import { NotFoundScreen } from "../pages/404/404";
import { IsAuthcondition } from "./IsAuthcondition";
import { UserProfile } from "../components/UserProfile/UserProfile";
import { EmployeeLeaveCount } from "../pages/EmployeeLeaveCount/EmployeeLeaveCount";
import { UpdateEmployeeDependants } from "../pages/UpdateEmployeeDependants/UpdateEmployeeDependants";
import { CreateShift } from "../pages/CreateShift/CreateShift";
import { EmployeeType } from "../pages/EmployeeType/EmployeeType";
import { ResetPassword } from "../pages/Auth/ResetPassword/ResetPassword";
import { LeaveType } from "../pages/LeaveType/LeaveType";
import { DepartmentCrud } from "../pages/Department/DepartmentCrud";
import { DesignationCrud } from "../pages/Designation/DesignationCrud";
import { AdminLeaveCount } from "../pages/LeavesTable/AdminLeaveCount";
import { OrganisationTeam } from "../pages/OrganisationTeam/OrganisationTeam";
import { AssignLevels } from "../pages/AssignLevels/AssignLevels";
import { StockEntry } from "../pages/StockEntry/StockEntry";
import { StockAssign } from "../pages/StockAssign/StockAssign";
import { InventoryVendor } from "../pages/InventoryMasterMenu/InventoryVendor/InventoryVendor";
import { InventoryProduct } from "../pages/InventoryMasterMenu/InventoryProduct/InventoryProduct";
import { PaymentMode } from "../pages/InventoryMasterMenu/PaymentMode/PaymentMode";
import { ProductStatus } from "../pages/InventoryMasterMenu/ProductStatus/ProductStatus";
import { InventoryBilling } from "../pages/InventoryBilling/InventoryBilling";
import { InventoryDashboard } from "../pages/InventoryDashboard/InventoryDashboard";
import { ClientContactList } from "../pages/clientcontact/clientcontactlist/ClientContactList";
// import { InvoiceList } from "../pages/invoice/invoicelist/InvoiceList";
// import ViewInvoice from "../pages/invoice/viewinvoice/ViewInvoice";
// import { CreateInvoice } from "../pages/invoice/createinvoice/CreateInvoice";
// import { ViewClientDetails } from "../pages/clientcontact/viewclients/ViewClients";
import { AddClient } from "../pages/clientcontact/clientcontactlist/AddClient";
import { CreateInvoice } from "../pages/invoice/invoicelist/CreateInvoice";
import { BillingCrud } from "../pages/billingType/BillingCrud";
import { PaymentCrud } from "../pages/paymentType/PaymentCrud";
import { AdditionalAccountDetails } from "../pages/AdditionalAccountDetails/AdditionalAccountDetails";
// import { FinanacialYearCrud } from "../pages/financialyearCrud";
import { FinancialYear } from "../pages/FinancialYear/FinancialYear";
// import { BillingCrud } from "../pages/billingType/BillingCrud";

const RouteCheck = () => {
  const { userInfo, auth: { isAuth } } = useSelector((state) => state.UIStore);
  // console.log("🚀 ~ file: RouteCheck.js:55 ~ RouteCheck ~ isAuth:", isAuth)
  let role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null
  var menuPermissions = userInfo ? (userInfo.menuPermissions ? userInfo.menuPermissions.split(",") : []) : null

  return (
    <Routes>
      <Route path={isAuth ? RouteStrings.dashboard : RouteStrings.login} element={<SignIn />} />
      <Route path={isAuth ? RouteStrings.dashboard : RouteStrings.signup} element={<SignUp />} />
      <Route path={isAuth ? RouteStrings.dashboard : RouteStrings.forgotpassword} element={<ForgotPassword />} />
      <Route path={isAuth ? RouteStrings.dashboard : RouteStrings.resetpassword} element={<ResetPassword />} />

      <Route path={RouteStrings.dashboard} element={<Dashboard />}>
        <Route index element={<DashBoardhome />} />
        {menuPermissions && <>
          {(role === "super_admin" || role === "hr" || menuPermissions.includes("Employee-module")) ? <>
            <Route path={RouteStrings.createEmployee} element={<CreateUser />} />
            <Route
              path={RouteStrings.udpateEmployeeDependants}
              element={<UpdateEmployeeDependants />}
            />
          </> : null}


          {(role === "super_admin" || role === "hr" || menuPermissions.includes("Leave-approval")) ? <>
            <Route path={RouteStrings.leaves} element={<Leaves />} /> </> : null}
          {menuPermissions.includes("Inventory-module") ?
            <>
              <Route path={RouteStrings.inventoryDashboard} element={<InventoryDashboard />} />
              <Route path={RouteStrings.inventoryBilling} element={<InventoryBilling />} />
              <Route path={RouteStrings.stockentry} element={<StockEntry />} />
              <Route path={RouteStrings.stockassign} element={<StockAssign />} />
              <Route path={RouteStrings.inventoryVendor} element={<InventoryVendor />} />
              <Route path={RouteStrings.inventoryProduct} element={<InventoryProduct />} />
              <Route path={RouteStrings.paymentMode} element={<PaymentMode />} />
              <Route path={RouteStrings.productStatus} element={<ProductStatus />} />
            </> : null}
          {menuPermissions.includes("Invoice-module") ?
            <>
              <Route path={RouteStrings.clientcontactlist} element={<AddClient />} />
              <Route path={RouteStrings.invoicelist} element={<CreateInvoice />} />
              <Route path={RouteStrings.companydetails} element={<CompanyDetails />} />
              <Route path={RouteStrings.billingdata} element={<BillingCrud />} />
              <Route path={RouteStrings.paymenttype} element={<PaymentCrud />} />
              <Route path={RouteStrings.additionalaccountsdetails} element={<AdditionalAccountDetails />} />
            </> : null}
          {(role === "super_admin" || role === "hr" || menuPermissions.includes("Settings")) ?
            <>
              <Route path={RouteStrings.createrole} element={<CreateRole />} />
              <Route path={RouteStrings.payslips} element={<Payslips />} />
              <Route path={RouteStrings.fields} element={<Fields />} />
              <Route path={RouteStrings.createshift} element={<CreateShift />} />
              <Route path={RouteStrings.employeetype} element={<EmployeeType />} />
              <Route path={RouteStrings.leavetype} element={<LeaveType />} />
              <Route path={RouteStrings.assignLevels} element={<AssignLevels />} />
              <Route path={RouteStrings.department} element={<DepartmentCrud />} />
              <Route path={RouteStrings.designation} element={<DesignationCrud />} />
              <Route path={RouteStrings.financialyear} element={<FinancialYear />} />
            </> : null}

          {/* <Route path={RouteStrings.settings} element={<SettingsPage />} /> */}
        </>}

        <Route path={RouteStrings.adminLeaveCount} element={<AdminLeaveCount />} />

        <Route
          path={RouteStrings.userprofile}
          element={<UserProfile />}
        />
        <Route path={RouteStrings.organisationtree} element={<OrganisationTeam />} />
        <Route path={RouteStrings[404]} element={<NotFoundScreen />} />
      </Route>
    </Routes >

  );
};

export default RouteCheck;
