import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik, FieldArray, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PostRequestHook } from "../../../apis/Services";
import { Selectelement } from "../../../components/Select_field/Selectelement";
import { Input_element } from "../../../components/input_field/Input_element";
import { configUrl } from "../../../apis/api.config";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { Images } from "../../../utils/images";
import { ClientContactList } from "./ClientContactList";
import Address from "../Address";

const ScrollToError = ({
    scrollBehavior = { behavior: 'smooth', block: 'center' }, getFieldErrorNames
}) => {

    const { submitCount, isValid, errors } = useFormikContext();
    // console.log("🚀 ~ file: AddClient.js:116 ~ errors:", errors)

    useLayoutEffect(() => {
        // console.log("isValid", isValid)
        // console.log("-------executing--------------")
        if (isValid) return;
        const fieldErrorNames = getFieldErrorNames(errors);
        // console.log("🚀 ~ file: AddClient.js:102 ~ useEffect ~ fieldErrorNames:", fieldErrorNames);
        if (fieldErrorNames.length <= 0) return;

        const element = document.querySelector(
            `input[name='${fieldErrorNames[0]}']`
        );
        // console.log("🚀 ~ file: AddClient.js:107 ~ useEffect ~ element:", element);
        if (!element) return;

        // Scroll to first known error into view
        element.scrollIntoView(scrollBehavior);

        // Formik doesn't (yet) provide a callback for a client-failed submission,
        // thus why this is implemented through a hook that listens to changes on
        // the submit count.
    }, [submitCount, isValid, errors]);

    return null;
};

export const AddClient = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    const { getRequest, postRequest, putRequest } = PostRequestHook()
    const [stockList, setStockList] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [stateOptions, setStateOptions] = useState({});
    // console.log("🚀 ~ file: AddClient.js:25 ~ stateOptions:", stateOptions)
    const [countryList, setCountryList] = useState([]);
    const [clientType, setClientType] = useState([]);
    const [regestrationType, setRegestrationType] = useState([]);
    // console.log("🚀 ~ file: AddClient.js:29 ~ regestrationType:", regestrationType)
    // console.log("🚀 ~ file: AddClient.js:31 ~ AddClient ~ regestrationType:", regestrationType)
    const [itemData, seItemData] = useState({})
    // console.log("🚀 ~ file: AddClient.js:31 ~ itemData:", itemData)
    const [updateClients, setUpdateClients] = useState(false);
    const [stateListArr, setstateListArr] = useState([])
    // console.log("🚀 ~ file: AddClient.js:35 ~ stateListArr:", stateListArr)
    const [registrationTypeArr, setregistrationTypeArr] = useState([])
    // console.log("🚀 ~ file: AddClient.js:36 ~ registrationTypeArr:", registrationTypeArr)
    // const [updateErrors, setUpdateErrors] = useState("")
    // console.log("🚀 ~ file: AddClient.js:35 ~ updateErrors:", updateErrors)


    const fetchOptionsForState = async (countryId) => {
        let response = await getRequest(`${configUrl.getState}${countryId}`)
        let stateList = response?.data?.data ? response?.data?.data : []
        // console.log("🚀 ~ file: AddClient.js:38 ~ fetchOptionsForState ~ stateList:", stateList)
        // let labelData = stateList?.filter(item => item.state_name !== "")
        // console.log("🚀 ~ file: AddClient.js:40 ~ fetchOptionsForState ~ labelData:", labelData)
        // var testlabeldata = Array.assignService(labelData)
        // console.log("🚀 ~ file: AddClient.js:42 ~ fetchOptionsForState ~ testlabeldata:", testlabeldata)
        // return testlabeldata
        setstateListArr(stateList);
        return stateList
    };
    const fetchOptionsForRegestration = async (clientTypeId) => {
        let response = await getRequest(`${configUrl.getRegestrationType}${clientTypeId}`)
        let regestrationList = response?.data?.data ? response?.data?.data : []
        setregistrationTypeArr(regestrationList)
        return regestrationList
    };
    // let stateList = itemData?.client_address?.filter(item => item?.country)
    let countryData = itemData?.client_address?.map((mapVal) => {
        const country = mapVal
        return country
    })
    // console.log("🚀 ~ file: AddClient.js:65 ~ countryData ~ countryData:", countryData)
    let countryCode = countryData?.find(findVal => findVal?.country)
    // console.log("🚀 ~ file: AddClient.js:67 ~ countryCode:", countryCode)
    // { console.log("Country", countryCode) }
    useEffect(() => { getCountryList() }, [])
    useEffect(() => {
        updateClients && fetchOptionsForState(countryCode?.country)
    }, [updateClients])
    const getCountryList = async () => {
        let response = await getRequest(configUrl.getCountry)
        setCountryList(response?.data?.data ? response?.data?.data : [])
    }

    useEffect(() => { getClientType() }, [])
    useEffect(() => {
        updateClients && fetchOptionsForRegestration(itemData?.client_type)
    }, [updateClients])
    const getClientType = async () => {
        let response = await getRequest(configUrl.getClientType)
        setClientType(response?.data?.data ? response?.data?.data : [])
    }

    const selectFieldValue = (selectArray, label) => {
        var fieldSelectValue = selectArray ? selectArray.find(option => option.value === label) : "";
        fieldSelectValue = fieldSelectValue ? fieldSelectValue : null
        return fieldSelectValue
    }

    // Scroll to top
    const getFieldErrorNames = (formikErrors) => {
        const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
            Object.keys(obj).forEach((key) => {
                const value = obj[key]
                if (!value) return
                const nextKey = prefix ? `${prefix}.${key}` : key
                if (typeof value === 'object') {
                    transformObjectToDotNotation(value, nextKey, result)
                } else {
                    result.push(nextKey)
                }
            })
            return result
        }

        return transformObjectToDotNotation(formikErrors)
    }

    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required("Required"),
        website: Yup.string(),
        client_type: Yup.string().required("Required"),
        registration_type: Yup.string(),
        // registration_type: Yup.string().required("Required"),
        registration_number: Yup.string(),
        company_contact_number: Yup.number(),
        remarks: Yup.string(),
        tax_registration_no: Yup.string(),
        cst_registration_no: Yup.string(),
        pan_no: Yup.string(),
        client_address: Yup.array().of(
            Yup.object().shape({
                street: Yup.string(),
                country: Yup.string().required("Required"),
                state: Yup.string().required("Required"),
                city: Yup.string().required("Required"),
                pincode: Yup.string().required("Required"),
                set_default_address: Yup.string().required("Please Select Checkbox")
            })
        ),
        client_contact_information: Yup.array().of(
            Yup.object().shape({
                contact_person_name: Yup.string().required("Required"),
                e_mail: Yup.string().required("Required"),
                contact_number: Yup.number(),
                set_primary_contact: Yup.string().required("Please Select Checkbox")
            })
        ),
    });


    // console.log(error)
    const initialValues = {
        company_name: updateClients ? itemData?.company_name : "",
        website: updateClients ? itemData?.website : "",
        client_type: updateClients ? itemData?.client_type : "",
        registration_type: updateClients ? itemData?.registration_type : "",
        registration_number: updateClients ? itemData?.registration_number : "",
        company_contact_number: updateClients ? itemData?.company_contact_number : "",
        remarks: updateClients ? itemData?.remarks : "",
        tax_registration_no: updateClients ? itemData?.tax_registration_no : "",
        cst_registration_no: updateClients ? itemData?.cst_registration_no : "",
        pan_no: updateClients ? itemData?.pan_no : "",
        client_address: updateClients ? itemData?.client_address : [{ street: '', country: '', state: '', city: "", pincode: "", set_default_address: true }],
        client_contact_information: updateClients ? itemData?.client_contact_information : [{ set_primary_contact: "", contact_person_name: '', e_mail: '', contact_number: "" }]
    };


    // Delete Bank Details
    const handleDeleteClientDetails = async (e, id) => {
        var response = await putRequest(`${configUrl.deleteClientDetails}${id}`)
        if (response.status == 200 || response.status == 201) {
            // console.log("🚀 ~ file: CompanyDetails.js:159 ~ handleDeleteBanks ~ response:", response)
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            // getCompanyDetails()
            // setIsShowDetails(true)
            window.location.reload();
        }
    }
    const handleDeleteClientAddress = async (e, id) => {
        var response = await putRequest(`${configUrl.deleteClientAddress}${id}`)
        if (response.status == 200 || response.status == 201) {
            // console.log("🚀 ~ file: CompanyDetails.js:159 ~ handleDeleteBanks ~ response:", response)
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            // getCompanyDetails()
            // setIsShowDetails(true)
            window.location.reload();
        }
    }

    // const scrollToFirstError = (error) => {
    //     const firstErrorField = error;
    //     console.log("🚀 ~ file: AddClient.js:146 ~ scrollToFirstError ~ firstErrorField:", firstErrorField)
    //     // if (firstErrorField) {
    //     //     const inputElement = document.querySelector(`[name="${firstErrorField}"]`);
    //     //     if (inputElement) {
    //     //         inputElement.scrollIntoView({ behavior: 'smooth' });
    //     //     }
    //     // }
    // };

    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }

    // Edit Function
    const handleViewClick = (data) => {
        setStockList(!stockList)
        seItemData(data)
        setUpdateClients(true)
    }
    const validateDuplicateAnotherFields = (products) => {
        const labelFields = products.map((product) => product.productLabel);
        const duplicates = labelFields.filter(
            (field, index) => labelFields.indexOf(field) !== index
        );
        return duplicates
    };

    const handleSubmit = (values) => {
        // console.log("🚀 ~ file: AddClient.js:163 ~ handleSubmit ~ values:", values)
        // console.log("🚀 ~ file: AddClient.js:134 ~ handleSubmit ~ values:", values
        addClientService(values)
        // scrollToFirstError()
        // setFormError({ ...formError, allError: true });
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    };

    const addClientService = async (data, e) => {
        // console.log("🚀 ~ file: AddClient.js:178 ~ addClientService ~ addClientService:", addClientService)
        // console.log("🚀 ~ file: AddClient.js:179 ~ addClientService ~ itemData:", itemData.id)
        if (updateClients) {
            var response = await putRequest(`${configUrl.updateClientData}${itemData?.id}`, data)
            // var response = await putRequest(`${configUrl.deleteClientData}${data?.id}`)
            // console.log("🚀 ~ file: AddClient.js:181 ~ addClientService ~ response:", response)
            // const rT = await fetchOptionsForRegestration(e.target.value);
            if (response.status == 200 || response.status == 201) {
                // setStateOptions();
                // setRegestrationType(rT)
                snackBarContent(true, response.data.message)
                setStockList(false)
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                snackBarContent(false, response.response.data.message)
            }
        } else {
            let response = await postRequest(configUrl.addClientData, data)
            // console.log("🚀 ~ file: AddClient.js:140 ~ addClientService ~ response:", response)
            if (response.status == 200 || response.status == 201) {
                snackBarContent(true, response.data.message)
                setStockList(false)
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                snackBarContent(false, response.response.data.message)
            }
        }
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="my-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="">{stockList ? `${updateClients ? 'Edit Client' : 'Add Client'}` : 'Clients List'}</h3>
                    </div>
                </div>
                <div className="text-end">
                    <Button
                        variant="primary"
                        onClick={() => {
                            setStockList(!stockList);
                            setUpdateClients(false);
                        }}
                    >{stockList ? "Client List" : `${updateClients ? 'Edit Client' : 'Add New Client'}`}</Button>
                </div>
                <div>
                    {stockList ? (
                        <div className="test_bg p-md-4 mt-4 p-3">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            // onSubmit={handleSubmit}
                            // onSubmit={async (handleSubmit) => {
                            //     await new Promise((resolve) => setTimeout(resolve, 500));
                            //     alert(JSON.stringify(handleSubmit, null, 2));
                            // }}
                            >
                                {/* {
                                    console.log("==========>", values)
                                } */}
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, validateForm }) => (
                                    // console.log("==========>values", values),
                                    <form onSubmit={handleSubmit}>
                                        <ScrollToError getFieldErrorNames={getFieldErrorNames} />
                                        <h4 className="text-white">Basic Info</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Company<strong className="text-danger">*</strong></>}
                                                    type="text"
                                                    name="company_name"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values?.company_name}
                                                    placeholder="Enter company name"
                                                    formikValidation={
                                                        <ErrorMessage name="company_name" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Website</>}
                                                    type="text"
                                                    name="website"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.website}
                                                    placeholder="Enter Website"
                                                    formikValidation={
                                                        <ErrorMessage name="website" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Selectelement
                                                    optionArray={
                                                        <>
                                                            {clientType.length > 0 && clientType.map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.id}>
                                                                        {item.client_type_name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </>}
                                                    select_Label={<>Clients Type<strong className="text-danger">*</strong></>}
                                                    // id={`client_address.${index}.country`}
                                                    name="client_type"
                                                    handleChange={async (e) => {
                                                        handleChange(e)
                                                        const options = await fetchOptionsForRegestration(e.target.value);
                                                        setRegestrationType(options);
                                                        // setFieldValue(`client_address.${index}.state`, '');
                                                    }}
                                                    handleBlur={handleBlur}
                                                    value={values?.client_type}
                                                    formikValidation={
                                                        <ErrorMessage name="client_type" component="div" className="text-danger position-absolute small" />
                                                    } />
                                            </div>
                                            <div className="col-md-6">
                                                <Selectelement
                                                    // optionArray={
                                                    //     <>{regestrationType?.length > 0 ? <>  {regestrationType?.map((item, i) => {
                                                    //         console.log("🚀 ~ file: AddClient.js:288 ~ <>{regestrationType.length>0?<>{regestrationType.map ~ item:", item)
                                                    //         return (
                                                    //             <option key={i} value={item.id}>
                                                    //                 {item?.registration_type_name}
                                                    //             </option>
                                                    //         )
                                                    //     })}</> : null}

                                                    //     </>}
                                                    optionArray={
                                                        <>
                                                            {registrationTypeArr.map((item, ind) => {
                                                                // console.log("🚀 ~ file: AddClient.js:411 ~ {registrationTypeArr.map ~ item:", item)
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.registration_type_name}
                                                                    </option>
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                    select_Label={<>Registration Type</>}
                                                    // id={`client_address.${index}.state`}
                                                    name="registration_type"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    // defaultValue={itemData?.registration_type_name}
                                                    // defaultValue={values?.registration_type}
                                                    value={values?.registration_type}
                                                    formikValidation={
                                                        <ErrorMessage name="registration_type" component="div" className="text-danger position-absolute small" />
                                                    } />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Registration Number</>}
                                                    type="text"
                                                    name="registration_number"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.registration_number}
                                                    placeholder="Enter Registration Number"
                                                // formikValidation={
                                                //     <ErrorMessage name="registration_number" component="div" className="text-danger position-absolute small" />
                                                // }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Company Phone Number</>}
                                                    type="text"
                                                    name="company_contact_number"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.company_contact_number}
                                                    placeholder="Enter Company Phone Number"
                                                // formikValidation={
                                                //     <ErrorMessage name="company_contact_number" component="div" className="text-danger position-absolute small" />
                                                // }
                                                />
                                            </div>
                                        </div>
                                        <h4 className="text-white mt-4">Tax Info</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Tax Reg. No.</>}
                                                    type="text"
                                                    name="tax_registration_no"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.tax_registration_no}
                                                    placeholder="Enter Tax Reg. No."
                                                    formikValidation={
                                                        <ErrorMessage name="tax_registration_no" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>CST reg. no.</>}
                                                    type="text"
                                                    name="cst_registration_no"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.cst_registration_no}
                                                    placeholder="Enter CST reg. no."
                                                    formikValidation={
                                                        <ErrorMessage name="cst_registration_no" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>PAN No.</>}
                                                    type="text"
                                                    name="pan_no"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.pan_no}
                                                    placeholder="Enter PAN No."
                                                    formikValidation={
                                                        <ErrorMessage name="pan_no" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="my-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Remarks</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    cols={6}
                                                    rows={6}
                                                    style={{ height: "100px", maxWidth: "400px" }}
                                                    name="remarks"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.remarks}
                                                />
                                            </Form.Group>
                                        </div>
                                        {/* address */}
                                        <Address
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            handleDeleteClientAddress={handleDeleteClientAddress}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            // country={country}
                                            countryList={countryList}
                                        />
                                        {/* contact detail */}
                                        <div className="">
                                            <h4 className="text-white mt-4">Contact Details</h4>
                                            <FieldArray name="client_contact_information">
                                                {({ push, remove }) => (
                                                    <div>
                                                        {values.client_contact_information.map((_, index) => (
                                                            <div key={index} className="row">
                                                                <div className="col-md-2">
                                                                    <Form.Group className="mb-4">
                                                                        <Form.Label className="mb-2">Primary contact</Form.Label>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            checked={values.client_contact_information[index].set_primary_contact}
                                                                            name={`client_contact_information.${index}.set_primary_contact`}
                                                                            onChange={(e) => {
                                                                                handleChange(e)
                                                                                const updatedProducts = [...values.client_contact_information];
                                                                                updatedProducts.forEach((primary, i) => {
                                                                                    if (i === index) {
                                                                                        primary.set_primary_contact = !updatedProducts.set_primary_contact;
                                                                                    } else {
                                                                                        primary.set_primary_contact = false;
                                                                                    }
                                                                                });
                                                                                setFieldValue("client_contact_information", updatedProducts);
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        {/* formikValidation={ */}
                                                                        <ErrorMessage name={`client_contact_information.${index}.set_primary_contact`} component="div" className="text-danger position-absolute small" />
                                                                        {/* } */}
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <Input_element
                                                                        input_label={<>Contact Person Name<strong className="text-danger">*</strong></>}
                                                                        type="text"
                                                                        id={`client_contact_information.${index}.contact_person_name`}
                                                                        name={`client_contact_information.${index}.contact_person_name`}
                                                                        handleChange={handleChange}
                                                                        handleBlur={handleBlur}
                                                                        value={values.client_contact_information[index].contact_person_name}
                                                                        formikValidation={
                                                                            <ErrorMessage name={`client_contact_information.${index}.contact_person_name`} component="div" className="text-danger position-absolute small" />
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <Input_element
                                                                        input_label={<>Email<strong className="text-danger">*</strong></>}
                                                                        type="email"
                                                                        id={`client_contact_information.${index}.e_mail`}
                                                                        name={`client_contact_information.${index}.e_mail`}
                                                                        handleChange={handleChange}
                                                                        handleBlur={handleBlur}
                                                                        value={values.client_contact_information[index].e_mail}
                                                                        formikValidation={
                                                                            <ErrorMessage name={`client_contact_information.${index}.e_mail`} component="div" className="text-danger position-absolute small" />
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <Input_element
                                                                        input_label={<>Contact Number</>}
                                                                        type="text"
                                                                        id={`client_contact_information.${index}.contact_number`}
                                                                        name={`client_contact_information.${index}.contact_number`}
                                                                        handleChange={handleChange}
                                                                        handleBlur={handleBlur}
                                                                        value={values.client_contact_information[index].contact_number}
                                                                    // formikValidation={
                                                                    //     <ErrorMessage name={`client_contact_information.${index}.contact_number`} component="div" className="text-danger position-absolute small" />
                                                                    // }
                                                                    />
                                                                </div>
                                                                <div className="col-md-1 my-auto">
                                                                    {values.client_contact_information.length > 1 ?
                                                                        <button type="button" className="bg-transparent border-0" onClick={(e) => {
                                                                            remove(index)
                                                                            if (values.client_contact_information[index].id) {
                                                                                handleDeleteClientDetails(e, values.client_contact_information[index].id)
                                                                            }
                                                                        }}>
                                                                            <img src={Images.deleteLogo} alt="icon" />
                                                                        </button>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button type="button" className="btn btn-light"
                                                            onClick={() => push({ set_primary_contact: 0, contact_person_name: '', e_mail: '', contact_number: "" })}>
                                                            Add Contact
                                                        </button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                        {/* <button
                                            type="button"
                                            onClick={() => validateForm().then(() => alert("blllla"))}
                                        >
                                            Validate All
                                        </button> */}
                                        <div className="d-flex justify-content-end mt-5">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <div className="leavesTable mt-5">
                            <ClientContactList
                                editData={handleViewClick}
                            />
                        </div>
                    )}
                </div>
            </div >

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    );
};





// <div className="mb-5">
// <h4 className="text-white mt-4">Address</h4>
// <FieldArray name="client_address">
//     {({ push, remove }) => (
//         <div>
//             {values.client_address.map((states, index) => (
//                 console.log("=====>>>>>>", states),
//                 <div key={index} className="row border pt-3 mb-3">
//                     <div className="col-md-12">
//                         <Form.Group className="mb-4">
//                             {/* <Form.Label className="mb-2">Primary Address</Form.Label> */}
//                             <Form.Check
//                                 type="checkbox"
//                                 label="Primary Address"
//                                 checked={values.client_address[index].set_default_address}
//                                 name={`client_address.${index}.set_default_address`}
//                                 onChange={(e) => {
//                                     handleChange(e)
//                                     const updatedAddress = [...values.client_address];
//                                     updatedAddress.forEach((primary, i) => {
//                                         if (i === index) {
//                                             primary.set_default_address = !updatedAddress.set_default_address;
//                                         } else {
//                                             primary.set_default_address = false;
//                                         }
//                                     });
//                                     setFieldValue("client_address", updatedAddress);
//                                 }}
//                                 onBlur={handleBlur}
//                                 className="text-white"

//                             />
//                             <ErrorMessage name={`client_address.${index}.set_default_address`} component="div" className="text-danger position-absolute small" />
//                             {/* <ErrorMessage name={`client_contact_information.${index}.set_default_address`} component="div" className="text-danger position-absolute small" /> */}
//                         </Form.Group>
//                     </div>
//                     <div className="col-md-4">
//                         <Input_element
//                             input_label={<>Street<strong className="text-danger"></strong></>}
//                             type="text"
//                             id={`client_address.${index}.street`}
//                             name={`client_address.${index}.street`}
//                             handleChange={handleChange}
//                             handleBlur={handleBlur}
//                             value={values.client_address[index].street}
//                             formikValidation={
//                                 <ErrorMessage name={`client_address.${index}.street`} component="div" className="text-danger position-absolute small" />
//                             }
//                         />
//                     </div>
//                     <div className="col-md-4">
//                         <Selectelement
//                             optionArray={
//                                 <>
//                                     {countryList.length > 0 && countryList.map((item, i) => {
//                                         return (
//                                             <option key={i} value={item.id}>
//                                                 {item.country_name}
//                                             </option>
//                                         );
//                                     })}
//                                 </>}
//                             select_Label={<>Country<strong className="text-danger">*</strong></>}
//                             // id={`client_address.${index}.country`}
//                             name={`client_address.${index}.country`}
//                             handleChange={async (e) => {
//                                 handleChange(e)
//                                 const countryId = e.target.value;
//                                 const options = await fetchOptionsForState(countryId);
//                                 // console.log("🚀 ~ file: AddClient.js:562 ~ handleChange={ ~ options:", options)
//                                 setStateOptions((prevOptions) => ({
//                                     ...prevOptions,
//                                     [countryId]: options,
//                                 }));
//                                 setFieldValue(`client_address.${index}.state`, '');
//                             }}
//                             // handleBlur={handleBlur}
//                             value={values.client_address[index].country}
//                             formikValidation={
//                                 <ErrorMessage name={`client_address.${index}.country`} component="div" className="text-danger position-absolute small" />
//                             } />
//                     </div>
//                     <div className="col-md-4">
//                         {console.log("->console", values?.client_address[index]?.state_name)}
//                         <Selectelement
//                             // optionArray={
//                             //     <>{Object.keys(stateOptions).length > 0 ? <>  {(stateOptions[states.country] ? stateOptions[states.country] : []).map((item, i) => {
//                             //         return (
//                             //             <option key={i} value={item.id}>
//                             //                 {item.state_name}
//                             //             </option>
//                             //         )
//                             //     })}</> : null}
//                             //     </>}
//                             optionArray={
//                                 <>
//                                     {stateListArr.map((item, ind) => {
//                                         return (
//                                             <option key={item.id} value={item.id}>
//                                                 {item.state_name}
//                                             </option>
//                                         )
//                                     })}
//                                 </>
//                             }
//                             // onChange={(item) => {
//                             //     console.log("🚀 ~ file: AddClient.js:615 ~ handleChange={ ~ item:", item)
//                             //     if (item) {
//                             //         setFieldValue(`client_address.${index}.state`, item.value)
//                             //     } else {
//                             //         setFieldValue(`client_address.${index}.state`, "")
//                             //     }
//                             // }}
//                             select_Label={<>State<strong className="text-danger">*</strong></>}
//                             id={`client_address.${index}.state`}
//                             name={`client_address.${index}.state`}
//                             handleChange={handleChange}
//                             handleBlur={handleBlur}
//                             // {selectFieldValue(productOptions[product.productName], values.productDetails[index].productLabel)}
//                             // defaultValue={values?.client_address[index]?.state_name}
//                             value={values?.client_address[index]?.state}
//                             // {values?.client_address[index]?.state}
//                             // options={stateOptions[states.state]}
//                             // className="basic-multi-select"
//                             // classNamePrefix="select"
//                             // isClearable={true}
//                             formikValidation={
//                                 <ErrorMessage name={`client_address.${index}.state`} component="div" className="text-danger position-absolute small" />
//                             } />

//                     </div>

//                     <div className="col-md-4">
//                         <Input_element
//                             input_label={<>City<strong className="text-danger">*</strong></>}
//                             type="text"
//                             // id={`client_address.${index}.city`}
//                             name={`client_address.${index}.city`}
//                             handleChange={handleChange}
//                             handleBlur={handleBlur}
//                             value={values.client_address[index].city}
//                             formikValidation={
//                                 <ErrorMessage name={`client_address.${index}.city`} component="div" className="text-danger position-absolute small" />
//                             }
//                         />
//                     </div>
//                     <div className="col-md-4">
//                         <Input_element
//                             input_label={<>Pincode<strong className="text-danger">*</strong></>}
//                             type="number"
//                             // id={`client_address.${index}.pincode`}
//                             name={`client_address.${index}.pincode`}
//                             handleChange={handleChange}
//                             handleBlur={handleBlur}
//                             value={values.client_address[index].pincode}
//                             formikValidation={
//                                 <ErrorMessage name={`client_address.${index}.pincode`} component="div" className="text-danger position-absolute small" />
//                             }
//                         />
//                     </div>
//                     <div className="col-md-2 my-auto">
//                         {values.client_address.length > 1 ? <button type="button" className="bg-transparent border-0" onClick={(e) => {
//                             remove(index)
//                             if (values.client_address[index].id) {
//                                 handleDeleteClientAddress(e, values.client_address[index].id)
//                             }
//                         }}>
//                             <img src={Images.deleteLogo} alt="icon" />
//                         </button> : null}
//                     </div>
//                 </div>
//             ))}

//             <button type="button" className="btn btn-light"
//                 onClick={() => push({ street: '', country: '', state: '', city: "", pincode: "", set_default_address: false })}>
//                 Add Address
//             </button>
//         </div>
//     )}
// </FieldArray>
// </div>