import editLogo from "../assets/edit.png";
import editLogoBlack from "../assets/editiconblack.png";
import deleteLogo from "../assets/delete.png";
import viewIcon from "../assets/view.png";
import hamburger from "../assets/menu.png";
import userIcon from "../assets/user.png";
import notFound from "../assets/404.jpg";
import dblogo from "../assets/db_logo.png";
import right from "../assets/next.png";
import down from "../assets/down-arrow.png";
import left from "../assets/left-arrow.png";
import hide_icon from "../assets/hide_icon.png";
import view_iconBlack from "../assets/view_black.png";
import history_icon from "../assets/history.png"
import InvoiceLogo from "../assets/dollarbird_colorLogo.svg"
import PaymentLogo from "../assets/paymenticon.png"
import Sign from "../assets/sign.png"

// excel file
import excel_file from "../assets/exceltemplate.xlsx";

export const Images = {
  editLogo,
  deleteLogo,
  viewIcon,
  hamburger,
  userIcon,
  notFound,
  dblogo,
  right,
  down,
  left,
  excel_file,
  hide_icon,
  view_iconBlack, history_icon,
  InvoiceLogo, PaymentLogo,
  Sign, editLogoBlack
};
