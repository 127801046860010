import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Input_element } from "../../components/input_field/Input_element";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Images } from "../../utils/images";
import { configUrl } from "../../apis/api.config";
import { PostRequestHook } from "../../apis/Services";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { RegEx } from "../../utils/RegEx";
import { useDispatch, useSelector } from "react-redux";
import { setDropdownlist } from "../../store/reducers/ui.reducer";

export const DepartmentCrud = () => {
    const { getRequest, postRequest, putRequest } = PostRequestHook()

    const [viewRole, setViewRole] = useState(false);
    const [typeId, setTypeId] = useState('');
    const [typeList, setTypeList] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")

    useEffect(() => {
        getTypes()
    }, [])
    const getTypes = async () => {
        var dropdowns = await getRequest(configUrl.getAllDropdowns)
        if (dropdowns) {
            setTypeList(dropdowns?.data?.data?.department_id ? dropdowns?.data?.data?.department_id : [])
        }
    }

    const formikRole = useFormik({
        initialValues: {
            department_name: "",
        },
        validate: (values) => {
            let errors = {};
            if (!values.department_name) {
                errors.department_name = "Required";
            } else if (!RegEx.name__regExwithhypen.test(values.department_name)) {
                errors.department_name = "Alphabets,Numbers and Only hyphen (-) is allowed";
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            var response = typeId ? await putRequest(`${configUrl.updatedepartment}${typeId}`, values)
                : await postRequest(configUrl.createdepartment, values)
            if (response.status == 201 || response.status == 200) {
                setSuccess(true)
                setSnackMessage(response.data.message)
                getTypes()
                setSnackOpen(true)
                setViewRole(false)
                getDropdownData()
                resetForm()
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                setSuccess(false)
                setSnackMessage(response.response.data.message)
                setSnackOpen(true)
            }
        },
    });

    const dispatch = useDispatch()
    const getDropdownData = async () => {
        var response = await getRequest(configUrl.getAllDropdowns)
        dispatch(setDropdownlist({
            dropdownList: response.data.data
        }))
    }

    const handleTypeDelete = async (e, id) => {
        var response = await putRequest(`${configUrl.deletedepartment}${id}`)
        if (response.status == 200 || response.status == 201) {
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            getTypes()
            setTypeId()
            getDropdownData()
        }
    }

    const handleTypeEdit = (e, id) => {
        var xyz = typeList.filter((item) => item.id == id)
        formikRole.setFieldValue("department_name", xyz[0].name)
        setTypeId(xyz[0].id)
        setViewRole(true)
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="employeetype_style">
                <div className="d-flex justify-content-between mb-4">
                    <div>
                        <h3>Department</h3>
                    </div>
                    <div className="text-end">
                        <Link id="dashboardbtn" to="/" className="goback">
                            Back to Dashboard
                        </Link>
                        <Button
                            id="createbtn"
                            type="button"
                            onClick={
                                viewRole ? () => {
                                    setViewRole(false)
                                    formikRole.setFieldValue("department_name", "")
                                    setTypeId()
                                } : () => {
                                    setViewRole(true)
                                    setTypeId()
                                }
                            }
                        >
                            {viewRole ? "Department List" : "Create Department"}
                        </Button>
                    </div>
                </div>
                <div className="mt-5">
                    {viewRole ? (
                        <div className="type_form">
                            <h5 className="mb-3 font_color">Add Department</h5>

                            <Form onSubmit={formikRole.handleSubmit}>
                                <Input_element
                                    input_label="Department Name"
                                    lableClass="font_color"
                                    name="department_name"
                                    handleChange={formikRole.handleChange}
                                    handleBlur={formikRole.handleBlur}
                                    value={formikRole.values.department_name}
                                    type="text"
                                    placeholder="Enter Department name"
                                    formikValidation={
                                        formikRole.touched.department_name &&
                                            formikRole.errors.department_name ? (
                                            <small className="text-danger position-absolute">
                                                {formikRole.errors.department_name}
                                            </small>
                                        ) : null
                                    }
                                />
                                <div className="text-end mt-4">
                                    <Button id={typeId ? "updatebtn" : "addbtn"} type="submit">{typeId ? "Update" : "Add"}</Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <div className="table_div">
                            <h5>Department List</h5>
                            <div className="mb-4 text-end"></div>

                            <div className="roles table_scroll">
                                <Table striped bordered hover className="table_role mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sl.No</th>
                                            <th>Department Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeList.map((value, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{value.name}</td>
                                                    <td>{value.id > 1 ? <div className="d-flex justify-content-center">
                                                        <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                                                        <img src={Images.deleteLogo} id={`delete${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeDelete(e, value.id)} />
                                                    </div> :
                                                        <>
                                                            <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                                                        </>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    );
};
