import React, { useEffect, useState } from "react";
import "./employeetype.scss";
import { Button, Form, Table } from "react-bootstrap";
import { ModalComponent } from "../../components/modal/ModalComponent";
import { Input_element } from "../../components/input_field/Input_element";
import { Selectelement } from "../../components/Select_field/Selectelement";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Images } from "../../utils/images";
import { configUrl } from "../../apis/api.config";
import { PostRequestHook } from "../../apis/Services";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { useDispatch } from "react-redux";
import { setDropdownlist } from "../../store/reducers/ui.reducer";
import { RegEx } from "../../utils/RegEx";

export const EmployeeType = () => {
  const [viewRole, setViewRole] = useState(false);
  const [typeId, setTypeId] = useState('');
  const [typeList, setTypeList] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [snackMessage, setSnackMessage] = useState("")

  const { getRequest, postRequest, putRequest } = PostRequestHook()

  useEffect(() => {
    getTypes()
  }, [])
  const getTypes = async () => {
    var shifts = await getRequest(configUrl.getAllEmpType)
    if (shifts) {
      setTypeList(shifts?.data?.data ? shifts?.data?.data : [])
    }
  }

  const dispatch = useDispatch()
  const getDropdownData = async () => {
    var response = await getRequest(configUrl.getAllDropdowns)
    dispatch(setDropdownlist({
      dropdownList: response.data.data
    }))
  }

  const formikRole = useFormik({
    initialValues: {
      employee_type_name: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.employee_type_name) {
        errors.employee_type_name = "Required";
      } else if (!RegEx.name__regEx.test(values.employee_type_name)) {
        errors.employee_type_name = "Alphabets only";
      }
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      var response = typeId ? await putRequest(`${configUrl.editEmpType}${typeId}`, values)
        : await postRequest(configUrl.createEmpType, values)
      if (response.status == 201 || response.status == 200) {
        setSuccess(true)
        setSnackMessage(response.data.message)
        getTypes()
        setSnackOpen(true)
        setViewRole(false)
        getDropdownData()
        setTypeId()
        resetForm()
      } else if (response.response.data.status == 400 || response.response.data.status == 409) {
        setSuccess(false)
        setSnackMessage(response.response.data.message)
        setSnackOpen(true)
      }
    },
  });


  const handleTypeDelete = async (e, id) => {
    var response = await putRequest(`${configUrl.deleteEmpType}${id}`)
    if (response.status == 200) {
      setSuccess(true)
      setSnackMessage(response.data.message)
      setSnackOpen(true)
      getTypes()
      getDropdownData()
    }
  }
  const handleTypeEdit = (e, id) => {
    let xyz = typeList.filter((item) => item.id == id)
    formikRole.setFieldValue("employee_type_name", xyz[0].employee_type_name)
    setTypeId(xyz[0].id)
    setViewRole(true)
    // getDropdownData()
  }

  const handlesnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  return (
    <>
      <div className="employeetype_style">
        <div className="d-flex justify-content-between mb-4">
          <div>
            <h3>Employee Type</h3>
          </div>
          <div className="text-end">
            <Link id="dashboardbtn" to="/" className="goback">
              Back to Dashboard
            </Link>
            <Button
              id="createbtn"
              type="button"
              onClick={
                viewRole ? () => {
                  setViewRole(false)
                  setTypeId()
                  formikRole.setFieldValue("employee_type_name", "")
                } : () => {
                  setViewRole(true)
                  setTypeId()
                }
              }
            >
              {viewRole ? "Employee Types List" : "Create Employee Types"}
            </Button>
          </div>
        </div>
        <div className="mt-5">
          {viewRole ? (
            <div className="type_form">
              <h5 className="mb-3 font_color">Add Employee Type</h5>

              <Form onSubmit={formikRole.handleSubmit}>
                <Input_element
                  input_label="Employee Type"
                  lableClass="font_color"
                  name="employee_type_name"
                  handleChange={formikRole.handleChange}
                  handleBlur={formikRole.handleBlur}
                  value={formikRole.values.employee_type_name}
                  type="text"
                  placeholder="Enter Employee Type"
                  formikValidation={
                    formikRole.touched.employee_type_name &&
                      formikRole.errors.employee_type_name ? (
                      <small className="text-danger position-absolute">
                        {formikRole.errors.employee_type_name}
                      </small>
                    ) : null
                  }
                />
                <div className="text-end mt-4">
                  <Button id={typeId ? "updatebtn" : "addbtn"} type="submit">{typeId ? "Update" : "Add"}</Button>
                </div>
              </Form>
            </div>
          ) : (
            <div className="table_div">
              <h5>Employee Type List</h5>
              <div className="mb-4 text-end"></div>

              <div className="roles table_scroll">
                <Table striped bordered hover className="table_role mb-0">
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Employee Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeList.map((value, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{value.employee_type_name}</td>
                          <td>{value.id > 1 ? <div className="d-flex justify-content-center">
                            <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                            <img src={Images.deleteLogo} id={`delete${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeDelete(e, value.id)} />
                          </div> :
                            <>
                              <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                            </>}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>

      </div>

      <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
        snackbg={success} message={snackMessage} />
    </>
  );
};
