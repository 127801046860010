import React, { useEffect, useState } from "react";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import '../Dashboard_Home/dashboardhome.scss'

export const DashboardEmployee = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    const organization_name = userInfo ? userInfo.organization_name : null
    const emp_id = userInfo ? userInfo.emp_id : null
    const { getRequest } = PostRequestHook()
    const [leavesCount, setLeavesCount] = useState([])
    const [employeeGender, setEmployeeGender] = useState("")

    useEffect(() => {
        getEmployeeGender()
    }, [])
    // useEffect(() => {
    //     getLeaveCount()
    // }, [employeeGender])

    const getEmployeeGender = async () => {
        var responsegender = await getRequest(`${configUrl.getEmployeeDetails}${emp_id}`)

        if (responsegender.status == 200) {
            let getdata = responsegender.data.data
            setEmployeeGender(getdata.gender_name)
            var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/total_count/${emp_id}`)
            var sortedLeaveType = (response?.data?.leave_count ? response?.data?.leave_count : []).filter(item => {
                if (getdata.gender_name === "male" && item.type === "Maternity Leave") {
                    return false
                } else if (getdata.gender_name === "female" && item.type === "Paternity Leave") {
                    return false;
                }
                return true;
            })
            setLeavesCount(sortedLeaveType)
        }
    }
    // const getLeaveCount = async (employeeGender2) => {
    //     var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/total_count/${emp_id}`)
    //     var sortedLeaveType = (response?.data?.leave_count ? response?.data?.leave_count : []).filter(item => {
    //         if (employeeGender2 === "male" && item.type === "Maternity Leave") {
    //             return false
    //         } else if (employeeGender2 === "female" && item.type === "Paternity Leave") {
    //             return false;
    //         }
    //         return true;
    //     })
    //     setLeavesCount(sortedLeaveType)
    // }

    return (
        <>
            <div className="my-4">
                <h4 className="header_color mb-5">leaves Count</h4>
                <div className="row leave_row">
                    {leavesCount.map((item, i) => {
                        if (item.type == "Loss Of Pay" || item.type == "Work From Home") {
                            return (
                                <div className="col-md-4 mb-3" key={i}>
                                    <div className="card p-3">
                                        <h6 className="mb-3">{item.type}</h6>
                                        <div className="d-flex justify-content-end">
                                            <p className="mb-0">Approved :</p>
                                            <p className="mb-0 ms-1">
                                                <strong>{item.no_of_days}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className="col-md-4 mb-3" key={i}>
                                    <div className="card p-3">
                                        <h6 className="mb-3">{item.type}</h6>
                                        <div className="d-flex justify-content-end">
                                            <p className="mb-0">Total leaves :</p>
                                            <p className="mb-0 ms-1">
                                                <strong>{item.type == "Maternity Leave" ? `${item.total_leaves} months` : item.total_leaves}</strong>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p className="mb-0">Approved leaves :</p>
                                            <p className="mb-0 ms-1">
                                                <strong>{item.type == "Maternity Leave" ? `${item.no_of_days} months` : item.no_of_days}</strong>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p className="mb-0">Remaining leaves :</p>
                                            <p className="mb-0 ms-1">
                                                <strong>{item.type == "Maternity Leave" ? `${item.total_leaves - item.no_of_days} months` : (item.total_leaves - item.no_of_days)}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </>
    )
}