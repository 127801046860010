import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Form, Modal, Table } from 'react-bootstrap'
import { PostRequestHook } from '../../../apis/Services';
import { configUrl } from '../../../apis/api.config';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Selectelement } from "../../../components/Select_field/Selectelement";
import { Input_element } from "../../../components/input_field/Input_element";
import DatePicker from "react-datepicker";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { Images } from "../../../utils/images";
import { PAYMENT_STATUS_MODE } from '../../../utils/common';

export const InvoiceDetail = (props) => {
    const { invoiceData, handleEdit, getInvoiceList } = props
    console.log("🚀 ~ file: InvoiceDetail.js:20 ~ invoiceData:", invoiceData)
    // console.log("🚀 ~ file: InvoiceDetail.js:20 ~ invoiceData:", invoiceData)
    const { getRequest, postRequest, putRequest } = PostRequestHook()

    const [individualPaymentDetails, setIndividualPaymentDetails] = useState([]);
    const [invoiceDropdowns, setInvoiceDropdowns] = useState([]);
    // console.log("🚀 ~ file: InvoiceDetail.js:25 ~ InvoiceDetail ~ invoiceDropdowns:", invoiceDropdowns)
    // console.log("🚀 ~ file: InvoiceDetail.js:24 ~ InvoiceDetail ~ individualPaymentDetails:", individualPaymentDetails)
    const [show, setShow] = useState(false);
    const [payModal, setPayModal] = useState(false);
    const [editPayment, setEditPayment] = useState({});
    // console.log("🚀 ~ file: InvoiceDetail.js:31 ~ InvoiceDetail ~ editPayment:", editPayment)
    const [editPaymentStatus, setEditPaymentStatus] = useState(false);
    const [paymentmode, setPaymentmode] = useState([]);
    const [companyDetail, setCompanyDetail] = useState({});
    const [companyAddress, setCompanyAddress] = useState({});
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [typeList, setTypeList] = useState([]);

    useEffect(() => {
        getCompanyDetails()
        getPaymentMode()
        getCompanyAddress()
    }, [])

    useEffect(() => {
        if (invoiceData.invoice_number) {
            getPaymentDetails(invoiceData.invoice_number)
        }
    }, [invoiceData])

    useEffect(() => { getInvoiceDropdowns() }, [])
    const getInvoiceDropdowns = async () => {
        let response = await getRequest(configUrl.getInvoiceDropdowns)
        setInvoiceDropdowns(response?.data?.data ? response?.data?.data : {})
    }

    // get Company Details
    const getCompanyDetails = async () => {
        var response = await getRequest(configUrl.getCompanyDetails)
        var _companydetails = response?.data?.data ? response?.data?.data : []
        setCompanyDetail(_companydetails.length > 0 ? _companydetails[0] : {})
    }
    // get Company Address
    const getCompanyAddress = async () => {
        var response = await getRequest(configUrl.getCompanyAddress)
        // console.log("🚀 ~ file: InvoiceDetail.js:73 ~ getCompanyAddress ~ response:", response)
        var _companydetails = response?.data?.data ? response?.data?.data : []
        setCompanyAddress(_companydetails.length > 0 ? _companydetails[0] : {})
    }

    // get payment details of particular invoice number
    const getPaymentDetails = async (data) => {
        let response = await getRequest(`${configUrl.getpaymentDetails}${data}`)
        setIndividualPaymentDetails(response?.data?.data ? response?.data?.data : [])
    }

    // calculating total billing amount
    const handleBillingAmount = (particularValue) => {
        let particularTotalSum = particularValue.reduce((accumulator, currentItem) => accumulator + ((currentItem.rate * currentItem.quantity) - currentItem.deduction_value), 0);
        // console.log("🚀 ~ file: InvoiceDetail.js:90 ~ handleBillingAmount ~ particularTotalSum:", particularTotalSum)
        return particularTotalSum.toFixed(2)
    }

    // claculatig particularTitalValue
    const handlemultiplywithDecimal = (arg1, arg2, arg3) => {
        let particularTotal = ((parseFloat(arg1) * parseFloat(arg2)) - parseFloat(arg3)).toFixed(2)
        return particularTotal
    };

    // calculate gst amount
    const handleCalculateGstAmount = (particularTotal, deduction_value, gst) => {
        let particularTotalSum = handleBillingAmount(particularTotal)
        particularTotalSum = particularTotalSum - (deduction_value ? deduction_value : 0)
        particularTotalSum = particularTotalSum * (gst / 100)
        return particularTotalSum
    }


    // Addditional Bank Details
    useEffect(() => {
        getTypes()
    }, [])
    const getTypes = async () => {
        var dropdowns = await getRequest(configUrl.getAdditionalBankDetail)
        // console.log("🚀 ~ file: BillingCrud.js:29 ~ getTypes ~ dropdowns:", dropdowns)
        if (dropdowns) {
            setTypeList(dropdowns?.data?.data ? dropdowns?.data?.data : [])
        }
    }
    // get payment Mode
    const getPaymentMode = async () => {
        let response = await getRequest(configUrl.getpaymentMode)
        setPaymentmode(response?.data?.data ? response?.data?.data : [])
    }

    // date format
    const formatDate = (dateString) => {
        if (dateString) {
            var date = new Date(dateString);
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            if (payModal) {
                var formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
            } else {
                var formattedDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
            }
            return formattedDate
        } else {
            return ""
        }
    }

    // download the generated invoice
    const printDocument = () => {
        const input = document.getElementById("divToPrint");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            // pdf.addImage(imgData, "JPEG", 0, 0);
            pdf.addImage(imgData, "JPEG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);

            // pdf.output('dataurlnewwindow');
            pdf.save("download.pdf");
        });
    };

    // const receivedAmount = editPayment?.amount_received;
    const payAmount = invoiceData?.payableAmount;
    // const excessAmount = receivedAmount > payAmount
    // const excessAmount = receivedAmount > payAmount;
    // console.log("🚀 ~ file: InvoiceDetail.js:133 ~ excessAmount:", excessAmount)
    // console.log("🚀 ~ file: InvoiceDetail.js:134 ~ InvoiceDetail ~ excessAmount:", excessAmount)

    // const excessAmount = receivedAmount && parseFloat(receivedAmount) > payAmount;

    // form validation
    const validationSchema = Yup.object().shape({
        // invoiceNumber: Yup.string().required("Required"),
        payment_note: Yup.string(),
        transaction_reference: Yup.string().required("Required"),
        amount_received_date: Yup.date().required("Required"),
        charge_note: Yup.string().notRequired(),
        // amount_received: Yup.string().required("Required").matches(
        //     excessAmount,
        //     "Execeeded"
        // ),
        amount_received: Yup.string().required("Required"),
        // .test('amount_received', (value, context) => {
        //     // parent data
        //     let parent_data = context?.parent
        //     console.log("🚀 ~ file: InvoiceDetail.js:153 ~ amount_received:Yup.string ~ parent_data:", parent_data)
        //     // convert to number
        //     let amountReceived = Number(value)
        //     if (amountReceived > payAmount) {
        //         // console.log("-------------testing");
        //         return context.createError({ message: 'Amount is Not Exceded than Payment Amount' })
        //     }
        //     return true
        // }),
        payment_mode_id: Yup.string().required("Required"),
        charge_value: Yup.string().notRequired(),
    });
    // console.log("🚀 ~ file: InvoiceDetail.js:175 ~ validationSchema ~ validationSchema:", validationSchema)
    const initialValues = {
        // invoiceNumber: invoiceData?.invoice_number,
        payment_note: editPaymentStatus ? editPayment?.payment_note : '',
        transaction_reference: editPaymentStatus ? editPayment?.transaction_reference : '',
        amount_received_date: editPaymentStatus ? formatDate(editPayment?.amount_received_date) : '',
        charge_note: editPaymentStatus ? editPayment?.charge_note : '',
        amount_received: editPaymentStatus ? editPayment?.amount_received : '',
        payment_mode_id: editPaymentStatus ? editPayment?.payment_mode_id : '',
        charge_value: editPaymentStatus ? editPayment?.charge_value : ''
    };

    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }
    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleSubmit = (data) => {
        let data1 = {
            ...data, amount_received: data.amount_received.toString(), charge_value: data.charge_value ? data.charge_value.toString() : "0",
            payment_mode_id: data.payment_mode_id.toString(), payment_note: data.payment_note ? data.payment_note : "",
            amount_received_date: formatDate(data.amount_received_date)
        }
        handleInvoicePayment(data1)
    }
    // payment update of invoice 
    const handleInvoicePayment = async (data) => {
        // console.log("🚀 ~ file: InvoiceDetail.js:160 ~ handleInvoicePayment ~ data:", data)
        let response = editPaymentStatus ? await putRequest(`${configUrl.updatePayment}${editPayment?.id}`, data) :
            await postRequest(`${configUrl.makepayment}${invoiceData?.id}`, data)
        if (response.status == 200 || response.status == 201) {
            snackBarContent(true, response?.data?.message)
            setPayModal(false)
            getPaymentDetails(invoiceData.invoice_number)
            setEditPayment({})
            setEditPaymentStatus(false)
            getInvoiceList()
        } else if (response?.response?.data?.status == 400 || response?.response?.data?.status == 409) {
            snackBarContent(false, response?.response?.data?.message)
        }
    }

    // edit the particular payment
    const handlePaymentEdit = (data) => {
        setEditPayment(data)
        setEditPaymentStatus(true)
        setPayModal(true)
    }


    return (
        <>
            <div className='invoice_detail'>
                <div>
                    {Object.keys(invoiceData).length > 0 &&
                        <>
                            <h4 className="mb-3">Invoice Details</h4>
                            {/* <h3 className="mb-4 text-decoration-underline">Invoice Details</h3> */}
                            <div className="text-end mb-3">
                                <h5>Invoice Status : <span className={`text-capitalize ${invoiceData?.payment_status == 2 ? "text-success" : "text-danger"}`}> {invoiceData?.payment_status == 1 ? "Pending" : (invoiceData?.payment_status == PAYMENT_STATUS_MODE.PAYMENT_DONE ? "Completed" : "Partial Payment")}</span></h5>
                            </div>
                            <div className="details">
                                <div className="row">
                                    <div className="col-md-6"><p><span>Invoice Number : </span><strong>{invoiceData?.invoice_number}</strong></p></div>
                                    <div className="col-md-6"><p><span>Client Name : </span><strong>{invoiceData?.clientDetail?.company_name}</strong></p></div>
                                    <div className="col-md-6"><p><span>Bank Details : </span>
                                        {/* {
                                            console.log("==>banks", invoiceData?.bankDetails?.bank_name)
                                        } */}
                                        {
                                            invoiceData?.bankDetails?.bank_name ?
                                                <>
                                                    <strong>{`${invoiceData?.bankDetails?.bank_name}(${invoiceData?.bankDetails?.ifsc})`}</strong>
                                                </>

                                                :
                                                <> <strong>{invoiceData?.upiDetails?.upi_name}</strong></>

                                        }

                                    </p></div>
                                    <div className="col-md-6"><p><span>Client Type : </span><strong>{invoiceData?.clientDetail?.client_type_name}</strong></p></div>
                                    {invoiceData?.clientDetail?.registration_type_name == null ? null :
                                        <div className="col-md-6"><p><span>Registration Type : </span><strong>{invoiceData?.clientDetail?.registration_type_name}</strong></p></div>
                                    }
                                    <div className="col-md-6"><p><span>Country : </span><strong>{invoiceData?.clientDetail?.country_name}</strong></p></div>
                                    <div className="col-md-6"><p><span>State : </span><strong>{invoiceData?.clientDetail?.state_name}</strong></p></div>
                                    <div className="col-md-6"><p><span>Currency : </span><strong>{invoiceData?.currency_name}</strong></p></div>
                                    <div className="col-md-6"><p><span>Invoice Month : </span><strong>{`${invoiceData?.invoice_month} - ${invoiceData?.invoice_year}`}</strong></p></div>
                                </div>
                            </div>

                            <h4 className="mt-4 mb-3">Billing Details</h4>
                            <div className="table_scroll billing-details-table ">
                                <Table bordered className="view_table ">
                                    {/* <Table bordered className="view_table table-striped "> */}
                                    <thead>
                                        <tr className="text-dark">
                                            <th className="text-dark">Billing Type</th>
                                            <th className="text-dark">Specification</th>
                                            <th className="text-dark">Description</th>
                                            <th className="text-dark">Rate</th>
                                            <th className="text-dark">Quantity</th>
                                            <th className="text-dark">Deduction value</th>
                                            <th className="text-dark">Deduction Note</th>
                                            <th className="text-dark">Total value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(invoiceData).length > 0 && invoiceData?.invoice_particulars.map((item, i) => {
                                            // console.log("🚀 ~ file: InvoiceDetail.js:249 ~ {Object.keys ~ item:", item)
                                            return (<>
                                                <tr className="text-center">
                                                    <td className="text-dark">{item.billing_type_name}</td>
                                                    <td className="text-dark">{item.specification}</td>
                                                    <td className="text-dark">{item.description}</td>
                                                    <td className="text-dark">{invoiceData?.currency_symbol} {item.rate}</td>
                                                    <td className="text-dark">{item.quantity}</td>
                                                    <td className="text-dark">{invoiceData?.currency_symbol} {item.deduction_value}</td>
                                                    <td className="text-dark">{item.deduction_note}</td>
                                                    <td className="text-dark">{invoiceData?.currency_symbol} {(handlemultiplywithDecimal(
                                                        item.rate, item.quantity, item.deduction_value
                                                    ))} </td>
                                                </tr>
                                            </>)
                                        })}
                                        <tr className='border_top'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='border_right text-dark'><strong>Total Billing Amount</strong> </td>
                                            <td className="text-dark text-center"><strong>{invoiceData?.currency_symbol} {handleBillingAmount(invoiceData?.invoice_particulars)}</strong>  </td>
                                        </tr>
                                        <tr className='border_top'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='border_right text-dark'><strong>Total Invoice Deductions</strong> </td>
                                            <td className="text-dark text-center"><strong>{invoiceData?.currency_symbol} {invoiceData?.deduction_value ? invoiceData?.deduction_value : "0"}</strong>  </td>
                                        </tr>
                                        {/* {(invoiceData.tds_value == 0) ? null : <>
                                            <p>Tds Value : <b>{invoiceData?.currency_symbol} {invoiceData?.tds_value ? invoiceData?.tds_value : "0"}</b></p>
                                        </>} */}
                                        {(invoiceData.igst == 0 && invoiceData.cgst_sgst == 0) ?
                                            null :
                                            <>{(invoiceData.igst == 0 && invoiceData.cgst_sgst == 1) ? <>
                                                <tr className='border_top'>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className='border_right text-dark'><strong> SGST @ 9%</strong></td>
                                                    {/* <td>{invoiceData?.currency == 59 ? "$" : "₹"} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.sgst)}</td> */}
                                                    <td className="text-dark text-center"><strong>{invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 9)}</strong> </td>
                                                </tr>
                                                <tr className='border_top'>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className='border_right text-dark'><strong>CGST @ 9%</strong> </td>
                                                    <td className="text-dark text-center"><strong>{invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 9)}</strong> </td>
                                                </tr>
                                            </> : <>
                                                <tr className='border_top'>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className='border_right text-dark'><strong>GST @ 18%</strong> </td>
                                                    <td className="text-dark text-center"><strong>  {invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 18)}</strong> </td>
                                                </tr>
                                            </>}
                                            </>}
                                        <tr className='border_top'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='border_right text-dark'><strong>Total Invoice Amount</strong> </td>
                                            <td className="text-dark text-center"><strong>{invoiceData?.currency_symbol} {invoiceData.totalInvoiceAmount}</strong> </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    }

                </div >
                <div className='mt-4'>
                    <h4 className="mb-3">Payment Details</h4>
                    <div>
                        {Object.keys(invoiceData).length > 0 &&
                            <>
                                <div className="modalBody">
                                    <div className="table_scroll payment-table">
                                        <Table bordered className="view_table ">
                                            <thead>
                                                <tr className="text-dark">
                                                    {/* <th className="text-dark">Sl. No.</th> */}
                                                    <th className="text-dark">Payment Date</th>
                                                    <th className="text-dark">Payment Reference</th>
                                                    <th className="text-dark">Payment Amount</th>
                                                    <th className="text-dark">Payment Charges</th>
                                                    <th className="text-dark">Payment Mode</th>
                                                    <th className="text-dark">Payment Note</th>
                                                    <th className="text-dark">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {individualPaymentDetails?.length > 0 && individualPaymentDetails.map((item, i) => {
                                                    return (<>
                                                        {item?.paymentHistory?.length > 0 && item?.paymentHistory?.map((subItem, index) => {
                                                            // console.log("🚀 ~ file: InvoiceDetail.js:391 ~ {item?.paymentHistory?.length>0&&item?.paymentHistory?.map ~ subItem:", subItem)
                                                            // console.log("🚀 ~ file: InvoiceDetail.js:321 ~ {item?.paymentHistory?.length>0&&item?.paymentHistory?.map ~ subItem:", subItem)
                                                            if (subItem?.amount_received) {
                                                                const date = subItem?.amount_received_date.split("T")[0];
                                                                return (
                                                                    <>
                                                                        <tr className="text-center">
                                                                            {/* <td className="text-dark">{index + 1}</td> */}
                                                                            <td className="text-dark">{date}</td>
                                                                            <td className="text-dark">{subItem?.transaction_reference}</td>
                                                                            <td className="text-dark">{invoiceData?.currency_symbol} {subItem?.amount_received}</td>
                                                                            <td className="text-dark"> {invoiceData?.currency_symbol} {subItem?.charge_value}</td>
                                                                            <td className="text-dark">{subItem?.payment_mode_name}</td>
                                                                            <td className="text-dark">{subItem?.payment_note}</td>
                                                                            {/* <td></td> */}
                                                                            <td className="text-dark"><img src={Images.editLogoBlack} alt="icon"
                                                                                onClick={() => handlePaymentEdit(subItem)} /></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            } else
                                                                return null
                                                        })}
                                                    </>)
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>

                {/* Balance Sheet */}
                <div>
                    <h4 className="mt-4 mb-3">Balance Sheet</h4>
                    {Object.keys(invoiceData).length > 0 &&
                        <div className="balance-sheet">
                            <p><span>Total Billing Amount : </span> <strong>{invoiceData?.currency_symbol} {handleBillingAmount(invoiceData?.invoice_particulars)}</strong></p>

                            <p><span>Deduction Value :</span><b>{invoiceData?.currency_symbol} {invoiceData?.deduction_value ? invoiceData?.deduction_value : "0"} </b></p>

                            {(invoiceData.igst == 0 && invoiceData.cgst_sgst == 0) ? null :
                                <>{(invoiceData.igst == 0 && invoiceData.cgst_sgst == 1) ? <>
                                    {/* {"₹"} */}
                                    <p><span>SGST @ 9% : </span><b>{invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 9)}</b></p>

                                    <p><span>CGST @ 9% : </span> <b>{invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 9)}</b></p>

                                </> :
                                    <p><span>GST @ 18% : </span><b>{invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 18)}</b></p>
                                }
                                </>
                            }
                            <p><span>Total Invoice Amount :</span> <strong>{invoiceData?.currency_symbol} {invoiceData.totalInvoiceAmount}</strong></p>
                            {(invoiceData.clientDetail?.client_type == 1) ?
                                <p><span>Tds Value : </span><b>{invoiceData?.currency_symbol} {invoiceData?.tds_value ? invoiceData?.tds_value : "0"}</b></p> : null
                            }
                            <p><span>Payable Amount : </span><strong>{invoiceData?.currency_symbol} {invoiceData.payableAmount}</strong></p>
                            <p><span>Total Payment Received : </span><strong className='text-success'>
                                {invoiceData?.currency_symbol}   {invoiceData?.totalReceivedAmount}</strong>
                            </p>
                            <p><span>Total Payment Charges : </span><strong>  {invoiceData?.currency_symbol}   {invoiceData?.totalChargeAmount}</strong></p>
                            {/* <p>Total Payment Charges : <strong> {invoiceData?.currency_symbol}   {invoiceData?.paidAmount}</strong></p> */}
                            <p><span>Balance : </span><strong className='text-danger'>
                                {/* {invoiceDropdowns} */}
                                {invoiceData?.currency_symbol}  {invoiceData?.pendingAmount}</strong></p>
                            {/* {invoiceData?.currency_symbol}  {(invoiceData.pendingAmount < 0 || !invoiceData.pendingAmount) ? "0" : invoiceData.pendingAmount}</strong></p> */}
                        </div>}
                </div>


                <div className="mt-5 d-md-flex justify-content-end gap-3">
                    <button className="btn btn-danger" onClick={() => {
                        handleEdit(invoiceData)
                    }}>Edit Invoice</button>
                    <button className="btn btn-success" onClick={() => {
                        setShow(true)
                    }}>Generate Invoice</button>

                    {/* {invoiceData.payment_status == PAYMENT_STATUS_MODE.PAYMENT_DONE ? null : */}
                    <button className="btn btn-primary" onClick={() => {
                        setPayModal(true)
                    }}>Receive Payment</button>
                    {/* } */}
                </div>

            </div >

            {/* Modal for the downloading generated Invoice View */}
            <Modal Modal show={show} onHide={() => setShow(false)} className="invoice-modal " >
                {
                    Object.keys(companyDetail).length > 0 &&
                    <>
                        <Modal.Body>
                            <div className="modalBody pb-3" id="divToPrint">
                                <div className="view-invoice">
                                    {/* <div className="container "> */}
                                    {/* <div className="container bg-white"> */}
                                    <div className="px-md-4 py-5 my-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <img src={Images.InvoiceLogo} alt="logo" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fw-bolder">
                                                    <span>
                                                        {companyDetail?.company_name}
                                                    </span><br />
                                                    {/* {companyAddress?.length > 0 && companyAddress?.map((item, index) => {
                                                        console.log("🚀 ~ file: InvoiceDetail.js:501 ~ {companyAddress?.length>0&&companyAddress?.map ~ item:", item)
                                                        return (
                                                            <> */}
                                                    <span> {companyAddress?.building}</span> ,
                                                    <span> {companyAddress?.street}</span> <br />
                                                    <span>{companyAddress?.city}</span> - <span>{companyAddress?.pincode}</span> <br />
                                                    <span>{companyAddress?.state_name}</span> ,
                                                    <span>{companyAddress?.country_name}</span>
                                                    {/* </>
                                                        )
                                                    })} */}
                                                </div >
                                            </div>
                                        </div>
                                        <div className="d-md-flex justify-content-between mt-3">
                                            <p className="f-700">Invoice No. : <span className="ml-3">{invoiceData?.invoice_number}</span> </p>
                                            <p className="f-700">Invoice Date : <span className="mx-4">{invoiceData?.created_at && formatDate(invoiceData?.created_at.split("T")[0])}</span></p>
                                        </div>
                                        <hr className="hr-line text-white" />
                                        <div className="row justify-content-between">
                                            <div className="col-md-5">
                                                <div className="bg-title">
                                                    <p className="f-700">{companyDetail?.company_name}</p>
                                                </div>
                                                <table className="table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td>contact</td>
                                                            <td>{companyDetail?.contact_person_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone</td>
                                                            <td>{companyDetail?.contact_number}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email</td>
                                                            <td><a href={`mailto:${companyDetail?.email}`}>{companyDetail?.email}</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>GST No</td>
                                                            <td>{companyDetail?.gst_no}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SAC CODE</td>
                                                            <td>{companyDetail?.sac_code}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-7 col-lg-6">
                                                <div className="bg-title">
                                                    <p className="f-700">{invoiceData?.clientDetail?.company_name}</p>
                                                </div>
                                                <table className="table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td>contact</td>
                                                            <td>{invoiceData?.clientDetail?.contact_person_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone</td>
                                                            <td>{invoiceData?.clientDetail?.contact_number}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email</td>
                                                            <td><a href={`mailto:${invoiceData?.clientDetail?.e_mail}`}>{invoiceData?.clientDetail?.e_mail}
                                                            </a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Address</td>
                                                            <td>
                                                                {invoiceData?.clientDetail?.state_name}, {invoiceData?.clientDetail?.country_name}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="table_scroll">
                                            <table className="table bill-table tables">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Month</th>
                                                        <th scope="col">Billing Type Name</th>
                                                        <th scope="col">Specification</th>
                                                        <th scope="col">Rate</th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Deductions</th>
                                                        <th scope="col">Total</th>
                                                    </tr>
                                                </thead>
                                                {Object.keys(invoiceData).length > 0 &&
                                                    <tbody>
                                                        {invoiceData?.invoice_particulars?.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{invoiceData?.invoice_month}-{invoiceData?.invoice_year}</td>
                                                                        <td>{item.billing_type_name}</td>
                                                                        <td>{item.specification}</td>
                                                                        <td>{invoiceData?.currency_symbol} {item?.rate}</td>
                                                                        <td >{item?.quantity}</td>
                                                                        <td>{invoiceData?.currency_symbol} {item.deduction_value}</td>
                                                                        <td>{invoiceData?.currency_symbol} {((item?.rate * item?.quantity) - item.deduction_value)}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                        <tr className='border_top'>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className='border_right'>Other Deductions (if any)</td>
                                                            <td>{invoiceData?.currency_symbol} {invoiceData?.deduction_value ? invoiceData?.deduction_value : "0"} </td>
                                                        </tr>
                                                        {(invoiceData.igst == 0 && invoiceData.cgst_sgst == 0) ?
                                                            null : <>{invoiceData.igst == 0 ? <>
                                                                <tr className='border_top'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className='border_right'>SGST @ 9%</td>
                                                                    {/* <td>{invoiceData?.currency == 59 ? "$" : "₹"} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.sgst)}</td> */}
                                                                    <td>{invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 9)}</td>
                                                                </tr>
                                                                <tr className='border_top'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className='border_right'>CGST @ 9%</td>
                                                                    <td>{invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 9)}</td>
                                                                </tr>
                                                            </> : <>
                                                                <tr className='border_top'>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className='border_right'>GST @ 18%</td>
                                                                    <td>{invoiceData?.currency_symbol} {handleCalculateGstAmount(invoiceData.invoice_particulars, invoiceData.deduction_value, 18)}</td>
                                                                </tr>
                                                            </>}
                                                            </>}
                                                        <tr className='border_top'>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className='border_right'>Grand Total</td>
                                                            <td>{invoiceData?.currency_symbol} {invoiceData?.totalInvoiceAmount} </td>
                                                        </tr>
                                                    </tbody>}
                                            </table>
                                        </div>
                                        {/* Deductions Note */}
                                        <div>
                                            <p className="f-700 f-16 u-line">Summary Deductions Note:</p>

                                            <div className="deductions-list">
                                                {Object.keys(invoiceData).length > 0 &&
                                                    <ul>
                                                        {invoiceData?.deduction_note && <li>{invoiceData?.deduction_note}</li>}
                                                        {invoiceData?.invoice_particulars.length > 0 && invoiceData?.invoice_particulars.map((item, i) => {
                                                            return <>{item.deduction_note ? <li>{item.deduction_note}</li> : null}</>
                                                        })}
                                                    </ul>}
                                            </div>
                                        </div>
                                        <div>
                                            <p className="f-700 f-16 u-line">Payment terms:</p>
                                            <p className="mb-0">Money wire transfer only to the company account</p>
                                            <p>Money transfer though USD only.</p>
                                        </div>

                                        {/* {
                                            invoiceData?.bankDetails ? <>Bank Details Exist</> :
                                                <>Upi Details Exist</>
                                        } */}

                                        {/* Bank Details */}

                                        {
                                            invoiceData?.bankDetails.bank_name ?
                                                <>
                                                    {(invoiceData?.bankDetails?.bank_name == "HDFC" || invoiceData?.bankDetails?.bank_name == "Hdfc" || invoiceData?.bankDetails?.bank_name == "hdfc" ?
                                                        <div>
                                                            <div>
                                                                <div class="">
                                                                    <table className="table bill-table tables">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Correspondent Bank</th>
                                                                                <th scope="col">Correspondent Bank's Swift Code</th>
                                                                                <th scope="col">Correspondent Account Number</th>
                                                                                <th scope="col">Additional Account Details</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {Object.keys(invoiceData).length > 0 &&
                                                                            <tbody>
                                                                                {/* {invoiceData?.map((item, index) => {
                                                                        console.log("🚀 ~ file: InvoiceDetail.js:717 ~ {invoiceData?.invoice_particulars?.map ~ item:", item)
                                                                        return ( */}
                                                                                <>
                                                                                    <tr>
                                                                                        <td>{invoiceData?.bankDetails?.correspondentBank}</td>
                                                                                        <td>{invoiceData?.bankDetails?.correspondentSwiftCode}</td>
                                                                                        <td>{invoiceData?.bankDetails?.correspondentAccountNumber}</td>
                                                                                        {/* {(invoiceData?.bankDetails?.bank_name == "HDFC" || invoiceData?.bankDetails?.bank_name == "Hdfc" || invoiceData?.bankDetails?.bank_name == "hdfc" ? */}

                                                                                        <td>
                                                                                            {invoiceData?.bankDetails?.additionalInformation?.map((item, i) => {
                                                                                                return (
                                                                                                    <div>
                                                                                                        <p className='mb-0'>{item?.additionalAccountValue}</p>
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </td>

                                                                                        {/* <td>
                                                                                            {invoiceData?.bankDetails?.additionalInformation?.map((item, i) => {
                                                                                                return (
                                                                                                    <div>
                                                                                                        <p className='mb-0'>{item?.additionalAccountValue}</p>
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </td> */}
                                                                                        {/* // : "")} */}

                                                                                    </tr>
                                                                                </>
                                                                                {/* )
                                                                    })} */}
                                                                            </tbody>}
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <table className="table account-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <p className="text-center f-700 mt-1">Beneficiary Bank</p>
                                                                        </td>
                                                                        <td>
                                                                            <p>Bank Name: {invoiceData?.bankDetails?.bank_name}</p>
                                                                            <hr />
                                                                            <p>Branch: {invoiceData?.bankDetails?.branch}</p>
                                                                            <hr />
                                                                            <p className="f-700">SWIFT Code: {invoiceData?.bankDetails?.swift_code}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p className="text-center f-700 mt-1">Beneficiary Customer</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="f-700">Beneficiary Name: {invoiceData?.bankDetails?.beneficiary}</p>
                                                                            <hr />
                                                                            <p className="f-700">Account No: {invoiceData?.bankDetails?.account_no}</p>
                                                                            <hr />
                                                                            <p className="f-700">Purpose Code: {invoiceData?.bankDetails?.purpose_code}</p>
                                                                            <hr />
                                                                            <p>IFSC Code: {invoiceData?.bankDetails?.ifsc}</p>
                                                                            {/* <p>SBI Branch / IFSC Code: {invoiceData?.bankDetails?.ifsc}</p> */}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p className="text-center f-700">Purpose Code/purpose of remittance(S0802)
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p>{invoiceData?.bankDetails?.purpose_remittance}</p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <div>
                                                            <table className="table account-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <p className="text-center f-700 mt-1">Field 57(Beneficiary Bank)</p>
                                                                        </td>
                                                                        <td>
                                                                            <p>Bank Name: {invoiceData?.bankDetails?.bank_name}</p>
                                                                            <hr />
                                                                            <p>Branch: {invoiceData?.bankDetails?.branch}</p>
                                                                            <hr />
                                                                            <p className="f-700">SWIFT Code: {invoiceData?.bankDetails?.swift_code}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p className="text-center f-700 mt-1">Field 59(Beneficiary Customer)</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="f-700">Beneficiary Name: {invoiceData?.bankDetails?.beneficiary}</p>
                                                                            <hr />
                                                                            <p className="f-700">Account No: {invoiceData?.bankDetails?.account_no}</p>
                                                                            <hr />
                                                                            <p className="f-700">Purpose Code: {invoiceData?.bankDetails?.purpose_code}</p>
                                                                            <hr />
                                                                            <p>IFSC Code: {invoiceData?.bankDetails?.ifsc}</p>
                                                                            {/* <p>SBI Branch / IFSC Code: {invoiceData?.bankDetails?.ifsc}</p> */}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p className="text-center f-700">Field 70/72(Remittance information/purpose of remittance)
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p>{invoiceData?.bankDetails?.purpose_remittance}</p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p className="mb-0"><span className='align-correct'>Account name : </span><span className="f-700">{invoiceData?.bankDetails?.beneficiary}</span></p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="mb-0"><span className='align-correct'>Account No : </span><span className="f-700">{invoiceData?.bankDetails?.account_no}</span></p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="mb-0"><span className='align-correct'>Bank : </span><span className="f-700">{invoiceData?.bankDetails?.bank_name}</span></p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="mb-0"><span className='align-correct'>Branch : </span> <span className="f-700"> {invoiceData?.bankDetails?.branch}</span></p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="mb-0"><span className='align-correct'> City : </span><span className="f-700"> {invoiceData?.bankDetails?.city}</span></p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="mb-0"><span className='align-correct'>State : </span><span className="f-700"> {invoiceData?.bankDetails?.state_name}</span></p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="mb-0"><span className='align-correct'>Country : </span><span className="f-700"> {invoiceData?.bankDetails?.country_name}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                                :
                                                // {/* Upi Details */}
                                                <>
                                                    {/* <h1>UPI Details</h1> */}
                                                    <div>
                                                        <table className="table account-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <p className="text-center f-700 mt-1 pt-3">UPI Details</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>UPI Name: {invoiceData?.upiDetails?.upi_name}</p>
                                                                        {invoiceData?.upiDetails?.upi_email == "" ? null :
                                                                            <>
                                                                                <hr />
                                                                                <p>UPI EmailID : {invoiceData?.upiDetails?.upi_email}</p>
                                                                            </>
                                                                        }
                                                                        {/* <hr />
                                                                        <p>UPI EmailID : {invoiceData?.upiDetails?.upi_email}</p> */}
                                                                        {invoiceData?.upiDetails?.upi_link == "" ? null :
                                                                            <>
                                                                                <hr />
                                                                                <p>UPI Link : {invoiceData?.upiDetails?.upi_link}</p>
                                                                            </>

                                                                        }

                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                        }
                                        <div className="mt-4">
                                            <div className="row">
                                                <div className="col-md-7 mb-4 mb-md-0">
                                                    <p>If you have any questions or concerning about this invoice, please contact</p>
                                                    <p className="f-700 mb-0">{companyDetail?.contact_person_name} : <a href="mailto:chandra@dollarbirdinc.com">{companyDetail?.email}</a></p>
                                                    {/* <p className="f-700 mb-0"><a href="mailto:chandra@dollarbirdinc.com">{companyDetail?.email}</a></p> */}
                                                    <p className="f-700"><span className="mr-5">Mob :</span> <a href="">{companyDetail?.contact_number}</a></p>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="text-center">
                                                        {/* <p className="f-16 f-700">For DOLLARBIRD TECHNOLOGIES PVT. LTD</p> */}
                                                        <img src={Images.Sign} className="w-75" alt="sign" />
                                                        {/* <h4 className="f-700 mt-4 pt-2">Managing Director</h4> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 bg-sec text-center">
                                            <p className="f-700 f-16">THANK YOU FOR YOUR BUSINESS!</p>
                                        </div>
                                    </div>
                                    {/* </div> */}

                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-danger mx-2" onClick={() => setShow(false)}>Close</button>
                                {/* <button className="btn btn-primary mx-2" onClick={() => {
                                    setShow(false)
                                    setInvoiceDetailModal(true)
                                }}>Back to Details</button> */}
                                <button className="btn btn-success" onClick={printDocument}>Download Invoice</button>
                            </div>
                        </Modal.Body>
                    </>
                }


            </Modal >

            {/* Modal for the Payment update Modal */}
            < Modal show={payModal} onHide={() => {
                setPayModal(false)
                setEditPayment({})
                setEditPaymentStatus(false)
            }} className="invoice-modal" >
                <Modal.Header closeButton>
                    <Modal.Title>Update Payment Details</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="modalBody pb-3">
                        {/* <div className="mb-3">
                            <p>Client Name : <strong>{invoiceData?.clientDetail?.company_name}</strong></p>
                        </div> */}
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit} autoComplete='off'>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Invoice Number<strong className="text-danger">*</strong></>}
                                                type="text"
                                                value={invoiceData?.invoice_number}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="mb-2">Received Payment Date<strong className="text-danger">*</strong></label>
                                                <DatePicker
                                                    name="amount_received_date"
                                                    selected={values.amount_received_date ? new Date(values.amount_received_date) : ""}
                                                    onSelect={(e) => {
                                                        setFieldValue("amount_received_date", e);
                                                    }}
                                                    onChange={(val) => {
                                                        setFieldValue("amount_received_date", val ? val : "");
                                                    }}
                                                    onBlur={handleBlur}
                                                    className="setting_field"
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="dd-mm-yyyy"
                                                />
                                                <ErrorMessage name="amount_received_date" component="div" className="text-danger position-absolute small" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Selectelement
                                                optionArray={
                                                    <>
                                                        {paymentmode.length > 0 && paymentmode.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item?.id}>
                                                                    {item?.payment_mode_name}
                                                                </option>
                                                            );
                                                        })}
                                                    </>}
                                                select_Label={<>Received Payment Mode<strong className="text-danger">*</strong></>}
                                                name="payment_mode_id"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.payment_mode_id}
                                                formikValidation={
                                                    <ErrorMessage name="payment_mode_id" component="div" className="text-danger position-absolute small" />
                                                } />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Received Payment Reference<strong className="text-danger">*</strong></>}
                                                type="text"
                                                name="transaction_reference"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.transaction_reference}
                                                placeholder="Enter transaction reference"
                                                formikValidation={
                                                    <ErrorMessage name="transaction_reference" component="div" className="text-danger position-absolute small" />
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Received Payment Amount<strong className="text-danger">*</strong></>}
                                                type="number"
                                                name="amount_received"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.amount_received}
                                                placeholder="Enter Amount"
                                                formikValidation={
                                                    <ErrorMessage name="amount_received" component="div" className="text-danger position-absolute small" />
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Recieved Payment Note</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: "80px" }}
                                                    name="payment_note"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.payment_note}

                                                />
                                                <ErrorMessage name="payment_note" component="div" className="text-danger position-absolute small" />

                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Payment Related Charges</>}
                                                type="number"
                                                name="charge_value"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.charge_value}
                                                placeholder="Enter Amount"
                                                formikValidation={
                                                    <ErrorMessage name="charge_value" component="div" className="text-danger position-absolute small" />
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Charges Note</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ height: "80px" }}
                                                    name="charge_note"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.charge_note}

                                                />
                                                <ErrorMessage name="charge_note" component="div" className="text-danger position-absolute small" />

                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-4">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>

            </Modal >

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    )
}
