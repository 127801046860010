import React, { useEffect, useMemo, useState } from "react";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useFilters,
} from "react-table";
import "../../../style/tableStyle.scss";
import { GlobalFilter } from "../../../utils/GlobalFilter";
import { ColumnFilter } from "../../../utils/ColumnFilter";
import { PostRequestHook } from "../../../apis/Services";
import { configUrl } from "../../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { Images } from "../../../utils/images";
import { TableNavigation } from "../../../utils/TableNavigation";
import { Link, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../utils/common";
import Modal from "react-bootstrap/Modal";

export const ClientContactList = (props) => {
    const { editData } = props
    const navigate = useNavigate()
    const [fieldData, setFieldData] = useState([]);
    const [show, setShow] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("");
    const [viewStatus, setViewStatus] = useState([]);
    // console.log("🚀 ~ file: ClientContactList.js:30 ~ viewStatus:", viewStatus)
    const handleCloseDelete = () => setShow(false);
    // const handleClose = () => {
    //     setShow(true);
    //     // setLeaveData([])
    // };

    const { getRequest, putRequest } = PostRequestHook()
    const { userInfo } = useSelector((state) => state.UIStore);

    useEffect(() => {
        getClientData()
    }, [])

    const getClientData = async () => {
        let response = await getRequest(configUrl.getClientData)
        // console.log("🚀 ~ file: ClientContactList.js:40 ~ getClientData ~ response:", response)
        let _tableData = response?.data?.data ? response?.data?.data : []
        setFieldData(_tableData)
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleShow = (data) => {
        setViewStatus([data])
        setShow(true)
    };
    const handleClose = () => {
        setShow(false);
        setViewStatus([])
    };

    const handleCancel = (data) => {
        deleteLeave(data)
    };

    const deleteLeave = async (data) => {
        var response = await putRequest(`${configUrl.deleteClientData}${data?.id}`)
        // if (response) {
        // console.log("🚀 ~ file: ClientContactList.js:66 ~ deleteLeave ~ response:", response)
        if (response.status == 201 || response.status == 200) {
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            window.location.reload();
            // getAdminLeaves()
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "SL No.",
                accessor: "id",
                accessor: (row, index) => index + 1,
                Cell: ({ value }) => <div>{value}</div>,
                Filter: ColumnFilter,
            },
            {
                Header: "Company Name",
                accessor: "company_name",
                Filter: ColumnFilter,
            },
            // {
            //     Header: "Registration Number",
            //     accessor: "registration_number",
            //     Filter: ColumnFilter,
            // },
            {
                Header: "Client Type",
                accessor: "client_type_name",
                Filter: ColumnFilter,
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            <div className="d-flex flex-row justify-content-center align-items-center">

                                {/* <button className="btn btn-transparent p-0 mx-2 border-0" type="button" onClick={() => onViewClick(cellProps)}>
                                    <img
                                        src={Images.editLogo}
                                        alt="icon"
                                        id="editInventory"
                                        className="editIcon mx-2"

                                    />
                                </button> */}

                                {/* <a href={RouteStrings.viewclient}> */}
                                <button
                                    id="viewbtn"
                                    className="btn btn-transparent p-0 mx-2 border-0"
                                    onClick={() => handleShow(cellProps.cell.row.original)}
                                >
                                    <img src={Images.viewIcon} alt="icon" />
                                </button>
                                {/* <img
                                    src={Images.viewIcon}
                                    alt="icon"
                                    id="editInventory"
                                    className="editIcon mx-2"
                                onClick={() => handleClose(show)}
                                /> */}
                                {/* </a> */}

                                {/* <a href={RouteStrings.editclient}> */}
                                <button
                                    id="cancelbtn"
                                    className="btn btn-transparent p-0 mx-2 border-0"
                                    onClick={() => handleCancel(cellProps.cell.row.original)}>
                                    <img src={Images.deleteLogo} alt="icon" />
                                </button>
                                {/* <img
                                    src={Images.editLogo}
                                    alt="icon"
                                    id="editInventory"
                                    className="editIcon mx-2"
                                onClick={() => onViewClick(cellProps)}
                                /> */}
                                {/* </a> */}

                                {/* <img
                                    src={Images.deleteLogo}
                                    alt="icon"
                                    id="showinventoryHistory"
                                    className="editIcon mx-2"
                                onClick={() => onDeleteClick(cellProps.cell.row.original.id)}
                                /> */}
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const data = useMemo(() => fieldData, [fieldData]);
    // const data = useMemo(() => fieldData, [fieldData] );
    // create a table instance
    const {
        tableInstance,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        usePagination
    );

    const { globalFilter, pageSize, pageIndex } = state;
    return (
        <>
            <div className="client-contact-list mt-5">
                {/* <div className="d-flex justify-content-between">
                    <div>
                        <h3 className="header_color">Client List</h3>
                    </div>
                    <div>
                        <div className="text-end">
                            <Link id="dashboardbtn" to="/" className="goback mb-2">
                                Back to Dashboard
                            </Link>
                        </div>
                        <div className="add-contact-btn text-end">
                            <Link to={`/${RouteStrings.addclient}`} className="btn btn-primary m-0"> Add Client</Link>
                        </div>

                    </div>
                </div> */}

                <div className="mt-5 mb-3 d-flex justify-content-between">
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                    <div>
                        <select
                            value={pageSize}
                            className="selectTag w-auto"
                            onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                            <option value="" disabled>
                                Select
                            </option>
                            {[5, 10, 15, 25, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <div className="table_scroll">
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps()}>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
                            nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
                    </div>
                </div>
            </div>
            {/* Modal for the Invoice View */}
            <Modal show={show} size="xl" onHide={handleCloseDelete} className="client-modal">
                {viewStatus.map(item => {
                    // console.log("🚀 ~ file: ClientContactList.js:284 ~ deleteLeave ~ item:", item)
                    return (
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>  <h3 className="header_color ms-3">Client Detail</h3></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modalBody pb-3" >
                                    <div className="add-contacts px-2 px-md-4">
                                        <div>
                                            <div className="test_bg p-2">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <p className="text-white">Company : {item?.company_name}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        item?.website == "" ?
                                                            null
                                                            :
                                                            <div className="col-md-6">
                                                                <div className="mb-2">
                                                                    <p className="text-white">Website : {item?.website}</p>
                                                                </div>
                                                            </div>
                                                    }

                                                    {/* Clients Type */}
                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <p className="text-white">Client Type : {item?.client_type_name}</p>

                                                        </div>
                                                    </div>

                                                    {item?.registration_type_name == null ? null : <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <p className="text-white">Registration Type : {item?.registration_type_name} </p>
                                                        </div>
                                                    </div>
                                                    }

                                                    {
                                                        item?.registration_number == "" ?
                                                            null
                                                            :
                                                            <div className="col-md-6">
                                                                <div className="mb-2">
                                                                    <p className="text-white">Registration Number : {item?.registration_number} </p>
                                                                </div>
                                                            </div>
                                                    }

                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <p className="text-white">Company Phone : {item?.company_contact_number}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Address */}
                                                <div className="address">
                                                    <h5 className="text-white  my-3 ">Address<span className="text-danger">*</span></h5>
                                                    {/* <div className="d-flex justify-content-between">
                                            <h5>Billing address</h5>
                                        </div> */}
                                                    {item?.client_address.map(subItem => {
                                                        // console.log("🚀 ~ file: ClientContactList.js:387 ~ deleteLeave ~ item:", item)
                                                        return (
                                                            <>
                                                                <div className="row border my-3">
                                                                    <p className="text-white text-bold fw-bolder mt-2" >{subItem?.set_default_address == 1 ?
                                                                        <>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1" checked />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                    Primary Address
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                        : ""}</p>
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <p className="text-white">Street : {subItem?.street}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <p className="text-white">Country : {subItem?.country_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <p className="text-white">State : {subItem?.state_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <p className="text-white">City : {subItem?.city}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="mb-2">
                                                                            <p className="text-white">PinCode : {subItem?.pincode}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}

                                                </div>

                                                {
                                                    item?.tax_registration_no == "" || item?.cst_registration_no == "" || item?.pan_no == "" || item?.remarks == "" ? null :
                                                        <div>
                                                            {/* Tax Info */}
                                                            < div className="tax-info ">
                                                                <h5 className="text-white  my-3 ">Tax Info</h5>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-2">
                                                                            <p className="text-white">Tax Reg. No. : {item?.tax_registration_no} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-2">
                                                                            <p className="text-white">CST reg. no. : {item?.cst_registration_no} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-4">
                                                                            <p className="text-white">PAN No. : {item?.pan_no} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ div>
                                                            {/* Remarks */}
                                                            <div className="remarks mt-4">
                                                                <h5 className="text-white  my-3 ">Remarks</h5>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-2">
                                                                            <p className="text-white">{item?.remarks}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                                {/* Contact Details */}
                                                <div className="contact-details">
                                                    <h5 className="text-white  my-3 ">Contact Details<span className="text-danger">*</span></h5>
                                                    {item?.client_contact_information.map(subItem => {
                                                        return (
                                                            <>
                                                                <div className="row border my-3">
                                                                    <p className="text-white font-blod fw-bolder mt-2">{subItem?.set_primary_contact == 1 ?
                                                                        <>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                    Primary Contact
                                                                                </label>
                                                                            </div>
                                                                        </> : ""}</p>
                                                                    <div className="col-md-6 my-2">
                                                                        <p className="text-white">Contact Person Name : {subItem?.contact_person_name}</p>
                                                                    </div>
                                                                    <div className="col-md-6 my-2">
                                                                        <p className="text-white">email : {subItem?.e_mail} </p>
                                                                    </div>
                                                                    <div className="col-md-6 my-2">
                                                                        <p className="text-white">Contact Number : {subItem?.contact_number} </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                    {/* <div className="row border">
                                                        <div className="col-md-6 my-2">
                                                            <p className="text-white">Contact Person Name : </p>
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <p className="text-white">email : </p>
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <p className="text-white">Contact Number : </p>
                                                        </div>
                                                    </div> */}
                                                </div>


                                            </div >
                                        </div >
                                        <div className="text-end mt-5">
                                            <button className="btn btn-danger" onClick={() => editData(viewStatus[0])}>Edit info</button>
                                        </div>
                                    </div >
                                </div>
                            </Modal.Body >
                        </>
                    )
                })}
            </Modal >

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    )
}
