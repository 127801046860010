import React, { useEffect, useMemo, useState } from "react";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useFilters,
    useSortBy
} from "react-table";
import "../../../style/tableStyle.scss";
import { GlobalFilter } from "../../../utils/GlobalFilter";
import { ColumnFilter } from "../../../utils/ColumnFilter";
import { PostRequestHook } from "../../../apis/Services";
import { configUrl } from "../../../apis/api.config";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { Images } from "../../../utils/images";
import { TableNavigation } from "../../../utils/TableNavigation";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { InvoiceDetail } from "./InvoiceDetail";
import { Button } from "react-bootstrap";
import { PAYMENT_STATUS_MODE } from "../../../utils/common";
import { FaSort, FaSortAmountDown, FaSortAmountUpAlt } from "react-icons/fa";

const changeRowBackGroundColor = (row_data) => {
    const payment_status = row_data?.original?.payment_status
    return payment_status ? (payment_status == PAYMENT_STATUS_MODE.PAYMENT_DONE ? "green" : "#003bc0") : ""
}

export const InvoiceList = (props) => {
    const { handleEdit, handleCreateInvoice } = props
    const [invoiceList, setInvoiceList] = useState([]);
    const [invoiceDetailModal, setInvoiceDetailModal] = useState(false);
    const [invoiceDetail, setInvoiceDetail] = useState({});
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const navigate = useNavigate()
    const { getRequest, putRequest } = PostRequestHook()

    useEffect(() => {
        getInvoiceList()
    }, [])
    // get invoice list
    const getInvoiceList = async () => {
        let response = await getRequest(configUrl.getInvoiceList)
        let _data = response?.data?.data ? response?.data?.data : []
        setInvoiceList(_data)
    }

    useEffect(() => {
        let data1 = invoiceList.filter(item => item.id == invoiceDetail.id)
        setInvoiceDetail(data1[0] || {})
    }, [invoiceList])

    // delete Invoice
    const handleInvoiceDelete = async (data) => {
        let response = await putRequest(`${configUrl.deleteInvoice}${data}`)
        if (response.status == 200 || response.status == 201) {
            snackBarContent(true, response.data.message)
            getInvoiceList()
        }
    }

    // view particular invoice details
    const handleViewDetail = (data) => {
        setInvoiceDetail(data)
        setInvoiceDetailModal(true)
    };


    // invoice list table Headers
    const columns = React.useMemo(
        () => [
            {
                Header: "SL No.",
                accessor: "slNo",
                accessor: (row, index) => index + 1,
                Cell: ({ value }) => <div>{value}</div>,
            },
            {
                Header: "Invoice No.",
                accessor: "invoice_number",
                Filter: ColumnFilter,
            },
            {
                Header: "Invoice Month",
                accessor: "invoice_month",
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            <>{cellProps.cell.row.original.invoice_month}</> - <>{cellProps.cell.row.original.invoice_year}</>
                        </div>
                    );
                },
                Filter: ColumnFilter,
            },
            {
                Header: "Client",
                accessor: "client",
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.clientDetail?.company_name}
                        </div>
                    );
                },
                Filter: ColumnFilter,
            },
            {
                Header: "Total Invoice Amount",
                accessor: "totalInvoiceAmount",
                Cell: (cellProps) => {
                    let currencySymbol = cellProps.cell.row.original.currency_symbol
                    let currencyPendingAmount = cellProps.cell.row.original.totalInvoiceAmount
                    return (
                        <div className="cellStyles">
                            <span>{currencySymbol}</span>
                            <span> {currencyPendingAmount}</span>
                        </div>
                    );
                },
                // accessor: "total_amount",
                Filter: ColumnFilter,
            },
            {
                Header: "Payble Amount",
                accessor: "payableAmount",
                Cell: (cellProps) => {
                    let currencySymbol = cellProps.cell.row.original.currency_symbol
                    let currencyPendingAmount = cellProps.cell.row.original.payableAmount
                    return (
                        <div className="cellStyles">
                            <span>{currencySymbol}</span>
                            <span> {currencyPendingAmount}</span>
                        </div>
                    );
                },
                Filter: ColumnFilter,
            },
            {
                Header: "Paid Amount",
                accessor: "paidAmount",
                Cell: (cellProps) => {
                    let currencySymbol = cellProps.cell.row.original.currency_symbol
                    let currencyPendingAmount = cellProps.cell.row.original.paidAmount
                    return (
                        <div className="cellStyles">
                            <span>{currencySymbol}</span>
                            <span> {currencyPendingAmount}</span>
                        </div>
                    );
                },
                Filter: ColumnFilter,
            },
            {
                Header: "Balance",
                accessor: "pendingAmount",
                Cell: (cellProps) => {
                    let currencySymbol = cellProps.cell.row.original.currency_symbol
                    let currencyPendingAmount = cellProps.cell.row.original.pendingAmount
                    return (
                        <div className="cellStyles">
                            <span>{currencySymbol}</span>
                            <span> {currencyPendingAmount}</span>
                        </div>
                    );
                },
                Filter: ColumnFilter,
            },
            {
                Header: "Payment Status",
                accessor: "payment_status_name",
                Cell: (cellProps) => {
                    let paymentstatus = cellProps.cell.row.original.payment_status
                    return (
                        <div className="cellStyles">
                            {paymentstatus == 1 ? "Pending" : (paymentstatus == PAYMENT_STATUS_MODE.PAYMENT_DONE ? "Completed" : "Partial Payment")}
                        </div>
                    );
                },
                Filter: ColumnFilter,
                // <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <img
                                    src={Images.viewIcon}
                                    alt="icon"
                                    id="editInventory"
                                    className="editIcon mx-2"
                                    onClick={() => handleViewDetail(cellProps.cell.row.original)}
                                />

                                <img
                                    src={Images.deleteLogo}
                                    alt="icon"
                                    id="showinventoryHistory"
                                    className="editIcon mx-2"
                                    onClick={() => handleInvoiceDelete(cellProps.cell.row.original.id)}
                                />
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );
    const data = useMemo(() => invoiceList, [invoiceList]);

    // create a table instance
    const {
        tableInstance,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
    );
    const { globalFilter, pageSize, pageIndex } = state;

    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }
    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };


    return (
        <>
            <div className="d-flex justify-content-between mt-4">
                <div>
                    <h3 className="">{invoiceDetailModal ? "Invoice and Payment Details" : "Invoice List"}</h3>
                </div>
                <div className="text-end">
                    {/* <div>
                        <Link id="dashboardbtn" to="/" className="goback mb-2">
                            Back to Dashboard
                        </Link>
                    </div> */}
                    <div>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (invoiceDetailModal) {
                                    setInvoiceDetailModal(false)
                                } else {
                                    handleCreateInvoice()
                                }
                            }}
                        >
                            {invoiceDetailModal ? "Invoice List" : "Create Invoice"}

                        </Button>
                    </div>
                </div>
            </div>

            {invoiceDetailModal ?
                <InvoiceDetail invoiceData={invoiceDetail} handleEdit={handleEdit} getInvoiceList={getInvoiceList}
                    setInvoiceDetailModal={() => {
                        setInvoiceDetailModal(false)
                        getInvoiceList()
                    }} /> :
                <div className="client-contact-list mt-5">
                    <div div className="mt-5 mb-3 d-flex justify-content-between" >
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <div>
                            <select
                                value={pageSize}
                                className="selectTag w-auto"
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                {[5, 10, 15, 25, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div >
                    <div className="text-center mt-3">
                        <div className="table_scroll">
                            <table {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    <div className="table-head">
                                                        {column.render('Header')}
                                                        <span className="sort">
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <FaSortAmountDown />
                                                                    : <FaSortAmountUpAlt />
                                                                : <FaSort color="#e7ecf1" />}
                                                        </span>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...{ style: { backgroundColor: changeRowBackGroundColor(row) }, ...row.getRowProps() }} >
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...{ style: { backgroundColor: "unset" }, ...cell.getCellProps() }}>{cell.render("Cell")}</td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-center">
                            <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
                                nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
                        </div>
                    </div>
                </div >}

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    )
}
