import { useState } from "react";
import "./App.css";
import RouteCheck from "./routes/RouteCheck";
import { Header } from "./components/Header/Header";
import { UpdatePassword } from "./pages/Auth/UpdatePassword/UpdatePassword";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetAuth, setExpired } from "./store/reducers/ui.reducer";
import { ENVDATA } from "./Conflict/Conflct";
import { Spinner } from "./components/Spinner/Spinner";
import { LogoutModal } from "./components/LogoutModal/LogoutModal";
import { RouteStrings } from "./utils/common";

function App() {
  const { userInfo, loader, isExpired } = useSelector((state) => state.UIStore);
  let role = userInfo ? userInfo.role_name || userInfo.role : null
  let isAuthenticate = userInfo ? userInfo.isAuthenticated : null
  const organization_name = userInfo ? userInfo.organization_name : null
  var adminId = userInfo ? userInfo.emp_id : null

  let show = (role !== "super_admin" && isAuthenticate == 0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleUpdateModal = () => {
    dispatch(resetAuth());
    navigate(`/${organization_name}/login`)
  }
  const handleLogoutModal = () => {
    dispatch(resetAuth());
    navigate((adminId == null || adminId == "") ? "/login" : `/${organization_name}/login`)
  }

  return (
    <div>
      <Header />
      <div>
        <RouteCheck />
      </div>
      <UpdatePassword updateModal={show} handleClick={handleUpdateModal} />
      <Spinner spin={loader} />

      <LogoutModal logoutModal={isExpired} handleLogout={handleLogoutModal} />
    </div>
  );
}

export default App;
