import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  useTable,
  usePagination,
  useFilters,
  useRowSelect,
} from "react-table";
import "../../style/tableStyle.scss";
import { GlobalFilter } from "../../utils/GlobalFilter";
import { ColumnFilter } from "../../utils/ColumnFilter";
import Form from "react-bootstrap/Form";
import { Checkbox } from "../../utils/checkbox";
import { useSelector } from "react-redux";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import Select from 'react-select';
import { TableNavigation } from "../../utils/TableNavigation";

export const UpdateEmployeeDependantsTable = () => {
  const [lengthString, setLengthString] = useState(0);
  const [updatedData, setUpdatedData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [filter, setFilter] = useState('');
  const [snackOpen, setSnackOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [snackMessage, setSnackMessage] = useState("")
  const [employeeArray, setEmployeeArray] = useState([])

  const { getRequest, putRequest } = PostRequestHook()

  useEffect(() => {
    getEmployeeDepaedents()
    getEmployeelist()
  }, [])

  useEffect(() => {
    if (filter) {
      let _updatedData = updatedData.filter(item => ((item.employee_id).toLowerCase().includes(filter.toLowerCase()) || (item.name.toLowerCase().includes(filter.toLowerCase()))))
      setResultData(_updatedData)
    } else {
      setResultData(updatedData)
    }
  }, [filter, updatedData])

  const getEmployeeDepaedents = async () => {
    var response = await getRequest(configUrl.getEmployeeDepedents)
    if (response) {
      setUpdatedData(response?.data?.data ? response?.data?.data : [])
      // let _data = (response?.data?.data || []).filter(item => ((item.employee_id).toLowerCase().includes(filter.toLowerCase()) || (item.name.toLowerCase().includes(filter.toLowerCase()))))
      // setResultData(_data)
    }
  }
  const getEmployeelist = async () => {
    var employeeData = await getRequest(configUrl.employeelist)
    var converted_data = Array.converterid(employeeData?.data?.data ? employeeData?.data?.data : [])
    setEmployeeArray(converted_data)
  }



  const { dropdownList } = useSelector(state => state.UIStore)
  var userDropdowns = dropdownList ? dropdownList.dropdownList : null

  const columns = React.useMemo(() => [
    {
      Header: "Emp Id",
      accessor: "employee_id",
      Filter: ColumnFilter,
    },
    {
      Header: "Employee Name",
      accessor: "name",
      Filter: ColumnFilter,
    },
    {
      Header: <div className="w-150">Department</div >,
      accessor: "department_id",
      // Filter: ColumnFilter,
      Cell: ({ row }) => (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div className="update_emp_dependants_inp_div">
            <Form.Select id={`dept${row.original.id}`} name={`department${row.original.id}`} defaultValue={row.original.department_id} onChange={(event) => {
              row.original.department_id = event.target.value
            }}>
              <option value="0">Select</option>
              {userDropdowns.department_id.map((item, i) => {
                return (
                  <option key={i} value={item.id}>{item.name}</option>
                )
              })}
            </Form.Select>
          </div>
        </div>
      ),
    },

    {
      Header: <div className="w-150">Designation</div >,
      accessor: "designation_id",
      // Filter: ColumnFilter,
      Cell: ({ row }) => (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div className="update_emp_dependants_inp_div">
            <Form.Select id={`designa${row.original.id}`} name={`designation${row.original.id}`} defaultValue={row.original.designation_id} onChange={(event) => {
              row.original.designation_id = event.target.value
            }}>
              <option value="0">Select</option>
              {userDropdowns.designation_id.map((item, i) => {
                return (
                  <option key={i} value={item.id}>{item.name}</option>
                )
              })}
            </Form.Select>
          </div>
        </div>
      ),
    },

    {
      Header: <div className="w-150">Employee Type</div >,
      accessor: "employee_type_id",
      // Filter: ColumnFilter,
      Cell: ({ row }) => (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div className="update_emp_dependants_inp_div">
            <Form.Select id={`emptype${row.original.id}`} name={`type${row.original.id}`} defaultValue={row.original.employee_type_id} onChange={(event) => {
              row.original.employee_type_id = event.target.value
            }}>
              <option value="0">Select</option>
              {userDropdowns.employee_type_id.map((item, i) => {
                return (
                  <option key={i} value={item.id}>{item.name}</option>
                )
              })}
            </Form.Select>
          </div>
        </div>
      ),
    },

    {
      Header: <div className="w-150">Employee Shifts</div>,
      accessor: "shifts_id",
      // Filter: ColumnFilter,
      Cell: ({ row }) => (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div className="update_emp_dependants_inp_div">
            <Form.Select id={`empshift${row.original.id}`} name={`shift${row.original.id}`} defaultValue={row.original.shifts_id} onChange={(event) => {
              row.original.shifts_id = event.target.value
            }}>
              <option value="0">Select</option>
              {userDropdowns.shifts_id.map((item, i) => {
                return (
                  <option key={i} value={item.id}>{item.name}</option>
                )
              })}
            </Form.Select>
          </div>
        </div>
      ),
    },
    {
      Header: <div className="w-200">Reporting Person</div>,
      accessor: "reporting_person",
      // Filter: ColumnFilter,
      Cell: ({ row }) => {
        var sortedEmployeeArray = employeeArray.filter(item => item.value != (row.original.employee_id || ""))
        if (typeof row.original.reporting_person == "object") {
          var reportingpersondefault = row.original.reporting_person
        } else {
          var reportingpersondefault = row.original.reporting_person ? employeeArray.find(option => option.value === row.original.reporting_person) : "";
        }
        var employeeRole = row.original.role_name ? row.original.role_name.trim().replace(/\s+/g, "_").toLowerCase() : null
        return (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <div className="update_emp_dependants_inp_div position-relative">
              <Select
                onChange={(item) => {
                  row.original.reporting_person = item
                }}
                id={`reporting${row.original.id}`}
                defaultValue={reportingpersondefault}
                options={sortedEmployeeArray}
                className="basic-multi-select"
                styles={customStyles}
                classNamePrefix="select"
                isClearable={true}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                isDisabled={employeeRole == "super_admin" ? true : false}
              />
            </div>
          </div>
        )
      }
    },
    {
      Header: <div className="w-200">Matrix Managers</div>,
      accessor: "matrix_manager",
      // Filter: ColumnFilter,
      Cell: ({ row }) => {
        var sortedEmployeeArray = employeeArray.filter(item => item.value != (row.original.employee_id || ""))
        let matrixManagersDefaultOptions;
        if (row?.original?.matrix_manager != "") {
          if (typeof row?.original?.matrix_manager === "string") {
            let matrixManagersDefault = row?.original?.matrix_manager ? row?.original?.matrix_manager.split("," ? "," : null) : "";
            matrixManagersDefaultOptions = row.original.matrix_manager ? employeeArray.filter(option => matrixManagersDefault.includes(option.value))
              : [];
          }
          else if (Array.isArray(row?.original?.matrix_manager)) {
            if (row?.original?.matrix_manager[0].employee_id) {
              let matrixmangerDefault = row?.original?.matrix_manager.map(item => item.employee_id)
              matrixManagersDefaultOptions = row.original.matrix_manager ? employeeArray.filter(option => matrixmangerDefault.includes(option.value))
                : [];
            } else {
              matrixManagersDefaultOptions = row?.original?.matrix_manager.length > 0 ? row?.original?.matrix_manager : []
            }
          }
        }

        return (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <div className="update_emp_dependants_inp_div">
              <Select
                onChange={(item) => {
                  row.original.matrix_manager = item
                }}
                id={`matrix${row.original.id}`}
                isMulti
                defaultValue={matrixManagersDefaultOptions}
                options={sortedEmployeeArray}
                className="basic-multi-select"
                styles={customStyles}
                classNamePrefix="select"
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
            </div>
          </div>
        )
      },
    },
    {
      id: "selection",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>
          <p className="mb-0">Select All</p>
          <Checkbox id="selectAll" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <div>
          <Checkbox id={`checkbox${row.original.id}`} name={`tablecheckbox${row.original.id}`}
            {...row.getToggleRowSelectedProps()} />
        </div>
      )
    },
    {
      Header: "Action",
      accessor: "update",
      // Filter: ColumnFilter,
      Cell: ({ row }) => {
        const [check_show, setChekShow] = useState(false)
        return (
          <div className="position-relative">
            <button
              // disabled={row.isSelected ? (lengthString == 1 ? false : true) : true}
              id={`update${row.original.id}`}
              className="btn btn-primary disabled_btn"
              onClick={() => {
                if (row.isSelected == false) {
                  setChekShow(true)
                } else {
                  setChekShow(false)
                  handleUpdateEmployee(row.original)
                }
              }}
            >
              Update
            </button>
            {check_show ? <div className="check_error">Select checkbox</div> : null}
          </div>
        )
      },
    },
  ],
    [lengthString, employeeArray]
  );
  const data = useMemo(() => resultData, [resultData]);

  // create a table instance
  const {
    tableInstance,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    usePagination,
    useRowSelect,
  );
  const { globalFilter, pageSize, pageIndex } = state;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      marginBottom: '2px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'white',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      position: 'absolute',
      maxHeight: '160px',
      overflowY: 'hidden',
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: '160px',
      overflow: 'auto',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : 'white',
      color: state.isSelected ? 'black' : 'blue',
    }),
  };

  useEffect(() => {
    setLengthString(selectedFlatRows.length)
  }, [selectedFlatRows])


  const handlesnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const handleUpdateEmployee = (values) => {
    let matrixList = [];
    if (values.matrix_manager != "") {
      if (typeof values.matrix_manager == "string") {
        var employeeids = values.matrix_manager.split(",");

        var result = employeeids.map((id) => {
          return { "employee_id": id };
        });
        matrixList = result
      } else if (Array.isArray(values.matrix_manager)) {
        matrixList = values.matrix_manager.length > 0 ? values.matrix_manager.map(value => {
          return { "employee_id": value.value }
        }) : []
      }
    }
    var { reporting_person_information, matrix_manager_information, role_name, role_id, id, ...rest } = values

    let payload = {
      userInformation: [{
        ...rest, matrix_manager: matrixList,
        reporting_person: values.reporting_person ? (values.reporting_person.value ? values.reporting_person.value : (values.reporting_person ? values.reporting_person : "")) : ""
      }]
    }
    updateemployeedata(payload)
  }

  // checking the reporting person and matrix mangers is same
  const checkForErrors = (data) => {
    try {
      const seenEmployeeIds = new Set();

      for (const item of data) {
        const { employee_id, reporting_person, matrix_manager } = item;
        let _matrixmanger = [];
        if (matrix_manager && (typeof matrix_manager === "string")) {
          _matrixmanger = matrix_manager.split(",")
        } else if (matrix_manager && Array.isArray(matrix_manager)) {
          if (matrix_manager?.[0]?.employee_id) {
            _matrixmanger = matrix_manager.map(item => item.employee_id)
          } else {
            _matrixmanger = matrix_manager.map(item => item.value)
          }
        }
        // Check if reporting_person and matrix_manager.employee_id are the same
        if (_matrixmanger.includes(reporting_person)) {
          setSuccess(false)
          setSnackMessage(`Employee ${employee_id} has the same reporting person and matrix manager.`)
          setSnackOpen(true)
          return false
        }


        if (seenEmployeeIds.has(employee_id)) {
          // console.error(`Error: Duplicate employee_id found - ${employee_id}`);
        } else {
          seenEmployeeIds.add(employee_id);
        }
      }
      return true
    } catch (error) {
      throw error
    }

  };

  const updateAllDepedents = (values) => {
    // setGlobalFilter(null)
    var updateAllEmployees = values.map(item => item.original)
    try {
      let value = checkForErrors(updateAllEmployees)
      if (value) {
        var updatedDataEmployees = updateAllEmployees.map((obj) => {
          // Remove reporting_person_information and matrix_manager_information properties
          delete obj.reporting_person_information;
          delete obj.matrix_manager_information;
          delete obj.role_name;
          delete obj.role_id;
          delete obj.id;

          // Modify matrix_manager property
          if (obj.matrix_manager === "") {
            obj.matrix_manager = [];
          } else if (typeof obj.matrix_manager === "string") {
            var employeeids = obj.matrix_manager.split(",");

            var result = employeeids.map((id) => {
              return { "employee_id": id };
            });
            obj.matrix_manager = result;
          } else if (Array.isArray(obj.matrix_manager)) {
            obj.matrix_manager = obj.matrix_manager.map((item) => {
              return { "employee_id": item.value };
            });
          }

          // Modify reporting_person property
          if (typeof obj.reporting_person === "object" && obj.reporting_person !== null) {
            obj.reporting_person = obj.reporting_person.value;
          } else if (typeof obj.reporting_person === "string" && obj.reporting_person !== null) {
            obj.reporting_person = obj.reporting_person;
          } else {
            obj.reporting_person = "";
          }

          return obj;
        });

        var allEmpPayload = { userInformation: updatedDataEmployees }
        updateemployeedata(allEmpPayload)
      }
    } catch (err) {
      console.log("====error", err)
    }

  }

  const updateemployeedata = async (values) => {
    var response = await putRequest(configUrl.updateEmployeeDepedents, values)
    if (response.status === 200) {
      setSuccess(true)
      setSnackMessage(response.data.message)
      setSnackOpen(true)
      getEmployeeDepaedents()
    } else if (response?.response?.status === 400) {
      setSuccess(false)
      setSnackMessage(response?.response?.data?.message)
      setSnackOpen(true)
      // getEmployeeDepaedents()
    }
  }

  return (
    <>
      <div className="leves_style update_style">
        <div className="mb-5">
          <h3 className="header_color">Update Employee Dependents</h3>
        </div>

        <div className="mt-3 mb-3 d-flex align-items-center justify-content-between">
          {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
          <input className="input_serach" placeholder="Search" onChange={e => {
            setResultData([])
            setFilter(e.target.value)
          }} value={filter} />
          <div>
            <select
              id="tablenumber"
              value={pageSize}
              className="selectTag w-auto"
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value="" disabled>
                Select
              </option>
              {[5, 10, 15, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="text-center mt-3 table_scroll">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>


        </div>
        {/* {lengthString > 1 ? */}
        <div className="text-end mt-3">
          <button
            //  disabled={lengthString > 1 ? false : true}
            id="updateall"
            className="btn btn-primary"
            onClick={() => {
              if (selectedFlatRows.length !== rows.length) {
                setSnackMessage("Plese Select All Checkbox")
                setSuccess(false)
                setSnackOpen(true)
              } else {
                updateAllDepedents(selectedFlatRows)
              }
            }}>Update All</button>
        </div>
        {/* : null} */}


        <div className="text-center">
          <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
            nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
        </div>
      </div>

      <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
        snackbg={success} message={snackMessage} />
    </>
  );
};
