export const RouteStrings = {
  login: "/:org?/login",
  signin: "/:id",
  signup: "/signup",
  forgotpassword: "/:org?/forgotpassword",
  resetpassword: "/:org?/resetpassword",
  dashboard: "/",
  employeeDashboard: "/:id/",
  subAdminSignUp: "subAdminSignUp",
  companydetails: "companydetails",
  leaves: "leaves",
  employeeApplyLeave: "employeeApplyLeave",
  leavestable: "leavestable",
  settings: "settings",
  createEmployee: "createemployee",
  createrole: "createrole",
  payslips: "payslips",
  fields: "fields",
  userprofile: "userprofile",
  employeeLeaveCount: "employeeLeaveCount",
  udpateEmployeeDependants: "udpateEmployeeDependants",
  createshift: "createshift",
  employeetype: "employeetype",
  adminLeaveCount: "applyLeave",
  assignLevels: "assignlevels",
  404: "*",
  leavetype: "leavetype",
  designation: "designation",
  department: "department",
  organisationtree: "organizationtree",
  inventoryDashboard: "inventorydashboard",
  inventoryBilling: "purchasebilling",
  stockentry: "stocklist",
  stockassign: "stockassign",
  inventoryVendor: "vendorlist",
  inventoryProduct: "inventoryproduct",
  paymentMode: "paymentmode",
  productStatus: "productstatus",
  clientcontactlist: "clientcontactlist",
  addclient: "addclient",
  editclient: "editclient",
  invoicelist: "invoice",
  viewinvoice: "viewinvoice",
  createinvoice: "createinvoice",
  viewclient: "viewclient",
  financialYear: "financialyear",
  billingdata: "billingdata",
  paymenttype: "paymenttype",
  additionalaccountsdetails: "additionalaccountsdetails",
  financialyear: "financialyear",
};

export const PAYMENT_STATUS_MODE = Object.freeze({
  PAYMENT_DONE: 2,
  PAYMENT_PENDING: 1,
  PARTIAL_PAYMENT: 3
})