import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Selectelement } from "../../components/Select_field/Selectelement";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { StockTable } from "./StockTable";
import { Input_element } from "../../components/input_field/Input_element";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const StockEntry = () => {
    // const { userInfo } = useSelector((state) => state.UIStore);
    // var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { getRequest, postRequest, putRequest } = PostRequestHook()
    const [stockList, setStockList] = useState(false);

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [products, setProducts] = useState([])
    const [itemData, seItemData] = useState({})

    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }


    useEffect(() => {
        getProductStatus()
    }, [])
    const getProductStatus = async () => {
        var response = await getRequest(configUrl.getProductStatus)
        var _productstatus = (response?.data?.payload ? response?.data?.payload : []).filter(item => item.id != 3)
        setProducts(_productstatus)
    }



    const formatDate = (dateString) => {
        var date = new Date(dateString);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
        return formattedDate
    }
    const calculateTotalAmount = (billingPrice, gstRate) => {
        const gstDecimal = Number(gstRate) / 100;
        const gstAmount = Number(billingPrice) * gstDecimal;
        const totalAmount = Number(billingPrice) + gstAmount;
        return totalAmount;
    }
    const calculateWarrantyUpto = (date, timeMonth) => {
        let newdate = new Date(date)
        newdate.setMonth(newdate.getMonth() + Number(timeMonth));

        const year = newdate.getFullYear();
        const month = newdate.getMonth() + 1;
        const day = newdate.getDate() - 1;

        const resultDateStr = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return resultDateStr;
    }

    const handleViewClick = (cellData) => {
        seItemData(cellData.cell.row.original)
        setStockList(true)
    }
    const validationSchema = Yup.object().shape({
        receiptNo: Yup.string(),
        billingId: Yup.string(),
        invoiceNumber: Yup.string(),
        invoiceDate: Yup.string(),
        vendorName: Yup.string(),
        companyName: Yup.string(),
        contactNumber: Yup.string(),
        brand_Manufacturer: Yup.string().required("Required"),
        serialNo: Yup.string().required("Required"),
        operatingSystem: Yup.string().notRequired(),
        modelList: Yup.string().notRequired(),
        memoryList: Yup.string().notRequired(),
        processor: Yup.string().notRequired(),
        storageCapacity: Yup.string().notRequired(),
        displaySize: Yup.string().notRequired(),
        macAddress: Yup.string().notRequired(),
        imeNo: Yup.string().notRequired(),
        simNo: Yup.string().notRequired(),
        productPrice: Yup.number().required("Required"),
        gstRate: Yup.number().required("Required"),
        totalPrice: Yup.string(),
        productStatus: Yup.string().required("Required"),
        dateOfPurchase: Yup.date().notRequired(),
        warrantyPeriod: Yup.number().notRequired(),
        remarks: Yup.string()
    });

    const initialValues = {
        receiptNo: itemData.receiptNo,
        billingId: itemData.billingId,
        invoiceNumber: itemData.invoiceNumber,
        invoiceDate: itemData.invoiceDate ? formatDate(itemData.invoiceDate) : "",
        vendorName: itemData.vendorName,
        companyName: itemData.companyName,
        contactNumber: itemData.vendorContactNumber,
        brand_Manufacturer: itemData.brand_Manufacturer,
        serialNo: itemData.serialNo,
        operatingSystem: itemData.operatingSystem,
        modelList: itemData.modelList,
        memoryList: itemData.memoryList,
        processor: itemData.processor,
        storageCapacity: itemData.storageCapacity,
        displaySize: itemData.displaySize,
        macAddress: itemData.macAddress,
        imeNo: itemData.imeNo,
        simNo: itemData.simNo,
        productPrice: itemData.productPrice ? Number(itemData.productPrice) : "",
        gstRate: itemData.gstRate ? Number(itemData.gstRate.replace(/%/g, "")) : "",
        totalPrice: "",
        productStatus: itemData.productStatus,
        dateOfPurchase: itemData.dateOfPurchase ? formatDate(itemData.dateOfPurchase) : "",
        warrantyPeriod: itemData.warrantyPeriod ? Number(itemData.warrantyPeriod) : "",
        remarks: itemData.remarks
    };

    const handleSubmit = (values) => {
        let value2 = {
            ...values, serialNo: values.serialNo.trim(), gstRate: values.gstRate.toString(), productPrice: values.productPrice.toString(),
            totalPrice: calculateTotalAmount(values.productPrice, values.gstRate).toString(),
            warrantyPeriod: values.warrantyPeriod.toString()
        }
        updateProductDetails(value2)
    };

    const updateProductDetails = async (data2) => {
        let response = await putRequest(`${configUrl.updateProductItem}${itemData.productCode}/${itemData.vendorBillingId}`, data2)
        if (response.status == 201 || response.status == 200) {
            snackBarContent(true, response.data.message)
            setStockList(false)
        } else if (response.response.data.status == 400 || response.response.data.status == 404 || response.response.data.status == 409) {
            snackBarContent(false, response.response.data.message)
        }
    }





    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="leves_style px-md-2 px-md-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="header_color">{stockList ? 'Update the Stock item' : 'Stock List'}</h3>
                    </div>
                </div>
                <div className="text-end">
                    {stockList ?
                        <Button
                            variant="primary"
                            onClick={() => {
                                setStockList(!stockList);
                            }}
                        >
                            {stockList ? "Stock List" : "Add New Stock"}
                        </Button>
                        : <>
                            {/* <Button
                                variant="primary"
                                onClick={() => {
                                    setLabelModal(true);
                                }}>Assign Label Number
                            </Button> */}
                        </>}
                </div>

                <div>
                    {stockList ? (
                        <div className="test_bg p-3 mt-4">

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <div className="mb-3">
                                                <h5>Vendor Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Vendor Name</>}
                                                        type="text"
                                                        name="vendorName"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.vendorName}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Company Name</>}
                                                        type="text"
                                                        name="companyName"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.companyName}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Receipt No.</>}
                                                        type="text"
                                                        name="receiptNo"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.receiptNo}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Bill Number</>}
                                                        type="text"
                                                        name="billingId"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.billingId}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Invoice Number</>}
                                                        type="text"
                                                        name="invoiceNumber"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.invoiceNumber}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="mb-3">
                                                        <label className="font_color mb-2">Invoice Date</label>
                                                        <DatePicker
                                                            name="invoiceDate"
                                                            selected={new Date(values.invoiceDate) == "Invalid Date" ? "" : new Date(values.invoiceDate)}
                                                            className="setting_field"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="dd-mm-yyyy"
                                                            readOnly
                                                        />
                                                    </div>
                                                    {/* <Input_element
                                                        input_label={<>Invoice Date</>}
                                                        type="date"
                                                        name="invoiceDate"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.invoiceDate}
                                                        readOnly
                                                    /> */}
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Contact Number</>}
                                                        type="text"
                                                        name="contactNumber"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.contactNumber}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-3">
                                                <h5>Product Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-4">
                                                    <Selectelement
                                                        optionArray={
                                                            <>{itemData.productStatus == 3 ?
                                                                <option value={itemData.productStatus == 3}>
                                                                    {itemData.productStatusName}
                                                                </option> :
                                                                <>
                                                                    {products.length == 0 ?
                                                                        null : <>
                                                                            {products.map((status, i) => {
                                                                                return (
                                                                                    <option key={i} value={status.id}>
                                                                                        {status.productStatusName}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </>}</>}
                                                            </>}
                                                        select_Label={<>Status <strong className="text-danger">*</strong></>}
                                                        name="productStatus"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.productStatus}
                                                        formikValidation={
                                                            <ErrorMessage name="productStatus" component="div" className="text-danger position-absolute small" />
                                                        }
                                                        disabled={itemData.productStatus == 3 ? true : ((itemData.productStatus == 2 && itemData.isServiceProduct == 0) ? true : false)}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Product Label Number <strong className="text-danger">*</strong></>}
                                                        type="text"
                                                        name="serialNo"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.serialNo}
                                                        placeholder="Enter label number"
                                                        formikValidation={
                                                            <ErrorMessage name="serialNo" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    // readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Product Brand <strong className="text-danger">*</strong></>}
                                                        type="text"
                                                        name="brand_Manufacturer"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.brand_Manufacturer}
                                                        placeholder="Enter brand name"
                                                        formikValidation={
                                                            <ErrorMessage name="brand_Manufacturer" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Operating System</>}
                                                        type="text"
                                                        name="operatingSystem"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.operatingSystem}
                                                        placeholder="Enter OS"
                                                        formikValidation={
                                                            <ErrorMessage name="operatingSystem" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Model</>}
                                                        type="text"
                                                        name="modelList"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.modelList}
                                                        placeholder="Enter Model"
                                                        formikValidation={
                                                            <ErrorMessage name="modelList" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Memory(RAM)</>}
                                                        type="text"
                                                        name="memoryList"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.memoryList}
                                                        placeholder="Enter RAM"
                                                        formikValidation={
                                                            <ErrorMessage name="memoryList" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Processor</>}
                                                        type="text"
                                                        name="processor"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.processor}
                                                        placeholder="Enter processor"
                                                        formikValidation={
                                                            <ErrorMessage name="processor" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Storage Capacity</>}
                                                        type="text"
                                                        name="storageCapacity"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.storageCapacity}
                                                        placeholder="Enter storage capacity"
                                                        formikValidation={
                                                            <ErrorMessage name="storageCapacity" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Display Size</>}
                                                        type="text"
                                                        name="displaySize"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.displaySize}
                                                        placeholder="Enter display size"
                                                        formikValidation={
                                                            <ErrorMessage name="displaySize" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>MAC Address</>}
                                                        type="text"
                                                        name="macAddress"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.macAddress}
                                                        placeholder="Enter mac address"
                                                        formikValidation={
                                                            <ErrorMessage name="macAddress" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>IMEI No.</>}
                                                        type="text"
                                                        name="imeNo"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.imeNo}
                                                        placeholder="Enter IME No."
                                                        formikValidation={
                                                            <ErrorMessage name="imeNo" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>SIM No.</>}
                                                        type="text"
                                                        name="simNo"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.simNo}
                                                        placeholder="Enter SIM No"
                                                        formikValidation={
                                                            <ErrorMessage name="simNo" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Product Price <strong className="text-danger">*</strong></>}
                                                        type="number"
                                                        name="productPrice"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.productPrice}
                                                        placeholder="Enter price"
                                                        formikValidation={
                                                            <ErrorMessage name="productPrice" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>GST Rate <strong className="text-danger">*</strong></>}
                                                        type="number"
                                                        name="gstRate"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.gstRate}
                                                        placeholder="Enter GST rate"
                                                        formikValidation={
                                                            <ErrorMessage name="gstRate" component="div" className="text-danger position-absolute small" />
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Total Amount</>}
                                                        type="text"
                                                        name="totalPrice"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={(values.productPrice && values.gstRate) ? calculateTotalAmount(values.productPrice, values.gstRate) : ""}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Description</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            style={{ minHeight: "80px" }}
                                                            name="remarks"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.remarks}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-3">
                                                <h5>Warranty Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="mb-3">
                                                        <label className="font_color mb-2">Date of Purchase</label>
                                                        <DatePicker
                                                            name="dateOfPurchase"
                                                            selected={new Date(values.dateOfPurchase) == "Invalid Date" ? "" : new Date(values.dateOfPurchase)}
                                                            onSelect={(e) => {
                                                                setFieldValue("dateOfPurchase", e);
                                                            }}
                                                            onChange={(val) => {
                                                                setFieldValue("dateOfPurchase", val ? val : "");
                                                            }}
                                                            onBlur={handleBlur}
                                                            className="setting_field"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="dd-mm-yyyy"
                                                        />
                                                    </div>
                                                    {/* <Input_element
                                                        input_label={<>Date of Purchase</>}
                                                        type="date"
                                                        name="dateOfPurchase"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.dateOfPurchase}
                                                    /> */}
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <Input_element
                                                        input_label={<>Warranty Period(in Months) </>}
                                                        type="number"
                                                        name="warrantyPeriod"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={values.warrantyPeriod}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="mb-3">
                                                        <label className="font_color mb-2">Date of Warranty Period(Upto)</label>
                                                        <DatePicker
                                                            name="expiryWarranty"
                                                            selected={(values.dateOfPurchase && values.warrantyPeriod) ? new Date(calculateWarrantyUpto(values.dateOfPurchase, values.warrantyPeriod)) : ""}
                                                            className="setting_field"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="dd-mm-yyyy"
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                    {/* <Input_element
                                                        input_label="Date of Warranty Period(Upto)"
                                                        type="date"
                                                        name="expiryWarranty"
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        value={(values.dateOfPurchase && values.warrantyPeriod) ? calculateWarrantyUpto(values.dateOfPurchase, values.warrantyPeriod) : ""}
                                                        readOnly
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end mt-4">
                                            <Button type="submit">
                                                Submit
                                            </Button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <div className="leavesTable mt-5">
                            <StockTable onViewClick={handleViewClick} />
                        </div>
                    )}
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

        </>
    );
};
