import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Selectelement } from "../../components/Select_field/Selectelement";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from 'react-select/animated'
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { RouteStrings } from "../../utils/common";
import { Input_element } from "../../components/input_field/Input_element";
import { StockAssignTable } from "./StockAssignTable";
import "./stockassign.scss"
import { Images } from "../../utils/images";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const StockAssign = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { getRequest, putRequest, postRequest } = PostRequestHook()
    const [stockList, setStockList] = useState(false);
    const [products, setProducts] = useState([])
    const [productOptions, setProductOptions] = useState({});
    const [productUpdateData, setProductUpdateData] = useState({});

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [updateAssign, setUpdateAssign] = useState(false)
    const [employeeArray, setEmployeeArray] = useState([])



    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }

    useEffect(() => {
        getEmployeeList()
        getProductDropdowns()
    }, [])
    const getProductDropdowns = async () => {
        var response = await getRequest(configUrl.getProduct)
        setProducts(response?.data?.payload ? response?.data?.payload : [])
    }
    const getEmployeeList = async () => {
        var response = await getRequest(configUrl.employeelist)
        var converted_data = Array.converterid(response?.data?.data ? response?.data?.data : [])
        var _converted_data = converted_data.filter(item => item.value !== "")
        setEmployeeArray(_converted_data)
    }

    const selectFieldValue = (selectArray, label) => {
        var fieldSelectValue = selectArray ? selectArray.find(option => option.value === label) : "";
        fieldSelectValue = fieldSelectValue ? fieldSelectValue : null
        return fieldSelectValue
    }

    const handleViewClick = (cellData) => {
        let updatingdata = cellData.cell.row.original
        // setProductUpdateData(updatingdata)
        const filteredData = updatingdata.productDetails.map(item => {
            const { productCode, dateOfAssign, dateOfReturn, description, productNameId, productLabel } = item;
            return {
                productCode,
                dateOfAssign,
                dateOfReturn,
                description: "",
                productName: productNameId,
                productLabel
            };
        });
        setProductUpdateData({ ...productUpdateData, employeeName: updatingdata.employeeName, assignProduct: filteredData })
        setStockList(!stockList)
        setUpdateAssign(true)
    }



    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };
    const fetchOptionsForProduct = async (productId) => {
        let response = await getRequest(`${configUrl.getInStockproducts}${productId}`)
        let productLabelData = response?.data?.payload ? response?.data?.payload : []
        let labelData = productLabelData.filter(item => item.productLabel !== "")
        var testlabeldata = Array.assignStock(labelData)
        return testlabeldata
        // return labelData
    };

    const validationSchema = Yup.object().shape({
        employeeName: Yup.string().required("Required"),
        assignProduct: Yup.array().of(
            Yup.object().shape({
                productName: Yup.string().required("Required"),
                productCode: Yup.string().required("Required"),
                dateOfAssign: Yup.date().required("Required"),
                description: Yup.string(),
            })
        ),
    });

    const initialValues = {
        employeeName: updateAssign ? productUpdateData.employeeName : '',
        assignProduct: updateAssign ? productUpdateData.assignProduct : [{ productName: '', productCode: '', dateOfAssign: '', dateOfReturn: '', description: '' }],
    };

    const handleSubmit = (values) => {
        let duplicateData = validateDuplicateAnotherFields(values.assignProduct)
        if (duplicateData.length > 0) {
            snackBarContent(false, `Label ${duplicateData[0]} selected multiple time`)
        } else {
            values.assignProduct = values.assignProduct.map((product) => ({
                ...product,
                dateOfAssign: formatDate(product.dateOfAssign),
                dateOfReturn: product.dateOfReturn ? formatDate(product.dateOfReturn) : "",
            }));
            assignStockItem(values)
        }

    };
    const validateDuplicateAnotherFields = (products) => {
        const labelFields = products.map((product) => product.productCode);
        const duplicates = labelFields.filter(
            (field, index) => labelFields.indexOf(field) !== index
        );
        const duplicateLabels = getCorrespondingLabels(productOptions, duplicates)
        return duplicateLabels
    };

    const getCorrespondingLabels = (data, inputArray) => {
        const correspondingLabels = [];

        inputArray.forEach(value => {
            for (const key in data) {
                const item = data[key].find(entry => entry.value === value);
                if (item) {
                    correspondingLabels.push(item.label);
                    break;
                }
            }
        });

        return correspondingLabels;
    }
    const assignStockItem = async (data) => {
        if (updateAssign) {
            const assignProducts3 = data.assignProduct.map(({ productLabel, ...rest }) => rest)
            let value3 = { ...data, assignProduct: assignProducts3 }
            let response = await putRequest(configUrl.updateAssignStockitems, value3)
            if (response.status == 201 || response.status == 200) {
                snackBarContent(true, response.data.message)
                setStockList(false)
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                snackBarContent(false, response.response.data.message)
            }
        } else {
            const assignProducts2 = data.assignProduct.map(({ dateOfReturn, ...rest }) => rest)
            let value2 = { ...data, assignProduct: assignProducts2 }
            let response = await postRequest(configUrl.assignStockItems, value2)
            if (response.status == 201 || response.status == 200) {
                snackBarContent(true, response.data.message)
                setStockList(false)
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                snackBarContent(false, response.response.data.message)
            }
        }
    }

    const formatDate = (dateString) => {
        if (dateString) {
            var date = new Date(dateString);
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();

            var formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
            return formattedDate
        } else {
            return ""
        }
    }

    return (
        <>
            <div className="leves_style px-2 px-md-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="header_color">{stockList ? 'Assign Stock item' : 'Allocated Stock list'}</h3>
                    </div>
                </div>
                <div className="text-end">
                    <Button
                        variant="primary"
                        onClick={() => {
                            setStockList(!stockList);
                            setUpdateAssign(false)
                        }}
                    >
                        {stockList ? "Allocated Stock List" : "Assign Stock"}
                    </Button>
                    {/* : null} */}
                </div>

                <div>
                    {stockList ? (
                        <div className="test_bg p-4 mt-4">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-4">
                                                    <label className="font_color mb-2">Employee name <strong className="text-danger">*</strong></label>
                                                    <Select
                                                        id="employeeName"
                                                        name="employeeName"
                                                        onChange={(item) => {
                                                            if (item) {
                                                                setFieldValue("employeeName", item.value)
                                                            } else {
                                                                setFieldValue("employeeName", "")
                                                            }
                                                        }}
                                                        placeholder="Select"
                                                        value={selectFieldValue(employeeArray, values.employeeName)}
                                                        options={employeeArray}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        isClearable={true}
                                                        isDisabled={updateAssign ? true : false}
                                                    />
                                                    <ErrorMessage name="employeeName" component="div" className="text-danger position-absolute small" />

                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <h5 className="mb-2 text-decoration-underline">Assign Products</h5>

                                                <FieldArray name="assignProduct">
                                                    {({ push, remove }) => (
                                                        <div>
                                                            {values.assignProduct.map((product, index) => (
                                                                <div key={index} className="row mb-3">
                                                                    <div className="col-md-1">
                                                                        <Input_element
                                                                            input_label={<>SL no.</>}
                                                                            type="text"
                                                                            value={index + 1}
                                                                            readOnly />
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <Selectelement
                                                                            optionArray={
                                                                                <>
                                                                                    {products.length > 0 && products.map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={item.id}>
                                                                                                {item.productName}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </>}
                                                                            select_Label={<>Product Name <strong className="text-danger">*</strong></>}

                                                                            name={`assignProduct.${index}.productName`}
                                                                            handleChange={async (e) => {
                                                                                handleChange(e);
                                                                                const productId = e.target.value;
                                                                                const options = await fetchOptionsForProduct(productId);
                                                                                setProductOptions((prevOptions) => ({
                                                                                    ...prevOptions,
                                                                                    [productId]: options,
                                                                                }));
                                                                                setFieldValue(`assignProduct.${index}.productCode`, '');
                                                                            }}
                                                                            handleBlur={handleBlur}
                                                                            value={values.assignProduct[index].productName}
                                                                            formikValidation={
                                                                                <ErrorMessage name={`assignProduct.${index}.productName`} component="div" className="text-danger position-absolute small" />
                                                                            }
                                                                            disabled={updateAssign ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="mb-4">
                                                                            <label className="font_color mb-2">Product Label Number <strong className="text-danger">*</strong></label>
                                                                            <Select
                                                                                id={`assignProduct.${index}.productCode`}
                                                                                name={`assignProduct.${index}.productCode`}
                                                                                onChange={(item) => {
                                                                                    if (item) {
                                                                                        setFieldValue(`assignProduct.${index}.productCode`, item.value)
                                                                                    } else {
                                                                                        setFieldValue(`assignProduct.${index}.productCode`, "")
                                                                                    }
                                                                                }}
                                                                                placeholder="Select"
                                                                                value={updateAssign ? { value: values.assignProduct[index].productCode, label: values.assignProduct[index].productLabel } : selectFieldValue(productOptions[product.productName], values.assignProduct[index].productCode)}
                                                                                options={productOptions[product.productName]}
                                                                                className="basic-multi-select"
                                                                                classNamePrefix="select"
                                                                                isClearable={true}
                                                                                isDisabled={updateAssign ? true : false}
                                                                            />
                                                                            <ErrorMessage name={`assignProduct.${index}.productCode`} component="div" className="text-danger position-absolute small" />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="mb-3">
                                                                            <label className="font_color mb-2">Date of Assign <strong className="text-danger">*</strong></label>
                                                                            <DatePicker
                                                                                name={`assignProduct.${index}.dateOfAssign`}
                                                                                selected={new Date(values.assignProduct[index].dateOfAssign) == "Invalid Date" ? "" : new Date(values.assignProduct[index].dateOfAssign)}
                                                                                onSelect={(e) => {
                                                                                    setFieldValue(`assignProduct.${index}.dateOfAssign`, e);
                                                                                }}
                                                                                onChange={(val) => {
                                                                                    setFieldValue(`assignProduct.${index}.dateOfAssign`, val ? val : "");
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                // value={values.assignProduct[index].dateOfAssign}
                                                                                className="setting_field"
                                                                                dateFormat="dd-MM-yyyy"
                                                                                placeholderText="dd-mm-yyyy"
                                                                                readOnly={updateAssign ? true : false}
                                                                            />
                                                                            <ErrorMessage name={`assignProduct.${index}.dateOfAssign`} component="div" className="text-danger position-absolute small" />
                                                                        </div>
                                                                    </div>
                                                                    {updateAssign ?
                                                                        <div className="col-md-3">
                                                                            <div className="mb-3">
                                                                                <label className="font_color mb-2">Date of Return</label>
                                                                                <DatePicker
                                                                                    name={`assignProduct.${index}.dateOfReturn`}
                                                                                    selected={new Date(values.assignProduct[index].dateOfReturn) == "Invalid Date" ? "" : new Date(values.assignProduct[index].dateOfReturn)}
                                                                                    onSelect={(e) => {
                                                                                        setFieldValue(`assignProduct.${index}.dateOfReturn`, e);
                                                                                    }}
                                                                                    onChange={(val) => {
                                                                                        setFieldValue(`assignProduct.${index}.dateOfReturn`, val ? val : "");
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    // value={values.assignProduct[index].dateOfReturn}
                                                                                    className="setting_field"
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    placeholderText="dd-mm-yyyy"
                                                                                    minDate={new Date(values.assignProduct[index].dateOfAssign)}
                                                                                />
                                                                                <ErrorMessage name={`assignProduct.${index}.dateOfReturn`} component="div" className="text-danger position-absolute small" />
                                                                            </div>
                                                                        </div> : null}

                                                                    <div className="col-md-6">
                                                                        <Form.Group className="mb-2">
                                                                            <Form.Label>Description</Form.Label>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                style={{ height: "38px" }}
                                                                                name={`assignProduct.${index}.description`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.assignProduct[index].description}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                    {updateAssign ? null : (values.assignProduct.length > 1 ? <div className="col-md-1 my-auto"> <button type="button" className="bg-transparent border-0" onClick={() => remove(index)}>
                                                                        <img src={Images.deleteLogo} alt="icon" />
                                                                    </button></div> : null)}
                                                                </div>
                                                            ))}
                                                            {updateAssign ? null :
                                                                <button type="button" className="btn btn-light mt-3"
                                                                    onClick={() => push({ productName: '', productCode: '', dateOfAssign: '', dateOfReturn: '', description: '' })}>
                                                                    Assign more
                                                                </button>}
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn btn-light">Submit</button>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                            {/* <Form
                                //  onSubmit={formik.handleSubmit} 
                                autoComplete="off">
                                <div className="mb-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Selectelement
                                                select_Label="Employee name"
                                                name="emp_name"
                                                optionArray={
                                                    <>
                                                        {["DB001-Chandrahasa", "DB002-Sundar"].length == 0 ?
                                                            null : <>
                                                                {["DB001-Chandrahasa", "DB002-Sundar"].map((leave, i) => {
                                                                    return (
                                                                        <option key={i} value={leave}>
                                                                            {leave}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </>}

                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Selectelement
                                                select_Label="Product Name"
                                                name="product_list"
                                                optionArray={
                                                    <>
                                                        {["LAPTOP", "MOUSE"].length == 0 ?
                                                            null : <>
                                                                {["LAPTOP", "MOUSE"].map((leave, i) => {
                                                                    return (
                                                                        <option key={i} value={leave}>
                                                                            {leave}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </>}

                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Selectelement
                                                select_Label="Product Code"
                                                name="product_listcode"
                                                optionArray={
                                                    <>
                                                        {["DB-L-001", "DB-L-002"].length == 0 ?
                                                            null : <>
                                                                {["DB-L-001", "DB-L-002"].map((leave, i) => {
                                                                    return (
                                                                        <option key={i} value={leave}>
                                                                            {leave}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </>}

                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label="Date of assign"
                                                type="date"
                                                name="date_of_assign"
                                            />
                                        </div>
                                        {updateAssign ?
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label="Date of Return"
                                                    type="date"
                                                    name="date_of_return"
                                                />
                                            </div> : null}
                                        <div className="col-md-6">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Remarks</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ minHeight: "80px" }}
                                                    name="remarks_product"
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end mt-4">
                                    <Button type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form> */}
                        </div >
                    ) : (
                        <div className="leavesTable mt-5">
                            <StockAssignTable onViewClick={handleViewClick} />
                        </div>
                    )}
                </div >
            </div >

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

        </>
    );
};
