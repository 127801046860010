import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Input_element } from "../../components/input_field/Input_element";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Images } from "../../utils/images";
import { configUrl } from "../../apis/api.config";
import { PostRequestHook } from "../../apis/Services";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { useDispatch } from "react-redux";
import { setDropdownlist } from "../../store/reducers/ui.reducer";
import { RegEx } from "../../utils/RegEx";
import Select from 'react-select';

export const DesignationCrud = () => {
    const { getRequest, postRequest, putRequest } = PostRequestHook()
    const [viewRole, setViewRole] = useState(false);
    const [typeId, setTypeId] = useState('');
    const [typeList, setTypeList] = useState([]);
    console.log("🚀 ~ file: DesignationCrud.js:20 ~ DesignationCrud ~ typeList:", typeList)
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")


    useEffect(() => {
        getTypes()
    }, [])
    const getTypes = async () => {
        var dropdowns = await getRequest(configUrl.getAllDropdowns)
        if (dropdowns) {
            setTypeList(dropdowns?.data?.data?.designation_id ? dropdowns?.data?.data?.designation_id : [])
        }
    }

    const formikRole = useFormik({
        initialValues: {
            designation_name: "",
            menu_options: ""
        },
        validate: (values) => {
            let errors = {};
            if (!values.designation_name) {
                errors.designation_name = "Required";
            } else if (!RegEx.name__regEx.test(values.designation_name)) {
                errors.designation_name = "Alphabets only";
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            let menuOptions = values.menu_options ? values.menu_options.map(option => option.value).join(',') : "";
            let value2 = { ...values, menu_options: menuOptions }
            let response = null
            if (typeId) {
                response = await putRequest(`${configUrl.updatedesignation}${typeId}`, value2)
            }
            else {
                response = await postRequest(configUrl.createdesignation, value2)
            }

            if (response.status == 201 || response.status == 200) {
                setSuccess(true)
                setSnackMessage(response.data.message)
                getTypes()
                setSnackOpen(true)
                setViewRole(false)
                setTypeId()
                getDropdownData()
                resetForm()
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                setSuccess(false)
                setSnackMessage(response.response.data.message)
                setSnackOpen(true)
            }
        },
    });

    const menuOptions = [{ value: "Employee-module", label: "Employee module" },
    { value: "Inventory-module", label: "Inventory module" },
    { value: "Leave-approval", label: "Leave Approval" }, { value: "Settings", label: "Settings" },
        // { value: "Invoice-module", label: "Invoice module" }
    ]


    const handleTypeDelete = async (e, id) => {
        var response = await putRequest(`${configUrl.deletedesignation}${id}`)
        if (response.status == 200 || response.status == 201) {
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            getTypes()
            getDropdownData()
        }
    }
    const handleTypeEdit = (e, id) => {
        var xyz = typeList.filter((item) => item.id == id)
        let _menuOptions = ['']
        if (xyz[0].menu_options) {
            _menuOptions = xyz[0].menu_options.split(",")
        }
        let editMenuOptions = menuOptions.filter(item => _menuOptions.includes(item.value))
        formikRole.setFieldValue("designation_name", xyz[0].name)
        formikRole.setFieldValue("menu_options", editMenuOptions)
        setTypeId(xyz[0].id)
        setViewRole(true)
    }

    const dispatch = useDispatch()
    const getDropdownData = async () => {
        var response = await getRequest(configUrl.getAllDropdowns)
        dispatch(setDropdownlist({
            dropdownList: response.data.data
        }))
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="employeetype_style">
                <div className="d-flex justify-content-between mb-4">
                    <div>
                        <h3>Designation</h3>
                    </div>
                    <div className="text-end">
                        <Link id="dashboardbtn" to="/" className="goback">
                            Back to Dashboard
                        </Link>
                        <Button
                            id="createbtn"
                            type="button"
                            onClick={
                                viewRole ? () => {
                                    setViewRole(false)
                                    setTypeId()
                                    formikRole.setFieldValue("designation_name", "")
                                    formikRole.setFieldValue("menu_options", "")
                                    formikRole.setFieldTouched("designation_name", false)
                                } : () => {
                                    setViewRole(true)
                                    setTypeId()
                                }
                            }
                        >
                            {viewRole ? "Designation List" : "Create Designation"}
                        </Button>
                    </div>
                </div>
                <div className="mt-5">
                    {viewRole ? (
                        <div className="type_form">
                            <h5 className="mb-3 font_color">Add Designation</h5>

                            <Form onSubmit={formikRole.handleSubmit}>
                                <Input_element
                                    input_label="Designation Name"
                                    lableClass="font_color"
                                    name="designation_name"
                                    handleChange={formikRole.handleChange}
                                    handleBlur={formikRole.handleBlur}
                                    value={formikRole.values.designation_name}
                                    type="text"
                                    placeholder="Enter Designation name"
                                    formikValidation={
                                        formikRole.touched.designation_name &&
                                            formikRole.errors.designation_name ? (
                                            <small className="text-danger position-absolute">
                                                {formikRole.errors.designation_name}
                                            </small>
                                        ) : null
                                    }
                                />
                                <div className="mb-3">
                                    <label className="font_color mb-2">Menu Options</label>
                                    <Select
                                        id="menu_options"
                                        name="menu_options"
                                        onChange={(item) => {
                                            formikRole.setFieldValue("menu_options", item)
                                        }}
                                        isMulti
                                        options={menuOptions}
                                        value={formikRole.values.menu_options}
                                        // defaultValue={}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select"
                                    />
                                </div>
                                <div className="text-end mt-4">
                                    <Button id={typeId ? "updatebtn" : "addbtn"} type="submit">{typeId ? "Update" : "Add"}</Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <div className="table_div">
                            <h5>Designation List</h5>
                            <div className="mb-4 text-end"></div>

                            <div className="roles table_scroll">
                                <Table striped bordered hover className="table_role mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sl.No</th>
                                            <th>Designation Name</th>
                                            <th>Menu Options</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeList.map((value, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{value.name}</td>
                                                    <td>{value.menu_options}</td>
                                                    <td>{value.id > 1 ? <div className="d-flex justify-content-center align-items-center">
                                                        <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                                                        <img src={Images.deleteLogo} id={`delete${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeDelete(e, value.id)} />
                                                    </div> :
                                                        <>
                                                            <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                                                        </>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    );
};
