import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

export const ViewPdf = ({ url }) => {

    const [extension, setExtension] = useState('');

    useEffect(() => {
        if (url) {
            const ext = url.split('.').pop().toLowerCase();
            setExtension(ext);
        }
    }, [url]);

    let content;

    if (extension === 'pdf') {
        content = (
            <embed src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${url}`} type="application/pdf" width="100%" height="200px" />
        );
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        content = (
            <img src={url} alt="Uploaded" className="img-fluid" style={{ height: '150px', objectFit: "contain", objectPosition: "top" }} />
        );
    } else {
        content = (
            <p>Unsupported file type</p>
        );
    }
    return (<>
        {content}
    </>)
}
