import React, { useEffect, useMemo, useState } from "react";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useFilters,
} from "react-table";
import "../../style/tableStyle.scss";
import { GlobalFilter } from "../../utils/GlobalFilter";
import { ColumnFilter } from "../../utils/ColumnFilter";
import Modal from "react-bootstrap/Modal";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { Images } from "../../utils/images";
import { TableNavigation } from "../../utils/TableNavigation";

export const StockAssignTable = (props) => {
    const { onViewClick } = props
    const [show, setShow] = useState(false);
    const [fieldData, setFieldData] = useState([]);
    const [viewData, setViewData] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")

    const { deleteRequest, getRequest } = PostRequestHook()
    const { userInfo } = useSelector((state) => state.UIStore);
    const organization_name = userInfo ? userInfo.organization_name : null
    const employee_id = userInfo ? userInfo.emp_id : null

    useEffect(() => {
        getTableData()
    }, [])

    const getTableData = async () => {
        let response = await getRequest(configUrl.getAssignedStockList)
        let tabledData = response?.data?.payload ? response?.data?.payload : []
        let _tableData = tabledData.filter(item => item.productDetails.length != 0)
        setFieldData(_tableData)
    }

    const handleClose = () => {
        setShow(false);
        setViewData([])
    };

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "SL No.",
                accessor: (row, index) => index + 1,
                Cell: ({ value }) => <span>{value}</span>,
                Filter: ColumnFilter,
            },
            {
                Header: "Emp. Id",
                accessor: "employee_id",
                Filter: ColumnFilter,
            },
            {
                Header: "Employee Name",
                accessor: "employee_name",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div>
                            {cellProps.cell.row.original.productDetails[0].employee_name}
                        </div>
                    );
                },
            },
            {
                Header: "Product Code",
                accessor: "productCode",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.productDetails.map((val) => (
                                <span className="cell_data" style={{ display: "block" }}>
                                    {val.productCode}
                                </span>
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: "Product Label Number",
                accessor: "productLabel",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.productDetails.map((val) => (
                                <span className="cell_data" style={{ display: "block" }}>
                                    {val.productLabel}
                                </span>
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: "Product Name",
                accessor: "productName",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.productDetails.map((val) => (
                                <span className="cell_data" style={{ display: "block" }}>
                                    {val.productName}
                                </span>
                            ))}
                        </div>
                    );
                },
            },
            {
                Header: "Date of Assign",
                accessor: "dateOfAssign",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            {cellProps.cell.row.original.productDetails.map((val) => (
                                <span className="cell_data" style={{ display: "block" }}>
                                    {val.dateOfAssign}
                                </span>
                            ))}
                        </div>
                    );
                },
            },
            // {
            //     Header: "Date of Return",
            //     accessor: "date_of_return",
            //     Filter: ColumnFilter,
            //     Cell: (cellProps) => {
            //         return (
            //             <div className="cellStyles">
            //                 {cellProps.cell.row.original.data.map((val) => (
            //                     <span className="cell_data" style={{ display: "block" }}>
            //                         {val.date_of_return}
            //                     </span>
            //                 ))}
            //             </div>
            //         );
            //     },
            // },
            {
                Header: "Action",
                accessor: "action",
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            <div className="d-flex flex-row justify-content-center align-items-center my-2">
                                <img
                                    src={Images.editLogo}
                                    alt="icon"
                                    id="editInventory"
                                    className="editIcon mx-2"
                                    onClick={() => onViewClick(cellProps)}
                                />
                                {/* {tableProps.cell.row.original.id == 1 ? null : */}
                                {/* <img
                                src={Images.deleteLogo}
                                alt="icon"
                                id="showinventoryHistory"
                                className="editIcon mx-2"
                            // onClick={(e) => handleDeleteClick(tableProps.cell.row.original.employee_id)}
                            /> */}
                                {/* } */}
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const data = useMemo(() => fieldData, [fieldData]);

    // create a table instance
    const {
        tableInstance,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        usePagination
    );

    const { globalFilter, pageSize, pageIndex } = state;
    return (
        <>
            <div className="my-3 d-flex justify-content-between">
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

                <div>
                    <select
                        value={pageSize}
                        className="selectTag w-auto"
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {[5, 10, 15, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="text-center mt-3">
                <div className="table_scroll">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="text-center">
                    <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
                        nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

        </>
    )
}
