import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Input_element } from "../../components/input_field/Input_element";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Images } from "../../utils/images";
import { configUrl } from "../../apis/api.config";
import { PostRequestHook } from "../../apis/Services";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { RegEx } from "../../utils/RegEx";
import { useSelector } from "react-redux";

export const LeaveType = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    const organization_name = userInfo ? userInfo.organization_name : null

    const { getRequest, postRequest, putRequest } = PostRequestHook()

    const [viewRole, setViewRole] = useState(false);
    const [typeId, setTypeId] = useState('');
    const [typeList, setTypeList] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [updateleave, setUpdateleave] = useState(false)

    const formikRole = useFormik({
        initialValues: {
            type: "",
            total_leaves: "",
        },
        validate: (values) => {
            let errors = {};
            if (!values.type) {
                errors.type = "Required";
            }
            if (!values.total_leaves) {
                errors.total_leaves = "Required";
            } else if (!RegEx.only__number__regEx.test(values.total_leaves)) {
                errors.total_leaves = "Numerics only";
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            var response = updateleave ? await putRequest(`${configUrl.getAllLeave}${organization_name}/update_leave_type/${typeId}`, values)
                : await postRequest(`${configUrl.getAllLeave}${organization_name}/add_leave_type`, values)
            if (response.status == 201 || response.status == 200) {
                setSuccess(true)
                setSnackMessage(response.data.message)
                getTypes()
                setSnackOpen(true)
                setViewRole(false)
                setUpdateleave(false)
                setTypeId()
                resetForm()
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                setSuccess(false)
                setSnackMessage(response.response.data.message)
                setSnackOpen(true)
            }
        },
    });

    useEffect(() => {
        getTypes()
    }, [])
    const getTypes = async () => {
        var types = await getRequest(`${configUrl.getAllLeave}${organization_name}/get_leaves`)
        if (types) {
            setTypeList(types?.data?.data ? types?.data?.data : [])
        }
    }

    const handleTypeDelete = async (e, id) => {
        let value = { status: "0" }
        var response = await putRequest(`${configUrl.getAllLeave}${organization_name}/delete_leave_type/${id}`, value)
        if (response.status == 200 || response.status == 201) {
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            getTypes()
            setTypeId()
        }
    }
    const handleTypeEdit = (e, id) => {
        // var xyz = typeList.filter(item => item.id == e.target.id)
        var xyz = typeList.filter(item => item.id == id)
        formikRole.setFieldValue("type", xyz[0].type)
        formikRole.setFieldValue("total_leaves", xyz[0].total_leaves)
        setUpdateleave(true)
        setTypeId(xyz[0].id)
        setViewRole(true)
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="employeetype_style">
                <div className="d-flex justify-content-between mb-4">
                    <div>
                        <h3>Leave Type</h3>
                    </div>
                    <div className="text-end">
                        <Link id="dashboardbtn" to="/" className="goback">
                            Back to Dashboard
                        </Link>
                        <Button
                            id="createbtn"
                            type="button"
                            onClick={
                                viewRole ? () => {
                                    setViewRole(false)
                                    setUpdateleave(false)
                                    setTypeId()
                                    formikRole.setFieldValue("type", "")
                                    formikRole.setFieldValue("total_leaves", "")
                                } : () => {
                                    setViewRole(true)
                                    setUpdateleave(false)
                                    setTypeId()
                                }
                            }
                        >
                            {viewRole ? "Leave Types List" : "Create Leave Types"}
                        </Button>
                    </div>
                </div>
                <div className="mt-5">
                    {viewRole ? (
                        <div className="type_form">
                            <h5 className="mb-3 font_color">Add Leave Type</h5>

                            <Form onSubmit={formikRole.handleSubmit}>
                                <Input_element
                                    input_label="Leave Type Name"
                                    lableClass="font_color"
                                    name="type"
                                    handleChange={formikRole.handleChange}
                                    handleBlur={formikRole.handleBlur}
                                    value={formikRole.values.type}
                                    type="text"
                                    placeholder="Enter Leave Type"
                                    formikValidation={
                                        formikRole.touched.type &&
                                            formikRole.errors.type ? (
                                            <small className="text-danger position-absolute">
                                                {formikRole.errors.type}
                                            </small>
                                        ) : null
                                    }
                                    disabled={!(typeId) ? false : typeId > 4 ? false : true}
                                />
                                <Input_element
                                    input_label="Total Leaves"
                                    lableClass="font_color"
                                    name="total_leaves"
                                    handleChange={formikRole.handleChange}
                                    handleBlur={formikRole.handleBlur}
                                    value={formikRole.values.total_leaves}
                                    type="text"
                                    placeholder="Enter Total leaves"
                                    formikValidation={
                                        formikRole.touched.total_leaves &&
                                            formikRole.errors.total_leaves ? (
                                            <small className="text-danger position-absolute">
                                                {formikRole.errors.total_leaves}
                                            </small>
                                        ) : null
                                    }
                                />
                                <div className="text-end mt-4">
                                    <Button id={typeId ? "updatebtn" : "addbtn"} type="submit">{typeId ? "Update" : "Add"}</Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <div className="table_div">
                            <h5>Leave Type List</h5>
                            <div className="mb-4 text-end"></div>

                            <div className="roles table_scroll">
                                <Table striped bordered hover className="table_role mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sl.No</th>
                                            <th>Leave Type</th>
                                            <th><div> Total Leaves</div><small>(in days)</small></th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeList.map((value, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{value.type}</td>
                                                    <td>{value.id > 2 ? (value.id == 4 ? `${value.total_leaves} months` : value.total_leaves) : "---"}</td>
                                                    <td>{(value.id > 4) ? <div className="d-flex justify-content-center">
                                                        <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                                                        <img src={Images.deleteLogo} id={`delete${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeDelete(e, value.id)} />
                                                    </div> :
                                                        <div className="d-flex justify-content-center">
                                                            {value.id > 2 ?
                                                                <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} /> : null
                                                            }
                                                        </div>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    );
};
