// all urls should be define here  

import { ENVDATA } from "../Conflict/Conflct"

export const baseUrl = ENVDATA.baseUrl

export const configUrl = {
    // employee module
    designationArray: "/auth/api/get-designation",
    signup: "/auth/api/superadmin/register",
    login: "/auth/api/superadmin/login",
    refreshToken: "/auth/api/revoke-refresh-token",
    forgotpassword: "/auth/api/forgot-password",
    resetPassword: "/auth/api/reset-forgot-password/",
    createnewEmployee: "/superadmin/api/create-employees",
    adminLogin: "/auth/api/organisation/signin",
    subadminupdatePassword: "/auth/api/organisation/change_password",
    createrole: "/employee/api/create-role",
    assignrole: "/employee/api/update-role",
    employeelist: "/employee/api/employee-list",
    updateEmployeeDetail: "/employee/api/update-employee-details/",
    deleteEmployee: "/employee/api/delete-employee/",
    getAllRoles: "/employee/api/get-roles",
    deleteRole: "/employee/api/delete-role/",
    updateRole: "/employee/api/updateRole/",
    getAllShifts: "/employee/api/get-shifts",
    createShift: "/employee/api/create-shifts",
    editShift: "/employee/api/update-shifts/",
    deleteShift: "/employee/api/change-shifts-status/",
    createEmpType: "/employee/api/create-employee-type",
    getAllEmpType: "/employee/api/get-employee-type",
    editEmpType: "/employee/api/update-employee-type/",
    deleteEmpType: "/employee/api/delete-employee-type-status/",
    updatePassword: "/auth/api/update-password",
    getAllDropdowns: "/employee/api/get-data",
    createdepartment: "/employee/api/create-department",
    updatedepartment: "/employee/api/update-department/",
    deletedepartment: "/employee/api/change-department-status/",
    createdesignation: "/employee/api/create-designation",
    updatedesignation: "/employee/api/update-designation/",
    deletedesignation: "/employee/api/change-designation-status/",
    getEmployeeData: "/employee/api/get-employee-list",
    basicValidation: "/superadmin/api/validate-basic-details",
    updateValidation: "/employee/api/validate-UpdateEmployee-Detail/",
    getEmployeeDepedents: "/employee/api/get-employee-dependants",
    getEmployeeDetails: "/employee/api/employee/",
    updateEmployeeDepedents: "/employee/api/update-employee-dependants",
    uploadExcel: "/excel/upload_test",
    tokenexpireapi: "/auth/api/get-forgot-password/",

    // leave module
    applyleave: "/employee-leaves/leaves/applyleave",
    getAllLeave: "/employee-leaves/leaves/",
    getAdmins: "/employee/api/admin-list",
    approveLeave: "/employee-leaves/leaves/",
    getEmailDetails: "/employee-leaves/leaves/get_receiver/",
    getEmailCredentials: "/employee/api/list-of-matrix-managers/",
    getAllLevels: "/employee/api/get-all-level",
    addLevel: "/employee/api/add-level",
    deleteLevel: "/employee/api/delete-level/",
    getParentsData: "/employee/api/get-all-parents/",
    assignLevels: "/employee/api/create-organization-structure",
    organisationTreeData: "/employee/api/organization-tree",
    newOrganisationTreeData: "/employee/api/get-organ",
    getHrList: "/employee/api/get-reporting-hr",

    // inventory module
    createVendor: "/inventory/api/create-vendors",
    getVendors: "/inventory/api/view-vendors-list",
    updateVendor: "/inventory/api/update-vendors/",
    deleteVendor: "/inventory/api/delete-vendor/",
    createProduct: "/inventory/api/create-products",
    getProduct: "/inventory/api/view-products-list",
    updateProduct: "/inventory/api/update-product/",
    deleteProduct: "/inventory/api/delete-product/",
    createPaymentMode: "/inventory/api/create-payment",
    getPaymentMode: "/inventory/api/get-payments-list",
    updatePaymentMode: "/inventory/api/update-payment-mode/",
    deletePaymentMode: "/inventory/api/delete-payment-mode/",
    createProductStatus: "/inventory/api/create-product-status",
    getProductStatus: "/inventory/api/get-product-status",
    updateProductStatus: "/inventory/api/update-product-status/",
    deleteProductStatus: "/inventory/api/delete-product-status/",
    getInventoryDropdowns: "/inventory/api/get-dropdown-data",
    addPurchaseBilling: "/inventory/api/create-vendorBilling",
    getPurchaseBillingList: "/inventory/api/get-billing-list",
    addServiceBill: "/inventory/api/create-serviceBill",
    getProductCodesItem: "/inventory/api/get-product-list/",
    getServiceCodesItem: "/inventory/api/get-service-product/",
    getServiceBillList: "/inventory/api/get-service-billList",
    assignProductService: "/inventory/api/assign-product-toService",
    getProductServiceList: "/inventory/api/getServiceProductDetails",
    getUnLabeledProducts: "/inventory/api/get-product/",
    updateLabelNumber: "/inventory/api/assign-product-label",
    getStockList: "/inventory/api/view-stock-details",
    getAssignedStockList: "/inventory/api/view-assign-stocks",
    updateProductItem: "/inventory/api/update-stock-item/",
    getInStockproducts: "/inventory/api/get-product-code/",
    assignStockItems: "/inventory/api/assign-stock",
    updateAssignStockitems: "/inventory/api/update-assigned-stock",
    getAssignHistoryData: "/inventory/api/get-stock-history/",
    getServiceHistoryData: "/inventory/api/get-service-history/",

    // Company Details
    addCompanyDetails: "invoice/api/create-company-profile/",
    getCompanyDetails: "invoice/api/company-details",
    updateCompanyDetails: "/invoice/api/update-company-details/",

    // invoice module
    getCountry: "/invoice/api/get-country",
    getState: "/invoice/api/get-state/",
    getClientType: "invoice/api/get-client-type",
    getRegestrationType: "invoice/api/get-registration-type/",
    addClientData: "/invoice/api/add-client",
    getClientData: "/invoice/api/get-client/",
    deleteClientData: "/invoice/api/delete-client/",
    updateClientData: "/invoice/api/update-client/",
    getInvoiceDropdowns: "/invoice/api/get-invoice-dropdown",
    createInvoice: "/invoice/api/create-invoice",
    updateInvoice: "/invoice/api/update-invoice/",
    getInvoiceList: "/invoice/api/get-invoice",
    makepayment: "/invoice/api/make-payment/",
    getpaymentMode: "/invoice/api/get-payment-mode/",
    getpaymentDetails: "/invoice/api/get-invoice-paymentDetails/",
    deleteInvoice: "/invoice/api/delete-invoice/",
    getBillingType: "invoice/api/get-billing-type",
    createBillingType: "invoice/api/create-billing-type",
    updateBillingType: "invoice/api/update-billing-type/",
    deleteBillingType: "invoice/api/update-billing-type-status/",
    updatePayment: "invoice/api/update-payment/",
    createPaymentType: "invoice/api/create-payment-mode",
    getPaymentType: "invoice/api/get-payment-mode",
    updatePaymentType: "invoice/api/update-payment-mode/",
    deletePaymentType: "invoice/api/update-payment-mode-status/",
    deleteBankDetails: "invoice/api/updateBank-status/",
    deleteClientDetails: "invoice/api/updateClientDetails-status/",
    deleteClientAddress: "invoice/api/updateClientAddress-status/",
    deleteCompanyAddress: "invoice/api/updateAddress-status/",
    getCompanyAddress: "invoice/api/company-details-primary-address",
    deleteInvoiceParticular: "invoice/api/updateInvoiceParticular-status/",
    getAdditionalBankDetail: "invoice/api/getAdditional-bankDetails",
    createAdditionalBankDetail: "invoice/api/create-additionalbank-details",
    updateAdditionalBankDetail: "invoice/api/updateAdditional-bankDetails/",
    deleteAdditionalBankDetail: "invoice/api/updateAdditional-bank-status/",
    deleteUpiDetail: "invoice/api/updateUpiDetails-status/",
    // getAdditionalBankDetail:"invoice/api/getAdditional-bankDetails"
}