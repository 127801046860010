import React, { useEffect } from 'react'
import './organisationteam.scss'
import OrganizationChart from "@dabeng/react-orgchart";
import uiReducer from '../../store/reducers/ui.reducer';
import { useSelector } from 'react-redux';
import { PostRequestHook } from '../../apis/Services';
import { configUrl } from '../../apis/api.config';
import { useState } from 'react';
import MyTreeComponent from './Reactd3tree';
import { Modal } from 'react-bootstrap';
import OrgChartWithTooltips from './Reactd3tree';
import { Checktree } from './Checktree1';



const NodeTemplate = ({ nodeData }) => {

    return (
        <div className="custom-node">

            <>{nodeData.parent ? <>
                {nodeData.parent.length > 1 ? <>
                    <div className="d-flex test_style">
                        {nodeData.parent.map(item => {
                            return (
                                <div className='xyz common'>
                                    <p className='emp_name'>{`${item.emp_id ? `${item.emp_id} - ` : ""}${item.name}`}</p>
                                    <p className='emp_designation'>{item.title}</p>
                                </div>)
                        })}
                    </div>
                </> : <>
                    {nodeData.parent.map(item => {
                        return (
                            <div className='common'>
                                <p className='emp_name'>{`${item.emp_id ? `${item.emp_id} - ` : ""}${item.name}`}</p>
                                <p className='emp_designation'>{item.title}</p>
                            </div>)
                    })}
                </>}
            </> : <>
                <div>
                    <div className='common'>
                        {console.log("nodeData.emp_id", nodeData.emp_id)}
                        <p className='emp_name'>{`${nodeData.emp_id ? `${nodeData.emp_id} - ` : ""}${nodeData.name}`}</p>
                        <p className='emp_designation'>{nodeData.title}</p>
                    </div>
                </div>
            </>}

            </>
        </div >
    );
};


export const OrganisationTeam = () => {

    // const [treedata, setTreeData] = useState(null)
    // const [excelModal, setExcelModal] = useState(false)
    // const { getRequest } = PostRequestHook()
    // const { userInfo } = useSelector(state => state.UIStore)
    // var organization_name = userInfo ? userInfo.organization_name : null

    // useEffect(() => {
    //     getTreeData()
    // }, [])

    // const getTreeData = async () => {
    //     var response = await getRequest(configUrl.organisationTreeData)
    //     var renderedData = [{
    //         "name": `${organization_name.replace(/_/g, ' ')}`,
    //         "emp_id": "",
    //         "children": response?.data?.data
    //     }]
    //     var treeData1 = generateHierarchy(renderedData)[0]
    //     setTreeData(treeData1)
    // }



    // function checkSelfOccurrences(arr) {
    //     return arr.some((item, index) => arr.indexOf(item) !== index);
    // }
    // const generateHierarchy = (data) => {
    //     const hierarchy = {};

    //     const traverse = (node, parents) => {
    //         const { emp_id, name, title, children } = node;

    //         if (!parents) {
    //             hierarchy.parent = [{ name, emp_id, title }];
    //             hierarchy.children = [];
    //             parents = hierarchy.children;
    //         } else {
    //             let existingNode = parents.find((parent) => parent.parent[0].name === name);

    //             if (!existingNode) {
    //                 existingNode = {
    //                     parent: [{ name, emp_id, title }],
    //                     children: [],
    //                 };
    //                 parents.push(existingNode);

    //             }
    //             parents = existingNode.children;
    //         }

    //         if (children && children.length > 0) {
    //             var commonChildrenArray = []
    //             children.filter((item) => {
    //                 return (
    //                     item.children.filter(item1 => {
    //                         commonChildrenArray.push(item1.emp_id)
    //                     })
    //                 )
    //             })

    //             const segregateData = () => {
    //                 const groupedArrays = children.reduce((result, current) => {
    //                     const matchingIndex = result.findIndex(group => {
    //                         if (group.children.length !== current.children.length) {
    //                             return false;
    //                         }
    //                         for (let i = 0; i < group.children.length; i++) {
    //                             if (group.children[i].emp_id !== current.children[i].emp_id) {
    //                                 return false;
    //                             }
    //                         }
    //                         return true;
    //                     });

    //                     if (matchingIndex !== -1) {
    //                         result[matchingIndex].elements.push(current);
    //                     } else {
    //                         result.push({
    //                             children: current.children,
    //                             elements: [current]
    //                         });
    //                     }

    //                     return result;
    //                 }, []);

    //                 const separateArrays = groupedArrays.map(group => group.elements);

    //                 separateArrays.map((group, i) => {
    //                     var parent1 = []
    //                     var children1 = []
    //                     group.filter(element => {
    //                         parent1.push({ name: element.name, emp_id: element.emp_id, title: element.title })
    //                         children1 = element.children
    //                     })
    //                     const modifyData = (data) => {
    //                         return data.map(item => ({
    //                             parent: [{
    //                                 "emp_id": item.emp_id,
    //                                 "name": item.name,
    //                                 "type": item.type,
    //                                 "title": item.title
    //                             }],
    //                             children: modifyData(item.children)
    //                         }));
    //                     };
    //                     parents.push({
    //                         parent: parent1,
    //                         // children: generateHierarchy(children1),
    //                         children: modifyData(children1),
    //                     });
    //                 })
    //             }


    //             if (checkSelfOccurrences(commonChildrenArray) == true) {
    //                 segregateData()
    //             } else {
    //                 children.forEach((child) => {
    //                     traverse(child, parents);
    //                 });
    //             }

    //         }
    //     };

    //     data.forEach((node) => traverse(node, null));

    //     return [hierarchy];
    // };



    return (
        <>
            <h2 className='my-4'>Organization Tree</h2>

            {/* {treedata && <div>
                <OrganizationChart
                    datasource={treedata}
                    pan={true}
                    zoom={true}
                    collapsible={false}
                    NodeTemplate={NodeTemplate}
                    onClickNode={(e) => setExcelModal(true)}
                />
            </div>} */}

            <OrgChartWithTooltips />
            {/* <Checktree /> */}

        </>
    )
}
