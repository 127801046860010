import { ErrorMessage, FieldArray } from 'formik';
import React, { useRef } from 'react'
import { Form } from 'react-bootstrap';
import { Input_element } from '../../components/input_field/Input_element';
import { Selectelement } from '../../components/Select_field/Selectelement';
import { Images } from '../../utils/images';
import { useState } from 'react';
import { PostRequestHook } from '../../apis/Services';
import { configUrl } from '../../apis/api.config';
import { useEffect } from 'react';

const Address = ({ values, setFieldValue, handleDeleteClientAddress, handleChange, handleBlur, countryList }) => {
    // console.log("----------------------> valus", values)
    const [stateListArr, setstateListArr] = useState([]);
    const [stateOptions, setStateOptions] = useState({});
    const { getRequest, postRequest, putRequest } = PostRequestHook()
    const [testStatesMap, setTestStateMap] = useState({})

    const reff = useRef({})
    // console.log("---------ref-----------🚀 ~ file: Address.js:20 ~ reff:", reff)


    useEffect(() => {
        editData()
    }, [])

    // useEffect(() => {
    //     console.log("🚀 ~ file: Address.js:17 ~ testStatesMap:", testStatesMap)
    // }, [reff])

    let editData = async () => {
        // console.log("------------------------------------------------------")
        // console.log("🚀 ~ file: Address.js:27 ~ editData ~ values?.client_address?.[0]?.country:", values?.client_address?.[0]?.country)
        if (values?.client_address?.[0]?.country) {
            values?.client_address?.map(async (mapVal) => {
                // console.log("🚀 ~ file: Address.js:23 ~ values?.client_address?.map ~ mapVal:", mapVal)
                let data = await fetchOptionsForState(mapVal?.country_id)
                // console.log(mapVal?.country_id, "----------------------🚀 ~ file: Address.js:31 ~ values?.client_address?.map ~ data: -----------", data)
                // setTestStateMap({ ...testStatesMap, ...(Object.fromEntries([[mapVal?.country_id, data]])) })
                reff.current[mapVal?.country_id] = data
                let clr = setTimeout(() => {
                    setTestStateMap({ ...testStatesMap, ...reff.current })
                    clearTimeout(clr)
                }, 900)
            })
        }
    }

    const fetchOptionsForState = async (countryId) => {
        // console.log("🚀 ~ file: Address.js:17 ~ fetchOptionsForState ~ countryId:", countryId)

        let response = await getRequest(`${configUrl.getState}${countryId}`)
        let stateList = response?.data?.data ? response?.data?.data : []
        // console.log(countryId, "🚀 ===========================", stateList)
        // console.log("🚀 ~ file: Address.js:27 ~ fetchOptionsForState ~  testStatesMap.countryId != countryId:", testStatesMap.countryId != countryId)
        // console.log("🚀 ~ file: Address.js:27 ~ fetchOptionsForState ~ !Object.hasOwn(`countryId`):", !Object.hasOwn(`countryId`))
        if (!Object.hasOwn(`countryId`) && testStatesMap.countryId != countryId) {
            setTestStateMap({ ...testStatesMap, ...(Object.fromEntries([[countryId, stateList]])) })
            // console.log("🚀 ~ file: Address.js:29 ~ fetchOptionsForState ~ Object.fromEntries([countryId, stateList]):", Object.fromEntries([[countryId, stateList]]))
        }
        // let labelData = stateList?.filter(item => item.state_name !== "")
        // console.log("🚀 ~ file: AddClient.js:40 ~ fetchOptionsForState ~ labelData:", labelData)
        // var testlabeldata = Array.assignService(labelData)
        // console.log("🚀 ~ file: AddClient.js:42 ~ fetchOptionsForState ~ testlabeldata:", testlabeldata)
        // return testlabeldata
        // setstateListArr(stateList);
        return stateList
    };

    return (
        <div className="mb-5">
            <h4 className="text-white mt-4">Address</h4>
            <FieldArray name="client_address">
                {({ push, remove }) => (
                    <div>
                        {values.client_address.map((states, index) => (
                            // console.log("=====>>>>>>", states),
                            <div key={index} className="row border pt-3 mb-3">
                                <div className="col-md-12">
                                    <Form.Group className="mb-4">
                                        {/* <Form.Label className="mb-2">Primary Address</Form.Label> */}
                                        <Form.Check
                                            type="checkbox"
                                            label="Primary Address"
                                            checked={values.client_address[index].set_default_address}
                                            name={`client_address.${index}.set_default_address`}
                                            onChange={(e) => {
                                                handleChange(e)
                                                const updatedAddress = [...values.client_address];
                                                updatedAddress.forEach((primary, i) => {
                                                    if (i === index) {
                                                        primary.set_default_address = !updatedAddress.set_default_address;
                                                    } else {
                                                        primary.set_default_address = false;
                                                    }
                                                });
                                                setFieldValue("client_address", updatedAddress);
                                            }}
                                            onBlur={handleBlur}
                                            className="text-white"

                                        />
                                        <ErrorMessage name={`client_address.${index}.set_default_address`} component="div" className="text-danger position-absolute small" />
                                        {/* <ErrorMessage name={`client_contact_information.${index}.set_default_address`} component="div" className="text-danger position-absolute small" /> */}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Input_element
                                        input_label={<>Street<strong className="text-danger"></strong></>}
                                        type="text"
                                        id={`client_address.${index}.street`}
                                        name={`client_address.${index}.street`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.client_address[index].street}
                                        formikValidation={
                                            <ErrorMessage name={`client_address.${index}.street`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Selectelement
                                        optionArray={
                                            <>
                                                {countryList.length > 0 && countryList.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>
                                                            {item.country_name}
                                                        </option>
                                                    );
                                                })}
                                            </>}
                                        // ${values.client_address[index].country || "empty"}
                                        select_Label={<>Country<strong className="text-danger">*</strong></>}
                                        // id={`client_address.${index}.country`}
                                        name={`client_address.${index}.country`}
                                        handleChange={async (e) => {
                                            handleChange(e)
                                            const countryId = e.target.value;
                                            const options = await fetchOptionsForState(countryId);
                                            // console.log("🚀 ~ file: AddClient.js:562 ~ handleChange={ ~ options:", options)
                                            setStateOptions((prevOptions) => ({
                                                ...prevOptions,
                                                [countryId]: options,
                                            }));
                                            setFieldValue(`client_address.${index}.state`, '');
                                        }}
                                        // handleBlur={handleBlur}
                                        value={values.client_address[index].country}
                                        formikValidation={
                                            <ErrorMessage name={`client_address.${index}.country`} component="div" className="text-danger position-absolute small" />
                                        } />
                                </div>
                                <div className="col-md-4">
                                    {/* {console.log("99999999999", testStatesMap, "------------", testStatesMap?.[values.client_address[index].country])} */}
                                    <Selectelement
                                        // optionArray={
                                        //     <>{Object.keys(stateOptions).length > 0 ? <>  {(stateOptions[states.country] ? stateOptions[states.country] : []).map((item, i) => {
                                        //         return (
                                        //             <option key={i} value={item.id}>
                                        //                 {item.state_name}
                                        //             </option>
                                        //         )
                                        //     })}</> : null}
                                        //     </>}
                                        optionArray={
                                            <>
                                                {testStatesMap?.[values.client_address[index].country]?.map((item, ind) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.state_name}
                                                        </option>
                                                    )
                                                })}
                                            </>
                                        }
                                        // onChange={(item) => {
                                        //     console.log("🚀 ~ file: AddClient.js:615 ~ handleChange={ ~ item:", item)
                                        //     if (item) {
                                        //         setFieldValue(`client_address.${index}.state`, item.value)
                                        //     } else {
                                        //         setFieldValue(`client_address.${index}.state`, "")
                                        //     }
                                        // }}
                                        select_Label={<>State<strong className="text-danger">*</strong></>}
                                        id={`client_address.${index}.state`}
                                        name={`client_address.${index}.state`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        // {selectFieldValue(productOptions[product.productName], values.productDetails[index].productLabel)}
                                        // defaultValue={values?.client_address[index]?.state_name}
                                        value={values?.client_address[index]?.state}
                                        // {values?.client_address[index]?.state}
                                        // options={stateOptions[states.state]}
                                        // className="basic-multi-select"
                                        // classNamePrefix="select"
                                        // isClearable={true}
                                        formikValidation={
                                            <ErrorMessage name={`client_address.${index}.state`} component="div" className="text-danger position-absolute small" />
                                        } />

                                </div>

                                <div className="col-md-4">
                                    <Input_element
                                        input_label={<>City<strong className="text-danger">*</strong></>}
                                        type="text"
                                        // id={`client_address.${index}.city`}
                                        name={`client_address.${index}.city`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.client_address[index].city}
                                        formikValidation={
                                            <ErrorMessage name={`client_address.${index}.city`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input_element
                                        input_label={<>Pincode<strong className="text-danger">*</strong></>}
                                        type="number"
                                        // id={`client_address.${index}.pincode`}
                                        name={`client_address.${index}.pincode`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.client_address[index].pincode}
                                        formikValidation={
                                            <ErrorMessage name={`client_address.${index}.pincode`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-2 my-auto">
                                    {values.client_address.length > 1 ? <button type="button" className="bg-transparent border-0" onClick={(e) => {
                                        remove(index)
                                        if (values.client_address[index].id) {
                                            handleDeleteClientAddress(e, values.client_address[index].id)
                                        }
                                    }}>
                                        <img src={Images.deleteLogo} alt="icon" />
                                    </button> : null}
                                </div>
                            </div>
                        ))}

                        <button type="button" className="btn btn-light"
                            onClick={() => push({ street: '', country: '', state: '', city: "", pincode: "", set_default_address: false })}>
                            Add Address
                        </button>
                    </div>
                )}
            </FieldArray>
        </div>
    )
}

export default Address