import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Selectelement } from "../../components/Select_field/Selectelement";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useDispatch, useSelector } from "react-redux";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { Input_element } from "../../components/input_field/Input_element";
import { InventoryBillingTable } from "./InventoryBillingTable";
import "./inventorybilling.scss"
import { Images } from "../../utils/images";
import AWS from 'aws-sdk';
import { ENVDATA } from "../../Conflict/Conflct";
import { setLoader } from "../../store/reducers/ui.reducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ViewPdf } from "./ViewPdf";

export const PurchaseBilling = ({ vendorData, productData }) => {
    const { userInfo } = useSelector((state) => state.UIStore);
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { getRequest, postRequest } = PostRequestHook()
    const [stockList, setStockList] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [show, setShow] = useState(false);
    const [viewData, setViewData] = useState({});

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [filePreview, setFilePreview] = useState(null);
    const [fileName, setFileName] = useState("");

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setFilePreview(reader.result);
                setFileName(file.name)
            };

            reader.readAsDataURL(file);
        }
    };


    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }

    const validationSchema = Yup.object().shape({
        vendorName: Yup.string().required("Required"),
        billingId: Yup.string().required("Required"),
        invoiceNumber: Yup.string().required("Required"),
        invoiceDate: Yup.date().required("Required"),
        receiptNo: Yup.string().required("Required"),
        billingPrice: Yup.number().required("Required"),
        gstRate: Yup.number().required("Required"),
        paymentStatus: Yup.string(),
        uploadBill: Yup.string().required("Required"),
        productDetails: Yup.array().of(
            Yup.object().shape({
                productName: Yup.string().required("Required"),
                brand_Manufacturer: Yup.string().required("Required"),
                quantity: Yup.string().required("Required"),
            })
        ),
    });

    const initialValues = {
        vendorName: '',
        billingId: '',
        invoiceNumber: '',
        invoiceDate: '',
        receiptNo: '',
        billingPrice: '',
        gstRate: '',
        totalAmount: '',
        paymentStatus: '',
        uploadBill: '',
        productDetails: [{ productName: '', brand_Manufacturer: '', quantity: '' }],
    };




    const handleViewClick = () => {
        setStockList(!stockList)
    }
    const handleClose = () => {
        setShow(false);
        setViewData({})
    };
    const handleSubmit = (values) => {
        values.productDetails.forEach(item => {
            item.quantity = item.quantity.toString();
        });
        var value2 = {
            ...values, gstRate: values.gstRate.toString(), billingPrice: values.billingPrice.toString(),
            invoiceDate: formatDate(values.invoiceDate),
            totalAmount: calculateTotalAmount(values.billingPrice, values.gstRate).toString(), paymentStatus: 'NULL',
        }
        setViewData(value2)
        setShow(true)
    };

    const dispatch = useDispatch()

    const handleUploadS3Bucket = (value) => {
        dispatch(setLoader(true))
        AWS.config.update({
            accessKeyId: ENVDATA.accessKeyId,
            secretAccessKey: ENVDATA.secretAccessKey,
            region: ENVDATA.region,
        });
        const s3 = new AWS.S3();
        const bucketName = ENVDATA.bucketName;

        const uploadParams = {
            Bucket: bucketName,
            Key: selectedFile.name,
            Body: selectedFile,
            ContentType: selectedFile.type,
            ACL: 'public-read',
        };

        // Upload the image to S3
        s3.upload(uploadParams, (err, data) => {
            if (err) {
                console.error('Error uploading image: ', err);
                snackBarContent(true, "cannot upload file/image")
                dispatch(setLoader(false))
            } else {

                var value3 = {
                    ...value, uploadBill: data.Location
                }
                addProducts(value3)

            }
        });
    }
    const addProducts = async (data) => {
        setShow(false)
        var response = await postRequest(configUrl.addPurchaseBilling, data)
        if (response.status == 200 || response.status == 201) {
            snackBarContent(true, response.data.message)
            setStockList(false)
            setViewData({})
        } else if (response.response.data.status == 400 || response.response.data.status == 409) {
            snackBarContent(false, response.response.data.message)
            handleDelete(data.uploadBill)
        }
    }


    const handleDelete = (deleteUrl) => {
        AWS.config.update({
            accessKeyId: ENVDATA.accessKeyId,
            secretAccessKey: ENVDATA.secretAccessKey,
            region: ENVDATA.region,
        });
        const s3 = new AWS.S3();
        const bucketName = ENVDATA.bucketName;
        const fileName = selectedFile.name;

        const deleteParams = {
            Bucket: bucketName,
            Key: fileName,
        };

        // Delete the file from S3
        s3.deleteObject(deleteParams, (err, data) => {
            if (err) {
                console.log('Error deleting file: ', err);
            } else {
                console.log('File deleted successfully!', data);
            }
        });
    };

    const getVendorName = (vendorId) => {
        let vendor = vendorData.filter(item => item.id == Number(vendorId))
        let vendorName = vendor[0].vendorName
        return vendorName
    }
    const getProductName = (productId) => {
        let product = productData.filter(item => item.id == Number(productId))
        let productName = product[0].productName
        return productName
    }


    function calculateTotalAmount(billingPrice, gstRate) {
        const gstDecimal = gstRate / 100;
        const gstAmount = billingPrice * gstDecimal;
        const totalAmount = (billingPrice + gstAmount).toFixed(2);
        return totalAmount;
    }
    const formatDate = (dateString) => {
        if (dateString) {
            var date = new Date(dateString);
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();

            var formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
            return formattedDate
        } else {
            return ""
        }
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="my-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="font_color">{stockList ? 'Add Purchase Billing' : 'Purchase Billing List'}</h3>
                    </div>
                </div>
                <div className="text-end">
                    <Button
                        variant="primary"
                        onClick={() => {
                            setStockList(!stockList);
                        }}
                    >
                        {stockList ? "Purchase Billing List" : "Add New Purchase Billing"}
                    </Button>
                    {/* : null} */}
                </div>

                <div>
                    {stockList ? (
                        <div className="test_bg p-md-4 mt-4">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Selectelement
                                                    optionArray={
                                                        <>
                                                            {vendorData && vendorData.length > 0 && vendorData.map((vendor, i) => {
                                                                return (
                                                                    <option key={i} value={vendor.id}>
                                                                        {vendor.vendorName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </>}
                                                    select_Label={<>Vendor Name <strong className="text-danger">*</strong></>}
                                                    name="vendorName"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.vendorName}
                                                    formikValidation={
                                                        <ErrorMessage name="vendorName" component="div" className="text-danger position-absolute small" />
                                                    } />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Billing ID <strong className="text-danger">*</strong></>}
                                                    type="text"
                                                    name="billingId"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.billingId}
                                                    placeholder="Enter Billing id"
                                                    formikValidation={
                                                        <ErrorMessage name="billingId" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Invoice Number <strong className="text-danger">*</strong></>}
                                                    type="text"
                                                    name="invoiceNumber"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.invoiceNumber}
                                                    placeholder="Enter invoice number"
                                                    formikValidation={
                                                        <ErrorMessage name="invoiceNumber" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="font_color mb-2">Invoice Date <strong className="text-danger">*</strong></label>
                                                    <DatePicker
                                                        name="invoiceDate"
                                                        selected={new Date(values.invoiceDate) == "Invalid Date" ? "" : new Date(values.invoiceDate)}
                                                        onSelect={(e) => {
                                                            setFieldValue("invoiceDate", e);
                                                        }}
                                                        onChange={(val) => {
                                                            setFieldValue("invoiceDate", val ? val : "");
                                                        }}
                                                        onBlur={handleBlur}
                                                        className="setting_field"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="dd-mm-yyyy"
                                                    />
                                                    <ErrorMessage name="invoiceDate" component="div" className="text-danger position-absolute small" />
                                                </div>
                                                {/* <Input_element
                                                    input_label={<>Invoice Date <strong className="text-danger">*</strong></>}
                                                    type="date"
                                                    name="invoiceDate"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.invoiceDate}
                                                    placeholder="Enter invoice date"
                                                    formikValidation={
                                                        <ErrorMessage name="invoiceDate" component="div" className="text-danger position-absolute small" />
                                                    }
                                                /> */}
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Receipt Number <strong className="text-danger">*</strong></>}
                                                    type="text"
                                                    name="receiptNo"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.receiptNo}
                                                    placeholder="Enter receipt number"
                                                    formikValidation={
                                                        <ErrorMessage name="receiptNo" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Billing Price <strong className="text-danger">*</strong></>}
                                                    type="number"
                                                    name="billingPrice"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.billingPrice}
                                                    placeholder="Enter billing price"
                                                    formikValidation={
                                                        <ErrorMessage name="billingPrice" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>GST Rate <strong className="text-danger">*</strong></>}
                                                    type="number"
                                                    name="gstRate"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.gstRate}
                                                    placeholder="Enter gst rate"
                                                    formikValidation={
                                                        <ErrorMessage name="gstRate" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Total Amount</>}
                                                    type="number"
                                                    name="totalAmount"
                                                    readOnly
                                                    value={values.billingPrice && values.gstRate ? calculateTotalAmount(values.billingPrice, values.gstRate) : ""}
                                                />
                                            </div>
                                            {/* <div className="col-md-6">
                                                <Selectelement
                                                    optionArray={
                                                        <>
                                                            {["Completed", "Pending"].map((leave, i) => {
                                                                return (
                                                                    <option key={i} value={leave}>
                                                                        {leave}
                                                                    </option>
                                                                );
                                                            })}
                                                        </>}
                                                    select_Label={<>Payment Status <strong className="text-danger">*</strong></>}
                                                    name="paymentStatus"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.paymentStatus}
                                                    formikValidation={
                                                        <ErrorMessage name="paymentStatus" component="div" className="text-danger position-absolute small" />
                                                    } />
                                            </div> */}
                                            <div className="col-md-6">
                                                <Input_element
                                                    input_label={<>Upload Bill <strong className="text-danger">*</strong></>}
                                                    type="file"
                                                    name="uploadBill"
                                                    handleChange={(e) => {
                                                        handleChange(e)
                                                        setSelectedFile(e.target.files[0]);
                                                        handleFileChange(e)
                                                    }}
                                                    handleBlur={handleBlur}
                                                    value={values.uploadBill}
                                                    accept="image/*,.pdf"
                                                    // accept="image/*"
                                                    formikValidation={
                                                        <ErrorMessage name="uploadBill" component="div" className="text-danger position-absolute small" />
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <h5 className="mb-2 text-decoration-underline">Add Products</h5>

                                                <FieldArray name="productDetails">
                                                    {({ push, remove }) => (
                                                        <div>
                                                            {values.productDetails.map((_, index) => (
                                                                <div key={index} className="d-flex">
                                                                    <div className="w-10">
                                                                        <Input_element
                                                                            input_label={<>SL no.</>}
                                                                            type="text"
                                                                            handleChange={handleChange}
                                                                            handleBlur={handleBlur}
                                                                            value={index + 1}
                                                                            readOnly />
                                                                    </div>
                                                                    <div className="mx-3">
                                                                        <Selectelement
                                                                            optionArray={
                                                                                <>
                                                                                    {productData.length > 0 && productData.map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={item.id}>
                                                                                                {item.productName}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </>}
                                                                            select_Label={<>Product Name <strong className="text-danger">*</strong></>}
                                                                            // id={`productDetails.${index}.productName`}
                                                                            name={`productDetails.${index}.productName`}
                                                                            handleChange={handleChange}
                                                                            handleBlur={handleBlur}
                                                                            value={values.productDetails[index].productName}
                                                                            formikValidation={
                                                                                <ErrorMessage name={`productDetails.${index}.productName`} component="div" className="text-danger position-absolute small" />
                                                                            } />
                                                                    </div>
                                                                    <div className="mx-3">
                                                                        <Input_element
                                                                            input_label={<>Brand/Manufacturer <strong className="text-danger">*</strong></>}
                                                                            type="text"
                                                                            id={`productDetails.${index}.brand_Manufacturer`}
                                                                            name={`productDetails.${index}.brand_Manufacturer`}
                                                                            handleChange={handleChange}
                                                                            handleBlur={handleBlur}
                                                                            value={values.productDetails[index].brand_Manufacturer}
                                                                            formikValidation={
                                                                                <ErrorMessage name={`productDetails.${index}.brand_Manufacturer`} component="div" className="text-danger position-absolute small" />
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="mx-3">
                                                                        <Input_element
                                                                            input_label={<>Quantity <strong className="text-danger">*</strong></>}
                                                                            type="number"
                                                                            // id={`productDetails.${index}.quantity`}
                                                                            name={`productDetails.${index}.quantity`}
                                                                            handleChange={handleChange}
                                                                            handleBlur={handleBlur}
                                                                            value={values.productDetails[index].quantity}
                                                                            formikValidation={
                                                                                <ErrorMessage name={`productDetails.${index}.quantity`} component="div" className="text-danger position-absolute small" />
                                                                            }
                                                                        />
                                                                    </div>

                                                                    {values.productDetails.length > 1 ? <button type="button" className="bg-transparent border-0" onClick={() => remove(index)}>
                                                                        <img src={Images.deleteLogo} alt="icon" />
                                                                    </button> : null}
                                                                </div>
                                                            ))}

                                                            <button type="button" className="btn btn-light"
                                                                onClick={() => push({ productName: '', brand_Manufacturer: '', quantity: '' })}>
                                                                Add Product
                                                            </button>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn btn-light">Submit</button>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <div className="leavesTable mt-5">
                            <InventoryBillingTable onViewClick={() => handleViewClick()} />
                        </div>
                    )}
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />


            <Modal show={show} size="lg" onHide={handleClose}>
                <>
                    <Modal.Header>
                        <Modal.Title>Billing Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalBody px-md-4 pb-3 mb-4">
                            <h5 className="font_color mb-3">Please verify the Details</h5>
                            <div className="mb-4">
                                <h6 className="font_color text-decoration-underline">Basic Information</h6>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Vendor Name :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{show ? getVendorName(viewData.vendorName) : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Billing ID :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.billingId}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Invoice Number :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.invoiceNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Invoice Date :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.invoiceDate}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Receipt No :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.receiptNo}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Billing Price :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.billingPrice}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">GST rate :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{`${viewData.gstRate}%`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Total Amount :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.totalAmount}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Uploaded Bill :</p>
                                            </div>
                                            <div className="ms-2">
                                                {show ?
                                                    <>
                                                        <p className="font_color">{fileName}</p>
                                                        {filePreview.includes('pdf') ? (
                                                            <embed src={filePreview} type="application/pdf" width="100%" height="200px" />
                                                        ) : (
                                                            <img src={filePreview} alt="Uploaded" className="img-fluid" style={{ height: '150px', objectFit: "contain", objectPosition: "top" }} />
                                                        )}

                                                    </> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="d-flex my-2">
                                    <div className="">
                                        <p className="mb-0 font_color">Payment Status :</p>
                                    </div>
                                    <div className="ms-2">
                                        <p className="mb-0 font_color">{viewData.paymentStatus}</p>
                                    </div>
                                </div> */}

                            </div>
                            <div>
                                <h6 className="font_color text-decoration-underline">
                                    Added Items
                                </h6>
                                <div className="d-flex my-2">
                                    <div className="col-md-2 font_color text-decoration-underline">Sl. no.</div>
                                    <div className="col-md-2 font_color text-decoration-underline">Product</div>
                                    <div className="col-md-2 font_color text-decoration-underline">Brand</div>
                                    <div className="col-md-2 font_color text-decoration-underline">Quantity</div>
                                </div>
                                {viewData.productDetails && viewData.productDetails.length > 0 && viewData.productDetails.map((item, i) => {
                                    return (<div className="d-flex my-1" key={i}>
                                        <div className="col-md-2 font_color">{i + 1}</div>
                                        <div className="col-md-2 font_color">{show ? getProductName(item.productName) : ""}</div>
                                        <div className="col-md-2 font_color">{item.brand_Manufacturer}</div>
                                        <div className="col-md-3 font_color">{item.quantity}</div>
                                    </div>)
                                })}
                            </div>
                            <div className="d-flex justify-content-end mt-5">
                                <button className="btn btn-danger mx-3" onClick={handleClose}>Edit</button>
                                <button className="btn btn-success" onClick={() => { handleUploadS3Bucket(viewData) }}>Submit</button>
                            </div>
                        </div>
                    </Modal.Body>
                </>
            </Modal >

        </>
    );
};
