import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Selectelement } from "../../components/Select_field/Selectelement";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { Input_element } from "../../components/input_field/Input_element";
import "./inventorybilling.scss"
import { Images } from "../../utils/images";
import { AssignProductTable } from "./AssignProductTable";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const AssignProductService = ({ vendorData, productData }) => {
    const { userInfo } = useSelector((state) => state.UIStore);
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { getRequest, postRequest } = PostRequestHook()
    const [stockList, setStockList] = useState(false);

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [productOptions, setProductOptions] = useState({});


    const fetchOptionsForProduct = async (productId) => {
        let response = await getRequest(`${configUrl.getProductCodesItem}${productId}`)
        let productLabelData = response?.data?.payload ? response?.data?.payload : []
        let labelData = productLabelData.filter(item => item.productLabel !== "")
        var testlabeldata = Array.assignService(labelData)
        return testlabeldata
        // return labelData
    };

    const selectFieldValue = (selectArray, label) => {
        var fieldSelectValue = selectArray ? selectArray.find(option => option.value === label) : "";
        fieldSelectValue = fieldSelectValue ? fieldSelectValue : null
        return fieldSelectValue
    }

    const validationSchema = Yup.object().shape({
        vendorName: Yup.string().required("Required"),
        assignedDate: Yup.date().required("Required"),
        productDetails: Yup.array().of(
            Yup.object().shape({
                productName: Yup.string().required("Required"),
                productLabel: Yup.string().required("Required"),
                description: Yup.string().notRequired(),
            })
        ),
    });

    const initialValues = {
        vendorName: '',
        assignedDate: '',
        productDetails: [{ productName: '', productLabel: '', description: '' }],
    };


    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }

    const handleViewClick = () => {
        setStockList(!stockList)
    }
    const validateDuplicateAnotherFields = (products) => {
        const labelFields = products.map((product) => product.productLabel);
        const duplicates = labelFields.filter(
            (field, index) => labelFields.indexOf(field) !== index
        );
        return duplicates
    };
    const formatDate = (dateString) => {
        if (dateString) {
            var date = new Date(dateString);
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();

            var formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
            return formattedDate
        } else {
            return ""
        }
    }
    const handleSubmit = (values) => {
        let duplicateData = validateDuplicateAnotherFields(values.productDetails)
        if (duplicateData.length > 0) {
            snackBarContent(false, `Label ${duplicateData[0]} selected multiple time`)
        } else {
            values.assignedDate = formatDate(values.assignedDate)
            addProductService(values)
        }
    };

    const addProductService = async (data) => {
        let response = await postRequest(configUrl.assignProductService, data)
        if (response.status == 200 || response.status == 201) {
            snackBarContent(true, response.data.message)
            setStockList(false)
        } else if (response.response.data.status == 400 || response.response.data.status == 409) {
            snackBarContent(false, response.response.data.message)
        }
    }



    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="my-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="font_color">{stockList ? 'Assign Products to Service' : 'Servicing Product List'}</h3>
                    </div>
                </div>
                <div className="text-end">
                    <Button
                        variant="primary"
                        onClick={() => {
                            setStockList(!stockList);
                        }}
                    >
                        {stockList ? "Servicing Product List" : "Assign Products to Service"}
                    </Button>
                    {/* : null} */}
                </div>

                <div>
                    {stockList ? (
                        <div className="test_bg p-md-4 mt-4">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Selectelement
                                                    optionArray={
                                                        <>
                                                            {vendorData.length > 0 && vendorData.map((vendor, i) => {
                                                                return (
                                                                    <option key={i} value={vendor.id}>
                                                                        {vendor.vendorName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </>}
                                                    select_Label={<>Service Provider <strong className="text-danger">*</strong></>}
                                                    name="vendorName"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.vendorName}
                                                    formikValidation={
                                                        <ErrorMessage name="vendorName" component="div" className="text-danger position-absolute small" />
                                                    } />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="font_color mb-2">Assigned Date <strong className="text-danger">*</strong></label>
                                                    <DatePicker
                                                        name="assignedDate"
                                                        selected={new Date(values.assignedDate) == "Invalid Date" ? "" : new Date(values.assignedDate)}
                                                        onSelect={(e) => {
                                                            setFieldValue("assignedDate", e);
                                                        }}
                                                        onChange={(val) => {
                                                            setFieldValue("assignedDate", val ? val : "");
                                                        }}
                                                        onBlur={handleBlur}
                                                        className="setting_field"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="dd-mm-yyyy"
                                                    />
                                                    <ErrorMessage name="assignedDate" component="div" className="text-danger position-absolute small" />
                                                </div>
                                                {/* <Input_element
                                                    input_label={<>Assigned Date <strong className="text-danger">*</strong></>}
                                                    type="date"
                                                    name="assignedDate"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={values.assignedDate}
                                                    placeholder="Enter Billing id"
                                                    formikValidation={
                                                        <ErrorMessage name="assignedDate" component="div" className="text-danger position-absolute small" />
                                                    }
                                                /> */}
                                            </div>
                                            <div className="col-md-12">
                                                <h5 className="mb-2 text-decoration-underline">Add Products</h5>

                                                <FieldArray name="productDetails">
                                                    {({ push, remove }) => (
                                                        <div>
                                                            {values.productDetails.map((product, index) => (
                                                                <div key={index} className="d-flex">
                                                                    <div className="w-10">
                                                                        <Input_element
                                                                            input_label={<>SL no.</>}
                                                                            type="text"
                                                                            value={index + 1}
                                                                            readOnly />
                                                                    </div>
                                                                    <div className="mx-3">
                                                                        <Selectelement
                                                                            optionArray={
                                                                                <>
                                                                                    {productData.length > 0 && productData.map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={item.id}>
                                                                                                {item.productName}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </>}
                                                                            select_Label={<>Product Name <strong className="text-danger">*</strong></>}
                                                                            // id={`productDetails.${index}.productName`}
                                                                            name={`productDetails.${index}.productName`}
                                                                            handleChange={async (e) => {
                                                                                handleChange(e);
                                                                                const productId = e.target.value;
                                                                                const options = await fetchOptionsForProduct(productId);
                                                                                setProductOptions((prevOptions) => ({
                                                                                    ...prevOptions,
                                                                                    [productId]: options,
                                                                                }));
                                                                                setFieldValue(`productDetails.${index}.productLabel`, '');
                                                                            }}
                                                                            handleBlur={handleBlur}
                                                                            value={values.productDetails[index].productName}
                                                                            formikValidation={
                                                                                <ErrorMessage name={`productDetails.${index}.productName`} component="div" className="text-danger position-absolute small" />
                                                                            } />
                                                                    </div>
                                                                    <div className="mx-3">
                                                                        <label className="font_color me-2 form-label">Product Label Number <strong className="text-danger">*</strong></label>
                                                                        <Select
                                                                            id={`productDetails.${index}.productLabel`}
                                                                            name={`productDetails.${index}.productLabel`}
                                                                            onChange={(item) => {
                                                                                if (item) {
                                                                                    setFieldValue(`productDetails.${index}.productLabel`, item.value)
                                                                                } else {
                                                                                    setFieldValue(`productDetails.${index}.productLabel`, "")
                                                                                }
                                                                            }}
                                                                            placeholder="Select"
                                                                            value={selectFieldValue(productOptions[product.productName], values.productDetails[index].productLabel)}
                                                                            options={productOptions[product.productName]}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            isClearable={true}
                                                                        />
                                                                        <ErrorMessage name={`productDetails.${index}.productLabel`} component="div" className="text-danger position-absolute small" />
                                                                    </div>
                                                                    <div className="mx-3">
                                                                        <Form.Group className="mb-2">
                                                                            <Form.Label>Description</Form.Label>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                style={{ height: "38px" }}
                                                                                name={`productDetails.${index}.description`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.productDetails[index].description}

                                                                            />
                                                                        </Form.Group>
                                                                    </div>

                                                                    {values.productDetails.length > 1 ? <button type="button" className="bg-transparent border-0" onClick={() => remove(index)}>
                                                                        <img src={Images.deleteLogo} alt="icon" />
                                                                    </button> : null}
                                                                </div>
                                                            ))}

                                                            <button type="button" className="btn btn-light"
                                                                onClick={() => push({ productName: '', productLabel: '', description: '' })}>
                                                                Add Product
                                                            </button>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn btn-light">Submit</button>
                                        </div>

                                    </form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <div className="leavesTable mt-5">
                            <AssignProductTable onViewClick={() => handleViewClick()} />
                        </div>
                    )}
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />


        </>
    );
};
