import React, { useEffect, useMemo, useState } from "react";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useFilters,
} from "react-table";
import "../../style/tableStyle.scss";
import { GlobalFilter } from "../../utils/GlobalFilter";
import { ColumnFilter } from "../../utils/ColumnFilter";
import Modal from "react-bootstrap/Modal";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { Images } from "../../utils/images";
import { TableNavigation } from "../../utils/TableNavigation";
import { ViewPdf } from "./ViewPdf";

export const ServiceBillingTable = (props) => {
    const { onViewClick } = props
    const [show, setShow] = useState(false);
    const [fieldData, setFieldData] = useState([]);
    const [viewData, setViewData] = useState({});
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")

    const { deleteRequest, getRequest } = PostRequestHook()
    const { userInfo } = useSelector((state) => state.UIStore);
    const organization_name = userInfo ? userInfo.organization_name : null
    const employee_id = userInfo ? userInfo.emp_id : null

    useEffect(() => {
        getTableData()
    }, [])

    const getTableData = async () => {
        let response = await getRequest(configUrl.getServiceBillList)
        setFieldData(response?.data?.payload ? response?.data?.payload : [])
    }

    const handleClose = () => {
        setShow(false);
        setViewData([])
    };

    const handleView = (rowData) => {
        setViewData(rowData)
        setShow(true);
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };


    const columns = React.useMemo(
        () => [
            {
                Header: "SL No.",
                accessor: (row, index) => index + 1,
                Cell: ({ value }) => <div>{value}</div>,
                Filter: ColumnFilter,
            },
            {
                Header: "Service Provider",
                accessor: "vendorName",
                Filter: ColumnFilter,
            },
            {
                Header: "Billing Id",
                accessor: "billingId",
                Filter: ColumnFilter,
            },
            {
                Header: "Invoice No.",
                accessor: "invoiceNumber",
                Filter: ColumnFilter,
            },
            {
                Header: "Invoice Date",
                accessor: "invoiceDate",
                Filter: ColumnFilter,
            },
            {
                Header: "Receipt No.",
                accessor: "receiptNo",
                Filter: ColumnFilter,
            },
            // {
            //     Header: "Product Quantity",
            //     accessor: "product_quantity",
            //     Filter: ColumnFilter,
            // },
            // {
            //     Header: "Billing Price",
            //     accessor: "billingPrice",
            //     Filter: ColumnFilter,
            // },
            // {
            //     Header: "GST(%)",
            //     accessor: "gstRate",
            //     Filter: ColumnFilter,
            // },
            // {
            //     Header: "Total Amount",
            //     accessor: "totalAmount",
            //     Filter: ColumnFilter,
            // },
            {
                Header: "Action",
                accessor: "action",
                Cell: (tableProps) => (
                    <div>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <img
                                src={Images.viewIcon}
                                alt="icon"
                                id="editInventory"
                                className="editIcon mx-2"
                                onClick={() => {
                                    handleView(tableProps.cell.row.original)
                                }}
                            />
                            {/* {tableProps.cell.row.original.id == 1 ? null :
                            <img
                                src={Images.deleteLogo}
                                alt="icon"
                                id="showinventoryHistory"
                                className="editIcon mx-2"
                            // onClick={(e) => handleDeleteClick(tableProps.cell.row.original.employee_id)}
                            />
                            } */}
                        </div>
                    </div>
                ),
            },
        ],
        []
    );

    const data = useMemo(() => fieldData, [fieldData]);

    // create a table instance
    const {
        tableInstance,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        usePagination
    );

    const { globalFilter, pageSize, pageIndex } = state;
    return (
        <>
            <div className="my-3 d-flex justify-content-between">
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

                <div>
                    <select
                        value={pageSize}
                        className="selectTag w-auto"
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {[5, 10, 15, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="text-center mt-3">
                <div className="table_scroll">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="text-center">
                    <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
                        nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

            <Modal show={show} size="xl" onHide={handleClose}>
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Billing Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalBody px-md-4 pb-3 mb-4">
                            <div className="mb-4">
                                <h6 className="font_color text-decoration-underline">Basic Information</h6>
                                <div className="row">
                                    <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">Vendor Name :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.vendorName}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">Billing ID :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.billingId}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">Invoice Number :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.invoiceNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">Invoice Date :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.invoiceDate}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">Receipt No :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.receiptNo}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">Billing Price :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.billingPrice}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">GST rate :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{`${viewData.gstRate}%`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">Total Amount :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.totalAmount}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="d-flex my-2">
                                            <div className="">
                                                <p className="mb-0 font_color">Uploaded Bill :</p>
                                            </div>
                                            {show ? <div className="ms-2">
                                                {/* <p className="font_color">{fileName}</p> */}

                                                <a href={viewData.uploadBill}>
                                                    <ViewPdf url={viewData.uploadBill} />
                                                </a>


                                            </div> : null}

                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 my-2">
                                        <div className="d-flex">
                                            <div className="">
                                                <p className="mb-0 font_color">Payment Status :</p>
                                            </div>
                                            <div className="ms-2">
                                                <p className="mb-0 font_color">{viewData.paymentStatus}</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div>
                                <h6 className="font_color text-decoration-underline">
                                    Added Items
                                </h6>
                                <div className="row my-2">
                                    <div className="col-md-2 col-lg-1 font_color text-decoration-underline">Sl. no.</div>
                                    <div className="col-md-2 col-lg-1 font_color text-decoration-underline">Product</div>
                                    <div className="col-md-2 font_color text-decoration-underline">Produt label Number</div>
                                    <div className="col-md-2 font_color text-decoration-underline">Date of Return</div>
                                    <div className="col-md-2 font_color text-decoration-underline">Service Status</div>
                                    <div className="col-md-2 font_color text-decoration-underline">Description</div>
                                </div>
                                {viewData.productDetails && viewData.productDetails.length > 0 && viewData.productDetails.map((item, i) => {
                                    return (<div className="row my-1" key={i}>
                                        <div className="col-md-2 col-lg-1 font_color">{i + 1}</div>
                                        <div className="col-md-2 col-lg-1 font_color">{item.productName}</div>
                                        <div className="col-md-2 font_color">{item.productLabel}</div>
                                        <div className="col-md-2 font_color">{item.dateOfReturn}</div>
                                        <div className="col-md-2 font_color">{item.serviceStatus}</div>
                                        <div className="col-md-2 font_color">{item.description}</div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </Modal.Body>
                </>
            </Modal >
        </>
    )
}
