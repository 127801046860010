import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Input_element } from "../../../components/input_field/Input_element";
import { useDispatch } from "react-redux";
import { setAccessToken, setIsAuth, setLoggedInUser, setOrgName } from "../../../store/reducers/ui.reducer";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import "./SignIn.scss";
import { PostRequestHook } from "../../../apis/Services";
import { configUrl } from "../../../apis/api.config";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { RegEx } from "../../../utils/RegEx";
import { RouteStrings } from "../../../utils/common";
import { PasswordShowHide } from "../../../components/PasswordShow/PasswordShowHide";


export const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState("")
  const [success, setSuccess] = useState(false)

  const { getRequest, postRequest } = PostRequestHook()

  const params = useParams();
  let organisation_name = params.org
  useEffect(() => {
    if (organisation_name) {
      dispatch(setOrgName(organisation_name))
    }
  }, [organisation_name])
  const postSigninUpdate = async (url, data) => {
    var response = await postRequest(url, data)
    if (response.status == 200) {
      setSnackMessage(response.data.message)
      setSuccess(true)
      setSnackOpen(true)
      dispatch(setAccessToken(response.data.token))
      dispatch(setIsAuth({
        isAuth: true,
        data: response.data.data,
        refresh_token: response.data.refreshToken
      }));
      navigate(RouteStrings.dashboard, { replace: true })
    } else if (response.response.data.status == 401 || response.response.data.status == 404) {
      setSnackMessage(response.response.data.message)
      setSuccess(false)
      setSnackOpen(true)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "Email is required";
      } else if (!RegEx.email__regEx.test(values.email)) {
        errors.email = "Enter Valid Email";
      }
      if (!values.password) {
        errors.password = "Password is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        email: values.email,
        password: values.password
      }

      let url = organisation_name ? configUrl.adminLogin : configUrl.login
      let data1 = organisation_name ? { ...data, organization_name: organisation_name } : data
      postSigninUpdate(url, data1)
    },
  });

  const handlesnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };


  return (
    <>
      <div className="my-4 text-center">
        <h3 className="header_color">SignIn</h3>
      </div>
      <div className="formsignin_width">
        <Form onSubmit={formik.handleSubmit}>
          <Input_element
            name="email"
            input_label="Email Address"
            type="email"
            lableClass="font_color"
            handleChange={formik.handleChange}
            value={formik.values.email}
            handleBlur={formik.handleBlur}
            placeholder="Enter Email Address"
            formikValidation={formik.touched.email && formik.errors.email ? (
              <>
                <span className="text-danger small">{formik.errors.email}</span>
              </>
            ) : null}
          />


          <PasswordShowHide
            name="password"
            input_label="Password"
            lableClass="font_color"
            placeholder="Enter Valid Password"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.password}
            formikValidation={formik.touched.password && formik.errors.password ? (
              <>
                <span className="text-danger small">{formik.errors.password}</span>
              </>
            ) : null}
          />

          <Button type="submit" className="btn_submit">
            LOGIN
          </Button>
        </Form>
        <div className="text-end mt-2">
          <Link to={organisation_name ? `/${organisation_name}/forgotpassword` : "/forgotpassword"} className="forgot_link">
            Forgot Password?
          </Link>
        </div>
        <hr />
        {organisation_name ? "" :
          <div className="text-center">
            <p className="font_color m-0">
              Not a customer yet?
              <Link to={RouteStrings.signup} className="login_link text-light ms-1">
                Sign Up!
              </Link>
            </p>
          </div>}
      </div>

      <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose} message={snackMessage}
        snackbg={success} />
    </>
  );
};
