import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import './userprofile.scss'
import { useDispatch, useSelector } from 'react-redux';
import { PostRequestHook } from '../../apis/Services';
import { configUrl } from '../../apis/api.config';
import { SnackBar } from '../SnackBars/SnackBar';
import { RegEx } from '../../utils/RegEx';
import employeefields from '../../pages/CreateUser/employeeinfo.json';
import { useEffect } from 'react';
import CustomInput from '../../pages/Fields/CustomInput';
import DatePicker from "react-datepicker";
import { setIsAuth } from '../../store/reducers/ui.reducer';
import { PasswordShowHide } from '../PasswordShow/PasswordShowHide';
import { Button } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';

export const UserProfile = () => {

    const { userInfo, dropdownList, auth } = useSelector((state) => state.UIStore);
    var adminid = userInfo ? userInfo.emp_id : null
    var userDropdowns = dropdownList ? dropdownList.dropdownList : null
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null
    let work_email = userInfo ? userInfo.work_email : null

    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [success, setSuccess] = useState(false)
    const [updateInfo, setUpdateInfo] = useState({})
    const [changeInfo, setChangeInfo] = useState({})
    const [editInfo, setEditInfo] = useState(false)
    const [fieldDropdowns, setEmployeedropdowns] = useState([])
    const [normalid, setNormalId] = useState('')

    const { getRequest, putRequest } = PostRequestHook()

    const [calculateExp, setCalculateExp] = useState()
    const [calculateAge, setCalculateAge] = useState()

    useEffect(() => {
        updateSuperDetails()
    }, [])

    useEffect(() => {
        assigndropdown(employeefields)
    }, [employeefields])




    const assigndropdown = (value) => {
        var dropdowns = value.filter((item) => {
            item.fields.filter((item1) => {
                if (item1.field_values) {
                    item1.field_values = userDropdowns ? userDropdowns[item1.name] : []
                }
                return item1
            })
            return item
        })
        setEmployeedropdowns(dropdowns)
    }
    const bloodGroupOptions = [{ value: "O+ve", name: "O+ve" }, { value: "A+ve", name: "A+ve" },
    { value: "B+ve", name: "B+ve" }, { value: "AB+ve", name: "AB+ve" }, { value: "O-ve", name: "O-ve" },
    { value: "A-ve", name: "A-ve" }, { value: "B-ve", name: "B-ve" }, { value: "AB-ve", name: "AB-ve" }]

    const updateSuperDetails = async () => {
        var response = await getRequest(configUrl.employeelist)
        response?.data?.data?.filter(item => {
            if (adminid) {
                if (item.employee_id == adminid) {
                    getDetailsById(item)
                }
            } else {
                if (item.isUpdated == 0) {
                    getDetailsById(item)
                } else if (item.id == normalid) {
                    getDetailsById(item)
                }
            }
        })
    }

    const getDetailsById = (viewDeatil) => {
        let _viewDeatil = {
            ...viewDeatil,
            department_id: viewDeatil.department_id ? viewDeatil.department_id.toString() : "",
            designation_id: viewDeatil.designation_id ? viewDeatil.designation_id.toString() : "",
            bank_account_type_id: viewDeatil.bank_account_type_id ? viewDeatil.bank_account_type_id.toString() : "",
            employee_type_id: viewDeatil.employee_type_id ? viewDeatil.employee_type_id.toString() : "",
            gender_id: viewDeatil.gender_id ? viewDeatil.gender_id.toString() : "",
            shifts_id: viewDeatil.shifts_id ? viewDeatil.shifts_id.toString() : "",
        }
        setUpdateInfo(_viewDeatil)
        setChangeInfo(_viewDeatil)
        setNormalId(_viewDeatil.id)
        autoCalculateExp(_viewDeatil.date_of_joining)
        autoCalculateage(_viewDeatil.date_of_birth)
    }

    const autoCalculateage = (date) => {
        var dob = new Date(date);
        var month_diff = Date.now() - dob.getTime();
        var age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();
        var age = Math.floor(year - 1970);
        setCalculateAge(isNaN(age) ? "" : `${age} Years`)
    }
    const autoCalculateExp = (date) => {
        var dob = new Date(date);
        var today = new Date()
        let difference = today.getTime() - dob.getTime();
        let TotalDays = Math.floor(difference / (1000 * 3600 * 24 * 30)) >= "12" ?
            (Math.fround(difference / (1000 * 3600 * 24 * 365))).toFixed(1)
            : Math.floor(difference / (1000 * 3600 * 24 * 30));
        setCalculateExp(`${isNaN(TotalDays) ? "" : TotalDays} ${isNaN(TotalDays) ? "" : Math.floor(difference / (1000 * 3600 * 24 * 30)) >= "12" ? "Year" : "Months"}`)
    }
    const dispatch = useDispatch()
    const postPayloadData = async (data) => {
        let matrixList = []
        if (data.matrix_manager != "") {
            var employeeids = data.matrix_manager.split(",");

            var result = employeeids.map((id) => {
                return { "employee_id": id };
            });
            matrixList = result
        }

        let _payload1 = {
            ...data,
            date_of_birth: new Date(data.date_of_birth).toISOString().slice(0, 10),
            date_of_joining: new Date(data.date_of_joining).toISOString().slice(0, 10),
            gender_id: Number(data.gender_id),
            employee_type_id: Number(data.employee_type_id),
            department_id: Number(data.department_id ? data.department_id : 0),
            designation_id: Number(data.designation_id ? data.designation_id : 0),
            years_of_experience: Number(data.years_of_experience ? data.years_of_experience : 0),
            shifts_id: Number(data.shifts_id ? data.shifts_id : 0),
            marital_status: Number(data.marital_status),
            esi_number: data.esi_number ? data.esi_number.toString() : "NULL",
            pf_number: data.pf_number ? data.pf_number : "NULL",
            uan_number: data.uan_number ? data.uan_number.toString() : "NULL",
            aadhaar_number: Number(data.aadhaar_number),
            bank_account_number: Number(data.bank_account_number),
            bank_account_type_id: Number(data.bank_account_type_id),
            phone_number: data.phone_number.toString(),
            emergency_contact: data.emergency_contact.toString(),
            year_of_passing: data.year_of_passing ? data.year_of_passing.toString() : "NULL",
            highest_qualification: data.highest_qualification ? data.highest_qualification : "NULL",
            university_name: data.university_name ? data.university_name : "NULL",
            matrix_manager: matrixList,
        }

        let updateData = { ..._payload1 }
        delete updateData.isUpdated
        delete updateData.reporting_person_information
        delete updateData.matrix_manager_information
        let sendData = updateData
        const response = await putRequest(`${configUrl.updateEmployeeDetail}${normalid}`, sendData)
        if (response) {
            if (response.status == 201 || response.status == 200) {
                setSuccess(true)
                setSnackMessage(response.data.message)
                setSnackOpen(true)
                dispatch(setIsAuth({
                    isAuth: auth.isAuth,
                    data: { ...userInfo, emp_id: sendData.employee_id },
                    refresh_token: auth.refresh_token
                }))
                updateSuperDetails()
                setEditInfo(false)
            } else if (response.response.status == 400 || response.response.data.status == 409) {
                setSuccess(false)
                setSnackMessage(response.response.data.message)
                setSnackOpen(true)
            }
        }
    }

    const errorMessage = {
        color: "#e35050",
        position: "absolute",
        fontSize: "12px",
    };

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };


    const InfoDiv = () => {
        var namesObject = employeefields.reduce((obj, section) => {
            section.fields.forEach((field) => {
                obj[field.name] = changeInfo[field.name];
            });
            return obj;
        }, {});

        var requiredNamesObject = employeefields.reduce((obj, section) => {
            section.fields.forEach((field) => {
                if (field.isRequired) {
                    obj[field.name] = "";
                }
            });
            return obj;
        }, {});

        return (
            <Formik
                initialValues={namesObject}
                validate={(values) => {
                    const errors = {};
                    Object.keys(requiredNamesObject).forEach(function (key) {
                        if (values[key] == "") {
                            errors[key] = `Required`;
                        }
                        if (values.name) {
                            if (!RegEx.name__regEx.test(values.name)) {
                                errors.name = "Alphabets only";
                            }
                        }
                        // if (values.father_name) {
                        //     if (!RegEx.name__regEx.test(values.father_name)) {
                        //         errors.father_name = "Alphabets only";
                        //     }
                        // }
                        // if (values.mother_name) {
                        //     if (!RegEx.name__regEx.test(values.mother_name)) {
                        //         errors.mother_name = "Alphabets only";
                        //     }
                        // }
                        if (values.blood_group) {
                            if (!RegEx.blood__regEx.test(values.blood_group)) {
                                errors.blood_group = "Select Blood group";
                            }
                        }
                        // if (values.emergency_contact_name) {
                        //     if (!RegEx.name__regEx.test(values.emergency_contact_name)) {
                        //         errors.emergency_contact_name = "Alphabets only";
                        //     }
                        // }
                        // if (values.emergency_contact_relation) {
                        //     if (!RegEx.name__regEx.test(values.emergency_contact_relation)) {
                        //         errors.emergency_contact_relation = "Alphabets only";
                        //     }
                        // }
                        if (values.email) {
                            if (!RegEx.email__regEx.test(values.email)) {
                                errors.email = "Invalid email address";
                            }
                        }
                        if (values.personal_email) {
                            if (!RegEx.email__regEx.test(values.personal_email)) {
                                errors.personal_email = "Invalid email address";
                            } else if (values.personal_email == changeInfo.email) {
                                errors.personal_email = "email and personal email should not be same";
                            }
                        }
                        if (values.phone_number) {
                            if (!RegEx.only__number__regEx.test(values.phone_number)) {
                                errors.phone_number = "Numeric only";
                            }
                        }
                        if (values.emergency_contact) {
                            if (!RegEx.only__number__regEx.test(values.emergency_contact)) {
                                errors.emergency_contact = "Numerics only";
                            } else if (values.emergency_contact == changeInfo.phone_number) {
                                errors.emergency_contact = "phone and emergency contact number should not be same";
                            }
                        }
                        if (values.esi_number) {
                            if (!RegEx.only__number__regEx.test(values.esi_number)) {
                                errors.esi_number = "Numerics only";
                            }
                        }
                        if (values.aadhaar_number) {
                            if (!RegEx.only__number__regEx12.test(values.aadhaar_number)) {
                                errors.aadhaar_number = "Numerics only and contains 12 digits";
                            }
                        }
                        if (values.bank_account_number) {
                            if (!RegEx.only__number__regEx20.test(values.bank_account_number)) {
                                errors.bank_account_number = "Numerics only and contains 10 to 20 digits";
                            }
                        }
                        if (values.pf_number) {
                            if (!RegEx.capitalalphanumeric__without__spaces22.test(values.pf_number)) {
                                errors.pf_number = "Enter Valid PF number";
                            } else if (values.pf_number.length !== 22) {
                                errors.pf_number = "must contains 22 digits";
                            }
                        }
                        if (values.pan_number) {
                            if (!RegEx.capitalalphanumeric__without__spaces22.test(values.pan_number)) {
                                errors.pan_number = "Enter valid PAN number";
                            } else if (values.pan_number.length !== 10) {
                                errors.pan_number = "contains 10 digits";
                            }
                        }
                        if (values.ifsc_code) {
                            if (!RegEx.capitalalphanumeric__without__spaces22.test(values.ifsc_code)) {
                                errors.ifsc_code = "Enter valid IFSC";
                            } else if (values.ifsc_code.length !== 11) {
                                errors.ifsc_code = "contains 11 digits";
                            }
                        }
                        if (values.uan_number) {
                            if (!RegEx.only__number__regEx12.test(values.uan_number)) {
                                errors.uan_number = "Numerics only and contains 12 digits";
                            }
                        }
                    });
                    return errors;
                }}
                onSubmit={(values) => {
                    postPayloadData(values)
                }}
            >

                {({ setFieldValue, values, handleChange }) => (
                    <Form autoComplete='off'>
                        {fieldDropdowns.map((field, i) => {
                            return (
                                <>
                                    <h5 className="text-light text-decoration-underline my-3">{field.title}</h5>
                                    <div className="row">{field.fields.map((item, i) => {
                                        if (item.name == "reporting_person") {
                                            return null
                                        } else if (item.name == "matrix_manager") { return null }
                                        else if (item.name == "blood_group") {
                                            return (
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="font_color mb-2">
                                                            {item.isRequired
                                                                ? `${item.field_name} *`
                                                                : `${item.field_name}`}
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            className="setting_field"
                                                            name={item.name}
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                setChangeInfo({ ...changeInfo, [e.target.name]: e.target.value })
                                                            }}
                                                        >
                                                            <option value="0">
                                                                select menu
                                                            </option>
                                                            {bloodGroupOptions.length == 0 ? null : <>
                                                                {bloodGroupOptions.map((item1) => {
                                                                    return (
                                                                        <option value={item1?.value}>{item1?.name}</option>
                                                                    );
                                                                })}
                                                            </>}
                                                        </Field>
                                                        <ErrorMessage
                                                            component="div"
                                                            name={item.name}
                                                            style={errorMessage}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                        else if (item.feild_type == "select") {
                                            let optionValues = item?.field_values ? item?.field_values : [];
                                            return (
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="font_color mb-2">
                                                            {item.isRequired
                                                                ? `${item.field_name} *`
                                                                : `${item.field_name}`}
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            className="setting_field"
                                                            name={item.name}
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                setChangeInfo({ ...changeInfo, [e.target.name]: e.target.value })
                                                            }}
                                                        >
                                                            <option value="0">
                                                                select menu
                                                            </option>
                                                            {optionValues.length == 0 ? null : <>
                                                                {optionValues.map((item1) => {
                                                                    return (
                                                                        <option value={item1?.id}>{item1?.name}</option>
                                                                    );
                                                                })}
                                                            </>}
                                                        </Field>
                                                        <ErrorMessage
                                                            component="div"
                                                            name={item.name}
                                                            style={errorMessage}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        } else if (item.feild_type == "textarea") {
                                            return (
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="font_color mb-2">
                                                            {item.isRequired
                                                                ? `${item.field_name} *`
                                                                : `${item.field_name}`}
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            className="setting_field"
                                                            name={item.name}
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                setChangeInfo({ ...changeInfo, [e.target.name]: e.target.value })
                                                            }}
                                                            rows="4"
                                                            cols="50"
                                                        ></Field>
                                                        <ErrorMessage
                                                            component="div"
                                                            name={item.name}
                                                            style={errorMessage}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                        else if (item.name == "date_of_birth") {
                                            return (
                                                <div className="col-md-6">
                                                    <label className="font_color me-2 form-label">{
                                                        item.isRequired
                                                            ? `${item.field_name} *`
                                                            : `${item.field_name}`
                                                    }</label>
                                                    <DatePicker
                                                        name={item.name}
                                                        selected={new Date(values[item.name]) == "Invalid Date" ? "" : new Date(values[item.name])}
                                                        onSelect={(e) => {
                                                            setFieldValue(item.name, e.toISOString()?.split("T")[0] || "");
                                                            setChangeInfo({ ...changeInfo, [item.name]: e })
                                                            autoCalculateage(e)
                                                        }}
                                                        onChange={(val) => {
                                                            setFieldValue(item.name, val ? val.toISOString()?.split("T")[0] || "" : "");
                                                            setChangeInfo({ ...changeInfo, [item.name]: val })
                                                            autoCalculateage(val)
                                                        }}
                                                        className="setting_field"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="dd-mm-yyyy"
                                                        maxDate={new Date()}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name={item.name}
                                                        style={errorMessage}
                                                    />
                                                </div>)
                                        }
                                        else if (item.name == "date_of_joining") {
                                            return (
                                                <div className="col-md-6">
                                                    <label className="font_color me-2 form-label">{
                                                        item.isRequired
                                                            ? `${item.field_name} *`
                                                            : `${item.field_name}`
                                                    }</label>
                                                    <DatePicker
                                                        name={item.name}
                                                        selected={new Date(values[item.name]) == "Invalid Date" ? "" : new Date(values[item.name])}
                                                        onSelect={(e) => {
                                                            setFieldValue(item.name, e.toISOString()?.split("T")[0] || "");
                                                            setChangeInfo({ ...changeInfo, [item.name]: e })
                                                            autoCalculateExp(e)
                                                        }}
                                                        onChange={(val) => {
                                                            setFieldValue(item.name, val ? val.toISOString()?.split("T")[0] || "" : "");
                                                            setChangeInfo({ ...changeInfo, [item.name]: val })
                                                            autoCalculateExp(val)
                                                        }}
                                                        className="setting_field"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="dd-mm-yyyy"
                                                        maxDate={new Date()}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name={item.name}
                                                        style={errorMessage}
                                                    />
                                                </div>)
                                        }
                                        else {
                                            return (
                                                <div className="col-md-6">

                                                    <CustomInput
                                                        label={
                                                            item.isRequired
                                                                ? `${item.field_name} *`
                                                                : `${item.field_name}`
                                                        }
                                                        type={item.feild_type}
                                                        name={item.name}
                                                        onChange={(e) => {
                                                            if (item.name == "ifsc_code" || item.name == "pan_number" || item.name == "pf_number") {
                                                                setFieldValue(item.name, e.target.value.toUpperCase())
                                                                setChangeInfo({ ...changeInfo, [e.target.name]: e.target.value.toUpperCase() })
                                                            } else {
                                                                setFieldValue(item.name, e.target.value)
                                                                setChangeInfo({ ...changeInfo, [e.target.name]: e.target.value })
                                                            }
                                                        }}
                                                        readOnly={(item.name == "age") || (item.name == "yoe_dolarbird")}
                                                        value={item.name == "age" ? values[item.name] ? values[item.name] : calculateAge
                                                            : item.name == "yoe_dolarbird" ? values[item.name] ? values[item.name] : calculateExp
                                                                : values[item.name]}
                                                        disabled={(editInfo && item.name == "employee_id") ? (changeInfo.isUpdated == 0 ? false : true) : (editInfo && item.name == "email") ? true : false}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                    </div>
                                </>
                            )
                        })}
                        <div className="col-md-12">
                            <div className="text-end">
                                <button id='submitbtn' type='submit' className='btn btn-success'>Submit</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    };

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validate: (values) => {
            let errors = {};
            if (!values.currentPassword) {
                errors.currentPassword = "Enter correct password";
            }
            if (!values.newPassword) {
                errors.newPassword = "Password is required";
            } else if (!RegEx.password__regEx.test(values.newPassword)) {
                errors.newPassword = "Must Contain min 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Case Character";
            }
            if (!values.confirmPassword || (values.confirmPassword != values.newPassword)) {
                errors.confirmPassword = "Must match with New Password";
            }

            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            var values2 = { ...values, email: work_email }
            var response = await putRequest(configUrl.updatePassword, values2)
            if (response) {
                if (response.status == 201 || response.status == 200) {
                    setSuccess(true)
                    setSnackMessage(response.data.message)
                    setSnackOpen(true)
                    resetForm()
                } else if (response.response.data.status == 400 || response.response.data.status == 401
                    || response.response.data.status == 409) {
                    setSuccess(false)
                    setSnackMessage(response.response.data.message)
                    setSnackOpen(true)
                }
            }
        }
    });


    const formatDate = (dateString) => {
        const dateParts = dateString.split('-');
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }

    return (
        <>
            <div className='w-800'>
                <h3 className=''>User Profile</h3>
            </div>

            <div div className='userprofile_div rounded' >
                <Tabs defaultActiveKey="tab1" id="my-tabs" className='profile_tab'>
                    <Tab eventKey="tab1" className='font_color' title="User Details">
                        {(role == 'super_admin' || role == 'hr') ?
                            <div className='mt-4 text-end'>
                                <button id='editbtn' className='btn btn-light' onClick={() => setEditInfo(!editInfo)}>{editInfo ? "View Info" : "Edit Info"}</button>
                            </div> : null}
                        {editInfo ? <div className='mt-4'>
                            {(role == 'super_admin' || role == 'hr') ?
                                <div>{InfoDiv()}</div> : null}
                        </div> :
                            <div className='mt-4'>
                                {[updateInfo].map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <div className="">
                                                <h5 className="my-3 text-decoration-underline font_color">Basic Details</h5>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Name: <strong> {item.name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Gender: <strong> {item.gender_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Contact Number: <strong> {item.phone_number}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Employee Id: <strong> {item.employee_id}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <h5 className="mb-3 text-decoration-underline font_color">Work Details</h5>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Office Email: <strong> {item.email}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Employee Type: <strong> {item.employee_type_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Date of Joining: <strong> {item.date_of_joining ? formatDate(item.date_of_joining) : ""}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Years of Experience(previous) : <strong> {item.years_of_experience ? item.years_of_experience : "0"} years</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Years of Experience(Dollarbird) : <strong> {calculateExp}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            shifts: <strong> {item.shift_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Department: <strong> {item.department_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Designation: <strong> {item.designation_name}</strong>
                                                        </p>
                                                    </div>
                                                    {(role == 'super_admin' || role == 'hr') ? null : <>
                                                        <div className="col-md-6">
                                                            <div className="d-flex">
                                                                <p className="font_color">
                                                                    Reporting Person:
                                                                </p>
                                                                <p className="font_color ms-1">
                                                                    <strong> {item.reporting_person_information &&
                                                                        item.reporting_person_information.length > 0 &&
                                                                        item.reporting_person_information.map(item => {
                                                                            return (<>{`${item.employee_id} - ${item.name}`}</>)
                                                                        })
                                                                    }</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="d-flex">
                                                                <p className="font_color">
                                                                    Matrix Manager:
                                                                </p>
                                                                <div>
                                                                    <strong> {item.matrix_manager_information &&
                                                                        item.matrix_manager_information.length > 0 &&
                                                                        item.matrix_manager_information.map((item1, index) => {
                                                                            return (<p className="font_color my-0 ms-1">{`${item1.employee_id} - ${item1.name}`}
                                                                                {index !== item.matrix_manager_information.length - 1 && ", "}
                                                                            </p>)
                                                                        })
                                                                    }</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <h5 className="mb-3 text-decoration-underline font_color">Personal Information</h5>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Date of Birth: <strong> {item.date_of_birth ? formatDate(item.date_of_birth) : ''}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Father Name : <strong> {item.father_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Mother Name: <strong> {item.mother_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Permanent Address: <strong> {item.permanent_address}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Postal Address: <strong> {item.postal_address}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Personal Email: <strong> {item.personal_email}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Marital Status: <strong> {item.marital_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Blood Group: <strong> {item.blood_group}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Emergency Contact Person Name: <strong> {item.emergency_contact_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Emergency Contact Person Relation: <strong> {item.emergency_contact_relation}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Emergency Contact Number: <strong> {item.emergency_contact}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <h5 className="mb-3 text-decoration-underline font_color">Educational Details</h5>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Highest Qualification : <strong> {item.highest_qualification}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            University Name : <strong> {item.university_name}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Years of Passing : <strong> {item.year_of_passing}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <h5 className="mb-3 text-decoration-underline font_color">Account Information</h5>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            ESI Number: <strong> {item.esi_number}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            PF Number : <strong> {item.pf_number}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            UAN Number: <strong> {item.uan_number}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            PAN Number: <strong> {item.pan_number}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Aadhaar Number : <strong> {item.aadhaar_number}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Bank Account Number: <strong> {item.bank_account_number}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            IFSC code: <strong> {item.ifsc_code}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p className="font_color">
                                                            Bank Account Type: <strong> {item.bank_account_type_name}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-5">
                                                <h5 className="mb-2 text-decoration-underline font_color">Assigned Products</h5>
                                                {(item.assignedProducts && item.assignedProducts.length > 0) ? <>
                                                    <div className="d-flex my-2">
                                                        <div className="col-md-3 font_color text-decoration-underline text-center">Product Name</div>
                                                        <div className="col-md-3 font_color text-decoration-underline text-center">Product Label No.</div>
                                                        <div className="col-md-3 font_color text-decoration-underline text-center">Date of Assign</div>
                                                    </div>
                                                    {item.assignedProducts.map((item, i) => {
                                                        return (<div className="d-flex my-1" key={i}>
                                                            <div className="col-md-3 font_color text-center">{item.productName}</div>
                                                            <div className="col-md-3 font_color text-center">{item.productLabel}</div>
                                                            <div className="col-md-3 font_color text-center">{item.assignDate}</div>
                                                        </div>)
                                                    })}
                                                </> : <div>
                                                    <h6 className="font_color my-4">Products are not assigned</h6>
                                                </div>}
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>}
                    </Tab>
                    <Tab eventKey="tab2" title="Change Password">
                        <div className="mt-4 mt-md-5 px-md-5">
                            <form onSubmit={formik.handleSubmit}>
                                <PasswordShowHide
                                    name="currentPassword"
                                    input_label="Current Password *"
                                    lableClass="font_color"
                                    placeholder="Enter Current Password"
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    value={formik.values.currentPassword}
                                    formikValidation={formik.touched.currentPassword && formik.errors.currentPassword ? (
                                        <>
                                            <span className="text-danger small">{formik.errors.currentPassword}</span>
                                        </>
                                    ) : null}
                                />
                                <PasswordShowHide
                                    name="newPassword"
                                    input_label="New Password *"
                                    lableClass="font_color"
                                    placeholder="Enter New Password"
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    value={formik.values.newPassword}
                                    formikValidation={formik.touched.newPassword && formik.errors.newPassword ? (
                                        <>
                                            <span className="text-danger small">{formik.errors.newPassword}</span>
                                        </>
                                    ) : null}
                                />
                                <PasswordShowHide
                                    name="confirmPassword"
                                    input_label="Confirm Password *"
                                    lableClass="font_color"
                                    placeholder="Enter Confirm Password"
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                    formikValidation={formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                        <>
                                            <span className="text-danger small">{formik.errors.confirmPassword}</span>
                                        </>
                                    ) : null}
                                />
                                <div className="text-end mt-4">
                                    <Button id='passwordbtn' type="submit" className="btn_submit">
                                        Update Password
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Tab>
                </Tabs>
            </div >

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose} message={snackMessage}
                snackbg={success} />
        </>
    )
}
