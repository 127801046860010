import { ErrorMessage, FieldArray } from 'formik';
import React, { useRef } from 'react'
import { Form } from 'react-bootstrap';
import { Input_element } from '../../components/input_field/Input_element';
import { Selectelement } from '../../components/Select_field/Selectelement';
import { Images } from '../../utils/images';
import { useState } from 'react';
import { PostRequestHook } from '../../apis/Services';
import { configUrl } from '../../apis/api.config';
import { useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const CompanyBankDetails = ({ values, setFieldValue, handleDeleteBanks, handleChange, handleBlur, countryList,
    dropDownOption,
    selectHandler,
    options,
    additionalBankDetails,
    setAdditionalBankDetails
}) => {
    console.log("🚀 ~ file: CompanyBankDetails.js:13 ~ values:", values)

    let dropOption = options.length > 0 ? options.map(option => option.value).join(',') : ""
    // console.log("🚀 ~ file: CompanyBankDetails.js:23 ~ dropOption:", dropOption)
    // console.log("----------------------> valus", values)
    const [stateListArr, setstateListArr] = useState([]);
    const [stateOptions, setStateOptions] = useState({});
    const { getRequest, postRequest, putRequest } = PostRequestHook()
    const [testStatesMap, setTestStateMap] = useState({})
    const [checked, setChecked] = useState(false);
    const [field, setField] = useState([]);
    // ---


    const handleCheckChange = () => {
        // Change state to the opposite (to ture) when checkbox changes
        // console.log("------target checked", e.target.checked)
        setChecked(!checked);
    };

    const reff = useRef({})
    // console.log("---------ref-----------🚀 ~ file: Address.js:20 ~ reff:", reff)

    useEffect(() => {
        editData()
    }, [])

    // useEffect(() => {
    //     console.log("🚀 ~ file: Address.js:17 ~ testStatesMap:", testStatesMap)
    // }, [reff])

    let editData = async () => {
        // console.log("------------------------------------------------------")
        // console.log("🚀 ~ file: Address.js:27 ~ editData ~ values?.company_bank_details?.[0]?.country:", values?.company_bank_details?.[0]?.country)
        if (values?.company_bank_details?.[0]?.country) {
            values?.company_bank_details?.map(async (mapVal) => {
                // console.log("🚀 ~ file: Address.js:23 ~ values?.company_bank_details?.map ~ mapVal:", mapVal)
                let data = await fetchOptionsForState(mapVal?.country)
                // console.log(mapVal?.country, "----------------------🚀 ~ file: Address.js:31 ~ values?.company_bank_details?.map ~ data: -----------", data)
                // setTestStateMap({ ...testStatesMap, ...(Object.fromEntries([[mapVal?.country, data]])) })
                reff.current[mapVal?.country] = data
                let clr = setTimeout(() => {
                    setTestStateMap({ ...testStatesMap, ...reff.current })
                    clearTimeout(clr)
                }, 900)
            })
        }
    }

    const fetchOptionsForState = async (countryId) => {
        console.log("🚀 ~ file: Address.js:17 ~ fetchOptionsForState ~ countryId:", countryId)

        let response = await getRequest(`${configUrl.getState}${countryId}`)
        let stateList = response?.data?.data ? response?.data?.data : []
        // console.log(countryId, "🚀 ===========================", stateList)
        // console.log("🚀 ~ file: Address.js:27 ~ fetchOptionsForState ~  testStatesMap.countryId != countryId:", testStatesMap.countryId != countryId)
        // console.log("🚀 ~ file: Address.js:27 ~ fetchOptionsForState ~ !Object.hasOwn(`countryId`):", !Object.hasOwn(`countryId`))
        if (!Object.hasOwn(`countryId`) && testStatesMap.countryId != countryId) {
            setTestStateMap({ ...testStatesMap, ...(Object.fromEntries([[countryId, stateList]])) })
            // console.log("🚀 ~ file: Address.js:29 ~ fetchOptionsForState ~ Object.fromEntries([countryId, stateList]):", Object.fromEntries([[countryId, stateList]]))
        }
        return stateList
    };

    const getOption = (data) => {
        // console.log("-data--", data)
        let options = data.map((data) => ({
            value: data?.id,
            label: data?.additionalAccountValue,
        }));
        // console.log("---options", options);
        // setAdditionalBankDetails(options)
        return options;
    }

    return (

        <div className="mb-5">
            <FieldArray name="company_bank_details">
                {({ push, remove }) => (
                    <div>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-light text-decoration-underline my-3">Bank Information</h5>
                        </div>
                        {values?.company_bank_details?.map((states, index) => (
                            <div key={index} className="row border pt-3 mb-3">
                                {/* {console.log("-------",states)} */}
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">Bank Name<strong className="text-danger">*</strong></span>}
                                        type="text"
                                        id={`company_bank_details.${index}.bank_name`}
                                        name={`company_bank_details.${index}.bank_name`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].bank_name}
                                        formikValidation={
                                            <ErrorMessage name={`company_bank_details.${index}.bank_name`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">Branch Name<strong className="text-danger">*</strong></span>}
                                        type="text"
                                        id={`company_bank_details.${index}.branch`}
                                        name={`company_bank_details.${index}.branch`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].branch}
                                        formikValidation={
                                            <ErrorMessage name={`company_bank_details.${index}.branch`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">Beneficiary Name<strong className="text-danger">*</strong></span>}
                                        type="text"
                                        id={`company_bank_details.${index}.beneficiary`}
                                        name={`company_bank_details.${index}.beneficiary`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].beneficiary}
                                        formikValidation={
                                            <ErrorMessage name={`company_bank_details.${index}.beneficiary`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">Account No<strong className="text-danger">*</strong></span>}
                                        type="text"
                                        id={`company_bank_details.${index}.account_no`}
                                        name={`company_bank_details.${index}.account_no`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].account_no}
                                        formikValidation={
                                            <ErrorMessage name={`company_bank_details.${index}.account_no`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">IFSC Code<strong className="text-danger">*</strong></span>}
                                        type="text"
                                        id={`company_bank_details.${index}.ifsc`}
                                        name={`company_bank_details.${index}.ifsc`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].ifsc}
                                        formikValidation={
                                            <ErrorMessage name={`company_bank_details.${index}.ifsc`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">Swift Code</span>}
                                        type="text"
                                        id={`company_bank_details.${index}.swift_code`}
                                        name={`company_bank_details.${index}.swift_code`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].swift_code}
                                    // formikValidation={
                                    //     <ErrorMessage name={`company_bank_details.${index}.swift_code`} component="div" className="text-danger position-absolute small" />
                                    // }
                                    />
                                </div>
                                {/* country dropdown */}
                                <div className="col-md-5">
                                    <Selectelement
                                        optionArray={
                                            <>
                                                {countryList.length > 0 && countryList.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>
                                                            {item.country_name}
                                                        </option>
                                                    );
                                                })}
                                            </>}
                                        // ${values.company_bank_details[index].country || "empty"}
                                        select_Label={<span className='text-white'>Country<strong className="text-danger">*</strong></span>}
                                        // id={`company_bank_details.${index}.country`}
                                        name={`company_bank_details.${index}.country`}
                                        handleChange={async (e) => {
                                            handleChange(e)
                                            const countryId = e.target.value;
                                            const options = await fetchOptionsForState(countryId);
                                            // console.log("🚀 ~ file: AddClient.js:562 ~ handleChange={ ~ options:", options)
                                            setStateOptions((prevOptions) => ({
                                                ...prevOptions,
                                                [countryId]: options,
                                            }));
                                            setFieldValue(`company_bank_details.${index}.state`, '');
                                        }}
                                        // handleBlur={handleBlur}
                                        value={values.company_bank_details[index].country}
                                        formikValidation={
                                            <ErrorMessage name={`company_bank_details.${index}.country`} component="div" className="text-danger position-absolute small" />
                                        } />
                                </div>
                                {/* state dropdown */}
                                <div className="col-md-5">
                                    {/* {console.log("99999999999", testStatesMap, "------------", testStatesMap?.[values.company_bank_details[index].country])} */}
                                    <Selectelement
                                        // optionArray={
                                        //     <>{Object.keys(stateOptions).length > 0 ? <>  {(stateOptions[states.country] ? stateOptions[states.country] : []).map((item, i) => {
                                        //         return (
                                        //             <option key={i} value={item.id}>
                                        //                 {item.state_name}
                                        //             </option>
                                        //         )
                                        //     })}</> : null}
                                        //     </>}
                                        optionArray={
                                            <>
                                                {testStatesMap?.[values.company_bank_details[index].country]?.map((item, ind) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.state_name}
                                                        </option>
                                                    )
                                                })}
                                            </>
                                        }
                                        // onChange={(item) => {
                                        //     console.log("🚀 ~ file: AddClient.js:615 ~ handleChange={ ~ item:", item)
                                        //     if (item) {
                                        //         setFieldValue(`company_bank_details.${index}.state`, item.value)
                                        //     } else {
                                        //         setFieldValue(`company_bank_details.${index}.state`, "")
                                        //     }
                                        // }}
                                        select_Label={<span className='text-white'>State<strong className="text-danger">*</strong></span>}
                                        id={`company_bank_details.${index}.state`}
                                        name={`company_bank_details.${index}.state`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        // {selectFieldValue(productOptions[product.productName], values.productDetails[index].productLabel)}
                                        // defaultValue={values?.company_bank_details[index]?.state_name}
                                        value={values?.company_bank_details[index]?.state}
                                        formikValidation={
                                            <ErrorMessage name={`company_bank_details.${index}.state`} component="div" className="text-danger position-absolute small" />
                                        } />

                                </div>
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">City<strong className="text-danger">*</strong></span>}
                                        type="text"
                                        id={`company_bank_details.${index}.city`}
                                        name={`company_bank_details.${index}.city`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].city}
                                        formikValidation={
                                            <ErrorMessage name={`company_bank_details.${index}.city`} component="div" className="text-danger position-absolute small" />
                                        }
                                    />
                                </div>
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">Purpose Code</span>}
                                        type="text"
                                        id={`company_bank_details.${index}.purpose_code`}
                                        name={`company_bank_details.${index}.purpose_code`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].purpose_code}
                                    // formikValidation={
                                    //     <ErrorMessage name={`company_bank_details.${index}.purpose_code`} component="div" className="text-danger position-absolute small" />
                                    // }
                                    />
                                </div>
                                <div className="col-md-5">
                                    <Input_element
                                        input_label={<span className="text-white">Purpose Remittance</span>}
                                        type="text"
                                        id={`company_bank_details.${index}.purpose_remittance`}
                                        name={`company_bank_details.${index}.purpose_remittance`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.company_bank_details[index].purpose_remittance}
                                    // formikValidation={
                                    //     <ErrorMessage name={`company_bank_details.${index}.purpose_remittance`} component="div" className="text-danger position-absolute small" />
                                    // }
                                    />
                                </div>
                                {/* Additional Information */}
                                <div>
                                    <label className='className="mr-3 mb-3 text-light fw-bolder'>Additional Information(Optional)</label>
                                    {/* <input
                                         type="checkbox"
                                        //  checked={values.company_bank_details[index].setAdditionalInformation}
                                        //  name={`company_bank_details.${index}.setAdditionalInformation`}
                                        onChange={handleCheckChange}
                                         onBlur={handleBlur}
                                         className="text-white"
                                         checked={checked}
                                    /> */}

                                    {/* <input
                                        type="checkbox"
                                        checked={values.company_bank_details[index].setAdditionalInformation}
                                        name={`company_bank_details.${index}.setAdditionalInformation`}
                                        onChange={(e) => {
                                            handleChange(e)
                                            let value = values?.company_bank_details[index]?.setAdditionalInformation == 'on' ? 0 : 1
                                            // const value = e.target.checked ? 1 : 0;
                                            const updatedBank = [...values.company_bank_details];
                                            updatedBank[index].setAdditionalInformation = value;
                                            // updatedBank.forEach((primary, i) => {
                                            //     if (i === index) {
                                            //         primary.setAdditionalInformation = value;
                                            //         console.log("---primary.setAdditionalInformation ",primary.setAdditionalInformation )
                                            //     } 
                                            // });


                                            setFieldValue("company_bank_details", updatedBank);
                                        }}
                                        onBlur={handleBlur}
                                        className="text-white"
                                    // checked={checked}
                                    /> */}
                                    {/* {values.company_bank_details[index].setAdditionalInformation} */}
                                    {/* <h5 className='text-white fw-bolder text-decoration-underline'> */}
                                    {/* <div className="col-md-12">
                                            <div className="mb-4">
                                                <label className="me-3 text-light fw-bolder">Primary Address</label>
                                                <input
                                                    type="checkbox"
                                                    // checked={values.company_bank_details[index].setAdditionalInformation}
                                                    name={`company_bank_details.${index}.setAdditionalInformation`}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        const updatedAddress = [...values.company_bank_details];
                                                        updatedAddress.forEach((primary, i) => {
                                                            if (i === index) {
                                                                primary.setAdditionalInformation = !updatedAddress.setAdditionalInformation;
                                                            } else {
                                                                primary.setAdditionalInformation = false;
                                                            }
                                                        });
                                                        setFieldValue("company_bank_details", updatedAddress);
                                                    }}
                                                    onBlur={handleBlur}
                                                    className="text-white"
                                                    checked={checked}
                                                />
                                                <ErrorMessage name={`company_bank_details.${index}.setAdditionalInformation`} component="div" className="text-danger position-absolute small" />
                                            </div>
                                        </div> */}
                                    {/* <input
                                            id="inputVacationPercentage"
                                            type="checkbox"
                                            checked={checked}
                                            onChange={handleCheckChange}
                                        /> */}

                                    {/* <span className='mx-3'>Additional Information(Optional)</span> */}
                                    {/* </h5> */}

                                    {/* checkbox checked */}
                                    {/* {checked && */}
                                    {/* {values.company_bank_details[index].setAdditionalInformation[0] === "on" ? "d-flex" : "d-none"} */}
                                    {/* <div className={`row ${values.company_bank_details[index].setAdditionalInformation[0] === "on" ? "d-flex" : "d-none"}`} > */}
                                    <div className={`row`} >
                                        <div className="col-md-5">
                                            <Input_element
                                                input_label={<span className="text-white">Correspondent Bank</span>}
                                                type="text"
                                                id={`company_bank_details.${index}.correspondentBank`}
                                                name={`company_bank_details.${index}.correspondentBank`}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.company_bank_details[index].correspondentBank}
                                            // formikValidation={
                                            //     <ErrorMessage name={`company_bank_details.${index}.correspondentBank`} component="div" className="text-danger position-absolute small" />
                                            // }
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <Input_element
                                                input_label={<span className="text-white">Correspondent Bank's Swift Code</span>}
                                                type="text"
                                                id={`company_bank_details.${index}.correspondentSwiftCode`}
                                                name={`company_bank_details.${index}.correspondentSwiftCode`}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.company_bank_details[index].correspondentSwiftCode}
                                            // formikValidation={
                                            //     <ErrorMessage name={`company_bank_details.${index}.correspondentSwiftCode`} component="div" className="text-danger position-absolute small" />
                                            // }
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <Input_element
                                                input_label={<span className="text-white">Correspondent Account Number</span>}
                                                type="text"
                                                id={`company_bank_details.${index}.correspondentAccountNumber`}
                                                name={`company_bank_details.${index}.correspondentAccountNumber`}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={values.company_bank_details[index].correspondentAccountNumber}
                                            // formikValidation={
                                            //     <ErrorMessage name={`company_bank_details.${index}.correspondentAccountNumber`} component="div" className="text-danger position-absolute small" />
                                            // }
                                            />
                                        </div>

                                        {/* additional bank details */}
                                        <div className="col-md-5">
                                            <div className='mb-4'>
                                                <label className='mb-2'><span className="text-white">Additional bank details</span></label>
                                                {/* {console.log("**************", states.additionalInformation, options)} */}
                                                <Select
                                                    id={`company_bank_details.${index}.additionalInformation`}
                                                    name={`company_bank_details.${index}.additionalInformation`}

                                                    defaultValue={states.additionalInformation.length > 0 ? getOption(states.additionalInformation) : null
                                                        //  [{value:states.additionalInformation.id,label:states.additionalInformation.additionalAccountValue}]
                                                    }

                                                    // onChange={selectHandler}
                                                    onChange={(selectedOptions) => {
                                                        // Extract the values from the selected options
                                                        const selectedValues = selectedOptions.map((option) => option.value);

                                                        // Update Formik's field value
                                                        setFieldValue(`company_bank_details.${index}.additionalInformation`, selectedValues);
                                                    }}
                                                    options={options}
                                                    placeholder="Select Additional bank details"
                                                    isMulti
                                                //    options={ }
                                                // formikValidation={
                                                //     <ErrorMessage name={`company_bank_details.${index}.correspondentAccountNumber`} component="div" className="text-danger position-absolute small" />
                                                // }
                                                />
                                                {/* {values.company_bank_details[index].additionalInformation} */}
                                            </div>

                                        </div>
                                    </div>
                                    {/* } */}


                                </div>

                                {/* {updateClients ? null : <div className="col-md-2 my-auto"> */}
                                {/* {updateClients ? null :  */}
                                <div className="mb-3 text-end">
                                    {values.company_bank_details.length > 1 ? <button type="button" className="bg-transparent border-0" onClick={(e) => {
                                        remove(index)
                                        if (values.company_bank_details[index].id) {
                                            handleDeleteBanks(e, values.company_bank_details[index].id)

                                        }
                                    }
                                    }
                                    >
                                        <img src={Images.deleteLogo} alt="icon" />
                                    </button>
                                        : null}
                                </div>
                                {/* } */}
                            </div>
                        ))}
                        {/* {updateClients ? null : */}
                        <div>
                            <button type="button" className="btn btn-light"
                                onClick={() => push({ setAdditionalInformation: 0, id: '', bank_name: '', branch: '', beneficiary: '', account_no: "", ifsc: "", swift_code: "", purpose_code: "", purpose_remittance: "", correspondentBank: "", correspondentSwiftCode: "", correspondentAccountNumber: "", additionalInformation: [] })}>
                                Add Banks
                            </button>
                        </div>
                        {/* } */}
                    </div>
                )}
            </FieldArray>
        </div>
    )
}

export default CompanyBankDetails