import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Input_element } from "../../components/input_field/Input_element";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Images } from "../../utils/images";
import { configUrl } from "../../apis/api.config";
import { PostRequestHook } from "../../apis/Services";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { RegEx } from "../../utils/RegEx";
import { useDispatch, useSelector } from "react-redux";
import { setDropdownlist } from "../../store/reducers/ui.reducer";

export const AdditionalAccountDetails = () => {
    const { getRequest, postRequest, putRequest } = PostRequestHook()

    const [viewRole, setViewRole] = useState(false);
    const [typeId, setTypeId] = useState('');
    const [typeList, setTypeList] = useState([]);
    // console.log("🚀 ~ file: AdditionalAccountDetails.js:20 ~ AdditionalAccountDetails ~ typeList:", typeList)
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")

    useEffect(() => {
        getTypes()
    }, [])
    const getTypes = async () => {
        var dropdowns = await getRequest(configUrl.getAdditionalBankDetail)
        console.log("🚀 ~ file: AdditionalAccountDetails.js:30 ~ getTypes ~ dropdowns:", dropdowns)
        // console.log("🚀 ~ file: BillingCrud.js:29 ~ getTypes ~ dropdowns:", dropdowns)
        if (dropdowns) {
            setTypeList(dropdowns?.data?.data ? dropdowns?.data?.data : [])
        }
    }

    const formikRole = useFormik({
        initialValues: {
            additionalAccountValue: "",
        }
        ,
        validate: (values) => {
            let errors = {};
            if (!values.additionalAccountValue) {
                errors.additionalAccountValue = "Required";
            }
            // else if (!RegEx.name__regEx.test(values.additionalAccountValue)) {
            //     errors.additionalAccountValue = "Alphabets,Numbers and Only hyphen (-) is allowed";
            // }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            var response = typeId ? await putRequest(`${configUrl.updateAdditionalBankDetail}${typeId}`, values)
                : await postRequest(configUrl.createAdditionalBankDetail, values)
            console.log("🚀 ~ file: BillingCrud.js:53 ~ onSubmit: ~ response:", response)
            // console.log("🚀 ~ file: BillingCrud.js:51 ~ onSubmit: ~ response:", response)
            if (response.status == 201 || response.status == 200) {
                setSuccess(true)
                setSnackMessage(response.data.message)
                getTypes()
                setSnackOpen(true)
                setViewRole(false)
                getDropdownData()
                resetForm()
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                setSuccess(false)
                setSnackMessage(response.response.data.message)
                setSnackOpen(true)
            }
        },
    });

    const dispatch = useDispatch()
    const getDropdownData = async () => {
        var response = await getRequest(configUrl?.getBillingType)
        dispatch(setDropdownlist({
            dropdownList: response.data.data
        }))
    }

    const handleTypeDelete = async (e, id) => {
        var response = await putRequest(`${configUrl.deleteAdditionalBankDetail}${id}`)
        if (response.status == 200 || response.status == 201) {
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            getTypes()
            setTypeId()
            getDropdownData()
        }
    }

    const handleTypeEdit = (e, id) => {
        var xyz = typeList.filter((item) => item.id == id)
        // console.log("🚀 ~ file: BillingCrud.js:93 ~ handleTypeEdit ~ xyz:", xyz)
        formikRole.setFieldValue("additionalAccountValue", xyz[0].additionalAccountValue)
        setTypeId(xyz[0].id)
        setViewRole(true)
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="employeetype_style">
                <div className="d-flex justify-content-between mb-4">
                    <div>
                        <h3>Additional Bank Details</h3>
                    </div>
                    <div className="text-end">
                        <Link id="dashboardbtn" to="/" className="goback">
                            Back to Dashboard
                        </Link>
                        <Button
                            id="createbtn"
                            type="button"
                            onClick={
                                viewRole ? () => {
                                    setViewRole(false)
                                    formikRole.setFieldValue("additionalAccountValue", "")
                                    setTypeId()
                                } : () => {
                                    setViewRole(true)
                                    setTypeId()
                                }
                            }
                        >
                            {viewRole ? "Additional BankDetail List" : "Create Additional Bank Detail"}
                        </Button>
                    </div>
                </div>
                <div className="mt-5">
                    {viewRole ? (
                        <div className="type_form">
                            <h5 className="mb-3 font_color">Add Additional BankDetail</h5>
                            <Form onSubmit={formikRole.handleSubmit}>
                                <Input_element
                                    input_label="Additional BankDetail Name"
                                    lableClass="font_color"
                                    name="additionalAccountValue"
                                    handleChange={formikRole.handleChange}
                                    handleBlur={formikRole.handleBlur}
                                    value={formikRole.values.additionalAccountValue}
                                    type="text"
                                    placeholder="Enter Billing name"
                                    formikValidation={
                                        formikRole.touched.additionalAccountValue &&
                                            formikRole.errors.additionalAccountValue ? (
                                            <small className="text-danger position-absolute">
                                                {formikRole.errors.additionalAccountValue}
                                            </small>
                                        ) : null
                                    }
                                />
                                <div className="text-end mt-4">
                                    <Button id={typeId ? "updatebtn" : "addbtn"} type="submit">{typeId ? "Update" : "Add"}</Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <div className="table_div">
                            <h5>Additional BankDetails List</h5>
                            <div className="mb-4 text-end"></div>
                            <div className="roles table_scroll">
                                <Table striped bordered hover className="table_role mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sl.No</th>
                                            <th>Additional BankDetails</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeList.map((value, i) => {
                                            // console.log("🚀 ~ file: AdditionalAccountDetails.js:181 ~ {typeList.map ~ value:", value)
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{value.additionalAccountValue}</td>
                                                    <td>{value.id > 1 ? <div className="d-flex justify-content-center">
                                                        <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                                                        <img src={Images.deleteLogo} id={`delete${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeDelete(e, value.id)} />
                                                    </div> :
                                                        <>
                                                            <img src={Images.editLogo} id={`edit${value.id}`} alt="icon" className='mx-2' onClick={(e) => handleTypeEdit(e, value.id)} />
                                                        </>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    );
};
