import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

export const uiReducer = createSlice({
  name: "uiReducer",
  initialState: {
    userInfo: null,
    auth: {
      isAuth: false,
      refresh_token: null
    },
    access_token: null,
    dropdownList: null,
    isSidebarOpen: false,
    loader: false,
    isExpired: false,
    orgName: "",
    screenwidth: ''
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    setIsAuth: (state, action) => {
      state.auth.isAuth = action.payload.isAuth;
      state.userInfo = action.payload.data;
      state.auth.refresh_token = action.payload.refresh_token;
    },
    setViewSidebar: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
    setDropdownlist: (state, action) => {
      state.dropdownList = action.payload;
    },
    setEmployeeEdit: (state, action) => {
      state.isEmployeeEdit = action.payload;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setExpired: (state, action) => {
      state.isExpired = action.payload;
    },
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    setScreenWidth: (state, action) => {
      state.screenwidth = action.payload;
    },
    // logout
    resetAuth: (state, action) => {
      state.auth = { isAuth: false, refresh_token: null }
      state.userInfo = null
      state.dropdownList = null
      state.access_token = null
      state.isExpired = false
    }
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, setIsAuth, setLoader, setExpired,
  setViewSidebar, resetAuth, setDropdownlist, setAccessToken, setOrgName, setScreenWidth } = uiReducer.actions;

export default uiReducer.reducer;
