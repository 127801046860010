import React, { useEffect, useMemo, useState } from "react";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useFilters,
} from "react-table";
import "../../../style/tableStyle.scss";
import { GlobalFilter } from "../../../utils/GlobalFilter";
import { ColumnFilter } from "../../../utils/ColumnFilter";
import { PostRequestHook } from "../../../apis/Services";
import { configUrl } from "../../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { Images } from "../../../utils/images";
import { TableNavigation } from "../../../utils/TableNavigation";

export const PaymentTable = (props) => {
    const { onViewClick } = props
    const [fieldData, setFieldData] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")

    const { putRequest, getRequest } = PostRequestHook()
    const { userInfo } = useSelector((state) => state.UIStore);
    const organization_name = userInfo ? userInfo.organization_name : null
    const employee_id = userInfo ? userInfo.emp_id : null

    useEffect(() => {
        getPaymentMode()
    }, [])
    const getPaymentMode = async () => {
        var response = await getRequest(configUrl.getPaymentMode)
        setFieldData(response?.data?.payload ? response?.data?.payload : [])
    }
    const onDeleteClick = async (id) => {
        var response = await putRequest(`${configUrl.deletePaymentMode}${id}`)
        if (response.status == 200) {
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            getPaymentMode()
        }
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "SL No.",
                accessor: (row, index) => index + 1,
                Cell: ({ value }) => <div>{value}</div>,
                Filter: ColumnFilter,
            },
            {
                Header: "Payment Mode Title",
                accessor: "paymentModeTitle",
                Filter: ColumnFilter,
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <img
                                    src={Images.editLogo}
                                    alt="icon"
                                    id="editInventory"
                                    className="editIcon mx-2"
                                    onClick={() => onViewClick(cellProps)}
                                />
                                <img
                                    src={Images.deleteLogo}
                                    alt="icon"
                                    id="showinventoryHistory"
                                    className="editIcon mx-2"
                                    onClick={() => onDeleteClick(cellProps.cell.row.original.id)}
                                />
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const data = useMemo(() => fieldData, [fieldData]);

    // create a table instance
    const {
        tableInstance,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        usePagination
    );

    const { globalFilter, pageSize, pageIndex } = state;
    return (
        <>
            <div className="my-3 d-flex justify-content-between">
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

                <div>
                    <select
                        value={pageSize}
                        className="selectTag w-auto"
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {[5, 10, 15, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="text-center mt-3">
                <div className="table_scroll">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="text-center">
                    <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
                        nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

        </>
    )
}
