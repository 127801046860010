import React, { useEffect, useMemo, useState } from "react";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useFilters,
} from "react-table";
import "../../style/tableStyle.scss";
import { GlobalFilter } from "../../utils/GlobalFilter";
import { ColumnFilter } from "../../utils/ColumnFilter";
import { Images } from "../../utils/images";
import { TableNavigation } from "../../utils/TableNavigation";

export const EmployeeTable = (props) => {
    const { EmployeeData, onViewClick, handleDeleteClick } = props
    const [fieldData, setFieldData] = useState([]);

    useEffect(() => {
        let empData = EmployeeData ? EmployeeData : []
        setFieldData(empData)
    }, [EmployeeData])

    const columns = React.useMemo(
        () => [
            {
                Header: "Emp ID",
                accessor: "employee_id",
                Filter: ColumnFilter,
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: ColumnFilter,
            },
            {
                Header: "Designation",
                accessor: "designation_name",
                Filter: ColumnFilter,
            },
            {
                Header: "Department",
                accessor: "department_name",
                Filter: ColumnFilter,
            },
            {
                Header: "Email",
                accessor: "email",
                Filter: ColumnFilter,
            },
            {
                Header: "Contact Number",
                accessor: "phone_number",
                Filter: ColumnFilter,
            },
            {
                Header: "View/Edit",
                accessor: "action",
                Cell: (tableProps) => (
                    <div>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <img
                                src={Images.viewIcon}
                                alt="icon"
                                id={`editInventory${tableProps.cell.row.original.id}`}
                                className="editIcon mx-2"
                                onClick={() => onViewClick(tableProps)}
                            />
                            {tableProps.cell.row.original.id == 1 ? null :
                                <img
                                    src={Images.deleteLogo}
                                    alt="icon"
                                    id={`deleteInventory${tableProps.cell.row.original.id}`}
                                    className="editIcon mx-2"
                                    onClick={(e) => handleDeleteClick(tableProps.cell.row.original.employee_id)}
                                />}
                        </div>
                    </div>
                ),
            },
        ],
        []
    );
    const data = useMemo(() => fieldData);


    const {
        tableInstance,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        usePagination
    );

    const { globalFilter, pageSize, pageIndex } = state;

    return (
        <>
            <div className="mt-4 mb-2 d-flex justify-content-between">
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                <div>
                    <select
                        id="tablenumber"
                        value={pageSize}
                        className="selectTag"
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {[5, 10, 15, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="text-center table_scroll">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="text-center">
                <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
                    nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
            </div>
        </>
    );
};
