import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "./CompanyDetails.scss";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { configUrl } from "../../apis/api.config";
import { PostRequestHook } from "../../apis/Services";
import { Selectelement } from "../../components/Select_field/Selectelement";
import { Input_element } from "../../components/input_field/Input_element";
import { Images } from "../../utils/images";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { RegEx } from "../../utils/RegEx";
import CompanyAddress from "./CompanyAddress";
import CompanyBankDetails from "./CompanyBankDetails";
import axios from "axios";


export const CompanyDetails = () => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  // console.log("🚀 ~ file: CompanyDetails.js:20 ~ CompanyDetails ~ isShowDetails:", isShowDetails)
  const [companyDetails, setCompanyDetails] = useState([]);
  // console.log("🚀 ~ file: CompanyDetails.js:22 ~ CompanyDetails ~ companyDetails:", companyDetails)
  // console.log("🚀 ~ file: CompanyDetails.js:17 ~ companyDetails:", companyDetails)

  const [stateOptions, setStateOptions] = useState({});
  // console.log("🚀 ~ file: CompanyDetails.js:21 ~ CompanyDetails ~ stateOptions:", stateOptions)
  const [countryList, setCountryList] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [snackMessage, setSnackMessage] = useState("")
  const [updateClients, setUpdateClients] = useState(false)
  // console.log("🚀 ~ file: CompanyDetails.js:31 ~ CompanyDetails ~ updateClients:", updateClients)
  // console.log("🚀 ~ file: CompanyDetails.js:30 ~ CompanyDetails ~ updateClients:", updateClients)
  const [stateListArr, setstateListArr] = useState([])
  console.log("🚀 ~ file: CompanyDetails.js:35 ~ CompanyDetails ~ stateListArr:", stateListArr)
  // additional bank details 
  const [additionalBankDetails, setAdditionalBankDetails] = useState([])
  // console.log("🚀 ~ file: CompanyDetails.js:36 ~ CompanyDetails ~ additionalBankDetails:", additionalBankDetails)
  const [dropDownOption, setDropDownOption] = useState(null)
  // console.log("🚀 ~ file: CompanyDetails.js:34 ~ CompanyDetails ~ dropDownOption:", dropDownOption)

  const { getRequest, putRequest, postRequest } = PostRequestHook()
  // is company details exist oe not
  const isCompanyDetailsExist = useMemo(() => {
    return companyDetails.length > 0 ? true : false
  }, [companyDetails])
  // console.log("🚀 ~ file: CompanyDetails.js:32 ~ isCompanyDetailsExist ~ isCompanyDetailsExist:", isCompanyDetailsExist)

  // const fetchOptionsForState = async (countryId) => {
  //   console.log("🚀 ~ file: CompanyDetails.js:50 ~ fetchOptionsForState ~ countryId:", countryId)
  //   let response = await getRequest(`${configUrl.getState}${countryId}`)
  //   let stateList = response?.data?.data ? response?.data?.data : []
  //   // console.log("🚀 ~ file: AddClient.js:38 ~ fetchOptionsForState ~ stateList:", stateList)
  //   // let labelData = stateList?.filter(item => item.state_name !== "")
  //   // console.log("🚀 ~ file: AddClient.js:40 ~ fetchOptionsForState ~ labelData:", labelData)
  //   // var testlabeldata = Array.assignService(labelData)
  //   // console.log("🚀 ~ file: AddClient.js:42 ~ fetchOptionsForState ~ testlabeldata:", testlabeldata)
  //   // return testlabeldata
  //   setstateListArr(stateList);
  //   return stateList
  // };
  // let stateList = itemData?.client_address?.filter(item => item?.country)
  let countryData = companyDetails?.company_address_details?.map((mapVal) => {
    const country = mapVal
    return country
  })
  // console.log("🚀 ~ file: AddClient.js:65 ~ countryData ~ countryData:", countryData)
  let countryCode = countryData?.find(findVal => findVal?.country)
  // console.log("🚀 ~ file: AddClient.js:67 ~ countryCode:", countryCode)
  // { console.log("Country", countryCode) }

  // additional bank details
  const getAdditionalBankDetails = async () => {
    let data = await getRequest(configUrl.getAdditionalBankDetail)
    if (data.status === 200) {
      setAdditionalBankDetails(data.data.data)
    }
    // console.log("🚀 ~ file: CompanyBankDetails.js:34 ~ getAdditionalBankDetails ~ data:", data)
  }
  // console.log("additionalBankDetails------", additionalBankDetails)
  let options = additionalBankDetails.map((data) => {
    // console.log("🚀 ~ file: CompanyDetails.js:79 ~ options ~ data:", data)
    return { value: data?.id, label: data?.additionalAccountValue };
  })
  // console.log("🚀 ~ file: CompanyBankDetails.js:49 ~ options ~ options:", options)
  // ----------
  useEffect(() => {
    getCountryList()
    getAdditionalBankDetails()
  }, [])
  // useEffect(() => {
  //   updateClients()
  // }, [updateClients])
  // useEffect(() => {
  //   updateClients && fetchOptionsForState(countryCode?.country)
  // }, [updateClients])
  const getCountryList = async () => {
    let response = await getRequest(configUrl.getCountry)
    setCountryList(response?.data?.data ? response?.data?.data : [])
  }
  // useEffect(() => { getCountryList() }, [])
  // useEffect(() => {
  //   updateClients && fetchOptionsForState(companyDetails?.at(0)?.company_address_details[0]?.country || companyDetails?.at(0)?.company_bank_details[0]?.country)
  // }, [updateClients])
  // const getCountryList = async () => {
  //   let response = await getRequest(configUrl.getCountry)
  //   setCountryList(response?.data?.data ? response?.data?.data : [])
  // }

  // Get Company Details
  useEffect(() => {
    getCompanyDetails()
  }, [])

  const getCompanyDetails = async () => {
    var response = await getRequest(configUrl.getCompanyDetails)
    var _companydetails = (response?.data?.data || [])
    // console.log("🚀 ~ file: CompanyDetails.js:108 ~ getCompanyDetails ~ _companydetails:", _companydetails)
    setCompanyDetails(_companydetails)
    setUpdateClients(_companydetails.length > 0 ? true : false)
  }

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Required"),
    website: Yup.string(),
    contact_person_name: Yup.string().required("Required"),
    registration_number: Yup.string(),
    email: Yup.string().required("Required").matches(
      RegEx.email__regEx,
      "Please Enter valid Email Address"
    ),
    contact_number: Yup.string()
    // .matches(
    //   RegEx.phone__regEx,
    //   "Not exceeded 12 Digits"
    // )
    ,
    gst_no: Yup.string().required("Required"),
    sac_code: Yup.string().required("Required"),
    company_address_details: Yup.array().of(
      Yup.object().shape({
        building: Yup.string(),
        street: Yup.string().required("Required"),
        country: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        pincode: Yup.string().required("Required"),
        // set_primary_address: Yup.string().required("Please Select Checkbox")
        set_primary_address: Yup.string().required("Please Select Checkbox")
      })
    ),
    company_bank_details: Yup.array().of(
      Yup.object().shape({
        bank_name: Yup.string().required("Required"),
        branch: Yup.string().required("Required"),
        beneficiary: Yup.string().required("Required"),
        account_no: Yup.number().required("Required"),
        ifsc: Yup.string().required("Required"),
        swift_code: Yup.string(),
        country: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        purpose_code: Yup.string(),
        purpose_remittance: Yup.string(),
        correspondentBank: Yup.string(),
        correspondentSwiftCode: Yup.string(),
        correspondentAccountNumber: Yup.string(),
        // additionalInformation:
        // set_primary_contact: Yup.string()
      })
    ),
    // Company Upi Details 
    upi_details: Yup.array().of(
      Yup.object().shape({
        upi_name: Yup.string(),
        upi_email: Yup.string().notRequired().matches(
          RegEx.email__regEx,
          "Please Enter valid Email Address"
        ),
        upi_link: Yup.string(),
      })
    ),
  });

  const initialValues = useMemo(() => {
    let data = {
      company_name: updateClients ? (companyDetails?.at(0)?.company_name || "") : "",
      website: updateClients ? (companyDetails?.at(0)?.website || "") : "",
      contact_person_name: updateClients ? (companyDetails?.at(0)?.contact_person_name || "") : "",
      registration_number: updateClients ? (companyDetails?.at(0)?.registration_number || "") : "",
      email: updateClients ? (companyDetails?.at(0)?.email || "") : "",
      contact_number: updateClients ? (companyDetails?.at(0)?.contact_number || "") : "",
      gst_no: updateClients ? (companyDetails?.at(0)?.gst_no || "") : "",
      sac_code: updateClients ? (companyDetails?.at(0)?.sac_code || "") : "",
      company_address_details: updateClients ? (companyDetails?.at(0)?.company_address_details || []) : [{ set_primary_address: true, building: '', street: '', country: '', country_name: '', state_name: '', state: '', city: "", pincode: "" }],
      company_bank_details: updateClients ? (companyDetails?.at(0)?.company_bank_details || []) : [{ setAdditionalInformation: 0, bank_name: '', branch: '', beneficiary: "", account_no: "", ifsc: "", swift_code: "", country: '', state: '', city: "", purpose_code: "", purpose_remittance: "", correspondentBank: "", correspondentSwiftCode: "", correspondentAccountNumber: "", additionalInformation: [] }],
      upi_details: updateClients ? (companyDetails?.at(0)?.upi_details || []) : [{ upi_name: '', upi_email: '', upi_link: '' }]
    }
    // console.log("🚀 ~ file: CompanyDetails.js:113 ~ initialValues ~ data.companyDetails:", companyDetails)
    // console.log("🚀 ~ file: CompanyDetails.js:111 ~ initialValues ~ data:", data, "----updateClients", updateClients)
    return data
  }, [updateClients]);


  const snackBarContent = (isSuccess, message) => {
    setSuccess(isSuccess)
    setSnackMessage(message)
    setSnackOpen(true)
  }
  const AddCompanyDetails = async (values) => {

    if (isCompanyDetailsExist) {
      // values.company_bank_details.map((data)=> console.log("--data",data))
      let incomingData = values

      const transformedData = {
        ...incomingData,
        // Array.isArray check if the incomingData.company_bank_details is array or not
        company_bank_details: Array.isArray(incomingData.company_bank_details)
          ? incomingData.company_bank_details.map(info => {
            if (Array.isArray(info.additionalInformation)) {
              // Extract 'id' property from objects within 'additionalInformation'
              const infoArry = info.additionalInformation.map(data =>
              // console.log("---data", )
              {
                let result = (typeof data === "object") ? data?.id : data
                return result
              }
                // data.id
              );

              return { ...info, additionalInformation: infoArry };
            } else {
              // Keep the 'additionalInformation' property as is
              return info;
            }
          })
          : [] // If it's not an array, make it an empty array
      };
      var response = await putRequest(`${configUrl.updateCompanyDetails}${companyDetails?.at(0)?.id}`, transformedData)
      if (response.status == 200 || response.status == 201) {

        snackBarContent(true, response.data.message)
        getCompanyDetails()
        setIsShowDetails(false)
        window.location.reload();
      } else if (response.response.data.status == 400 || response.response.data.status == 409) {
        snackBarContent(false, response.response.data.message)
      }
    } else {
      // console.log("----values create data", values)
      let response = await postRequest(`${configUrl.addCompanyDetails}`, values)
      if (response.status == 200 || response.status == 201) {
        // console.log("🚀 ~ file: CompanyDetails.js:118 ~ AddCompanyDetails ~ response:", response)
        snackBarContent(true, response.data.message)
        setIsShowDetails(false)
        window.location.reload();
      } else if (response.response.data.status == 400 || response.response.data.status == 409) {
        snackBarContent(false, response.response.data.message)
      }
    }

  }

  const handleSubmit = (values) => {
    // console.log("🚀 ~ file: CompanyDetails.js:187 ~ handleSubmit ~ values:", values)
    AddCompanyDetails(values)
  };

  // Delete Bank Details

  const handleDeleteBanks = async (e, id) => {
    var response = await putRequest(`${configUrl.deleteBankDetails}${id}`)
    if (response.status == 200 || response.status == 201) {
      // console.log("🚀 ~ file: CompanyDetails.js:159 ~ handleDeleteBanks ~ response:", response)
      setSuccess(true)
      setSnackMessage(response.data.message)
      setSnackOpen(true)
      getCompanyDetails()
      setIsShowDetails(true)
      window.location.reload();
    }
  }

  // Delete Company Address
  const handleDeleteAddress = async (e, id) => {
    var response = await putRequest(`${configUrl.deleteCompanyAddress}${id}`)
    if (response.status == 200 || response.status == 201) {
      // console.log("🚀 ~ file: CompanyDetails.js:159 ~ handleDeleteBanks ~ response:", response)
      setSuccess(true)
      setSnackMessage(response.data.message)
      setSnackOpen(true)
      getCompanyDetails()
      setIsShowDetails(true)
      // window.location.reload();
    }
  }

  // Delete Upi Details
  const handleDeleteUpi = async (e, id) => {
    var response = await putRequest(`${configUrl.deleteUpiDetail}${id}`)
    if (response.status == 200 || response.status == 201) {
      console.log("🚀 ~ file: CompanyDetails.js:298 ~ handleDeleteUpi ~ response:", response)
      // console.log("🚀 ~ file: CompanyDetails.js:159 ~ handleDeleteBanks ~ response:", response)
      setSuccess(true)
      setSnackMessage(response.data.message)
      setSnackOpen(true)
      getCompanyDetails()
      setIsShowDetails(true)
    }
  }

  const selectHandler = (data) => {
    // console.log("----data", data)
    setDropDownOption(data)
  }
  // let _companyDetail = companyDetails
  // let _companyDetail = companyDetails?.map((mapVal) => {
  //   let { company_name, company_bank_details, company_address_details } = mapVal
  //   return { company_name, company_bank_details, company_address_details }
  // })
  // console.log("🚀 ~ file: CompanyDetails.js:145 ~ let_companyDetail=companyDetails?.map ~ _companyDetail:", _companyDetail)
  // console.log("🚀 ~ file: CompanyDetails.js:142 ~ CompanyDetails ~ _companyDetail:", _companyDetail)

  // const handleViewClick = (data) => {
  //   console.log("🚀 ~ file: CompanyDetails.js:151 ~ handleViewClick ~ data:", data)
  //   // setIsShowDetails(!isShowDetails);
  //   setCompanyDetails(data)
  // }

  const handleViewClick = (data) => {
    // console.log("🚀 ~ file: CompanyDetails.js:151 ~ handleViewClick ~ data:", data)
    let isShowOrEdit = !isShowDetails
    // console.log("🚀 ~ file: CompanyDetails.js:163 ~ handleViewClick ~ isShowOrEdit:", isShowOrEdit)
    setIsShowDetails(isShowOrEdit);
    // true means edit mode
    // setUpdateClients(isShowOrEdit)
    // setIsShowDetails(!isShowDetails);
    // setCompanyDetails(data)
  }

  const handlesnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const InfoDiv = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <h5 className="text-light text-decoration-underline my-3">Basic Details</h5>
            <div className="row">
              <div className="col-md-6">
                <Input_element
                  input_label={<span className="text-white">Company Name<strong className="text-danger">*</strong></span>}
                  type="text"
                  name="company_name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.company_name}
                  placeholder="Enter company name"
                  formikValidation={
                    <ErrorMessage name="company_name" component="div" className="text-danger position-absolute small" />
                  }
                />
              </div>
              <div className="col-md-6">
                <Input_element
                  input_label={<span className="text-white">website</span>}
                  type="text"
                  name="website"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.website}
                  placeholder="Enter website"
                />
              </div>

              <div className="col-md-6">
                <Input_element
                  input_label={<span className="text-white">Contact Person Name<strong className="text-danger">*</strong></span>}
                  type="text"
                  name="contact_person_name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.contact_person_name}
                  placeholder="Enter contact_person_name"
                  formikValidation={
                    <ErrorMessage name="contact_person_name" component="div" className="text-danger position-absolute small" />
                  }
                />
              </div>
              <div className="col-md-6">
                <Input_element
                  input_label={<span className="text-white">Registration Number</span>}
                  type="text"
                  name="registration_number"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.registration_number}
                  placeholder="Enter registration_number"
                // formikValidation={
                //   <ErrorMessage name="registration_number" component="div" className="text-danger position-absolute small" />
                // }
                />
              </div>

              <div className="col-md-6">
                <Input_element
                  input_label={<span className="text-white">Email<strong className="text-danger">*</strong></span>}
                  type="email"
                  name="email"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.email}
                  placeholder="Enter email"
                  formikValidation={
                    <ErrorMessage name="email" component="div" className="text-danger position-absolute small" />
                  }
                />
              </div>
              <div className="col-md-6">
                <Input_element
                  input_label={<span className="text-white">Contact Number</span>}
                  type="text"
                  // pattern="[0-9]{10}"
                  name="contact_number"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.contact_number}
                  placeholder="Enter contact_number"
                // formikValidation={
                //   <ErrorMessage name="contact_number" component="div" className="text-danger position-absolute small" />
                // }
                />
              </div>
              <div className="col-md-6">
                <Input_element
                  input_label={<span className="text-white">GST No<strong className="text-danger">*</strong></span>}
                  type="text"
                  name="gst_no"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.gst_no}
                  placeholder="Enter gst_no"
                  formikValidation={
                    <ErrorMessage name="gst_no" component="div" className="text-danger position-absolute small" />
                  }
                />
              </div>
              <div className="col-md-6">
                <Input_element
                  input_label={<span className="text-white">SAC Code<strong className="text-danger">*</strong></span>}
                  type="text"
                  name="sac_code"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.sac_code}
                  placeholder="Enter sac_code"
                  formikValidation={
                    <ErrorMessage name="sac_code" component="div" className="text-danger position-absolute small" />
                  }
                />
              </div>
            </div>
            {/* {
              console.log("🚀 ~ file: CompanyDetails.js:416 ~ InfoDiv ~ values:", values)
            } */}
            {/* Company Details */}
            <CompanyAddress
              values={values}
              setFieldValue={setFieldValue}
              handleDeleteAddress={handleDeleteAddress}
              handleChange={handleChange}
              handleBlur={handleBlur}
              // country={country}
              countryList={countryList}
            />

            {/* Bank Information */}
            <CompanyBankDetails
              values={values}
              setFieldValue={setFieldValue}
              handleDeleteBanks={handleDeleteBanks}
              handleChange={handleChange}
              handleBlur={handleBlur}
              // country={country}
              countryList={countryList}
              dropDownOption={dropDownOption}
              selectHandler={selectHandler}
              additionalBankDetails={additionalBankDetails}
              setAdditionalBankDetails={setAdditionalBankDetails}
              options={options}
            />

            {/* UPI Details */}
            <div className="upi-details">
              <h5 className="text-light text-decoration-underline my-3">UPI Details</h5>
              <FieldArray name="upi_details">
                {({ push, remove }) => (
                  <div>
                    {values?.upi_details?.map((states, index) => (
                      <div key={index} className="row border pt-3 mb-3">
                        <div className="col-md-5">
                          <Input_element
                            input_label={<span className="text-white">UPI Name</span>}
                            type="text"
                            id={`upi_details.${index}.upi_name`}
                            name={`upi_details.${index}.upi_name`}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.upi_details[index].upi_name}
                          />
                        </div>
                        <div className="col-md-5">
                          <Input_element
                            input_label={<span className="text-white">UPI EmailId</span>}
                            type="text"
                            id={`upi_details.${index}.upi_email`}
                            name={`upi_details.${index}.upi_email`}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.upi_details[index].upi_email}
                          />
                        </div>
                        <div className="col-md-5">
                          <Input_element
                            input_label={<span className="text-white">UPI Link</span>}
                            type="text"
                            id={`upi_details.${index}.upi_link`}
                            name={`upi_details.${index}.upi_link`}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.upi_details[index].upi_link}
                          />
                        </div>

                        <div className="col-md-2 my-auto">
                          {values.upi_details.length > 1 ? <button type="button" className="bg-transparent border-0" onClick={(e) => {
                            remove(index)
                            if (values.upi_details[index].id) {
                              handleDeleteUpi(e, values.upi_details[index].id)
                            }
                          }
                          }>
                            <img src={Images.deleteLogo} alt="icon" />
                          </button> : null}
                        </div>
                      </div>
                    ))}

                    {/* {updateClients ? null :  */}
                    <button type="button" className="btn btn-light"
                      onClick={() => push({ upi_name: "", upi_email: "", upi_link: "" })}>
                      Add More
                    </button>
                    {/* } */}
                  </div>
                )}
              </FieldArray>
            </div>
            {/* </>
              )
            })} */}
            <div className="col-md-12">
              <div className="text-end">
                <button type='submit' className='btn btn-success'>Submit</button>
              </div>
            </div>
          </Form >
        )}
      </Formik >
    );
  };

  return (
    <>
      <div className="companyDetails">
        <div className="d-md-flex justify-content-between mt-4">
          <h3>Company Profile</h3>
          <div>
            <Link to="/" className="goback">Back to Dashboard</Link>
            <div className="text-end">
              <button
                className="btn btn-primary"
                // onClick={() => {
                //   setIsShowDetails(!isShowDetails);
                //   handleViewClick(companyDetails?.length > 0 ? companyDetails[0] : []);
                // }}
                onClick={handleViewClick}
              >
                {/* {isShowDetails
                  ? `Show Details${companyDetails?.length}`
                  : `${companyDetails?.length > 0 ? `Edit Details ${companyDetails?.length}` : `Add Details${companyDetails?.length}`}`} */}
                {isShowDetails
                  ? 'View Details' : (updateClients ? `Edit Details ` : 'Add Detail')
                }
              </button>
              {/* <button
                className="btn btn-primary"
                onClick={() => {
                  console.log("Button clicked. isShowDetails:", isShowDetails);
                  setIsShowDetails((isShowDetails) => !isShowDetails);
                  console.log("After setIsShowDetails. isShowDetails:", isShowDetails);

                  const detailsToPass = companyDetails?.length > 0 ? companyDetails[0] : {};
                  console.log("Details to pass:", detailsToPass);

                  handleViewClick(detailsToPass);
                }}
              >
                {isShowDetails ? "Show Details" : `${companyDetails?.length > 0 ? "Edit Details" : "Add Details"}`}
              </button> */}
              {/* <button
                className="btn btn-primary"
                onClick={() => {
                  setIsShowDetails(!isShowDetails);
                  handleViewClick(companyDetails?.length > 0 ? companyDetails[0] : {});
                }}>
                {isShowDetails ? "Show Details" : `${companyDetails?.length > 0 ? "Edit Details" : "Add Details"}`}
              </button> */}
            </div>
          </div>
        </div>

        {isShowDetails ? (
          <>
            <div className="companyCard">{InfoDiv()}</div>
          </>
        ) : (
          <>

            {

              companyDetails?.length > 0 ? <>
                <div className="companyCard">
                  {companyDetails.map((item, index) => {
                    return (
                      <>
                        <div className="">
                          <h5 className="my-3 text-decoration-underline font_color">Basic Details</h5>
                          <div className="row">
                            <div className="col-md-6">
                              <p className="font_color">
                                Company Name :  <strong className="text-capitalize"> {item?.company_name}</strong>
                              </p>
                            </div>
                            {
                              item.website == "" ? null :
                                <div className="col-md-6">
                                  <p className="font_color">
                                    Website : <strong> {item?.website}</strong>
                                  </p>
                                </div>
                            }
                            <div className="col-md-6">
                              <p className="font_color">
                                Contact Person Name : <strong> {item?.contact_person_name}</strong>
                              </p>
                            </div>
                            {
                              item.registration_number == "" ? null :
                                <div className="col-md-6">
                                  <p className="font_color">
                                    Registration Number : <strong> {item?.registration_number}</strong>
                                  </p>
                                </div>
                            }
                            <div className="col-md-6">
                              <p className="font_color">
                                Email : <strong> {item?.email}</strong>
                              </p>
                            </div>

                            {
                              item.contact_number == "" ? null :
                                <div className="col-md-6">
                                  <p className="font_color">
                                    Contact Number : <strong>{item?.contact_number}</strong>
                                  </p>
                                </div>
                            }

                            <div className="col-md-6">
                              <p className="font_color">
                                GST No : <strong> {item?.gst_no}</strong>
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p className="font_color">
                                SAC Code : <strong> {item?.sac_code}</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* Company Address */}
                        <div className="mb-3">
                          <h5 className="mb-3 text-decoration-underline font_color">Company Address</h5>
                          {item?.company_address_details?.map((subItem, index) => {
                            // console.log("🚀 ~ file: CompanyDetails.js:801 ~ {item?.company_address_details?.map ~ subItem:", subItem)
                            return (
                              <>
                                <div className="row border m-2 py-2">
                                  <p className="text-white font-blod fw-bolder mt-2">{subItem?.set_primary_address == 1 ?
                                    <>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                          Primary Address
                                        </label>
                                      </div>
                                    </> : ""}</p>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Building No. : <strong> {subItem?.building}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Street : <strong> {subItem?.street}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      City : <strong> {subItem?.city}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Pincode : <strong> {subItem?.pincode}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      State : <strong> {subItem?.state_name}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Country : <strong> {subItem?.country_name}</strong>
                                    </p>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>

                        {/* Bank Information */}

                        <div className="mb-3">
                          <h5 className="mb-3 text-decoration-underline font_color">Bank Information</h5>
                          {item?.company_bank_details?.map((subItem, index) => {
                            // console.log("🚀 ~ file: CompanyDetails.js:575 ~ {item?.company_bank_details?.map ~ subItem:", subItem)
                            return (
                              <>
                                <div className="row border m-2 py-2">
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Bank Name : <strong> {subItem?.bank_name}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Branch Name : <strong> {subItem?.branch}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Beneficiary Name : <strong> {subItem?.beneficiary}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Account No : <strong> {subItem?.account_no}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      IFSC : <strong> {subItem?.ifsc}</strong>
                                    </p>
                                  </div>
                                  {
                                    subItem.swift_code == "" ? null :
                                      <div className="col-md-6">
                                        <p className="font_color">
                                          Swift code : <strong> {subItem?.swift_code}</strong>
                                        </p>
                                      </div>
                                  }
                                  {/* <div className="col-md-6">
                                    <p className="font_color">
                                      Swift code : <strong> {subItem?.swift_code}</strong>
                                    </p>
                                  </div> */}
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      City : <strong> {subItem?.city}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      State : <strong> {subItem?.state_name}</strong>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="font_color">
                                      Country : <strong> {subItem?.country_name}</strong>
                                    </p>
                                  </div>
                                  {subItem?.purpose_code == "" ? null :
                                    <div className="col-md-6">
                                      <p className="font_color">
                                        Purpose Code : <strong> {subItem?.purpose_code}</strong>
                                      </p>
                                    </div>
                                  }
                                  {subItem?.purpose_remittance == "" ? null :
                                    <div className="col-md-6">
                                      <p className="font_color">
                                        Purpose Remittance : <strong> {subItem?.purpose_remittance}</strong>
                                      </p>
                                    </div>
                                  }

                                  {/* <h5>Additional Information:</h5>  */}
                                  {subItem?.correspondentBank == "" ? null :
                                    <div className="col-md-6">
                                      <p className="font_color">
                                        correspondentBank  : <strong> {subItem?.correspondentBank}</strong>
                                      </p>
                                    </div>
                                  }
                                  {subItem?.correspondentSwiftCode == "" ? null :
                                    <div className="col-md-6">
                                      <p className="font_color">
                                        Correspondent Bank Swift Code : <strong> {subItem?.correspondentSwiftCode}</strong>
                                      </p>
                                    </div>
                                  }
                                  {subItem?.correspondentAccountNumber == "" ? null :
                                    <div className="col-md-6">
                                      <p className="font_color">
                                        Correspondent AccountNumber : <strong> {subItem?.correspondentAccountNumber}</strong>
                                      </p>
                                    </div>
                                  }

                                  {subItem.additionalInformation == "" ? null :
                                    <div className="col-md-6">
                                      <p className="font_color">
                                        Additional Bank Details : {subItem.additionalInformation.length > 0 ?
                                          subItem?.additionalInformation?.map((subItem, index) => {
                                            // console.log("----subItem", subItem?.additionalAccountValue)
                                            return (
                                              <>
                                                <strong>{index + 1}.{subItem?.additionalAccountValue}</strong>
                                                <br />
                                              </>
                                            )
                                          }) : null
                                        }


                                        {/* {subItem?.additionalBankDetails.map((subItem, index) => {
                               console.log("🚀 ~ file: CompanyDetails.js:717 ~ {item?.company_bank_details?.map ~ subItem:", subItem)
                               // console.log("🚀 ~ file: CompanyDetails.js:801 ~ {item?.company_address_details?.map ~ subItem:", subItem)
                               return (
                                 <>
                                   <strong>{index+1}.{subItem.additionalAccountValue}</strong>
                                   <br />
                                 </>
                               )
                             })} */}
                                      </p>
                                    </div>
                                  }


                                </div>
                              </>
                            )
                          })}
                        </div>

                        {/* UPI Details */}
                        <div className="mb-3">
                          <h5 className="mb-3 text-decoration-underline font_color">UPI Details</h5>
                          {item?.upi_details?.map((subItem, index) => {
                            // console.log("🚀 ~ file: CompanyDetails.js:801 ~ {item?.company_address_details?.map ~ subItem:", subItem)
                            return (
                              <>
                                <div className="row border m-2 py-2">
                                  {
                                    subItem.upi_name == "" ? null :
                                      <div className="col-md-6">
                                        <p className="font_color">
                                          UPI Name : <strong> {subItem?.upi_name}</strong>
                                        </p>
                                      </div>
                                  }
                                  {
                                    subItem?.upi_email == "" ? null :
                                      <div className="col-md-6">
                                        <p className="font_color">
                                          UPI Email : <strong> {subItem?.upi_email}</strong>
                                        </p>
                                      </div>
                                  }
                                  {
                                    subItem?.upi_link == "" ? null :
                                      <div className="col-md-6">
                                        <p className="font_color">
                                          UPI Link : <strong> {subItem?.upi_link}</strong>
                                        </p>
                                      </div>
                                  }
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </>
                    )
                  })}
                </div>
              </> : <>
                <div className="text-center my-5">
                  <h4>Add Company details</h4>
                </div>
              </>
            }

          </>
        )}
      </div >
      <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
        snackbg={success} message={snackMessage} />
    </>
  );
};
