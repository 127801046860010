import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { RouteStrings } from "../../utils/common";
import { setDropdownlist } from "../../store/reducers/ui.reducer";
import { configUrl } from "../../apis/api.config";
import { getRequest } from "../../apis/Services";
import './Dashboard.scss'
import { SubMenu } from "../../components/Sidebar/submenu";

const Dashboard = () => {
  const { auth: { isAuth }, userInfo } = useSelector((state) => state.UIStore);

  var organisation_name = userInfo ? userInfo.organization_name : null


  const params = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    getDropdownData()
  }, [])
  const getDropdownData = async () => {
    var response = await getRequest(configUrl.getAllDropdowns)
    dispatch(setDropdownlist({
      dropdownList: response?.data?.data
    }))
  }

  return isAuth ? (
    <>
      <div className="row g-0 m-70">
        <div className="col-md-2">
          <Sidebar />
          {/* <SubMenu /> */}

        </div>
        <div className="col-md-10">
          <div className="px-3">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <Navigate to={organisation_name || params["*"] ? `/${organisation_name || params["*"].split("/")[0]}/login` : '/login'} />
      {/* // <Navigate to={RouteStrings.login} /> */}
    </>
  );
};

export default Dashboard;
