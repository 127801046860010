import React, { useEffect, useRef, useState } from "react";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { Tabs, Tab } from 'react-bootstrap';
import { PurchaseBilling } from "./PurchaseBilling";
import { ServiceBilling } from "./ServiceBilling";
import { AssignProductService } from "./AssignProductService";
// import Test from "./Test";


export const InventoryBilling = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { getRequest, postRequest } = PostRequestHook()
    const [vendors, setVendors] = useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
        getDropdowns()
    }, [])
    const getDropdowns = async () => {
        var response = await getRequest(configUrl.getInventoryDropdowns)
        if (response?.data?.payload) {
            setVendors(response?.data?.payload.vendorList)
            setProducts(response?.data?.payload.productList)
        }
    }

    return (
        <>
            <div className="leves_style px-md-2 px-md-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="header_color">Inventory Purchase/Service Provider Billing List</h3>
                    </div>
                </div>
            </div>
            <div div className='userprofile_div biiling_width rounded' >
                <Tabs defaultActiveKey="tab1" id="my-tabs" className='profile_tab'>
                    <Tab eventKey="tab1" className='font_color' title="Purchase Billing">
                        <PurchaseBilling vendorData={vendors} productData={products} />
                    </Tab>
                    <Tab eventKey="tab2" title="Service Billing">
                        {/* <Test vendorData={vendors} productData={products} /> */}
                        <ServiceBilling vendorData={vendors} productData={products} />
                    </Tab>
                    <Tab eventKey="tab3" title="Assign Products to Service">
                        <AssignProductService vendorData={vendors} productData={products} />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
};
