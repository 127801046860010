import React from "react";

export const Checkbox = React.forwardRef(({ indeterminate, handleUpdateClick, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  return (
    <>
      <input type="checkbox" onClick={handleUpdateClick} ref={resolvedRef} {...rest} />
    </>
  );
});
