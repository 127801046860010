import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { Selectelement } from "../../../components/Select_field/Selectelement";
import { PostRequestHook } from "../../../apis/Services";
import { useSelector } from "react-redux";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { Input_element } from "../../../components/input_field/Input_element";
import { ProductTable } from "./ProductTable";
import { configUrl } from "../../../apis/api.config";

export const InventoryProduct = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null

    const { postRequest, putRequest } = PostRequestHook()
    const [stockList, setStockList] = useState(false);

    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [updateVendor, setUpdateVendor] = useState(false)
    const [updateId, setUpdateId] = useState("")



    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }

    const formik = useFormik({
        initialValues: {
            productName: "",
            productCode: "",
            productType: "",
            productDescription: ""
        },
        validate: (values) => {
            let errors = {};
            if (!values.productName) {
                errors.productName = "Required";
            }
            if (!values.productCode) {
                errors.productCode = "Required";
            }
            if (!values.productType) {
                errors.productType = "Required";
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            var response = updateVendor ? await putRequest(`${configUrl.updateProduct}${updateId}`, values)
                : await postRequest(configUrl.createProduct, values)
            if (response.status == 201 || response.status == 200) {
                snackBarContent(true, response.data.message)
                setStockList(false)
                setUpdateVendor(false)
                resetForm()
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                snackBarContent(false, response.response.data.message)
            }
        },
    });

    const handleViewClick = (cell) => {
        setUpdateId(cell.cell.row.original.id)
        var { id, status, created_by, created_at, updated_at, updated_by, ...rest } = cell.cell.row.original
        formik.setValues(rest)
        setUpdateVendor(true)
        setStockList(!stockList)
    }

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <div className="leves_style px-md-2 px-md-4">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <h3 className="header_color">{stockList ? 'Add Inventory Product Category' : 'Inventory Product Category List'}</h3>
                    </div>
                </div>
                <div className="text-end">
                    {/* <Link to="/" className="goback">
                        Back to Dashboard
                    </Link> */}
                    {/* {stockList ? */}
                    <Button
                        variant="primary"
                        onClick={() => {
                            setStockList(!stockList);
                            setUpdateVendor(false)
                            formik.setValues({
                                productName: "",
                                productCode: "",
                                productType: "",
                                productDescription: ""
                            })
                            formik.setTouched({})
                        }}
                    >
                        {stockList ? "Product Category List" : "Add New Product Category"}
                    </Button>
                    {/* : null} */}
                </div>

                <div>
                    {stockList ? (
                        <div className="test_bg p-md-4 mt-4">
                            {/* <h4 className="mb-4">Update the Stock item</h4> */}
                            <Form
                                onSubmit={formik.handleSubmit}
                                autoComplete="off">
                                <div className="mb-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Product Category Name <strong className="text-danger">*</strong></>}
                                                type="text"
                                                name="productName"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.productName}
                                                placeholder="Enter Product name"
                                                formikValidation={
                                                    formik.touched.productName &&
                                                        formik.errors.productName ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.productName}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input_element
                                                input_label={<>Product Base Code <strong className="text-danger">*</strong></>}
                                                type="text"
                                                name="productCode"
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.productCode}
                                                placeholder="Enter Product code"
                                                formikValidation={
                                                    formik.touched.productCode &&
                                                        formik.errors.productCode ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.productCode}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Selectelement
                                                select_Label={<>Product Type <strong className="text-danger">*</strong></>}
                                                name="productType"
                                                optionArray={
                                                    <>
                                                        {["Hardware", "Software", "Other"].length == 0 ?
                                                            null : <>
                                                                {["Hardware", "Software", "other"].map((leave, i) => {
                                                                    return (
                                                                        <option key={i} value={leave}>
                                                                            {leave}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </>}

                                                    </>
                                                }
                                                handleChange={formik.handleChange}
                                                handleBlur={formik.handleBlur}
                                                value={formik.values.productType}
                                                placeholder="Select Product type"
                                                formikValidation={
                                                    formik.touched.productType &&
                                                        formik.errors.productType ? (
                                                        <small className="text-danger position-absolute">
                                                            {formik.errors.productType}
                                                        </small>
                                                    ) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ minHeight: "60px" }}
                                                    name="productDescription"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.productDescription}
                                                    placeholder="Enter Description"
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end mt-4">
                                    <Button type="submit">
                                        {updateVendor ? "Update" : "Add"}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <div className="leavesTable mt-5">
                            <ProductTable onViewClick={handleViewClick} />
                        </div>
                    )}
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

            {/* <Modal backdrop="static" centered show={superAdminModal} onHide={() => setSuperAdminModal(false)}>
                <Modal.Body className="bg-light rounded">
                    <h5>Please update Your details</h5>
                    <div className="text-end mt-3">
                        <a className="btn btn-success" href={RouteStrings.userprofile}>Update Details</a>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    );
};
