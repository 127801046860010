import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik, FieldArray, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useSelector } from "react-redux";
// import Select from 'react-select';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PostRequestHook } from "../../../apis/Services";
import { Selectelement } from "../../../components/Select_field/Selectelement";
import { Input_element } from "../../../components/input_field/Input_element";
import { configUrl } from "../../../apis/api.config";
import { SnackBar } from "../../../components/SnackBars/SnackBar";
import { Images } from "../../../utils/images";
import { InvoiceList } from "./InvoiceList";
// import { Link } from "react-router-dom";
// import { RegEx } from "../../../utils/RegEx";

// import { useRef } from "react";

export const CreateInvoice = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    const { getRequest, postRequest, putRequest } = PostRequestHook()
    const [stockList, setStockList] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [editInvoice, setEditInvoice] = useState(false);
    const [editDetails, setEditDetails] = useState({});
    console.log("🚀 ~ file: CreateInvoice.js:30 ~ editDetails:", editDetails)
    const [invoiceDropdowns, setInvoiceDropdowns] = useState({});
    console.log("🚀 ~ file: CreateInvoice.js:31 ~ invoiceDropdowns:", invoiceDropdowns)
    // console.log("🚀 ~ file: CreateInvoice.js:29 ~ invoiceDropdowns:", invoiceDropdowns)
    const [isGst, setGst] = useState(true)
    const [clientDetail, setclientDetail] = useState({});
    // const [stateListArr, setstateListArr] = useState([])

    // console.log("🚀 ~ file: CreateInvoice.js:35 ~ clientDetail:", clientDetail)
    // const [errorLogs, setErrorLogs] = useState(error);
    // console.log("🚀 ~ file: CreateInvoice.js:31 ~ clientDetail:", clientDetail)
    useEffect(() => { getInvoiceDropdowns() }, [])

    // get the data of dropdowns
    const getInvoiceDropdowns = async () => {
        let response = await getRequest(configUrl.getInvoiceDropdowns)
        setInvoiceDropdowns(response?.data?.data ? response?.data?.data : {})
    }


    //  year and months dropdown
    const getYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        const yearsToShow = 10;

        for (let year = currentYear - yearsToShow; year <= currentYear + yearsToShow; year++) {
            years.push(year);
        }
        return years
    }
    const months = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];



    // Scroll To Top
    const getFieldErrorNames = (formikErrors) => {
        const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
            Object.keys(obj).forEach((key) => {
                const value = obj[key]
                if (!value) return
                const nextKey = prefix ? `${prefix}.${key}` : key
                if (typeof value === 'object') {
                    transformObjectToDotNotation(value, nextKey, result)
                } else {
                    result.push(nextKey)
                }
            })
            // console.log("------------------------------", result)
            return result
        }
        return transformObjectToDotNotation(formikErrors)
    }
    // console.log("🚀 ~ file: CreateInvoice.js:82 ~ getFieldErrorNames ~ getFieldErrorNames:", getFieldErrorNames)
    const ScrollToFieldError = ({
        scrollBehavior = { behavior: 'smooth', block: 'center' },
    }) => {
        const { submitCount, isValid, errors } = useFormikContext();
        // console.log("🚀 ~ file: CreateInvoice.js:89 ~ errors:", errors)
        useLayoutEffect(() => {
            if (isValid) return;
            else {
                // console.log("===================================", isValid)
                const fieldErrorNames = getFieldErrorNames(errors);
                // console.log("🚀 ~ file: AddClient.js:102 ~ useEffect ~ fieldErrorNames:", fieldErrorNames[0]);
                if (fieldErrorNames.length <= 0) return;
                /**
                 *  if errors exist, then get the error
                 */
                // const element = document.getElementsByName(`input[name='${fieldErrorNames[0]}']`);
                let element = document.getElementsByName(`${fieldErrorNames[0]}`);
                // console.log("&&&&&&&&&🚀 ~ file: AddClient.js:107 ~ useEffect ~ element:", element);
                if (!element?.[0]) return;
                // Scroll to first known error into view
                element = element[0]
                element.scrollIntoView(scrollBehavior);
                // // Formik doesn't (yet) provide a callback for a client-failed submission,
                // // thus why this is implemented through a hook that listens to changes on
                // // the submit count.
            }
        }, [submitCount, isValid, errors]);
        return null;
    };


    // form validation
    const validationSchema = Yup.object({
        client_id: Yup.string().required("Required"),
        bank_details_id: Yup.string().required("Required"),
        currency: Yup.string().required("Required"),
        invoice_month: Yup.string().required("Required"),
        invoice_year: Yup.string().required("Required"),
        group1: Yup.string().required("Required"),
        group2: Yup.string().optional(),
        // deduction_value: Yup.string().matches,
        invoice_particulars: Yup.array().of(
            Yup.object().shape({
                billing_type_id: Yup.string().required("Required"),
                rate: Yup.number().required("Required"),
                quantity: Yup.number().required("Required"),
                set_primary_contact: Yup.string(),
            })
        ),
    }).test('gs', (value, context) => {
        if (value.group1 == 0 && !value.group2) {
            return context.createError({ message: "Required", path: "group2", params: "group2" })
        }
        return true
    });
    // console.log("🚀 ~ file: CreateInvoice.js:88 ~ validationSchema:", validationSchema)
    // console.log("🚀 ~ file: CreateInvoice.js:82 ~ validationSchema:", validationSchema)

    // form initial values
    const initialValues = {
        client_id: editInvoice ? editDetails.client_id : "",
        // `${editDetails?.bank_details_id}-${editDetails?.isBankDetails}`
        // bank_details_id: editInvoice ? `${editDetails?.insertBankData}` : "",
        bank_details_id: editInvoice ? `${editDetails.bank_details_id}-${editDetails.isBankDetails}` : "",
        currency: editInvoice ? editDetails.currency : "",
        group1: editInvoice ? (editDetails.igst == "0" && editDetails.cgst_sgst == "0") ? "1" : "0" : "",
        group2: editInvoice ? (editDetails.cgst_sgst == "1" ? "1" : "0") : "",
        deduction_value: editInvoice ? editDetails.deduction_value : "",
        deduction_note: editInvoice ? editDetails.deduction_note : "",
        tds_value: editInvoice ? editDetails.tds_value : "",
        tds_note: editInvoice ? editDetails.tds_note : "",
        invoice_month: editInvoice ? editDetails.invoice_month : "",
        invoice_year: editInvoice ? editDetails.invoice_year : "",
        purpose_code: "P1007 (IN)",
        invoice_particulars: editInvoice ? editDetails.invoice_particulars : [{
            billing_type_id: '', specification: '', description: '', rate: '',
            quantity: "", total: "", deduction_value: "", deduction_note: ""
        }],
    };
    // console.log("🚀 ~ file: CreateInvoice.js:103 ~ initialValues:", initialValues)

    // alert bar to show error and success or error message
    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }
    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    // invoice amoount calculation
    const handleTotalCalculation = (particularValue, discount, gst, tds) => {
        let gstTotal = (gst == 0) ? 18 : 0
        const updatedData = particularValue.map(item => ({
            ...item,
            deduction_value: item.deduction_value ? parseFloat(item.deduction_value) : 0,
            total: ((item.rate * item.quantity) - (item?.deduction_value || 0))
        }));
        const particularTotalSum = updatedData.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);
        const totalSum = particularTotalSum - (tds ? Number(tds) : 0) - (discount ? Number(discount) : 0)
        const totalCalculation = totalSum + (gst ? ((Number(gstTotal) / 100) * totalSum) : 0)
        const _totalCalculation = totalCalculation < 0 ? 0 : totalCalculation
        return _totalCalculation
    }
    const handleParticularCalculation = (rate, quantity, deduction) => {
        let particularValue = ((Number(rate) * Number(quantity)) - Number(deduction)).toFixed(2)
        return particularValue < 0 ? 0 : particularValue
    }
    // function useScrollToError(errors) {
    //     useEffect(() => {
    //         const errorsvalues = Object.values(errors)
    //         console.log("🚀 ~ file: CreateInvoice.js:265 ~ useEffect ~ errorsvalues:", errorsvalues)
    //         if (errorsvalues.length > 0) {
    //             errorsvalues[0].ref.scrollIntoView({ behavior: 'smooth' })
    //         }
    //     }, [errors])
    // }


    // const FocusErrorLogs = (errors) => {
    //     console.log("🚀 ~ file: CreateInvoice.js:156 ~ FocusErrorLogs ~ errors:", errors)
    //     useEffect(() => {
    //         const errorsvalues = Object.values(errors)
    //         console.log("🚀 ~ file: CreateInvoice.js:255 ~ useEffect ~ errorsvalues:", errorsvalues)
    //         if (errorsvalues.length > 0) {
    //             errorsvalues[0].ref.scrollIntoView({ behavior: 'smooth' })
    //         }
    //     }, [errors])
    // }

    // Inside your component, use useEffect to add the event listener
    useEffect(() => {
        const inputElement = document.querySelector('[name="deduction_value"]');
        const preventScroll = (e) => {
            e.preventDefault();
        };

        if (inputElement) {
            inputElement.addEventListener('wheel', preventScroll);
            return () => {
                // Clean up the event listener when the component unmounts
                inputElement.removeEventListener('wheel', preventScroll);
            };
        }
    }, []);


    // create Invoice
    const handleSubmit = (values) => {
        let particular_deduction_value = values.invoice_particulars.map(item => {
            item = {
                ...item, deduction_value: item.deduction_value ? item.deduction_value.toString() : "0",
                // total: ((item.rate * item.quantity) - (item.deduction_value || 0)).toString(),
                rate: item.rate.toString(),
                quantity: item.quantity.toString()
            }
            delete item.total
            return item
        })
        let value1 = {
            ...values, igst: (values.group1 == "0" && values.group2 == "0") ? "1" : "0",
            cgst_sgst: (values.group1 == "0" && values.group2 == "1") ? "1" : "0",
            deduction_value: values.deduction_value ? values.deduction_value.toString() : "0",
            tds_value: (values.group1 == "1" && values.tds_value) ? values.tds_value.toString() : "0",
            invoice_particulars: particular_deduction_value,
        }
        delete value1.group1
        delete value1.group2
        createInvoice(value1)
        // FocusErrorLogs()
    };
    const createInvoice = async (data) => {
        let response = editInvoice ? await putRequest(`${configUrl.updateInvoice}${editDetails.id}`, data) : await postRequest(configUrl.createInvoice, data)
        if (response.status == 200 || response.status == 201) {
            // setEditInvoice(true)
            snackBarContent(true, response.data.message)
            setStockList(false)
            setEditInvoice(false)
        } else if (response.response.data.status == 400 || response.response.data.status == 404 || response.response.data.status == 409) {
            snackBarContent(false, response.response.data.message)
        }
    }

    // individual data of the particular invoice
    const handleEditClick = (data) => {
        let data1 = data.invoice_particulars.map(item => {
            delete item.billing_type_name
            return item
        })
        data = { ...data, invoice_particulars: data1 }
        setEditDetails(data)
        setStockList(!stockList)
        setEditInvoice(true)
        setGst(data.igst == "0" && data.cgst_sgst == "0" ? false : true)
        let _data = invoiceDropdowns.getClient.filter(item => item.id == data.client_id)
        setclientDetail(_data.length > 0 ? _data[0] : {})
    }

    // redirect to the invoice form 
    const handleNewInvoice = () => {
        setStockList(!stockList);
        setEditInvoice(false)
        setEditDetails({})
        setclientDetail({})
    }

    // Delete Invoice Particulars
    const handleDeleteInvoiceParticulars = async (e, id) => {
        var response = await putRequest(`${configUrl.deleteInvoiceParticular}${id}`)
        if (response.status == 200 || response.status == 201) {
            // console.log("🚀 ~ file: CompanyDetails.js:159 ~ handleDeleteBanks ~ response:", response)
            setSuccess(true)
            setSnackMessage(response.data.message)
            setSnackOpen(true)
            // getCompanyDetails()
            // setIsShowDetails(true)
            window.location.reload();
        }
    }

    // document.addEventListener('DOMContentLoaded', () => {
    //     console.log('DOM is ready');
    //     const numberInput = document.getElementById('yourNumberInputId');
    //     if (numberInput) {
    //         numberInput.addEventListener('wheel', (e) => {
    //             e.preventDefault();
    //         });
    //     }
    // });

    return (
        <>
            <div className="my-5">
                <div>
                    {stockList ? (
                        <>
                            <div className="d-flex justify-content-between mt-4">
                                <div>
                                    <h3 className="">{stockList ? 'Create Invoice' : 'Invoice List'}</h3>
                                </div>
                                <div className="text-end">
                                    {/* <div>
                                        <Link id="dashboardbtn" to="/" className="goback mb-2">
                                            Back to Dashboard
                                        </Link>
                                    </div> */}
                                    <div>
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                handleNewInvoice()
                                                setclientDetail({})
                                            }}
                                        >
                                            {stockList ? "Invoice List" : "Create Invoice"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="test_bg p-md-4 mt-4 p-3">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                // onSubmit={async (handleSubmit) => {
                                //     await new Promise((resolve) => setTimeout(resolve, 500));
                                //     alert(JSON.stringify(handleSubmit, null, 2));
                                // }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldError, validateForm }) => {
                                        // console.log("---------------- errors", errors)
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <h4 className="text-white">Basic Info</h4>
                                                <ScrollToFieldError />
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Selectelement
                                                            optionArray={
                                                                <>
                                                                    {invoiceDropdowns?.getClient.map((item, i) => {
                                                                        // console.log("🚀 ~ file: CreateInvoice.js:240 ~ {invoiceDropdowns?.getClient.map ~ item:", item)
                                                                        // {invoiceDropdowns?.getClient > 0 && invoiceDropdowns?.getClient.map((item, i) => {
                                                                        // console.log("🚀 ~ file: CreateInvoice.js:237 ~ { ~ item:", item)
                                                                        return (
                                                                            <option key={i} value={item?.id}>
                                                                                {item?.company_name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </>}
                                                            select_Label={<>Client<strong className="text-danger">*</strong></>}
                                                            name="client_id"
                                                            handleChange={(e) => {
                                                                handleChange(e)
                                                                let _data = invoiceDropdowns.getClient.filter(item => item?.id == e.target.value)
                                                                // console.log("🚀 ~ file: CreateInvoice.js:255 ~ {invoiceDropdowns?.getClient.map ~ _data:", _data)
                                                                setclientDetail(_data.length > 0 ? _data[0] : {})
                                                                if (_data.length > 0) {
                                                                    if (_data[0].client_type == 1) {
                                                                        setFieldValue("group1", "0")
                                                                        setGst(true)
                                                                    } else if (_data[0].client_type == 2) {
                                                                        setFieldValue("group1", "1")
                                                                        setGst(false)
                                                                    }
                                                                }
                                                            }}
                                                            handleBlur={handleBlur}
                                                            value={values.client_id}
                                                            formikValidation={
                                                                <ErrorMessage name="client_id" component="div" className="text-danger position-absolute small" />
                                                            } />
                                                    </div>
                                                    <div className="col-md-6">
                                                        {
                                                            console.log("===Invoice", values?.bank_details_id)}
                                                        <Selectelement
                                                            optionArray={
                                                                <>
                                                                    {(invoiceDropdowns.getBankDetails && invoiceDropdowns.getBankDetails).map((item, i) => {
                                                                        console.log("🚀 ~ file: CreateInvoice.js:407 ~ { ~ item:", item)
                                                                        return (
                                                                            // <option key={i} value={item.isBankDetails}>
                                                                            < option key={i} value={`${item?.id}-${item?.isBankDetails}`}>
                                                                                {
                                                                                    item.isBankDetails == 1 ?
                                                                                        <>{`${item.bank_name} - ${item.ifsc}`}</>
                                                                                        : <>{item.upi_name}</>
                                                                                }
                                                                            </ option>
                                                                        );
                                                                    })}
                                                                </>}
                                                            select_Label={<>Bank Details<strong className="text-danger">*</strong></>}
                                                            name="bank_details_id"
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            value={values?.bank_details_id}
                                                            // defaultValue={values?.bank_details_id}
                                                            formikValidation={
                                                                <ErrorMessage name="bank_details_id" component="div" className="text-danger position-absolute small" />
                                                            } />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Input_element
                                                            input_label={<>Client Type</>}
                                                            type="text"
                                                            name="client_type"
                                                            value={clientDetail?.client_type_name ? clientDetail?.client_type_name : ''}
                                                            placeholder="Select Client Type"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Input_element
                                                            input_label={<>Registration Type</>}
                                                            type="text"
                                                            name="registration_number"
                                                            value={clientDetail?.registration_type_name ? clientDetail?.registration_type_name : ''}
                                                            placeholder="Select Registration Type"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Input_element
                                                            input_label={<>Country</>}
                                                            type="text"
                                                            name="country"
                                                            value={clientDetail?.country_name ? clientDetail?.country_name : ''}
                                                            placeholder="Select Country"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Input_element
                                                            input_label={<>State</>}
                                                            type="text"
                                                            name="state"
                                                            value={clientDetail?.state_name ? clientDetail?.state_name : ''}
                                                            placeholder="Select State"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Selectelement
                                                            optionArray={
                                                                <>
                                                                    {(invoiceDropdowns.currencyData && invoiceDropdowns.currencyData).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item.id}>
                                                                                {item.currency_name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </>}
                                                            select_Label={<>Currency<strong className="text-danger">*</strong></>}
                                                            name="currency"
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            value={values.currency}
                                                            formikValidation={
                                                                <ErrorMessage name="currency" component="div" className="text-danger position-absolute small" />
                                                            } />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Selectelement
                                                            optionArray={
                                                                <>
                                                                    {months.map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </>}
                                                            select_Label={<>Invoice Month<strong className="text-danger">*</strong></>}
                                                            name="invoice_month"
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            value={values.invoice_month}
                                                            formikValidation={
                                                                <ErrorMessage name="invoice_month" component="div" className="text-danger position-absolute small" />
                                                            } />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Selectelement
                                                            optionArray={
                                                                <>
                                                                    {getYears().map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </>}
                                                            select_Label={<>Invoice Year<strong className="text-danger">*</strong></>}
                                                            name="invoice_year"
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            value={values.invoice_year}
                                                            formikValidation={
                                                                <ErrorMessage name="invoice_year" component="div" className="text-danger position-absolute small" />
                                                            } />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Input_element
                                                            input_label={<>Deduction Value<strong className="text-danger"></strong></>}
                                                            type="number"
                                                            name="deduction_value"
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            value={values.deduction_value}
                                                            placeholder="Enter Deduction value"
                                                            className="form-control"
                                                            formikValidation={
                                                                <ErrorMessage name="deduction_value" component="div" className="text-danger position-absolute small" />
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <Form.Group className="mb-2">
                                                            <Form.Label>Deduction Note</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                cols={4}
                                                                rows={4}
                                                                style={{ height: "80px" }}
                                                                name="deduction_note"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.deduction_note}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    {(clientDetail?.client_type == 1) ?
                                                        <div className="col-md-12 mt-4">
                                                            <Form.Group className="mb-2">
                                                                <Form.Label className="me-3"><strong>Tax Applicable :</strong></Form.Label>
                                                                <Form.Check
                                                                    inline
                                                                    label="GST"
                                                                    name="group1"
                                                                    type="radio"
                                                                    value={"0"}
                                                                    className="text-white"
                                                                    onClick={() => setGst(true)}
                                                                    onChange={(e) => {
                                                                        handleChange(e)
                                                                        setFieldValue("tds_value", "")
                                                                    }}
                                                                    checked={values.group1 == "0" ? true : false}
                                                                    disabled={clientDetail?.client_type ? (clientDetail?.client_type == 1 ? false : true) : false}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Non-GST"
                                                                    name="group1"
                                                                    value={"1"}
                                                                    type="radio"
                                                                    className="text-white"
                                                                    onClick={() => setGst(false)}
                                                                    onChange={(e) => {
                                                                        handleChange(e)
                                                                        setFieldError("group2", "")
                                                                        setFieldValue("group2", "")
                                                                    }}
                                                                    checked={values.group1 ? (values.group1 == "0" ? false : true) : false}
                                                                />
                                                            </Form.Group>


                                                            {isGst ?
                                                                <div className="col-md-12 mt-3">
                                                                    <Form.Group className="ms-5 mb-2">
                                                                        <Form.Check
                                                                            inline
                                                                            label="IGST"
                                                                            name="group2"
                                                                            type="radio"
                                                                            value={"0"}
                                                                            className="text-white"
                                                                            onChange={handleChange}
                                                                            defaultChecked={values.group2 == "0" ? true : false}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="CGST+SGST"
                                                                            name="group2"
                                                                            type="radio"
                                                                            value={"1"}
                                                                            className="text-white"
                                                                            onChange={handleChange}
                                                                            defaultChecked={values.group2 == "1" ? true : false}
                                                                        />
                                                                        <ErrorMessage name="group2" component="div" className="text-danger position-absolute small" />

                                                                    </Form.Group>
                                                                </div> : <>{clientDetail?.client_type == 1 ? <>
                                                                    <div className="col-md-6">
                                                                        <Input_element
                                                                            input_label={<>TDS Value<strong className="text-danger"></strong></>}
                                                                            type="number"
                                                                            name="tds_value"
                                                                            handleChange={handleChange}
                                                                            handleBlur={handleBlur}
                                                                            value={values.tds_value}
                                                                            placeholder="Enter"
                                                                            formikValidation={
                                                                                <ErrorMessage name="tds_value" component="div" className="text-danger position-absolute small" />
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="mb-2">
                                                                            <Form.Label>TDS Note</Form.Label>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                cols={4}
                                                                                rows={4}
                                                                                style={{ height: "80px", maxWidth: "400px" }}
                                                                                name="tds_note"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.tds_note}
                                                                            />
                                                                        </Form.Group>
                                                                    </div> </> : null}
                                                                </>}

                                                        </div> : null
                                                    }
                                                </div>

                                                <div className="mb-4">
                                                    <h4 className="text-white mt-4">Invoice Particulars</h4>
                                                    <FieldArray name="invoice_particulars">
                                                        {({ push, remove }) => (
                                                            <div>
                                                                {values.invoice_particulars.map((states, index) => (
                                                                    <div key={index} className="row border pt-3 mb-3">
                                                                        <div className="col-md-4">
                                                                            <Selectelement
                                                                                optionArray={
                                                                                    <>
                                                                                        {(invoiceDropdowns.billingData && invoiceDropdowns.billingData).map((item, i) => {
                                                                                            return (
                                                                                                <option key={i} value={item.id}>
                                                                                                    {item.billing_type_name}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </>}
                                                                                select_Label={<>Billing Type<strong className="text-danger">*</strong></>}
                                                                                name={`invoice_particulars.${index}.billing_type_id`}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                value={values.invoice_particulars[index].billing_type_id}
                                                                                formikValidation={
                                                                                    <ErrorMessage name={`invoice_particulars.${index}.billing_type_id`} component="div" className="text-danger position-absolute small" />
                                                                                } />
                                                                        </div>

                                                                        <div className="col-md-4">
                                                                            <Input_element
                                                                                input_label={<>Specification<strong className="text-danger"></strong></>}
                                                                                type="text"
                                                                                id={`invoice_particulars.${index}.specification`}
                                                                                name={`invoice_particulars.${index}.specification`}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                value={values.invoice_particulars[index].specification}
                                                                                formikValidation={
                                                                                    <ErrorMessage name={`invoice_particulars.${index}.specification`} component="div" className="text-danger position-absolute small" />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <Input_element
                                                                                input_label={<>Description<strong className="text-danger"></strong></>}
                                                                                type="text"
                                                                                id={`invoice_particulars.${index}.description`}
                                                                                name={`invoice_particulars.${index}.description`}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                value={values.invoice_particulars[index].description}
                                                                                formikValidation={
                                                                                    <ErrorMessage name={`invoice_particulars.${index}.description`} component="div" className="text-danger position-absolute small" />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <Input_element
                                                                                input_label={<>Rate<strong className="text-danger">*</strong></>}
                                                                                type="number"
                                                                                id={`invoice_particulars.${index}.rate`}
                                                                                name={`invoice_particulars.${index}.rate`}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                value={values.invoice_particulars[index].rate}
                                                                                formikValidation={
                                                                                    <ErrorMessage name={`invoice_particulars.${index}.rate`} component="div" className="text-danger position-absolute small" />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <Input_element
                                                                                input_label={<>Quantity<strong className="text-danger">*</strong></>}
                                                                                type="number"
                                                                                id={`invoice_particulars.${index}.quantity`}
                                                                                name={`invoice_particulars.${index}.quantity`}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                value={values.invoice_particulars[index].quantity}
                                                                                formikValidation={
                                                                                    <ErrorMessage name={`invoice_particulars.${index}.quantity`} component="div" className="text-danger position-absolute small" />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <Input_element
                                                                                input_label={<>Deduction value<strong className="text-danger"></strong></>}
                                                                                type="number"
                                                                                id={`invoice_particulars.${index}.deduction_value`}
                                                                                name={`invoice_particulars.${index}.deduction_value`}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                value={values.invoice_particulars[index].deduction_value}
                                                                                formikValidation={
                                                                                    <ErrorMessage name={`invoice_particulars.${index}.deduction_value`} component="div" className="text-danger position-absolute small" />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <Input_element
                                                                                input_label={<>Deduction Note<strong className="text-danger"></strong></>}
                                                                                type="text"
                                                                                id={`invoice_particulars.${index}.deduction_note`}
                                                                                name={`invoice_particulars.${index}.deduction_note`}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                value={values.invoice_particulars[index].deduction_note}
                                                                                formikValidation={
                                                                                    <ErrorMessage name={`invoice_particulars.${index}.deduction_note`} component="div" className="text-danger position-absolute small" />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <Input_element
                                                                                input_label={<>Total<strong className="text-danger"></strong></>}
                                                                                type="number"
                                                                                name="total"
                                                                                id={`invoice_particulars${index}.total`}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                                value={(values.invoice_particulars[index].rate && values.invoice_particulars[index].quantity) ?
                                                                                    handleParticularCalculation(values.invoice_particulars[index].rate, values.invoice_particulars[index].quantity,
                                                                                        values.invoice_particulars[index].deduction_value ? values.invoice_particulars[index].deduction_value : "0") : ""}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                        {/* handleDeleteInvoiceParticulars */}
                                                                        <div className="col-md-2 my-auto">
                                                                            {values.invoice_particulars.length > 1 ?
                                                                                <button type="button" className="bg-transparent border-0" onClick={(e) => {
                                                                                    remove(index)
                                                                                    if (values.invoice_particulars[index].id) {
                                                                                        handleDeleteInvoiceParticulars(e, values.invoice_particulars[index].id)
                                                                                    }
                                                                                }}>
                                                                                    <img src={Images.deleteLogo} alt="icon" />
                                                                                </button> : null}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <button type="button" className="btn btn-light"
                                                                    onClick={() => push({
                                                                        billing_type_id: '', specification: '', description: '', rate: '',
                                                                        quantity: "", total: "", deduction_value: "", deduction_note: ""
                                                                    })}>
                                                                    Add More
                                                                </button>
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </div>
                                                <div className="my-5">
                                                    <h4 className="font_color">
                                                        Total Invoice Value = <strong>{handleTotalCalculation(values.invoice_particulars, values.deduction_value, values.group1, 0)}</strong>
                                                    </h4>
                                                    <h4 className="font_color">
                                                        Payable Value = <strong>{handleTotalCalculation(values.invoice_particulars, values.deduction_value, values.group1, values.tds_value)}</strong>
                                                    </h4>
                                                </div>
                                                <div className="d-flex justify-content-end mt-5">
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                </div>
                                                {/* <p className="text-danger text-end">  {errors ? "please fill all fields" : ""}</p> */}

                                            </form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </>
                    ) : (
                        <div className="leavesTable mt-5">
                            <InvoiceList handleEdit={handleEditClick} handleCreateInvoice={() => handleNewInvoice()} />
                        </div>
                    )}
                </div>
            </div >

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    );
};
