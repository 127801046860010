import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { PostRequestHook } from '../../apis/Services'
import { configUrl } from '../../apis/api.config'
import { SnackBar } from '../../components/SnackBars/SnackBar'
import { useSelector } from 'react-redux'
import DatePicker from "react-datepicker";

export const FinancialYear = () => {
    const { userInfo } = useSelector((state) => state.UIStore);
    var organization_name = userInfo ? userInfo.organization_name : null


    const [financeMonth, setFinanceMonth] = useState([])
    const [editMonth, setEditMonth] = useState(false)
    const [updateMonth, setUpdateMonth] = useState(false)

    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [success, setSuccess] = useState(false)

    const { getRequest, postRequest, putRequest } = PostRequestHook()

    useEffect(() => {
        getFinancialYear()
    }, [])


    const getFinancialYear = async () => {
        var response = await getRequest(`${configUrl.getAllLeave}${organization_name}/get-financialyear`)
        let _response = response?.data?.data ? response?.data?.data : []
        if (_response.length > 0) {
            setFinanceMonth(_response)
            setEditMonth(true)
            formik.setFieldValue("financialYearStart", _response?.[0]?.financialYearStart ? new Date(_response[0].financialYearStart) : "")
            formik.setFieldValue("financialYearEnd", _response?.[0]?.financialYearEnd ? new Date(_response[0].financialYearEnd) : "")
        }
    }

    const handleStartDateChange = (selectedDate) => {
        // Calculate one year from the selected start date
        const oneYearLater = new Date(selectedDate);
        oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

        // Set the start date and end date values in Formik
        formik.setValues({
            ...formik.values,
            financialYearStart: selectedDate,
            financialYearEnd: oneYearLater,
        });
    };

    // date format
    const formatDate = (dateString) => {
        if (dateString) {
            var date = new Date(dateString);
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            if (updateMonth) {
                var formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
            } else {
                var formattedDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
            }
            return formattedDate
        } else {
            return ""
        }
    }

    const formik = useFormik({
        initialValues: {
            financialYearStart: "",
            financialYearEnd: ""
        },
        validate: (values) => {
            let errors = {}
            if (!values.financialYearStart) {
                errors.financialYearStart = "Select Month"
            }
            if (!values.financialYearEnd) {
                errors.financialYearEnd = "Select Month"
            }
            return errors
        },
        onSubmit: async (value, { resetForm }) => {
            let payload = {
                ...value, financialYearStart: formatDate(value.financialYearStart),
                financialYearEnd: formatDate(value.financialYearEnd)
            }
            let finaceid = financeMonth.length > 0 ? financeMonth[0].id : ""
            const response = editMonth ? await putRequest(`${configUrl.getAllLeave}${organization_name}/update-financialyear/${finaceid}`, payload) :
                await postRequest(`${configUrl.getAllLeave}${organization_name}/create-financial-year`, payload)
            if (response.status == 201 || response.status == 200) {
                setSuccess(true)
                setSnackMessage(response.data.message)
                getFinancialYear()
                setEditMonth(true)
                setUpdateMonth(false)
                setSnackOpen(true)
            } else if (response.response.data.status == 400 || response.response.data.status == 409) {
                setSuccess(false)
                setSnackMessage(response.response.data.message)
                setSnackOpen(true)
            }
        }
    })

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };


    return (
        <>
            <div className="employeetype_style">
                <div className="d-flex justify-content-between mb-4">
                    <div><h3>Financial Year</h3></div>
                    <div className='text-end'>
                        <Link id='dashboardbtn' to="/" className="goback">Back to Dashboard</Link>
                        <Button id='assignbtn' type='button' onClick={updateMonth ? () => setUpdateMonth(false) : () => setUpdateMonth(true)}>
                            {updateMonth ? "View" : `${editMonth ? "Edit" : "Update"}`}</Button>
                    </div>
                </div>
                <div className='type_form'>
                    {updateMonth ? <Form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="font_color mb-2">Financial Year Start Month</label>
                                <DatePicker
                                    name="financialYearStart"
                                    selected={formik?.values?.financialYearStart ? new Date(formik?.values?.financialYearStart) : ""}
                                    onSelect={(e) => {
                                        formik.setFieldValue("financialYearStart", e);
                                    }}
                                    onChange={(val) => {
                                        formik.setFieldValue("financialYearStart", val ? val : "");
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="setting_field"
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="yyyy-mm-dd"
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="font_color mb-2">Financial Year End Month</label>
                                <DatePicker
                                    name="financialYearEnd"
                                    selected={formik.values.financialYearEnd ? new Date(formik.values.financialYearEnd) : ""}
                                    onSelect={(e) => {
                                        formik.setFieldValue("financialYearEnd", e);
                                    }}
                                    onChange={(val) => {
                                        formik.setFieldValue("financialYearEnd", val ? val : "");
                                    }}
                                    onBlur={formik.handleBlur}
                                    defaultValue={formik.values.financialYearEnd}
                                    className="setting_field"
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="yyyy-mm-dd"
                                />
                            </div>
                        </div>
                        <div className='text-end mt-4'><Button id='submitbtn' type="submit">Submit</Button></div>
                    </Form> : <>
                        <div className=''>
                            <h5 className='text-white'>Financial Year Start Month : <strong>{financeMonth.length > 0 ? formatDate(financeMonth[0].financialYearStart) : "---"}</strong></h5>
                            <h5 className='text-white'>Financial Year End Month : <strong>{financeMonth.length > 0 ? formatDate(financeMonth[0].financialYearEnd) : "---"}</strong></h5>
                        </div>
                    </>}


                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />
        </>
    )
}
