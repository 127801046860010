import React, { useEffect, useMemo, useState } from "react";
import {
    useGlobalFilter,
    useTable,
    usePagination,
    useFilters,
} from "react-table";
import "../../style/tableStyle.scss";
import { GlobalFilter } from "../../utils/GlobalFilter";
import { ColumnFilter } from "../../utils/ColumnFilter";
import Modal from "react-bootstrap/Modal";
import { PostRequestHook } from "../../apis/Services";
import { configUrl } from "../../apis/api.config";
import { useSelector } from "react-redux";
import { SnackBar } from "../../components/SnackBars/SnackBar";
import { Images } from "../../utils/images";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { Selectelement } from "../../components/Select_field/Selectelement";
import { Input_element } from "../../components/input_field/Input_element";
import { useFormik } from "formik";
import { TableNavigation } from "../../utils/TableNavigation";
import './stocktable.scss'

export const StockTable = (props) => {
    const { onViewClick } = props
    const [fieldData, setFieldData] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [labelModal, setLabelModal] = useState(false)
    const [productOptions, setProductOptions] = useState([])
    const [products, setProducts] = useState([])
    const [historyData, setHistoryData] = useState({});
    const [serviceHistoryData, setServiceHistoryData] = useState({});
    const [historyModal, setHistorModal] = useState(false);

    const { deleteRequest, getRequest, putRequest } = PostRequestHook()
    const { userInfo } = useSelector((state) => state.UIStore);
    const organization_name = userInfo ? userInfo.organization_name : null
    const employee_id = userInfo ? userInfo.emp_id : null

    useEffect(() => {
        getTableData()
        getProductDropdowns()
    }, [])

    const getTableData = async () => {
        let response = await getRequest(configUrl.getStockList)
        setFieldData(response?.data?.payload ? response?.data?.payload : [])
    }
    const getProductDropdowns = async () => {
        var response = await getRequest(configUrl.getProduct)
        setProducts(response?.data?.payload ? response?.data?.payload : [])
    }


    const fetchOptionsForProduct = async (productId) => {
        let response = await getRequest(`${configUrl.getUnLabeledProducts}${productId}`)
        let productLabelData = response?.data?.payload ? response?.data?.payload : []
        return productLabelData
    };

    const onHistoryClick = (cellData) => {
        getHistoryData(cellData.cell.row.original.productCode, cellData.cell.row.original.productLabel)
    }

    const getHistoryData = async (id, label) => {
        let response = await getRequest(`${configUrl.getAssignHistoryData}${id}`)
        setHistoryData(response?.data?.payload ? response?.data?.payload : {})
        let serviceResponse = await getRequest(`${configUrl.getServiceHistoryData}${label}`)
        let sentToService = (serviceResponse?.data?.payload ? serviceResponse?.data?.payload : []).filter((item) => item.dateOfReturn === "");
        let previouslyServiced = (serviceResponse?.data?.payload ? serviceResponse?.data?.payload : []).filter((item) => item.dateOfReturn !== "");
        let _serviceHistoryData = { sentToService, previouslyServiced }
        setServiceHistoryData(_serviceHistoryData)
        setHistorModal(true)
    }

    const snackBarContent = (isSuccess, message) => {
        setSuccess(isSuccess)
        setSnackMessage(message)
        setSnackOpen(true)
    }

    // assignlabelnumber to the productCode 
    const formik = useFormik({
        initialValues: {
            productName: "",
            productCode: "",
            productLabelNumber: ""
        },
        validate: (values) => {
            let errors = {};
            if (!values.productName) {
                errors.productName = "Required";
            }
            if (!values.productCode) {
                errors.productCode = "Required";
            }
            if (!values.productLabelNumber) {
                errors.productLabelNumber = "Required";
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            let value2 = { ...values, productLabelNumber: values.productLabelNumber.trim() }
            delete value2.productName
            updateLabel(value2, resetForm)
        },
    });
    const updateLabel = async (data, resetForm) => {
        let response = await putRequest(configUrl.updateLabelNumber, data)

        if (response.status == 201 || response.status == 200) {
            snackBarContent(true, response.data.message)
            resetForm()
            setProductOptions([])
            getTableData()
        } else if (response.response.data.status == 400 || response.response.data.status == 409) {
            snackBarContent(false, response.response.data.message)
        }
    }

    const handleClose = () => {
        setHistorModal(false);
        setHistoryData({})
        setServiceHistoryData({})
    };

    const handlesnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };


    const columns = React.useMemo(
        () => [
            {
                Header: "SL No.",
                accessor: (row, index) => index + 1,
                Cell: ({ value }) => <span>{value}</span>,
                Filter: ColumnFilter,
            },
            {
                Header: "Product Name",
                accessor: "productName",
                Filter: ColumnFilter,
            },
            {
                Header: "Product Code",
                accessor: "productCode",
                Filter: ColumnFilter,
            },
            {
                Header: "Label Number",
                accessor: "serialNo",
                Filter: ColumnFilter,
            },
            {
                Header: "Status",
                accessor: "productStatusName",
                Filter: ColumnFilter,
                Cell: (cellProps) => {
                    return (
                        <div className="cellStyles">
                            <span className="cell_data" style={{ display: "block" }}>
                                {(cellProps.cell.row.original.productStatus == 2 && cellProps.cell.row.original.isServiceProduct == 0) ? "Out for Service" : <>{cellProps.cell.row.original.productStatusName}</>}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: (tableProps) => (
                    <div>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <button type="button" className="history_btn" onClick={() => onHistoryClick(tableProps)}>
                                <img
                                    src={Images.history_icon}
                                    alt="icon"
                                    className="editIcon"
                                />
                            </button>
                            <button type="button" className="history_btn" onClick={() => onViewClick(tableProps)}>
                                <img
                                    src={Images.editLogo}
                                    alt="icon"
                                    className="editIcon"
                                />
                            </button>
                        </div>
                    </div>
                ),
            },
        ],
        []
    );

    const data = useMemo(() => fieldData, [fieldData]);

    // create a table instance
    const {
        tableInstance,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        usePagination
    );

    const { globalFilter, pageSize, pageIndex } = state;
    return (
        <>
            <div className="text-end">
                <Button
                    variant="primary"
                    onClick={() => {
                        setLabelModal(true);
                    }}>Assign Label Number
                </Button>
            </div>

            <div className="my-3 d-flex justify-content-between">
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

                <div>
                    <select
                        value={pageSize}
                        className="selectTag w-auto"
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        <option value="" disabled>
                            Select
                        </option>
                        {[5, 10, 15, 25, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="text-center mt-3">
                <div className="table_scroll">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="text-center">
                    <TableNavigation pageIndex={pageIndex} gotoPage={gotoPage} previousPage={previousPage}
                        nextPage={nextPage} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} />
                </div>
            </div>

            <SnackBar snackbarOpen={snackOpen} handleClose={handlesnackClose}
                snackbg={success} message={snackMessage} />

            <Modal show={labelModal} onHide={() => {
                setLabelModal(false)
            }}>
                <Modal.Header closeButton>
                    <h5 className="text-primary">Assign Product Label Number</h5>
                </Modal.Header>
                <Modal.Body className="bg-light">

                    <div>
                        <Form
                            onSubmit={formik.handleSubmit}
                            autoComplete="off">
                            <div className="mb-4">
                                {/* <div className="row"> */}
                                <div>
                                    <Selectelement
                                        optionArray={
                                            <>
                                                {products.length > 0 && products.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>
                                                            {item.productName}
                                                        </option>
                                                    );
                                                })}
                                            </>}
                                        select_Label={<>Product Name <strong className="text-danger">*</strong></>}
                                        // id={`productDetails.${index}.productName`}
                                        name="productName"
                                        handleChange={async (e) => {
                                            formik.handleChange(e);
                                            const productId = e.target.value;
                                            const options = await fetchOptionsForProduct(productId);
                                            setProductOptions(options);
                                            // setFieldValue(`productDetails.${index}.productLabel`, '');
                                        }}
                                        handleBlur={formik.handleBlur}
                                        value={formik.values.productName}
                                        formikValidation={
                                            formik.touched.productName &&
                                                formik.errors.productName ? (
                                                <small className="text-danger position-absolute">
                                                    {formik.errors.productName}
                                                </small>
                                            ) : null
                                        }
                                    />
                                </div>
                                <div className="">
                                    <Selectelement
                                        optionArray={
                                            <>
                                                {productOptions.length > 0 && productOptions.map((id, i) => {
                                                    return (
                                                        <option key={i} value={id.productCode}>
                                                            {id.productCode}
                                                        </option>
                                                    );
                                                })}
                                            </>}
                                        select_Label={<>Product code <strong className="text-danger">*</strong></>}
                                        name="productCode"
                                        handleChange={formik.handleChange}
                                        handleBlur={formik.handleBlur}
                                        value={formik.values.productCode}
                                        formikValidation={
                                            formik.touched.productCode &&
                                                formik.errors.productCode ? (
                                                <small className="text-danger position-absolute">
                                                    {formik.errors.productCode}
                                                </small>
                                            ) : null} />
                                </div>
                                <div className="">
                                    <Input_element
                                        input_label={<>Product Label number <strong className="text-danger">*</strong></>}
                                        type="text"
                                        name="productLabelNumber"
                                        handleChange={formik.handleChange}
                                        handleBlur={formik.handleBlur}
                                        value={formik.values.productLabelNumber}
                                        placeholder="Enter label number"
                                        formikValidation={
                                            formik.touched.productLabelNumber &&
                                                formik.errors.productLabelNumber ? (
                                                <small className="text-danger position-absolute">
                                                    {formik.errors.productLabelNumber}
                                                </small>
                                            ) : null
                                        }
                                    />
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="text-end mt-4">
                                <button id="excelclose" className="btn btn-primary mx-2" onClick={() => setLabelModal(false)}>
                                    Close
                                </button>
                                <button id="excelsubmitbtn" className="btn btn-success" type="submit">
                                    Submit
                                </button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body >
            </Modal >

            <Modal show={historyModal} size="lg" onHide={() => {
                handleClose()
            }}>
                <Modal.Header closeButton>
                    {/* <h5 className="text-primary"></h5> */}
                </Modal.Header>
                <Modal.Body className="history_data">
                    <Tabs defaultActiveKey="tab1" id="my-tabs" className='profile_tab'>
                        <Tab eventKey="tab1" className='font_color' title="Assigned History">
                            <div className="my-4">
                                {((historyData.assigned && historyData.assigned.length > 0) || (historyData.instock && historyData.instock.length > 1)) ? <>
                                    {historyData.assigned && historyData.assigned.length > 0 ?
                                        <div className="mb-4">
                                            <h6 className="font_color text-decoration-underline">Currently Assigned details</h6>

                                            {historyData.assigned.map((item, i) => {
                                                return (<>
                                                    <div className="" key={i}>
                                                        <div className="">
                                                            <p className="mb-0">Employee Name : <strong>{item.employeeName}</strong></p>
                                                        </div>
                                                        <div className="">
                                                            <p className="mb-0">Employee Id : <strong>{item.employeeID}</strong></p>
                                                        </div>
                                                        <div className="">
                                                            <p className="mb-0">Date of Assign : <strong>{item.dateOfAssign}</strong></p>
                                                        </div>
                                                    </div>
                                                </>)
                                            })}
                                        </div> : null}
                                    {historyData.instock && historyData.instock.length > 1 ?
                                        <div>
                                            <h6 className="font_color text-decoration-underline">Previously Assigned details</h6>
                                            <div className="row">
                                                <div className="col-md-3"><p className="text-center font_color mb-2">Employee Name</p></div>
                                                <div className="col-md-3"><p className="text-center font_color mb-2">Employee Id</p></div>
                                                <div className="col-md-3"><p className="text-center font_color mb-2">Date of Assign</p></div>
                                                <div className="col-md-3"><p className="text-center font_color mb-2">Date of Return</p></div>
                                            </div>
                                            {historyData.instock.map((item, i) => {
                                                if (item.employeeName != "") {
                                                    return (<>
                                                        <div className="row">
                                                            <div className="col-md-3"><p className="text-center font_color mb-0">{item.employeeName}</p></div>
                                                            <div className="col-md-3"><p className="text-center font_color mb-0">{item.employeeID}</p></div>
                                                            <div className="col-md-3"><p className="text-center font_color mb-0">{item.dateOfAssign}</p></div>
                                                            <div className="col-md-3"><p className="text-center font_color mb-0">{item.dateOfReturn}</p></div>
                                                        </div>
                                                    </>)
                                                }
                                            })}
                                        </div> : null}</> : <>
                                    <h6>No Data</h6></>}
                            </div>
                        </Tab>
                        <Tab eventKey="tab2" className='font_color' title="Servicing History">
                            <div className="my-4">
                                {((serviceHistoryData.sentToService && serviceHistoryData.sentToService.length > 0) || (serviceHistoryData.previouslyServiced && serviceHistoryData.previouslyServiced.length > 0)) ? <>
                                    {serviceHistoryData.sentToService && serviceHistoryData.sentToService.length > 0 ?
                                        <div className="mb-4">
                                            <h6 className="font_color text-decoration-underline">Currently Service Assigned details</h6>

                                            {serviceHistoryData.sentToService.map((item, i) => {
                                                return (<>
                                                    <div className="" key={i}>
                                                        <div className="">
                                                            <p className="mb-0 font_color">Vendor Name : <strong>{item.vendorName}</strong></p>
                                                        </div>
                                                        <div className="">
                                                            <p className="mb-0 font_color">Date of Assign : <strong>{item.assignDate}</strong></p>
                                                        </div>
                                                    </div>
                                                </>)
                                            })}
                                        </div> : null}
                                    {serviceHistoryData.previouslyServiced && serviceHistoryData.previouslyServiced.length > 0 ?
                                        <div>
                                            <h6 className="font_color text-decoration-underline">Previously Assigned details</h6>
                                            <div className="row">
                                                <div className="col-md-3"><p className="text-center font_color mb-2">Vendor Name</p></div>
                                                <div className="col-md-3"><p className="text-center font_color mb-2">Date of Assign</p></div>
                                                <div className="col-md-3"><p className="text-center font_color mb-2">Date of Return</p></div>
                                                <div className="col-md-3"><p className="text-center font_color mb-2">Service Status</p></div>
                                            </div>
                                            {serviceHistoryData.previouslyServiced.map((item, i) => {
                                                return (<>
                                                    <div className="row">
                                                        <div className="col-md-3"><p className="text-center font_color mb-0">{item.vendorName}</p></div>
                                                        <div className="col-md-3"><p className="text-center font_color mb-0">{item.assignDate}</p></div>
                                                        <div className="col-md-3"><p className="text-center font_color mb-0">{item.dateOfReturn}</p></div>
                                                        <div className="col-md-3"><p className="text-center font_color mb-0">{item.serviceStatus}</p></div>
                                                    </div>
                                                </>)
                                            })}
                                        </div> : null}</> : <>
                                    <h6 className="font_color">No Data</h6></>}
                            </div>
                        </Tab>
                    </Tabs>

                </Modal.Body >
            </Modal >
        </>
    )
}
