import React, { useEffect, useRef, useState } from 'react';
import OrgChart from '@dabeng/react-orgchart';
import './organisationteam.scss'
import { Button, Card, Modal } from 'react-bootstrap';
import { PostRequestHook } from '../../apis/Services';
import { useSelector } from 'react-redux';
import { configUrl } from '../../apis/api.config';

const OrgChartWithTooltips = () => {
    const [clickedNode, setClickedNode] = useState(null);
    const [treedata, setTreeData] = useState(null)
    const [emptreedata, setEmpTreeData] = useState(null)
    const [matrixModal, setMatrixModal] = useState(false)
    const [matrixTreeData, setMatrixTreeData] = useState(null)
    const [commonTreeData, seCommonTreeData] = useState([])

    const { getRequest } = PostRequestHook()
    const { userInfo } = useSelector(state => state.UIStore)
    var organization_name = userInfo ? userInfo.organization_name : null
    var emp_id = userInfo ? userInfo.emp_id : null
    var role = userInfo ? (userInfo.role).trim().replace(/\s+/g, "_").toLowerCase() : null


    // const orgChartRef = useRef(null);

    useEffect(() => {
        // getTreeData()
        getEmployeeList()
    }, [])

    const buildTree = (data, parentId) => {
        try {
            const children = data.filter(item => item.reporting_person === parentId);

            if (children.length === 0) {
                return null;
            }

            return children.map(child => {
                const node = {
                    ...child,
                    children: buildTree(data, child.employee_id)
                };

                if (!node.children) {
                    node.children = [];
                }

                return node;
            });
        } catch (err) {

        }
    }


    const getEmployeeList = async () => {
        var response = await getRequest(configUrl.employeelist)
        // setEmployeeData(response?.data?.data)
        const treeData1 = buildTree(response?.data?.data, '');
        seCommonTreeData(treeData1 ? treeData1 : [])
        var sortedTreeData = (treeData1 ? treeData1 : []).filter(item => ((item.role_name).trim().replace(/\s+/g, "_").toLowerCase()) == "super_admin")
        var organizationTreeData = {
            "name": `${organization_name.replace(/_/g, ' ')}`,
            "emp_id": "",
            "children": sortedTreeData ? sortedTreeData : []
        }
        setTreeData(organizationTreeData)
        const empTree = findEmployeeWithHierarchy(treeData1 ? treeData1 : [], emp_id);
        var employeeTreeData = {
            "name": `${organization_name.replace(/_/g, ' ')}`,
            "emp_id": "",
            "children": empTree ? [empTree] : []
        }
        setEmpTreeData(employeeTreeData)
    }

    function findEmployeeWithHierarchy(data, empId) {
        for (const item of data) {
            if (item.employee_id === empId) {
                return item;
            }

            if (item.children) {
                const result = findEmployeeWithHierarchy(item.children, empId);
                if (result) {
                    return {
                        ...item,
                        children: [result]
                    };
                }
            }
        }

        return null;
    }



    // const getTreeData = async () => {
    //     var response = await getRequest(configUrl.newOrganisationTreeData)
    //     // console.log("🚀 ~ file: Reactd3tree.js:25 ~ getTreeData ~ response:", response?.data?.data)
    //     if (response) {
    //         var organizationTreeData = {
    //             "name": `${organization_name.replace(/_/g, ' ')}`,
    //             "emp_id": "",
    //             "children": response?.data?.data ? (Array.isArray(response?.data?.data) ? response?.data?.data : [response?.data?.data]) : []
    //         }
    //         setTreeData(organizationTreeData)
    //     }
    // }

    const handleNodeClick = (nodeData) => {
        setClickedNode(nodeData);
        if (clickedNode && clickedNode.employee_id === nodeData.employee_id) {
            setClickedNode(null);
        } else {
            setClickedNode(nodeData);
        }
    };

    // const flattenData = (node) => {
    //     const flattenNodes = [];
    //     flattenNodes.push(node);
    //     if (node.children) {
    //         node.children.forEach(child => {
    //             flattenNodes.push(...flattenData(child));
    //         });
    //     }
    //     return flattenNodes;
    // };

    const handleMatrixTree = (matrix_id) => {
        const matrixTree = findEmployeeWithHierarchy(commonTreeData, matrix_id);
        var _matrixTree = {
            "name": `${organization_name.replace(/_/g, ' ')}`,
            "emp_id": "",
            "children": matrixTree ? [matrixTree] : []
        }
        setMatrixTreeData(_matrixTree)
        setMatrixModal(true)
        setClickedNode(null)
    }

    const renderNode = ({ nodeData }) => {
        try {
            const isClickedNode = clickedNode && clickedNode.employee_id === nodeData.employee_id;

            return (
                <div className={`custom-node ${isClickedNode ? 'active' : ''}`} id={nodeData.reporting_person_id}>
                    <div
                        className='node-content'>
                        <p className='emp_name'>{`${nodeData.employee_id ? `${nodeData.employee_id} - ` : ""}${nodeData.name ? nodeData.name : ""}`}</p>
                        <div className='emp_designation'>
                            <p className='mb-0'>{`Designation - ${nodeData.designation_name ? nodeData.designation_name : ""}`}</p></div>
                        <div className='position-relative'>
                            {nodeData.matrix_manager_information && nodeData.matrix_manager_information.length > 0 &&
                                <button id={`matrix${nodeData.employee_id}`} className='matrix_btn'
                                    onClick={() => handleNodeClick(nodeData)}
                                >{`+${nodeData.matrix_manager_information.length} matrix manager`}</button>
                            }
                            {isClickedNode && (
                                <div className="tooltip tooltip_design p-0">
                                    <Card className='border-0'>
                                        <Card.Body className='p-2'>
                                            {nodeData.matrix_manager_information &&
                                                nodeData.matrix_manager_information.length > 0 &&
                                                <>
                                                    <div><strong>Matrix Managers :</strong></div>
                                                    <div>
                                                        {nodeData.matrix_manager_information.map((item, index) => {
                                                            return (
                                                                <div className='d-flex align-items-center my-1'>
                                                                    <div>
                                                                        <img id={`img${item.employee_id}`} src="https://eitrawmaterials.eu/wp-content/uploads/2016/09/person-icon.png" className='employee_img' alt="icon" />
                                                                    </div>
                                                                    <div>
                                                                        <p className='m-0'> {`${item.employee_id}-${item.name}`}</p>
                                                                        <button id={`matrixbtn${item.employee_id}`} className='matrixmodal_btn' onClick={() => handleMatrixTree(item.employee_id)}>
                                                                            click here to view Org tree</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </>


                                            }
                                            <div className='text-end'>
                                                <button id='closenode' className='matrix_btn border' onClick={() => {
                                                    setClickedNode(null)
                                                }}>Close</button>
                                            </div>
                                            {/* <Button variant="primary" className='mt-2' onClick={() => {
                                            setClickedNode(null)
                                        }}>Close</Button> */}
                                        </Card.Body>
                                    </Card>
                                </div>
                            )}
                        </div>
                    </div>


                </div>
            );
        } catch (err) { }
    };

    return (
        <>
            <div>
                {treedata &&
                    <OrgChart
                        datasource={(role == "super_admin" || role == "hr") ? treedata : emptreedata}
                        pan={true}
                        zoom={true}
                        collapsible={false}
                        NodeTemplate={renderNode}
                    />}
            </div>
            <Modal
                centered
                size="lg"
                show={matrixModal}
                onHide={() => {
                    setMatrixModal(false)
                    setMatrixTreeData(null)
                }}>
                {/* <Modal.Header closeButton>
                </Modal.Header> */}
                <Modal.Body className="bg-light">
                    {/* <h5 className="text-primary">Details</h5> */}
                    <div className="mt-4">
                        {matrixTreeData &&
                            <OrgChart
                                datasource={matrixTreeData}
                                pan={true}
                                zoom={true}
                                collapsible={false}
                                NodeTemplate={renderNode}
                            />}
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <button id='closemodal' className="btn btn-primary" onClick={() => {
                        setMatrixModal(false)
                        setMatrixTreeData(null)
                    }}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default OrgChartWithTooltips;
